import { TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
	CellClickedEvent,
	ColDef,
	GridApi,
	GridOptions,
	GridReadyEvent,
} from 'ag-grid-community';
import { writeFile, utils } from 'xlsx';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { Calendar } from 'primeng/calendar';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { EstatusTableOrderAdminComponent } from '../../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../../date-text/date-text.component';
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { DialogOrderDetailComponent } from '../../dialog-order-detail/dialog-order-detail.component';
import { DialogDeliverPersonallyComponent } from '../../dialog-deliver-personally/dialog-deliver-personally.component';
import { Component, ViewChild } from '@angular/core';

@Component({
	selector: 'app-table-general',
	templateUrl: './table-general.component.html',
	styleUrls: ['./table-general.component.scss'],
})
export class TableGeneralComponent {
	// TABLE SETTINGS
	tableClass: string = 'table-navbar';
	calendarTable: string = '';
	btnExport: string = '';
	filterFunnel: string = '';
	// AG GRID SETTINGS
	@ViewChild(AgGridAngular) agGrid!: AgGridAngular;
	public columnDefsGeneral: ColDef[];
	private gridApi: GridApi;
	public rowDataGeneral: any[];
	public defaultColDefGeneral: ColDef = {
		sortable: true,
		filter: true,
	};
	rowData: any[];
	// LANGUAGES
	supportLanguages = ['es', 'en'];
	// CALENDAR
	@ViewChild('calendar') calendar: Calendar;
	InitialDate = new Date();
	FinalDate = new Date();
	rangeDates: Date[];
	minDate: Date;
	maxDate: Date;

	completedDataCompleted = [];
	dataTemporal = [];
	res;
	countCompleted = 0;
	display = false;
	orderSelectToExtendDate: any;
	displayDialogOrder = false;
	idLocker: string;

	// FILTER SETTINGS
	public filteredData: any[] = [];
	filterText: string = '';
	gridOptions: GridOptions;

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	constructor(
		private translate: TranslateService,
		private adminService: AdminClientService,
		private toastr: ToastrService,
		private matdialog: MatDialog
	) {
		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
		translate.setTranslation(
			'en',
			require('../../../../../../assets/languaje/en.json')
		);
		translate.setTranslation(
			'es',
			require('../../../../../../assets/languaje/es.json')
		);
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant(
						'table-general.tracking'
					),
					field: 'tracking',
					sortable: true,
					autoHeight: true,
					width: 170,
					cellStyle: {
						'font-family': 'Inter',
						color: '#FFFFFF !important',
						cursor: 'pointer',
						'text-decoration': 'underline',
						'text-transform': 'capitalize',
						'font-size': '14px',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
					onCellClicked: ($event) => this.openModalUserDetail($event),
				},
				{
					headerName: this.translate.instant(
						'table-general.lockerID'
					),
					field: 'lockerID',
					sortable: true,
					autoHeight: true,
					width: 144,
					cellStyle: {
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-general.email'),
					field: 'email',
					sortable: true,
					autoHeight: true,
					width: 270,
					cellStyle: {
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-general.employeeName'
					),
					field: 'nameEmploye',
					sortable: true,
					autoHeight: true,
					width: 115,
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
						'justify-content': 'center',
						'align-items': 'center',
						display: 'flex',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-general.employeeID'
					),
					field: 'employeeId',
					sortable: true,
					autoHeight: true,
					width: 130,
					cellStyle: {
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-general.doorNumber'
					),
					field: 'doorNumber',
					sortable: true,
					autoHeight: true,
					width: 100,
					cellStyle: {
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-general.status'),
					field: 'status',
					sortable: true,
					autoHeight: true,
					width: 123,
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
						'justify-content': 'center',
						'align-items': 'center',
						display: 'flex',
					},
					cellRenderer: EstatusTableOrderAdminComponent,
					onCellClicked: ($event) => this.openCalendar($event),
				},
				{
					headerName: this.translate.instant(
						'table-general.inLocker'
					),
					field: 'inLocker',
					sortable: true,
					autoHeight: true,
					width: 121,
					cellStyle: {
						'text-align': 'center',
					},
					cellRenderer: DateTextComponent,
				},
				{
					headerName: this.translate.instant(
						'table-general.dateCollected'
					),
					field: 'dateCollected',
					sortable: true,
					autoHeight: true,
					width: 117,
					cellStyle: {
						'text-align': 'center',
					},
					cellRenderer: DateTextComponent,
				},
				{
					headerName: this.translate.instant(
						'table-general.rateStart'
					),
					field: 'rate_start',
					sortable: true,
					autoHeight: true,
					width: 146,
					cellRenderer: StarRatingComponent,
				},
				{
					headerName: '...',

					// sortable:true,
					// autoHeight: true,
					width: 50,
					onCellClicked: ($event) =>
						this.openModalDeliverPersonally($event),
					cellRenderer: function (params) {
						return '<i class="fas fa-box"></i>';
					},
				},
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			overlayNoRowsTemplate:
				'<span style="padding: 10px; border: 1px solid #ccc;">No hay registros</span>',
		}
		this.minDate = new Date();
		this.minDate.setDate(this.minDate.getDate() + 1); // día mínimo es mañana
		this.maxDate = new Date();
		this.maxDate.setDate(this.maxDate.getDate() + 2); // día máximo es pasado mañana

		let today = new Date();
		let month = today.getMonth();
		let year = today.getFullYear();
		let day = today.getDate();
		let lastMonth;
		let endDate;
		let startDate;
		if (month < 0) {
			month = 11;
			year -= 1;
		}

		lastMonth = new Date(year, month - 1, day);
		endDate = new Date(year, month, day);
		startDate = new Date(year, lastMonth.getMonth(), day);
		this.rangeDates = [startDate, endDate];
		this.InitialDate = this.rangeDates[0];
		this.FinalDate = this.rangeDates[1];

		this.addEventRangeDates();
	}

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
	}

	onCellClicked(e: CellClickedEvent): void {
		if (e.colDef.field === 'status') {
			if (
				e.data.status_package === 14 &&
				e.data.id_status_code_package === 32
			) {
				this.display = true;
				this.orderSelectToExtendDate = e.data;
			} else {
				this.display = false;
			}
		}
	}

	openCalendar(e: CellClickedEvent) {
		if (
			e.data.status_package === 14 &&
			e.data.id_status_code_package === 32
		) {
			this.display = true;
			this.orderSelectToExtendDate = e.data;
		} else {
			this.display = false;
		}
	}

	async getTableCompleted() {
		this.rowData = [];

		this.idLocker = localStorage.getItem('id_locker');

		if (this.idLocker === null) {
			this.completedDataCompleted = [];
			let lang = localStorage.getItem('language').toLowerCase();
			let company = localStorage.getItem('id_company');
			let data = {
				language: lang,
				id_company: company,
				date_start: this.InitialDate.getTime(),
				date_end: this.FinalDate.getTime(),
			};
			this.adminService.getAllOrders(data).then((res:any) => {
				res.return_result.map((f) => {
						this.completedDataCompleted.push({
							order_number: f.order_number,
							tracking: f.tracking_number,
							email: f.email,
							lockerID: f.locker_name,
							phone_used: f.phone_used,
							nameEmploye: f.delivery_employee_name,
							door: f.door_number,
							compartmentSize: f.compartment_size,
							status: f.name_status_package,
							inLocker: f.package_delivery_date,
							dateCollected:
								f.status_package == 16
									? f.package_pickup_date
									: '',
							rate_start: f.rate_start,
							id_package: f.id_package,
							id_package_code: f.id_package_code,
							employeeId: f.delivery_company_employee_id,
							carrier: 'N/A',
							doorNumber:
								f.door_number + ',' + f.compartment_name,
							doorSize: f.compartment_name,
							id_status_code_package: f.id_status_code_package,
							status_package: f.status_package,
							qr_order: f.qr_order,
							name_status_notification:
								f.name_status_notification,
							expiration_date: f.expiration_date,
							extended_package: f.extended_package,
							resultNotification: f.result_notification,
							IMAGE: f.IMAGE,
						});
						this.rowData = this.completedDataCompleted;
				});
			}).catch((error) => {
				console.log(error);
			});
		} else if (this.idLocker !== null) {
			this.completedDataCompleted = [];
			let lang = localStorage.getItem('language').toLowerCase();
			let company = localStorage.getItem('id_company');
			let data = {
				language: lang,
				id_locker: this.idLocker,
				id_company: parseInt(company),
				date_start: this.InitialDate.getTime(),
				date_end: this.FinalDate.getTime(),
			};
			this.adminService
				.getOrders(data)
				.then((res) => {
					if (res.mensaje_return.ERROR_MENSAGGE === 'NO EXISTEN REGISTROS') {
						this.rowData = [];
					}
					else {
						res.return_result[0][1].map((f) => {
							this.completedDataCompleted.push({
								order_number: f.order_number,
								tracking: f.tracking_number,
								email: f.email,
								lockerID: f.locker_name,
								phone_used: f.phone_used,
								nameEmploye: f.delivery_employee_name,
								door: f.door_number,
								compartmentSize: f.compartment_size,
								status: f.name_status_package,
								inLocker: f.package_delivery_date,
								dateCollected: f.status_package == 16 ? f.package_pickup_date : '',
								rate_start: f.rate_start,
								id_package: f.id_package,
								id_package_code: f.id_package_code,
								employeeId: f.delivery_company_employee_id,
								carrier: 'N/A',
								doorNumber:	f.door_number + ',' + f.compartment_name,
								doorSize: f.compartment_name,
								id_status_code_package: f.id_status_code_package,
								status_package: f.status_package,
								qr_order: f.qr_order,
								name_status_notification: f.name_status_notification,
								expiration_date: f.expiration_date,
								extended_package: f.extended_package,
								resultNotification: f.result_notification,
								IMAGE: f.IMAGE,
							});
							this.rowData = this.completedDataCompleted;
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	showDate: Date;
	showDateFinal: Date;
	fechasByMonth: string;

	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
			this.getTableCompleted().catch((err) => {
				console.log(err);
			});
		}
	}

	DownloadExcel() {
		const dayInitial =
			this.InitialDate.getDate() < 10
				? '0' + this.InitialDate.getDate()
				: this.InitialDate.getDate();
		const monthInitial =
			this.InitialDate.getMonth() + 1 < 10
				? '0' + (this.InitialDate.getMonth() + 1)
				: this.InitialDate.getMonth() + 1;
		const yearInitial = this.InitialDate.getFullYear();
		const dayFinal =
			this.FinalDate.getDate() < 10
				? '0' + this.FinalDate.getDate()
				: this.FinalDate.getDate();
		const monthFinal =
			this.FinalDate.getMonth() + 1 < 10
				? '0' + (this.FinalDate.getMonth() + 1)
				: this.FinalDate.getMonth() + 1;
		const yearFinal = this.FinalDate.getFullYear();
		let headerExcel = [
			'tracking',
			'lockerID',
			'email',
			'employeeID',
			'nameEmploye',
			'inLockerDate',
			'inLockerTime',
			'dateCollectedDate',
			'dateCollectedTime',
			'rate_start',
		];
		let dataExcel = [];
		this.completedDataCompleted = this.completedDataCompleted.filter(
			(e: any) => {
				let info = {
					tracking: e.tracking,
					lockerID: e.lockerID,
					email: e.email,
					employeeID: e.employeeId,
					nameEmploye: e.nameEmploye,
					inLockerDate: e.inLocker.slice(0, 10),
					inLockerTime: e.inLocker.slice(11, 19),
					dateCollectedDate: e.dateCollected.slice(0, 10),
					dateCollectedTime: e.dateCollected.slice(11, 19),
					rate_start: e.rate_start,
				};
				dataExcel.push(info);
				return info; // Return the filtered result
			}
		);
		const worksheet = utils.json_to_sheet(dataExcel, {
			header: headerExcel,
		});
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Data');

		let nameFile =
			'Orders' +
			dayInitial +
			monthInitial +
			yearInitial +
			'_' +
			dayFinal +
			monthFinal +
			yearFinal +
			'.xlsx';
		const excelBuffer = writeFile(workbook, nameFile, { type: 'buffer' });
	}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}

	async extenderFechas(calendar) {
		let dateSelect = new Date(calendar.value);
		let month =
			dateSelect.getMonth().toString().length == 1
				? '0' + (dateSelect.getMonth() + 1)
				: dateSelect.getMonth() + 1;
		let day =
			dateSelect.getDate().toString().length == 1
				? '0' + dateSelect.getDate()
				: dateSelect.getDate();
		let d = dateSelect.getFullYear() + '-' + month + '-' + day;
		let dataSendUpdate = {
			language: localStorage.getItem('language').toString().toLowerCase(),
			id_company: localStorage.getItem('id_company'),
			id_package_code: this.orderSelectToExtendDate.id_package_code,
			date_update: d,
		};
		let res: any = await this.adminService.updateExpirationDate(
			dataSendUpdate
		);
		if (res.mensaje_return) {
			this.display = false;
			this.toastr.success(
				'la entrega santifactoriamente',
				'Se ha extendindo',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		}
		this.getTableCompleted().catch((err) => {
			console.log(err);
		});
	}

	openModalUserDetail(event) {
		const dialogRef = this.matdialog.open(DialogOrderDetailComponent, {
			width: '1304px',
			height: '952px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {});
	}

	openModalDeliverPersonally(event) {
		const dialogRef = this.matdialog.open(
			DialogDeliverPersonallyComponent,
			{
				width: '558px',
				height: '270px',
				data: event.data,
			}
		);

		dialogRef.afterClosed().subscribe((result) => {});
	}

	cerrarExtender() {
		this.display = false;
		this.orderSelectToExtendDate = [];
	}

	onExport() {
		const fileName = 'OrderGeneral.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'OrdersGeneral',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
}
