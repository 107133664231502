import { Component, Input } from '@angular/core';
import { arrayAlert } from 'src/app/core/models/shared/alerts.interface';

@Component({
	selector: 'alert-message',
	templateUrl: './alert-message.component.html',
	styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent {
	/*
    type: 'error | success'
    title:'my title';
    description:'my description';
    */
	@Input() arrayAlert: arrayAlert[] = [];
}
