import { Component } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { WebhookService } from 'src/app/core/http/webhook.service';
import { DataIntegrationsTable, tableHeader } from 'src/app/core/models';
import { QueryWebhook } from 'src/app/core/models/webhook/webhook.interface';
import { Clipboard } from '@angular/cdk/clipboard';
import { SystemService } from 'src/app/core/http/system.service';

@Component({
	selector: 'app-integrations',
	templateUrl: './integrations.component.html',
	styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent {
	showApiText:boolean=false;
	showTooltipApiValue:boolean=false;
	dateCreationApi;
	statusApi;
	apiText;
	urlDocumentation:string;
	linkTxt:string = 'integrations.events-webhook.linkTxt'
	jsonString1 = `
	{
		"EVENT_DATE":1672699329625,
		"END_DATE_EVENT":1672699329628,
		"REMOTE_ADDRESS":"189.218.1.233",
		"ROUTE":{
			"URL":"/v1/server/locker/query-locker?leng=es&locker_code=",
			"METHODS":{
				"get":true }
		},
		"DATA_RECEIVED":{
			"leng":"es",
			"locker_code":""
		}
	}
	`;

	jsonString2 = `
	{
		"mensaje_return":{
			"ERROR":false,
			"CODE":200,
			"ERROR_MESSAGE":"Éxito"
		},
		"data_locker":{
			"code_locker":252423,
			"compartment_quantity":41,
			"compartment_available":37,
			"occupied_compartment_quantity":0,
			"schedule_compartment_quantity":2,
			"description":"Locker de VIVILOCK",
			"coordinates":{
				"latitud":"25.7426451",
				"longitud":"-100.2169843"
			}
		}
	}
	`;

	jsonString3 = `
	{
		"mensaje_return":{
			"ERROR":false,
			"CODE":200,
			"ERROR_MESSAGE":"Éxito"
		},
		"data_locker":{
			"code_locker":252423,
			"compartment_quantity":41,
			"compartment_available":37,
			"occupied_compartment_quantity":0,
			"schedule_compartment_quantity":2,
			"description":"Locker de VIVILOCK",
			"coordinates":{
				"latitud":"25.7426451",
				"longitud":"-100.2169843"
			}
		}
	}
	`;
	pageLenght: number = 3;

	public dataConfig:DataIntegrationsTable[] = [
		{
		name:'API_VIVIPOST',
		date:'2 de Abril de 2023',
		statusApi:'suscrito',
		Api:'********',
		production:'Solicitar pase a producción'
		},
	];


	thConfig:tableHeader[] = [
		{
		th:'integrations.table.name',
		filtro:'name',
		route:'/integrations'
		},
		{
		th:'integrations.table.date',
		filtro:'date',
		},
		{
		th:'integrations.table.apiStatus',
		filtro:'statusApi',
		btn:true,
		},
		{
		th:'API',
		filtro:'Api',
		tooltipInfo:'Bla bla bli blu blu'
		},
		{
		th:'',
		filtro:'production',
		route:'/integrations'
		},
	]

	constructor(
		private translate: TranslateService,
		private integrationService: WebhookService,
		private clipboard: Clipboard,
		private systemService: SystemService
	){}
	ngOnInit(){

		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.getUrl();
		this.translate.onLangChange.subscribe( (event:LangChangeEvent)=>{
			this.translate.use(event.lang);
		});
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}

	getUrl(){
		let obj ={
			language: localStorage.getItem('language').toLowerCase()
		}
		this.integrationService.getApiKey(obj).then((res:any)=>{
			let dateElement = new Date(res.return_data_api_key.registration_date);
				this.dateCreationApi =  dateElement.toLocaleDateString("es-MX",{
					day: 'numeric',
					month: 'long',
					year: 'numeric'
				})
			this.statusApi = res.return_data_api_key.id_status;
			this.apiText = res.return_data_api_key.pk_api;
			// res.return_data_api_key.forEach(element => {
			// 	let dateElement = new Date(element.registration_date);
			// 	this.dateCreationApi =  dateElement.toLocaleDateString("es-MX",{
			// 		day: 'numeric',
			// 		month: 'long',
			// 		year: 'numeric'
			// 	})
			// 	this.statusApi = element.id_status;
			// 	this.apiText = element.pk_api;
			// });
		})

		this.systemService.queryCourierApiDocumentation(obj).then((res:any)=>{
			this.urlDocumentation = res.url_api_courrier;
		})
	}
	openLink(){
		window.open(this.urlDocumentation, "_blank");
	}
	copiarUrl(){
		this.clipboard.copy(this.urlDocumentation);
	}
	copiarApi(){
		this.clipboard.copy(this.apiText);
	}
	showApi(){
		this.showApiText = !this.showApiText;
	}
	showTooltipApi(){
		this.showTooltipApiValue = true;
	}
	hideTooltipApi(){
		this.showTooltipApiValue = false;
	}
}
