import { Component } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { AdminClientService } from 'src/app/core/http/admin-client.service';

@Component({
	selector: 'app-register-admin',
	templateUrl: './register-admin.component.html',
	styleUrls: ['./register-admin.component.scss'],
})
export class RegisterAdminComponent {
	//Esto permite que el invalid tooltip no se muestre al inicio
	submitted = false;
	sectors: any = [];
	propiedades: any = [];
	units: any = [];

	miRegistro: FormGroup = this.fb.group({
		firstName: [
			'',
			[
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(10),
			],
		],
		sector: ['', [Validators.required]],
		propiedad: ['', [Validators.required]],
		unidad: ['', [Validators.required]],
		lastName: [
			'',
			[
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(10),
			],
		],
		phoneNumber: [
			'',
			[
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(10),
			],
		],
		email: ['', [Validators.required]],
	});

	constructor(
		private router: Router,
		private fb: FormBuilder,
		private adminService: AdminClientService
	) {}

	async getSector() {
		this.propiedades = [];
		this.units = [];
		let body: object = {
			language: localStorage.getItem('language'),
		};

		let res: any = await this.adminService.getSectors(body);

		if (!res.mensaje_return.ERROR) {
			this.sectors = res.return_sectors;
		}
	}

	async getPropierty(select: MatSelectChange) {
		this.units = [];
		this.propiedades = [];

		let body: object = {
			language: localStorage.getItem('language'),
			id_propierty: select.value,
		};

		let res: any = await this.adminService.getPropierty(body);

		if (!res.mensaje_return.ERROR) {
			this.propiedades = res.return_propierty_unit;
		}
	}

	async getUnit(select: MatSelectChange) {
		this.units = [];
		let body: object = {
			language: localStorage.getItem('language'),
			id_propierty_unit: select.value,
		};

		let res: any = await this.adminService.getUnit(body);

		if (!res.mensaje_return.ERROR) {
			this.units = res.return_unit;
		}
	}

	ngOnInit(): void {
		this.getSector().catch((err) => {
			console.log(err);
		});
	}

	//SUBMIT ------------------------->
	async submitRegistro() {
		//Esto permite que el invalid tooltip no se muestre al inicio
		this.submitted = true;

		const { firstName, lastName, phoneNumber, email, unidad } =
			this.miRegistro.value;

		if (this.miRegistro.invalid) {
			// En dado caso de que quiere hacer un submit, esto marcara todos los inputs y mostrara los errores
			this.miRegistro.markAllAsTouched();
			return;
		}

		let body: object = {
			language: localStorage.getItem('language'),
			first_name: firstName,
			surname: lastName,
			main_tlf: phoneNumber,
			email: email,
			id_unit: unidad,
			alia: email.split('@')[0],
			register_admin: true,
		};

		/*
		 * En este punto se deberia poder utilizar el servicio de Auth para registrar al cliente
		 */

		let rest: any = await this.adminService.registerConsumerAccount(body);
		if (!rest.mensaje_return.ERROR) {
			/* ADD:
            Falta la interfaz donde se le muestra al cliente que se registro todo con exito,
            Que espere el EMAIL que le llegara para confirmar su registro,
            este paso lo revisamos luego para continuar.      */

			this.miRegistro.reset();
		} else {
			/* ADD:
            Falta mostrar un error si el back devuelve como TRUE rest.mensaje_return.ERROR
            Normalmente el back manda un mensaje, seria cuestion de ver los mensajes y ver si mostrarlo o
            mostrarle al cliente un error agradable como ejem: "Falla al intentar el registro, intente mas tarde"
      */
			this.miRegistro.reset();
		}

		/*
		 * Se consume el Endpoint para poder registrar al usuario
		 */

		// Aquí quito los invalid tooltip si la validación es correcta
		this.submitted = false;
		// Aquí borro los valores si la validación es correcta
		//this.miRegistro.reset();
		this.back();
	}

	//BACK BUTTON ------------------------->
	back() {
		this.router.navigateByUrl('l-retail/clients').catch((err) => {
			console.log(err);
		});
	}

	// VALIDATOR TOOLTIP -------------------------------------------------------->
	invalidForm(campo: string) {
		return (
			this.miRegistro.get(campo)?.invalid &&
			this.miRegistro.get(campo)?.touched
		);
	}
}
