
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TableUserService } from 'src/app/core/http/user.service';
import { SupervisoresInterface } from 'src/app/core/models/users.interfaces';
import * as XLSX from 'xlsx';
import { EstatusTableOrderAdminComponent } from '../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../date-text/date-text.component';
import { UserDetailModalComponent } from '../user-detail-modal/user-detail-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
import { EstatusTableAccessPointComponent } from '../estatus-table-access-point/estatus-table-access-point.component';
@Component({
	selector: 'app-table-access-point-family',
	templateUrl: './table-access-point-family.component.html',
	styleUrls: ['./table-access-point-family.component.scss'],
})
export class TableAccessPointFamilyComponent implements OnInit, OnChanges {
	valueInput;
	filterText: string = '';
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	supervisors: SupervisoresInterface[];
	language: string;
	public columnDefs: ColDef[];
	@Input() rowData: any[] = [];

	constructor(
		private translate: TranslateService,
		private userService: TableUserService,
		private dialog: MatDialog,
		private http: HttpClient,
		private router: Router
	) {}
	ngOnChanges(changes: SimpleChanges){
		this.loadData();
	}

	ngOnInit() {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataUser();
		this.loadData();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.language = event.lang;
			this.translate.use(event.lang);
			this.translate.get([
					'accessPointFamily.table.lockerID',
					'accessPointFamily.table.serialNumber',
					'accessPointFamily.table.hierachy',
					'accessPointFamily.table.addedBy',
					'accessPointFamily.table.dattedAdded',
					'accessPointFamily.table.status'
				])
				.subscribe((t: any) => {
					this.columnDefs = [
						{
							headerName: t['accessPointFamily.table.lockerID'],
							field: 'locker_id',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer
						},
						{
							headerName: t['accessPointFamily.table.serialNumber'],
							field: 'serial_number',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer
						},
						{
							headerName: t['accessPointFamily.table.hierachy'],
							field: 'hierarchy',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer
						},
						{
							headerName: t['accessPointFamily.table.doors'],
							field: 'total_doors',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer
						},
						{
							headerName: t['accessPointFamily.table.addedBy'],
							field: 'configured_by',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer
						},
						{
							headerName: t['accessPointFamily.table.dattedAdded'],
							field: 'registration_date',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: DateTextTablesComponent
						},
						{
							headerName: t['accessPointFamily.table.status'],
							field: 'id_status',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: EstatusTableAccessPointComponent
						}

					];
				});
		});
	}

	loadData() {
		this.supervisors = [];
		console.log(this.rowData);
		// let data = {
		// 	language: localStorage.getItem('language').toLowerCase(),
		// };
		// this.http.get('../../../../../assets/dummy/propertiesTable.json').toPromise().then((res:any)=>{
		// 	console.log(res.properties);
		// 	this.rowData = res.family_locker;
		// 	this.rowData = [... this.rowData];
		// })
		// this.userService.getSupervisor(data).subscribe((res: any) => {
		// 	if (res.result_return) {
		// 		res.result_return.map((item) => {
		// 			let hourRegistrationDate = item.registration_date.substring(0,2);
		// 			let minutesRegistrationDate = item.registration_date.substring(3,5);
		// 			let secondsRegistrationDate = item.registration_date.substring(6,8);
		// 			let monthRegistrationDate = parseInt(item.registration_date.substring(9,11));
		// 			let dayRegistrationDate = item.registration_date.substring(12,14);
		// 			let yearRegistrationDate = item.registration_date.substring(15,19);
		// 			let registrationDateString = yearRegistrationDate +'-'+monthRegistrationDate+'-'+dayRegistrationDate+' '+hourRegistrationDate+':'+minutesRegistrationDate+':'+secondsRegistrationDate;
		// 			this.supervisors.push({
		// 				acronym_language: item.acronym_language,
		// 				active_days: item.active_days,
		// 				alias_username: item.alias_username,
		// 				audit_package: item.audit_package,
		// 				audit_employee: item.audit_employee,

		// 				courier_name: item.courier_name,
		// 				email: item.email,
		// 				enable_token: item.enable_token,
		// 				first_name: item.first_name,
		// 				id_language: item.id_language,
		// 				id_status: item.id_status,
		// 				id_user: item.id_user,
		// 				language_name: item.language_name,
		// 				name_status: item.name_status,
		// 				registration_date: registrationDateString,
		// 				surname: item.surname,
		// 				url_token: item.url_token,
		// 			});
		// 		});
		// 		this.supervisors = [...this.supervisors];
		// 	}
		// });
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'left';
		cellDiv.style.justifyContent = 'left';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		const fileName = 'AccessPointFamily.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Access Point Family',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_status,audit_package,audit_employee,url_token,id_language,id_user,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			// Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (
			this.rowData.length > 0 &&
			this.filteredData.length === 0
		) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				const { id_status,audit_package,audit_employee,url_token,id_language,id_user,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			// Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
	onGridReady(params: any) {
		const { api } = params;
	}
	verAccessPoint(params:any){
		this.router.navigateByUrl(`/fetch/access-point-north`);
	}
	updateDataUser(): void {
		this.translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('accessPointFamily.table.lockerID'),
					field: 'locker_id',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('accessPointFamily.table.serialNumber'),
					field: 'serial_number',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('accessPointFamily.table.hierachy'),
					field: 'hierarchy',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('accessPointFamily.table.doors'),
					field: 'total_doors',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('accessPointFamily.table.addedBy'),
					field: 'configured_by',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
				},
				{
					headerName: this.translate.instant('accessPointFamily.table.dattedAdded'),
					field: 'registration_date',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
					cellRenderer: DateTextTablesComponent
				},
				{
					headerName: this.translate.instant('accessPointFamily.table.status'),
					field: 'id_status',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
					cellRenderer: EstatusTableAccessPointComponent
				},
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			headerHeight:62,
			rowHeight:62,
			paginationAutoPageSize: true,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
			rowClass:'rowPropertyClass',
			defaultColDef: {
				flex: 1,
			}
		}
	}

}

