//Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { CreateTicketsComponent } from './shared/components/create-tickets/create-tickets.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { CCCGuard } from './core/guards/ccc.guard';
import { ERGuard } from './core/guards/er.guard';
import { SuperAdminGuard } from './core/guards/super-admin.guard';
//Guards

const options: ExtraOptions = {
	onSameUrlNavigation: 'reload',
};

const routes: Routes = [
	{
		path: 'auth', //Login
		loadChildren: () =>	import('./modules/auth/auth.module').then((m) => m.AuthModule),
	},
	{
		canActivate:[AdminGuard],
		path: 'admin', //Dashboard-client
		loadChildren: () => import('./modules/admin-client/admin-client.module').then((m) => m.AdminClientModule),
	},
	{
		canActivate:[CCCGuard],
		path: 'ccc', //Dashboard-client
		loadChildren: () => import('./modules/admin-client/admin-client.module').then((m) => m.AdminClientModule),
	},
	{
		canActivate:[ERGuard],
		path: 'er', //Dashboard-carrier
		loadChildren: () =>	import('./modules/admin-client/admin-client.module').then((m) => m.AdminClientModule),
	},
	{
		canActivate:[SuperAdminGuard],
		path: 'fetch', //Dashboard-carrier
		loadChildren: () =>	import('./modules/admin-client/admin-client.module').then((m) => m.AdminClientModule),
	},
	{
		path: 'warehouse',
		loadChildren: () => import('./modules/warehouse/warehouse.module').then( (m)=> m.WarehouseModule )
	},
	{
		path: 'vivipost',
		loadChildren: () => import('./modules/vivipost/vivipost.module').then( (m)=> m.VivipostModule )
	},
	{
		path: 'carriers', //Dashboard-carrier
		loadChildren: () =>	import('./modules/carriers/carriers.module').then((m) => m.CarriersModule),
	},
	{
		path:'create-tickets/:tokenTicket',
		component: CreateTicketsComponent
	},
	{
		path: '**',
		redirectTo: 'auth'
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	declarations: [],
})
export class AppRoutingModule {}
