<div class="header-dialog-create-order">
	<div class="row" style="margin-left:0px; margin-right:0px; height: 100%;">
		<div class="col-6 col-md-6 col-lg-6 col-xl-6" style="height:100%;">
			<span class="header-dialog-create-order-text">
				{{title}}
			</span>
		</div>
		<div class="col-6 col-md-6 col-lg-6 col-xl-6 icon-close"  style="height:100%;">
			<i class="pi pi-times" (click)="closeAll()"></i>
		</div>
	</div>
</div>

<mat-stepper [linear]="true" class="demo-stepper" #stepper (selectionChange)="onStepChanged($event)">
	<mat-step [completed]="currentStep > 0" [ngClass]="{'select-step': currentStep === 0}">
		<ng-template matStepLabel>
			{{ "register_package.step_1" | translate }}
		</ng-template>
		<div class="row">
			<div class="col-12" class="container">
				<form [formGroup]="firstFormGroup" class="form-orden">
					<label class="label-form-orden">
						{{ "register_package.tracking_id" | translate }}
					</label>
					<div class="inputs {{ edit_package ? 'disabled' : '' }}">
						<input class="input-form-orden" formControlName="tracking" id="ftracking" autocomplete="off" minlength="13" maxlength="13" type="text" placeholder="{{'orden.create_orden.placeholder.tracking_id' | translate}}" (ngModelChange)="modelChanged($event)"/>
						<label *ngIf="typeMsgPhone == 'error-email'">
							{{"ERROR.error_tracking" | translate}}
						</label>
					</div>

					<label class="label-form-orden">
						{{ "register_package.email" | translate }}
					</label>
					<div class="inputs {{ styles_input_email }}">
						<input class="input-form-orden" formControlName="email"	matInput id="e-mail" autocomplete="off" type="email" placeholder="{{'orden.create_orden.placeholder.email' | translate}}" (ngModelChange)="modelChanged($event)" email/>
						<label *ngIf="typeMsgEmail == 'error-email'">
							{{
								"ERROR.error_email" | translate
							}}
						</label>
					</div>

					<label class="label-form-orden">
						{{ "register_package.phone" | translate }}
					</label>
					<div class="inputs {{ edit_package ? 'disabled' : '' }}">
						<input class="input-form-orden" formControlName="phone" matInput id="fphone" autocomplete="off" type="text" maxlength="12" placeholder="{{'orden.create_orden.placeholder.phone' | translate}}"(ngModelChange)="modelChanged($event)" appPhoneFormat/>
						<label *ngIf="typeMsgPhone == 'error-email'">
							{{"ERROR.error_phone" | translate}}
						</label>
					</div>
				</form>
			</div>
		</div>

		<div class="buttons-step-1">
			<div class="button-container">
				<button mat-button class="btn-canceled" (click)="closeAll()">
					{{ "buttons.cancel" | translate }}
				</button>

				<button mat-button class="btn-next-disabled" *ngIf="this.firstFormGroup.status !== 'VALID'" (click)="notReadyDataContat()">
					{{ "register_package.next" | translate }}
				</button>
				<button mat-button class="btn-next-abled"  *ngIf="this.firstFormGroup.status === 'VALID'" (click)="readyDataContact()">
					{{ "register_package.next" | translate }}
				</button>
			</div>
		</div>
	</mat-step>
	<mat-step [completed]="currentStep > 1" [ngClass]="{'select-step': currentStep === 1}">
		<ng-template matStepLabel>
			{{"register_package.step_2" | translate}}
		</ng-template>
		<div class="border-step-2 row">
			<div class="col-12">
				<ng-template matStepLabel>
					{{'register_package.step_2' | translate}}
				</ng-template>
				<div class="content-cards">
					<app-select-locker (onItemSelected)="onChildItemSelected($event)">
					</app-select-locker>
				</div>
			</div>
		</div>
		<div class="buttons-step-2">
			<div class="button-container">
				<button mat-button matStepperPrevious class="btn-canceled">
					{{'register_package.back' | translate}}
				</button>
				<button mat-button (click)="(currentStep===2) ? readyLocker() : ''" class="btn-next-abled" *ngIf="this.currentStep === 2">
					{{ "register_package.next" | translate }}
				</button>
				<button mat-button (click)="(currentStep===2) ? notReadyDataContat() : ''" class="btn-next-disabled" *ngIf="this.currentStep !== 2">
					{{ "register_package.next" | translate }}
				</button>
			</div>
		</div>
	</mat-step>
	<mat-step [completed]="currentStep > 2" [ngClass]="{'select-step': currentStep === 2}">
		<ng-template matStepLabel>
			{{"register_package.step_3" | translate}}
		</ng-template>
		<div style="width: 100%; margin-top:45px; height: 375px;" *ngIf="view_grid">
			<app-lockers-client-grid-admin 	[gridLocalhost]="gridLocalhost"
											[id_locker_select]="id_locker_select"
											[cStatus]="cStatus"
											[modulo]="modulo"
											[select_compartment]="select_compartment"
											fxLayoutGap="20px"
											(select_compartment_available)="select_compartment_available($event)">
			</app-lockers-client-grid-admin>
		</div>
		<div class="container-footer-step-3">
			<div class="container-disponibles">
				<div class="legend-disponibles">
					<i class="pi pi-circle-fill" style="color: #4e4e4e;"></i>
					Disponibles
				</div>
				<div class="legend-no-disponibles">
					<i class="pi pi-circle-fill" ></i>
					No Disponibles
				</div>
			</div>
			<div class="container-label-select-locker">
				Da clic en un compartimento para seleccionarlo
			</div>
			<div class="buttons-step-3">
				<div class="button-container">
					<button mat-button matStepperPrevious  class="btn-canceled" (click)="eraseGrid();">
						{{ "register_package.back" | translate }}
					</button>
					<button mat-button (click)="getComparmentSelected()" class="btn-next-abled" *ngIf="this.currentStep === 3">
						{{ "register_package.next" | translate }}
					</button>
					<button mat-button class="btn-next-disabled" *ngIf="this.currentStep < 3">
						{{ "register_package.next" | translate }}
					</button>
				</div>
			</div>
		</div>
	</mat-step>
	<mat-step [completed]="currentStep > 3" [ngClass]="{'select-step': currentStep === 3}">
		<ng-template matStepLabel>
			{{ "register_package.step_4" | translate }}
		</ng-template>
		<div class="row" *ngIf="view_grid === false ">
			<div class="col-12">
				<div class="card-container-step-4">
					<div class="row">
						<div class="card-container-step-4-info">
							<div class="card-container-step-info-locker">
								<label class="card-container-step-info-locker-lbl">
									Locker ID:
								</label>
								<label class="card-container-step-info-locker-lbl-res">
									{{lockerName}}
								</label>
							</div>
						</div>
						<div class="col-12" style="margin-left: -7px;">
							<hr>
						</div>
						<div class="col-12" class="info">
							<div class="row" class="info-detail">
								<div class="col-12" class="info-detail-size">
									<form [formGroup]="firstFormGroup">
										<div class="info-detail-size-item">
											<label class="card-input-label">
												Numero de Tracking
											</label>
											<div class="card-input">
												{{ tracking | slice : 0 : 4 }} {{ tracking | slice : 4 : 8 }} {{ tracking | slice : 8 : 13 }}
											</div>
										</div>
										<div class="info-detail-size-item">
											<label class="card-input-label">
												Correo electronico del Cliente
											</label>
											<div class="card-input">
												{{ email }}
											</div>
										</div>
										<div class="info-detail-size-item">
											<label class="card-input-label">
												Telefono del Cliente
											</label>
											<div class="card-input">
												{{phone | slice : 0 : 2 }}-{{phone | slice : 2 : 6 }}-{{phone | slice : 6 : 10}}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="back">
					<button mat-button matStepperPrevious id="go_back">{{'register_package.back' | translate}}</button>
				</div>
				<div class="next">
					<button mat-button matStepperNext id="create" (click)="getComparmentSelected()">{{'register_package.next' | translate}}</button>
				</div>
			</div>
    	</div>
		<div class="row" *ngIf="view_grid === true ">
			<div class="col-12">
				<div class="card-container-step-4">
					<div class="row">
						<div class="card-container-step-4-info">
							<div class="card-container-step-info-locker">
								<label class="card-container-step-info-locker-lbl">
									Locker ID:
								</label>
								<label class="card-container-step-info-locker-lbl-res">
									{{lockerName}}
								</label>
							</div>
						</div>
						<div class="col-12" style="margin-left: -7px;">
							<hr>
						</div>
						<div class="col-6" class="info">
							<div class="row" class="info-detail">
								<div class="info-detail-size">
									<div class="info-detail-size-item">
										<label class="card-input-label">
											Numero de Tracking
										</label>
										<div class="card-input">
											{{ trackingValue | slice : 0 : 4 }} {{ trackingValue | slice : 4 : 8 }} {{ trackingValue | slice : 8 : 13 }}
										</div>
									</div>
									<div class="info-detail-size-item">
										<label class="card-input-label">
											Correo electronico del Cliente
										</label>
										<div class="card-input">
											{{ emailValue }}
										</div>
									</div>
									<div class="info-detail-size-item">
										<label class="card-input-label">
											Telefono del Cliente
										</label>
										<div class="card-input">
											{{phoneValue}}
										</div>
									</div>
								</div>
								<div class="col-6">
									<label class="numero-compartimiento">
										Numero de Compartimiento
									</label>
									<div class="container-compartiment">
										<div class="number-locker" *ngIf="compartment_select < 10">
											0{{ compartment_select }}
										</div>
										<div class="number-locker" *ngIf="compartment_select >= 10">
											{{ compartment_select }}
										</div>
									</div>
									<div class="size-locker">
										Tamaño : {{lockerSize}} <br>
										Dimensiones : {{lockerDimension}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="buttons-step-4">
				<div class="button-container">
					<button mat-button matStepperPrevious class="btn-canceled" (click)="eraseCompartment()">
						{{ "register_package.back" | translate }}
					</button>
					<button mat-button matStepperNext class="btn-next-abled" (click)="creteOrder()">
						{{ "register_package.next" | translate }}
					</button>
				</div>
			</div>
      	</div>
	</mat-step>
</mat-stepper>
