// ANGULAR
import {
	CellClickedEvent,
	ColDef,
	GridOptions,
	GridReadyEvent,
} from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { EstatusTableOrderAdminComponent } from '../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../date-text/date-text.component';
import { Component, OnInit } from '@angular/core';
import { EstatusTableLogsComponent } from '../estatus-table-logs/estatus-table-logs.component';
interface GridConfig {
	columnDefs: ColDef[];
	rowData: any[];
}

@Component({
	selector: 'app-activity-table-admin',
	templateUrl: './activity-table-admin.component.html',
	styleUrls: ['./activity-table-admin.component.scss'],
})
export class ActivityTableAdminComponent implements OnInit {
	filterText: string = '';
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	rangeDates: Date[];
	InitialDate = new Date();
	FinalDate = new Date();
	rowData: any[];

	today = new Date();
	year = this.today.getFullYear();
	month = this.today.getMonth();
	day = this.today.getDate();
	lastMonth: Date;
	startDate: Date;
	endDate: Date;

	data;
	dataTemp = [];
	public columnDefsActivity: ColDef[];

	constructor(
		private adminService: AdminClientService,
		private translate: TranslateService,
		private datePipe: DatePipe,
		private toast: ToastrService
	) {

	}

	ngOnInit() {
		let today = new Date();
		let month = today.getMonth();
		let year = today.getFullYear();
		let day = today.getDate();
		let lastMonth;
		let endDate;
		let startDate;
		if (month < 0) {
			month = 11;
			year -= 1;
		}
		lastMonth = new Date(year, month - 1, day);
		endDate = new Date(year, month, day);
		startDate = new Date(year, lastMonth.getMonth(), day);
		this.rangeDates = [startDate, endDate];
		this.InitialDate = this.rangeDates[0];
		this.FinalDate = this.rangeDates[1];

		this.addEventRangeDates();
		this.translate.get([
			'table-activity.idEvent',
			'table-activity.topicNameEvent',
			'table-activity.registrationDateEvent',
			'table-activity.description'
		]).subscribe( (t:any)=>{
			this.columnDefsActivity =
			[
				{
					headerName: t['table-activity.idEvent'],
					field: 'idEvent',
					sortable: true,
					autoHeight: true,
					cellClass:'ActiveTableClass',
					headerClass:'borderHeaderActiveTable',
				},
				{
					headerName: t['table-activity.topicNameEvent'],
					field: 'topicNameEvent',
					sortable: true,
					autoHeight: true,
					cellClass:'ActiveTableClassLink',
					headerClass:'borderHeaderActiveTable',
					cellRenderer: EstatusTableLogsComponent,
				},
				{
					headerName: t['table-activity.registrationDateEvent'],
					field: 'registrationDateEvent',
					sortable: true,
					autoHeight: true,
					cellClass:'ActiveTableClassLink',
					headerClass:'borderHeaderActiveTable',
					cellRenderer: DateTextComponent,
				},
				{
					headerName: t['table-activity.description'],
					field: 'description',
					sortable: true,
					autoHeight: true,
					width:887,
					cellClass:'DescriptionActiveTableClass',
					headerClass:'borderHeaderActiveTable'
				},
			]
		})


	}
	onGridReady(params: GridReadyEvent) {}
	onCellClicked(e: CellClickedEvent): void {}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		let api = this.gridOptions.api;
		let temporal = [];
		this.filteredData = temporal;
	}

	addEventRangeDates() {
		this.rowData = [];
		this.dataTemp = [];
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
			this.getTableCompleted();
			this.updateTable();
		}
	}

	updateTable(){
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant(
						'table-activity.idEvent'
					),
					field: 'idEvent',
					sortable: true,
					autoHeight: true,
					cellClass:'ActiveTableClass',
					headerClass:'borderHeaderActiveTable',
				},
				{
					headerName: this.translate.instant(
						'table-activity.topicNameEvent'
					),
					field: 'topicNameEvent',
					sortable: true,
					autoHeight: true,
					width:250,
					cellClass:'ActiveTableClassLink',
					headerClass:'borderHeaderActiveTable',
					cellRenderer: EstatusTableLogsComponent,
				},
				{
					headerName: this.translate.instant(
						'table-activity.registrationDateEvent'
					),
					field: 'registrationDateEvent',
					sortable: true,
					autoHeight: true,
					cellClass:'ActiveTableClassLink',
					headerClass:'borderHeaderActiveTable',
					cellRenderer: DateTextComponent,
				},
				{
					headerName: this.translate.instant(
						'table-activity.description'
					),
					field: 'description',
					sortable: true,
					autoHeight: true,
					width:887,
					cellClass:'DescriptionActiveTableClass',
					headerClass:'borderHeaderActiveTable',
					cellRenderer: (params) => {
						const maxLength = 50; // Número máximo de caracteres antes de mostrar los puntos suspensivos
						const value = params.value;
						if (value.length > maxLength) {
							return value.slice(0, maxLength) + '...';
						}
						return value;
					},
				},
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 100,
			paginationAutoPageSize: false,
			domLayout: 'autoHeight',
			rowHeight:59,
			headerHeight:59,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		};
	}

	async getTableCompleted() {
		this.data = {
			id_locker: localStorage.getItem('id_locker'),
			language: localStorage.getItem('language'),
			date_start: this.InitialDate.getTime(),
			date_end: this.FinalDate.getTime(),
			limit: 30,
		};
		let res: any = await this.adminService.auditLocker(this.data);
		if (!res.mensaje_return.ERROR) {
			if (res.return_result.length > 0) {
				this.rowData =[];
				res.return_result.forEach((audit: any) => {
					this.rowData.push({
						description: audit.description,
						idEvent: audit.id_audit_locker,
						id_locker_type: audit.id_locker_type,
						locker_name: audit.locker_name,
						registrationDateEvent: audit.registration_date,
						topicNameEvent: audit.topic_name,
						serial_locker: audit.serial_locker,
						// cant_doors : audit.description.split("puerta")[0].split("del")[1],
						id_topic_audit: audit.id_topic_audit,
					});
					this.rowData = [...this.rowData];
				});
			}
		}
	}

	onExport() {
		const fileName = 'ActivityOfLocker.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'LOGS-ActivityOfLocker',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { idEvent,id_locker_type,id_topic_audit,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				const { idEvent,id_locker_type,id_topic_audit,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
}

interface InterfaceEmployeeRefund {
	name: string;
	id: string;
}
