import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminClientService } from '../../../../core/http/admin-client.service';
import Highcharts from 'highcharts';

@Component({
	selector: 'app-table-filter-by-year',
	templateUrl: './table-filter-by-year.component.html',
	styleUrls: ['./table-filter-by-year.component.scss'],
})
export class TableFilterByYearComponent implements OnInit {
	Highcharts = Highcharts;
	convertir: boolean = false;
	chartOptions: {};
	yearSelected: Date;
	today = new Date();
	dataFilterByYear = [];
	dataFilterByDay = [];
	tableFilterForm = new FormGroup({
		leng: new FormControl('es'),
		year: new FormControl(this.today.getFullYear()),
	});

	constructor(private adminService: AdminClientService) {}

	ngOnInit() {
		this.adminService
			.getMetricsByYear(this.tableFilterForm.value)
			.then((res) => {
				this.dataFilterByYear = res.result;
				this.chartOptions = {
					title: {
						text: '',
					},
					xAxis: {
						categories: this.dataFilterByYear.map((res) => res.Mes),
						labels: {
							style: {
								color: '#000000',
								fontFamily: 'Metropolis-Bold',
								fontSize: '12px',
								fontWeight: 'bold',
							},
						},
					},
					yAxis: {
						title: {
							text: '',
						},
					},
					legend: {
						enabled: true,
						layout: 'horizontal',
						align: 'center',
						verticalAlign: 'bottom',
						borderWidth: 1,
					},
					series: [
						{
							lineWidth: 3,
							marker: {
								enabled: true,
							},
							color: '#5B8FF9',
							name: 'Pickup',
							data: this.dataFilterByYear.map(
								(item) => item.cantidad_total_pickup
							),
							dataLabels: {
								enabled: true,
							},
						},
						{
							lineWidth: 3,
							marker: {
								enabled: true,
							},
							color: '#9E1252',
							name: 'Return',
							data: this.dataFilterByYear.map(
								(item) => item.cantidad_total_return
							),
							dataLabels: {
								enabled: true,
							},
						},
						{
							lineWidth: 3,
							marker: {
								enabled: true,
							},
							color: '#00A67D',
							name: 'Delivery',
							data: this.dataFilterByYear.map(
								(item) => item.cantidad_total_delivery
							),
							dataLabels: {
								enabled: true,
							},
						},
					],
					credits: {
						enabled: false,
					},
				};
			});
		this.adminService
			.getMetricsByDay(this.tableFilterForm.value)
			.then((res) => {
				this.dataFilterByDay = res.result;
			});
	}

	updateTable() {
		this.tableFilterForm
			.get('year')
			.setValue(this.yearSelected.getFullYear());

		this.adminService
			.getMetricsByYear(this.tableFilterForm.value)
			.then((res) => {
				this.dataFilterByYear = res.result;
				this.chartOptions = {
					title: {
						text: '',
					},
					xAxis: {
						categories: this.dataFilterByYear.map((res) => res.Mes),
						labels: {
							style: {
								color: '#000000',
								fontFamily: 'Metropolis-Bold',
								fontSize: '12px',
								fontWeight: 'bold',
							},
						},
					},
					yAxis: {
						title: {
							text: '',
						},
					},
					legend: {
						enabled: true,
						layout: 'horizontal',
						align: 'center',
						verticalAlign: 'bottom',
						borderWidth: 1,
					},
					series: [
						{
							lineWidth: 3,
							marker: {
								enabled: true,
							},
							color: '#5B8FF9',
							name: 'Pickup',
							data: this.dataFilterByYear.map(
								(item) => item.cantidad_total_pickup
							),
							dataLabels: {
								enabled: true,
							},
						},
						{
							lineWidth: 3,
							marker: {
								enabled: true,
							},
							color: '#9E1252',
							name: 'Return',
							data: this.dataFilterByYear.map(
								(item) => item.cantidad_total_return
							),
							dataLabels: {
								enabled: true,
							},
						},
						{
							lineWidth: 3,
							marker: {
								enabled: true,
							},
							color: '#00A67D',
							name: 'Delivery',
							data: this.dataFilterByYear.map(
								(item) => item.cantidad_total_delivery
							),
							dataLabels: {
								enabled: true,
							},
						},
					],
					credits: {
						enabled: false,
					},
				};
			});

		this.adminService
			.getMetricsByDay(this.tableFilterForm.value)
			.then((res) => {
				this.dataFilterByDay = res.result;
			});
	}

	convertirPorcentaje() {
		this.convertir = !this.convertir;
		if (this.convertir === true) {
			let pickup = [];
			let delivery = [];
			let return_array = [];
			let porcentaje_pickup_by_mes: number = 0;
			let porcentaje_delivery_by_mes: number = 0;
			let porcentaje_return_by_mes: number = 0;
			let porcentaje_pickup = [];
			let porcentaje_delivery = [];
			let porcentaje_return = [];
			let total_pickup: number = 0;
			let total_delivery: number = 0;
			let total_return: number = 0;
			this.dataFilterByYear.map((item) => {
				pickup.push(item.cantidad_total_pickup);
				delivery.push(item.cantidad_total_delivery);
				return_array.push(item.cantidad_total_return);

				total_pickup += item.cantidad_total_pickup;
				total_delivery += item.cantidad_total_delivery;
				total_return += item.cantidad_total_return;
			});
			pickup.forEach((res) => {
				porcentaje_pickup_by_mes = (res / total_pickup) * 100;
				porcentaje_pickup.push(
					parseFloat(porcentaje_pickup_by_mes.toFixed(2))
				);
			});
			delivery.forEach((res) => {
				porcentaje_delivery_by_mes = (res / total_delivery) * 100;
				porcentaje_delivery.push(
					parseFloat(porcentaje_delivery_by_mes.toFixed(2))
				);
			});
			return_array.forEach((res) => {
				porcentaje_return_by_mes = (res / total_return) * 100;
				porcentaje_return.push(
					parseFloat(porcentaje_return_by_mes.toFixed(2))
				); //(parseInt((porcentaje_return_by_mes.toFixed(2)))
			});
			this.chartOptions = {
				title: {
					text: '',
				},
				xAxis: {
					categories: this.dataFilterByYear.map((res) => res.Mes),
					labels: {
						style: {
							color: '#000000',
							fontFamily: 'Metropolis-Bold',
							fontSize: '12px',
							fontWeight: 'bold',
						},
					},
				},
				yAxis: {
					title: {
						text: '',
					},
				},
				legend: {
					enabled: true,
					layout: 'horizontal',
					align: 'center',
					verticalAlign: 'bottom',
					borderWidth: 1,
				},
				series: [
					{
						lineWidth: 3,
						marker: {
							enabled: true,
						},
						color: '#5B8FF9',
						name: 'Pickup',
						data: porcentaje_pickup.map((item) => item),
						stack: 'percent',
						dataLabels: {
							enabled: true,
						},
					},
					{
						lineWidth: 3,
						marker: {
							enabled: true,
						},
						color: '#9E1252',
						name: 'Return',
						data: porcentaje_delivery.map((item) => item),
						stack: 'percent',
						dataLabels: {
							enabled: true,
						},
					},
					{
						lineWidth: 3,
						marker: {
							enabled: true,
						},
						color: '#00A67D',
						name: 'Delivery',
						data: porcentaje_return.map((item) => item),
						stack: 'percent',
						dataLabels: {
							enabled: true,
						},
					},
				],
				credits: {
					enabled: false,
				},
			};
		} else {
			this.adminService
				.getMetricsByYear(this.tableFilterForm.value)
				.then((res) => {
					this.dataFilterByYear = res.result;
					this.chartOptions = {
						title: {
							text: '',
						},
						xAxis: {
							categories: this.dataFilterByYear.map(
								(res) => res.Mes
							),
							labels: {
								style: {
									color: '#000000',
									fontFamily: 'Noto-Ikea-Regular',
									fontSize: '12px',
									fontWeight: 'bold',
								},
							},
						},
						yAxis: {
							title: {
								text: '',
							},
						},
						legend: {
							enabled: true,
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom',
							borderWidth: 1,
						},
						series: [
							{
								lineWidth: 3,
								marker: {
									enabled: true,
								},
								color: '#5B8FF9',
								name: 'Pickup',
								data: this.dataFilterByYear.map(
									(item) => item.cantidad_total_pickup
								),
								dataLabels: {
									enabled: true,
								},
							},
							{
								lineWidth: 3,
								marker: {
									enabled: true,
								},
								color: '#9E1252',
								name: 'Return',
								data: this.dataFilterByYear.map(
									(item) => item.cantidad_total_return
								),
								dataLabels: {
									enabled: true,
								},
							},
							{
								lineWidth: 3,
								marker: {
									enabled: true,
								},
								color: '#00A67D',
								name: 'Delivery',
								data: this.dataFilterByYear.map(
									(item) => item.cantidad_total_delivery
								),
								dataLabels: {
									enabled: true,
								},
							},
						],
						credits: {
							enabled: false,
						},
					};
				});
		}
	}
}
