import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
	providedIn: 'root',
})
export class Interceptor implements HttpInterceptor {
	private token: string;
	constructor(
		private router: Router
	){}
	setToken(token: string) {
		this.token = token;
	}
	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}
	intercept(req: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		// Establece el encabezado "Content-Type" de la solicitud a "application/json"
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		const token = this.getToken();
		if (token) {
			headers = headers.set('token', token);
		}
		// Clona la solicitud original y agrega los nuevos encabezados
		const reqClone = req.clone({
			headers,
		});
		// Envía la solicitud clonada a través del siguiente interceptor o al servidor
		// y devuelve un Observable que emite la respuesta como un evento de HTTP
		return next.handle(reqClone).pipe(
			// Maneja cualquier error que se produzca en la solicitud y devuelve un nuevo Observable que emite un error personalizado
			catchError(this.handleError)
		);
	}
	// Función que maneja cualquier error que se produzca durante la solicitud
	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}
}
