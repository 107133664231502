<!--? CONTAINER MAIN -->
<div class="container-iterations">
    <!--? BLOCK 1 | HEADER -->
    <!-- HEADER CONTAINER -->
    <div class="header-container">
        <!-- TITLE -->
        <span> {{'navbar.integrations' | translate | titlecase }} </span>

        <!-- SPAN | 1 -->
        <span> {{'integrations.txt-1' | translate }} </span>

        <!-- HR -->
        <div class="hr"></div>

        <!-- SPAN | 2 -->
        <span> {{'integrations.txt-2' | translate }} </span>

    </div>

    <!--? BLOCK 2 | REST-API -->
    <div class="container-rest-api">

        <!-- BLOCK 1 REST-API | HEADER & TABLE -->
        <div class="rest-api-block-1">
            <!-- HEADER REST API CONTAINER -->
            <div class="header-rest-api">
                <!-- TITLE REST API -->
                <span> {{'integrations.rest-api-title' | translate }} </span>
                <!-- SUBTITLE REST API -->
                <span> {{'integrations.rest-api-subtitle' | translate }} </span>
            </div>

            <!-- TABLE CONTAINER -->
            <div>
                <!-- TABLE REST-API -->
                <!-- <new-table
                    [tableHeader]="thConfig"
                    [tableData]="dataConfig"
                    [pageLenght]="pageLenght">
                </new-table> -->

				<div class="row" style="display: flex; border:1px solid var(--color-primary-100); border-radius: 12px;">
					<div class="col-2" style="height:60px; border-bottom: 1px solid var(--color-primary-100);">
						<div class="headerName">
							{{'integrations.table.name' | translate | titlecase}}
						</div>
					</div>
					<div class="col-2" style="height:60px;  border-bottom: 1px solid var(--color-primary-100);">
						<div class="headerDateCreation">
							{{'integrations.table.date' | translate | titlecase}}
						</div>
					</div>
					<div class="col-2" style="height:60px;  border-bottom: 1px solid var(--color-primary-100);">
						<div class="headerDateCreation">
							{{'integrations.table.apiStatus' | translate | titlecase}}
						</div>
					</div>
					<div class="col-3" style="height:60px;  border-bottom: 1px solid var(--color-primary-100);">
						<div class="headerName">
							API
						</div>
					</div>
					<div class="col-3" style="height:60px;  border-bottom: 1px solid var(--color-primary-100); ">

					</div>


					<div class="col-2" style="height:76px;">
						<div class="labelName">
							API_VIVIPOST
						</div>
					</div>
					<div class="col-2" style="height:76px;">
						<div class="labelDateCreation">
							{{this.dateCreationApi}}
						</div>
					</div>
					<div class="col-2" style="height:76px;">
						<div class="labelButton">
							<app-button [activo]="false" btnType="primaryMedium" btnNombre="Suscrito" *ngIf="this.statusApi === 1">
							</app-button>
						</div>
					</div>
					<div class="col-3" style="height:76px;">
						<div class="labelApi">
							<div *ngIf="this.showApiText === true">
								{{this.apiText.slice(0, 20)}}...
							</div>
							<div *ngIf="this.showApiText === false">
								*********************
							</div>

							<div class="icon-copy" (click)="copiarApi()"></div>
							<div class="icon-view" (click)="showApi()" *ngIf="showApiText === false"> </div>
							<div class="icon-hide" (click)="showApi()" *ngIf="showApiText === true"> </div>
							<div class="icon-info" (mouseover)="showTooltipApi()" (mouseleave)="hideTooltipApi()">
								<tooltip tooltip="integrations.tooltip.apiToolTip" showTooltip="true" *ngIf="this.showTooltipApiValue === true">

								</tooltip>
							</div>


						</div>
					</div>
					<div class="col-3" style="height:76px;">
						<div class="labelName">
							{{'integrations.passToProduction' | translate}}
						</div>
					</div>
				</div>

            </div>

        </div>

        <!-- BLOCK 2 REST-API -->
        <div class="rest-api-block-2">
            <!-- SPAN 2 -->
            <span> {{'integrations.rest-api-span-2' | translate }} </span>
            <!-- UNORDERED LIST -->
            <ul class="list-1">
                <li>{{'integrations.li-1' | translate}}</li>
                <li>{{'integrations.li-2' | translate}}</li>
                <li>{{'integrations.li-3' | translate}}</li>
                <li>{{'integrations.li-4' | translate}}</li>
                <li>{{'integrations.li-5' | translate}}</li>
                <li>{{'integrations.li-6' | translate}}</li>
                <li>{{'integrations.li-7' | translate}}</li>
                <li>{{'integrations.li-8' | translate}}</li>
            </ul>

        </div>

        <!-- BLOCK 3 REST-API -->
        <div class="rest-api-block-3">
            <!-- SPAN BLOCK 3 -->
            <span> {{'integrations.block-3-span' | translate }}  </span>

            <!-- LINK DOCUMENTATION -->
            <div class="link-documentation">
                <!-- LINK BLOCK 3 -->
                <a style="cursor: pointer;" (click)="openLink()">
					{{'integrations.block-3-link' | translate }}
				</a>
                <!-- ICON ARROW 34 -->
                <div class="icon-arrow-34"></div>
                <!-- ICON COPY -->
                <div class="icon-copy" (click)="copiarUrl()"></div>
            </div>

        </div>

    </div>

    <!--? BLOCK 3 | WEBHOOKS -->
    <div class="about-webhooks">
        <!--? BLOCKS -->
        <!--? BLOCK 1 | ABOUT WEBHOOKS -->
        <div class="block-webhooks">
            <!-- WEBHOOKS TITLE -->
            <span class="block-webhooks-title"> {{"integrations.webhooks.title" | translate }} </span>
            <!-- WEBHOOKS CONTENT -->
            <div>
                <!-- SPAN 2 WEBHOOKS -->
                <span class="block-webhooks-description"> {{"integrations.webhooks.span-2" | translate }} </span>

                <!-- SPAN LIST -->
                <div class="span-list">
                    <!-- SPAN 3 WEBHOOKS -->
                    <span> {{"integrations.webhooks.span-3" | translate }} </span>

                    <!-- LIST-2 -->
                    <ul class="list-2">
                        <li>{{'integrations.webhooks.li-1' | translate }}</li>
                        <li>{{'integrations.webhooks.li-2' | translate }}</li>
                        <li>{{'integrations.webhooks.li-3' | translate }}</li>
                        <li>{{'integrations.webhooks.li-4' | translate }}</li>
                        <li>{{'integrations.webhooks.li-5' | translate }}</li>

                    </ul>

                </div>

            </div>

        </div>

        <!--? BLOCK 2 | ADDRESS -->
        <div class="block-webhooks">
            <!-- TITLE -->
            <span class="block-webhooks-title"> {{'integrations.address.title' | translate }} </span>
            <!-- TXT  -->
            <span class="block-webhooks-description"> {{'integrations.address.txt' | translate }} </span>
        </div>

        <!--? BLOCK 3 | TYPE -->
        <div class="block-webhooks">
            <!-- TITLE -->
            <span class="block-webhooks-title"> {{'integrations.type.title' | translate }} </span>
            <!-- TXT  -->
            <span class="block-webhooks-description"> {{'integrations.type.txt' | translate }} </span>
        </div>

        <!--? BLOCK 4 | EVENTS -->
        <div class="block-webhooks">
            <!-- TITLE -->
            <span class="block-webhooks-title"> {{'integrations.events.title' | translate }} </span>
            <!-- TXT  -->
            <span class="block-webhooks-description"> {{'integrations.events.txt' | translate }} </span>
        </div>

        <!--? BLOCK 5 | EVENTS ACTIVE -->
        <div class="block-webhooks">
            <!-- TITLE -->
            <span class="block-webhooks-title"> {{'integrations.events-active.title' | translate }} </span>
            <!-- TXT  -->
            <span class="block-webhooks-description"> {{'integrations.events-active.txt' | translate }} </span>
        </div>

        <!--? BLOCK 6 | EVENTS WEBHOOK -->
        <div class="block-webhooks">
            <!-- TITLE -->
            <span class="block-webhooks-title"> {{'integrations.events-webhook.title' | translate }} </span>
            <!-- TXT  -->
            <span class="block-webhooks-description"> {{ 'integrations.events-webhook.txt1' | translate }} <span class="linkTxt">{{ linkTxt | translate }}</span> {{ 'integrations.events-webhook.txt2' | translate }}</span>
        </div>
    </div>

    <!--? BLOCK 3 | ENDPOINTS -->
    <div class="container-endpoint">
        <!-- GRID TABLE -->
        <div class="grid-table">
            <!-- TABLE HEADER -->
            <div class="table-row">
                <div class="th">{{'integrations.th.item' | translate }}</div>
                <div class="th">{{'integrations.th.event' | translate }}</div>
                <div class="th">{{'integrations.th.endpoint' | translate }}</div>
                <div class="th">{{'integrations.th.description' | translate }}</div>
            </div>
            <!-- HR -->
            <hr>

            <!-- TABLE DATA ROW 1 -->
            <div class="table-row">
                <!-- ROW 1 -->
                <div class="td"> {{'integrations.td.item.1' | translate }}</div>
                <div class="td"> {{'integrations.td.event.1' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.1' | translate }}</div>
                <div class="td"> {{'integrations.td.description.1' | translate }}</div>
            </div>
            <!-- HR -->
            <hr>

            <!-- TABLE DATA ROW 2 -->
            <div class="table-row">
                <!-- ROW 2 -->
                <div class="td"> {{'integrations.td.item.2' | translate }}</div>
                <div class="td"> {{'integrations.td.event.2' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.2' | translate }}</div>
                <div class="td"> {{'integrations.td.description.2' | translate }}</div>
            </div>
            <!-- HR -->
            <hr>
            <!-- TABLE DATA ROW 3 -->
            <div class="table-row">
                <!-- ROW 3 -->
                <div class="td"> {{'integrations.td.item.3' | translate }}</div>
                <div class="td"> {{'integrations.td.event.3' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.3' | translate }}</div>
                <div class="td"> {{'integrations.td.description.3' | translate }}</div>
            </div>
            <!-- HR -->
            <hr>
            <!-- TABLE DATA ROW 4 -->
            <div class="table-row">
                <!-- ROW 4 -->
                <div class="td"> {{'integrations.td.item.4' | translate }}</div>
                <div class="td"> {{'integrations.td.event.4' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.4' | translate }}</div>
                <div class="td"> {{'integrations.td.description.4' | translate }}</div>
            </div>
            <!-- HR -->
            <hr>
            <!-- TABLE DATA ROW 5 -->
            <div class="table-row">
                <!-- ROW 5 -->
                <div class="td"> {{'integrations.td.item.5' | translate }}</div>
                <div class="td"> {{'integrations.td.event.5' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.5' | translate }}</div>
                <div class="td"> {{'integrations.td.description.5' | translate }}</div>
            </div>

        </div>

        <!-- PROPERTIES -->
        <div class="block-webhooks">
            <!-- TITLE -->
            <span class="block-webhooks-title"> {{'integrations.properties.title' | translate }} </span>
            <!-- TXT  -->
            <span class="block-webhooks-description"> {{'integrations.properties.txt' | translate }} </span>
        </div>

    </div>

    <!--? BLOCK 4 | BODY -->
    <div class="body-block-4">

        <!-- BLOCK | BODY -->
        <div class="block-body">
            <!-- BTN -->
            <span class="body-btn"> {{'integrations.body.btn' | translate }} </span>
            <!-- TXT -->
            <span class="txt-body"> {{'integrations.body.txt' | translate }} </span>
        </div>

        <!-- BLOCK | REQUEST -->
        <div class="block-request">
            <!-- ROW 1 -->
            <div class="row-1">
                <!-- BTN -->
                <span class="body-btn"> {{'integrations.request.btn' | translate }} </span>
                <!-- TXT -->
                <span class="txt-body"> {{'integrations.request.txt' | translate }} </span>
                <!-- JSON 1 -->
                <div class="json1">
                    <!-- TXT  -->
                    <span> {{'integrations.request.example' | translate }} </span>
                    <!-- JSON 1 -->
                    <pre> {{ jsonString1 }} </pre>
                </div>
            </div>

        </div>

        <!-- BLOCK | RESPONSE -->
        <div class="block-request">
            <!-- ROW 2 -->
            <div class="row-2">
                <!-- BTN -->
                <span class="body-btn"> {{'integrations.response.btn' | translate }} </span>
                <!-- TXT 1 -->
                <span class="txt-body"> {{'integrations.response.txt1' | translate }} </span>

                <!-- JSON 2 -->
                <div class="json2">
                    <!-- TXT 2 -->
                    <div class="list-body">
                        <span class="txt-body"> {{'integrations.response.txt2' | translate }} </span>
                        <ul>
                            <li>{{'integrations.response.error' | translate }}</li>
                            <li>{{'integrations.response.code' | translate }}</li>
                            <li>{{'integrations.response.error_message' | translate }}</li>
                            <li>{{'integrations.response.example' | translate }}</li>
                        </ul>
                    </div>

                    <div class="list-body2" >
                        <!-- TXT  -->
                        <span> {{'integrations.response.example' | translate }} </span>

                        <!-- JSON -->
                        <pre> {{ jsonString2 }} </pre>
                    </div>

                </div>

            </div>

        </div>

    </div>

    <!--? BLOCK 5 | EXAMPLE DELIVERY  -->
    <div class="example-delivery">

        <!-- TITLE -->
        <span class="block-webhooks-title"> {{'integrations.example-delivery' | translate }} </span>

        <!-- JSON DELIVERY -->
        <div class="json-example-delivery">
             <!-- JSON 1 -->
             <pre> {{ jsonString3 }} </pre>
        </div>

    </div>

</div>
