<div class="container-main">
	<!-- GRID HEADER -->
	<div class="grid-header">
		<!-- TITLE CONTAINER -->
		<div class="span-txt">
			<!-- TITLE -->
			<span class="txt-title">
				{{'overview.cardsOverview.frequencyOfUseByHours' | translate}}
			</span>
			 <!-- ICON INFO -->
			<div class="icon-info">
				<!-- TOOLTIP -->
				<app-tooltip-center-sm class="tooltip-span" [tooltip]="tooltipFrequency">
				</app-tooltip-center-sm>
			</div>
		</div>
	</div>

	<div class="chart" id="chart">
		<apx-chart 	[series]="chartHeatmap.series"
					[responsive]="chartHeatmap.responsive"
					[stroke]="chartHeatmap.stroke"
					[chart]="chartHeatmap.chart"
					[dataLabels]="chartHeatmap.dataLabels"
					[title]="chartHeatmap.title"
					[plotOptions]="chartHeatmap.plotOptions"
					[legend]="chartHeatmap.legend"
					[xaxis]="chartHeatmap.xaxis"
					[yaxis]="chartHeatmap.yaxis"
					[grid]="chartHeatmap.grid"
					[tooltip]="chartHeatmap.tooltip">
		</apx-chart>

		<div class="dates-change">
			<!-- BASE DATE -->
			<div class="span-date"  *ngIf="periodBase.length === 2">
				{{periodBase[0] | date:'MMMM dd, yyyy' }} - {{periodBase[1] | date:'MMMM dd, yyyy' }}
			</div>
			<!-- COMPARE DATE -->
			<div class="span-date"  *ngIf="periodComp.length === 2">
				{{periodComp[0] | date:'MMMM dd, yyyy'}} - {{periodComp[1] | date:'MMMM dd, yyyy'}}
			</div>
		</div>
	</div>
	<div class="container-legend" >
		<span>Min.</span>
		<div class="circle-1"></div>
		<div class="circle-2"></div>
		<div class="circle-3"></div>
		<div class="circle-4"></div>
		<div class="circle-5"></div>
		<span>Max.</span>
	</div>
</div>
