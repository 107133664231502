<!-- CARDS ITERATION -->
<div   
    [ngStyle]="{
    'grid-template-columns': 'repeat(' + cardsCol + ', 1fr)',
    'grid-template-rows': 'repeat(' + cardsRow + ', 1fr)'}"
    class="grid-row">
    <!--? CARDS SM -->
        <div [ngClass]="{
            'smCard'    : cardSize === 'sm',
            'mdCard'    : cardSize === 'md',
            'lgCard-1'  : cardSize === 'lg-1',
            'lgCard-2'  : cardSize === 'lg-2',
            'lgCard-3'  : cardSize === 'lg-3',
            'none'   : cardSize == null }"
            *ngFor="let item of dataCard; let i = index">
            <!-- TODO:FIX DRAG AND DROP -->
            <!-- [ngClass]="{'hover': item.isHovered}"
            [draggable]="true"
            (dragstart)="onDragStart($event, i)" 
            (dragover)="onDragOver($event)" 
            (dragleave)="onDragLeave($event)"
            (dragend)="onDragEnd($event)"
            (drop)="onDrop($event, i)" -->
            <!-- TODO:FIX DRAG AND DROP -->
        
        <div class="size">
            <!-- TODO: FIX DRAG AND DROP -->
            <!-- id="{{i+1}}"draggable="true" -->
            <!-- TODO: FIX DRAG AND DROP -->

            <div class="card-grid-content">
                <!--? HEADER -->
                <div class="header">
                    <!-- TITLE -->
                    <div [ngClass]="{
                         'title-txt' : header === true,
                         'none'   : header == null }">
                        {{item.header | translate }}
                    </div>
                    <!-- TOOLTIP -->
                    <div [ngClass]="{
                         'icon-info' : header === true,
                         'none'   : header == null }">
                        <!-- TOOLTIP CENTER SM -->
                        <tooltip 
                            class="tooltip"
                            [position]="item.positionTooltip"
                            [tooltip]="item.toolTip"
                            >
                        </tooltip>
                    </div>
                </div>
                <!--? BODY -->
                <div [ngClass]="{
                    'body' : body === true,
                    'none'   : body == null }">
                    <span>{{item.data}}</span>
                </div>
                <!--? FOOTER -->
                <div [ngClass]="{
                     'footer' : footer === true,
                     'none'   : footer == null }">
                    <!-- ARROW -->
                    <div [ngClass]="{
                        'success-arrow' : item.percent >= 0,
                        'danger-arrow' : item.percent < 0
                        }"></div>
                    <!-- TXT -->
                    <div [ngClass]="{
                         'success'  : item.percent >= 0,
                         'danger'   : item.percent < 0,
                         'none'     : item.percent == null
                        }">
                        {{item.percent}}%
                    </div>
                    <!-- FOOTER -->
                    <span [ngClass]="{
                        'none': item.footer == null}">
                        {{item.footer | translate }}
                    </span>

                    <!-- TOTAL -->
                    <span [ngClass]="{
                        'none': item.footerData == null}">
                        ({{item.footerData}}) 
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>