<!-- BTN EXPORT -->
<button type="button" class="btn-funnel">
    <div class="icon-funnel">
        <span class="margin-start-icon">
            {{'buttons.filter' | translate }}
        </span>
    </div>
</button>

<button type="button" class="btn-funnel-sm">
    <div class="icon-funnel-sm">
    </div>
</button>