
<div class="date-class" *ngIf="this.isBadDate === false">

    <div class="hora" style="height: 20px;" *ngIf="this.fecha !== null">
        {{this.fecha | hourOriginal}}
    </div>
    <div class="fecha" style="height: auto" *ngIf="this.fecha !== null">
        {{this.fecha | dateOriginal}}
    </div>
	<div class="hora" style="height: 20px;" *ngIf="this.fecha === null">
        ---
    </div>
	<div class="fecha" style="height: auto;" *ngIf="this.fecha === null">
        ---
    </div>
</div>
