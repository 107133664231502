import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexXAxis,
	ApexPlotOptions,
	ApexResponsive,
	ApexFill,
	ApexLegend,
	ApexYAxis,
	ApexStroke,
} from 'ng-apexcharts';

@Component({
	selector: 'app-rating-average',
	templateUrl: './rating-average.component.html',
	styleUrls: ['./rating-average.component.scss'],
})
export class RatingAverageComponent {
	@ViewChild('chart') chart: ChartComponent;
	@Input() dataBase = [];
	@Input() qualification = 0.0;
	@Input() quantityQualification = 0;
	@Input() rating = 0;
	public chartOptions: Partial<ChartOptions>;

	tooltipRating: string;
	constructor(private translate: TranslateService) {

	}

	ngOnInit() {
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.tooltipRating = this.translate.instant('tooltip.qualification');
		this.loadDataChart();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.tooltipRating = this.translate.instant('tooltip.qualification');
		})
	}

	ngOnChanges(changes: SimpleChanges) {
		this.loadDataChart();
	}

	loadDataChart() {
		this.chartOptions = {
			series: this.dataBase.map((item) => ({
				name: item.name,
				data: item.data ? item.data : [0],
			})),
			chart: {
				type: 'bar',
				height: 185,
				toolbar: {
					show: true,
							tools:{
								download:`
									<div class="downloadClassRatingAverage">
										<label class="textExport">
											${this.translate.instant('buttons.export')}
										</label>
										<div class="icon">
										</div>
									</div>
								`,
								pan:false,
								reset:false,
								zoom:false,
								zoomin:false,
								zoomout:false,
								selection:true
					},
				},
			},
			responsive: [
				{
					breakpoint: 620,
					options:{
						chart: {
							height: 0
						},
						series: this.dataBase.map((item) => ({
							name: item.name,
							data: item.data ? item.data : [0],
						}))
					}
				},
			],
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: '100',
				},
			},
			xaxis: {
				labels: {
					show: false,
				},
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				position: 'outside',
				type: 'category',
				categories: ['1', '2', '3', '4', '5'],
			},
			dataLabels: {
				enabled: true,
				offsetX: 300,
				style: {
					colors: ['#a6a6a6'],
					fontFamily: 'Inter',
					fontSize: '10px',
				},
				formatter: function (val: any, opts) {
					let total = opts.w.globals.seriesTotals.reduce(
						(a, b) => a + b,
						0
					);
					let percentage = ((val / total) * 100).toFixed(0);
					return `${val}(${percentage}%)`;
				},
				textAnchor: 'middle',
			},
			fill: {
				opacity: 10,
				colors: ['#8ac6e7', '#54abdc', '#0082cb', '#016299', '#003755'],
			},
			legend: {
				show: false,
			},
			stroke: {
				show: true,
				width: 5,
				colors: ['var(--color-container)'],
			},
		};
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis;
	responsive: ApexResponsive[];
	legend: ApexLegend;
	fill: ApexFill;
	stroke: ApexStroke;
};
