<div class="container-table">
    <div class="grid-header">
        <!-- HEADER COLUMN 1 -->
        <div class="grid-header-column-1">
            <!-- SEARCH -->
            <div class="input-container">
                <input 
                    type="text" class="input-gbl" 
                    (keyup)="onFilterTextChange($event)" 
                    placeholder="{{'dashboard.search_user' | translate}}">
                <button type="submit" class="btn-search"> <div class="icon-search"></div></button>
            </div>
        </div>
        <!--? HEADER COLUMN 2 -->
        <div class="grid-header-column-2">
        <!-- BUTTON EXPORT -->
            <button-export (click)="onExport()" class="{{btnExport}}" ></button-export>
        </div>
    </div>
    <!-- TABLE STYLE -->
    <ag-grid-angular 
        class="ag-theme-alpine" 
        [gridOptions]="gridOptions" 
        [rowData]="rowData" 
        style="width: 100%; height: 750px;">
    </ag-grid-angular>
</div>