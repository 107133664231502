import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-tooltip-center-sm',
	templateUrl: './tooltip-center-sm.component.html',
	styleUrls: ['./tooltip-center-sm.component.scss'],
})
export class TooltipCenterSmComponent {
	@Input() tooltip: string;
}
