import { Component, Input, OnInit } from '@angular/core';
import Highcharts from 'highcharts';

@Component({
	selector: 'app-pie-chart-admin',
	templateUrl: './pie-chart-admin.component.html',
	styleUrls: ['./pie-chart-admin.component.scss'],
})
export class PieChartAdminComponent implements OnInit {
	@Input() sizes;
	chartOptions: any;
	Highcharts: any = Highcharts;

	constructor() {}

	ngOnInit() {}

	ngOnChanges(): void {
		if (this.sizes) {
			this.chartOptions = {
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'column',
					inverted: true,
				},
				title: {
					text: '',
				},
				accesibility: {
					announceNewData: {
						enabled: true,
					},
				},
				xAxis: {
					categories: ['M', 'M/L', 'L', 'XL', 'XXL', 'XXXL'],
					labels: {
						style: {
							color: '#000000',
							fontFamily: 'Font-Roboto',
							fontSize: '14px',
							fontWeight: 'bold',
						},
					},
				},
				yAxis: {
					visible: false,
					title: {
						text: '',
					},
				},
				plotOptions: {
					series: {
						borderRadius: 6,
						pointWidth: 7,
					},
				},
				legend: {
					enabled: false,
				},
				colors: [
					'#00C48C',
					'#BB6BD9',
					'#96C0FF',
					'#7262FD',
					'#65789B',
					'#F96302',
				],
				series: [
					{
						colorByPoint: true,
						data: JSON.parse(this.sizes),
						dataLabels: {
							enabled: true,
							format: '{point.y:.1f}',
						},
						name: 'Size',
					},
				],
				credits: {
					enabled: false,
				},
			};
		}
	}
}
