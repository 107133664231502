import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApexDataLabels, ApexLegend, ApexPlotOptions, ChartComponent} from 'ng-apexcharts';
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	labels: string[];
	colors: string[];
	legend: ApexLegend;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive | ApexResponsive[];
	dataLabels: ApexDataLabels;
};

@Component({
	selector: 'app-cards-summary',
	templateUrl: './cards-summary.component.html',
	styleUrls: ['./cards-summary.component.scss'],
})
export class CardsSummaryComponent implements OnInit, OnChanges {
	@Input() delivery: number;
	@Input() pickup: number;
	@Input() refund: number;
	@Input() registroHistorico:string;
	tooltipDelivery: string = 'Tooltip delivery';
	tooltipChart: string = 'Tooltip chart';
	@ViewChild('chart') chart: ChartComponent;
	public chartOptions: Partial<ChartOptions>;
	totalSeries: number;
	constructor(private translate: TranslateService) {
		translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
	}
	ngOnInit(): void {
		this.loadDataChart();
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
		})
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.loadDataChart();
	}

	private loadDataChart(){
		this.chartOptions = {
			chart: {
				type: 'donut',
			},
			series: [this.delivery, this.pickup, this.refund],
			plotOptions: {
				pie: {
					offsetY: 20,
					dataLabels: {
						minAngleToShowLabel: 999,
					},
				},
			},
			colors: [
				'var(--color-chart-300)',
				'var(--color-chart-200)',
				'var(--color-chart-100)',
			],
			labels: ['Entregados', 'Retirados', 'Devueltos'],
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 0,
						},
					},
				},
			],
		};
		this.totalSeries = this.chartOptions.series.reduce((a, b) => a + b, 0);
	}
}
