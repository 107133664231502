<div class="row">
	<div class="col-12">
		<div class="boxAddNewCarrier">
			<div class="headerAddNewCarrier">
				<label class="text">
					Crea un nuevo Carrier
				</label>
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-x-lg icon" viewBox="0 0 16 16" (click)="closeModal()">
					<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
				</svg>
			</div>
		</div>
    </div>
</div>

<div class="container-fluid">
	<mat-stepper [linear]="true" class="carrierStepper" #stepper (selectionChange)="onStepChanged($event)">
		<mat-step [completed]="currentStep > 0" [ngClass]="{'select-step': currentStep === 0}">
			<ng-template matStepLabel>
				{{ "registerCarrier.step1" | translate }}
			</ng-template>
			<div class="container-fluid" style="text-align: -webkit-center;">
				<div class="formContainer">
					<div class="row">
						<div class="col-12">
							<div class="boxPrincipal">
								<form [formGroup]="informationCarrierForm" class="formGroupBox">
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep1.nameCarrier' | translate}}
										</label>
										<input
											type="text"
											class="formInput"
											[ngClass]="{ 'borderError' : errorCarrier === true}"
											formControlName="nameCarrier"
											(ngModelChange)="modelChangedInformationCarrier($event)"/>
										<label
											*ngIf="errorCarrier === true"
											class="errorText">
											ERROR-{{this.typeMsgNameCarrier}}
										</label>
									</div>
									<div class="formBox">
										<label class="formLabel">
											Acrónimo
										</label>
										<input
											type="text"
											class="formInput"
											[ngClass]="{ 'borderError' : errorCarrier === true}"
											formControlName="acronymCarrier"
											(ngModelChange)="modelChangedInformationCarrier($event)"/>
										<label
											*ngIf="errorCarrier === true"
											class="errorText">
											ERROR-{{this.typeMsgNameCarrier}}
										</label>
									</div>
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep1.rfcCarrier' | translate}}
										</label>
										<input
											type="text"
											class="formInput"
											[ngClass]="{ 'borderError' : errorRFC === true}"
											formControlName="rfcCarrier"
											(ngModelChange)="modelChangedInformationCarrier($event)"/>
										<label *ngIf="errorRFC === true" class="errorText">
											ERROR-{{this.typeMsgRFCError}}
										</label>
									</div>
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep1.addressCarrier' | translate}}
										</label>

										<label
											*ngIf="errorAddress === true"
											class="errorText">
											ERROR-{{this.typeMsgAddressError}}
										</label>
									</div>
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep1.telephoneCarrier' | translate}}
										</label>
										<input
											type="text"
											class="formInput"
											[ngClass]="{ 'borderError' : errorTelephone === true}"
											formControlName="telephoneCarrier"
											(ngModelChange)="modelChangedInformationCarrier($event)"/>
										<label
											*ngIf="errorTelephone === true"
											class="errorText">
											ERROR-{{this.typeMsgTelephoneError}}
										</label>
									</div>

								</form>
								<div class="boxButtons">
									<app-button
										[btnNombre]="this.btnCancelar"
										btnType="secondaryMedium"
										(click)="closeModal()">
									</app-button>
									<div *ngIf="this.validationInformationCarrierForm === true">
										<app-button
											[btnNombre]="this.btnAceptar"
											btnType="primaryMedium"
											[activo]="false"
											(click)="saveInformationCarrier()">
										</app-button>
									</div>
									<div *ngIf="this.validationInformationCarrierForm === false">
										<app-button
											[btnNombre]="this.btnAceptar"
											btnType="primaryMedium"
											[activo]="true">
										</app-button>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</mat-step>
		<mat-step [completed]="currentStep > 1" [ngClass]="{'select-step': currentStep === 1}">
			<ng-template matStepLabel>
				{{ "registerCarrier.step2" | translate }}
			</ng-template>
			<div class="container" style="text-align: -webkit-center;">
				<div class="formContainer">
					<div class="row">
						<div class="col-12">
							<div class="boxPrincipal">
								<form [formGroup]="preferencesCarrierForm" class="formGroupBox">
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep2.hourExpired' | translate}}
										</label>
										<!-- <input
											type="text"
											class="formInput"
											[ngClass]="{ 'borderError' : errorHourExpired === true}"
											formControlName="hourExpiredPackaged"
											(ngModelChange)="modelChangedPreferences($event)"/> -->
										<p-calendar
											class="formInputCalendarHour"
											dateFormat="HH:mm"
											formControlName="hourExpiredPackaged"
											[showIcon]="true"
											[showTime]="true"
											[timeOnly]="true">
										</p-calendar>
										<label
											*ngIf="errorHourExpired === true"
											class="errorText">
											ERROR-{{this.typeMsgHourExpiredError}}
										</label>
									</div>
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep2.daysExpired' | translate}}
										</label>
										<input
											type="number"
											class="formInput"
											[ngClass]="{ 'borderError' : this.errorDaysExpired === true}"
											formControlName="daysExpiredPackaged"
											(ngModelChange)="modelChangedPreferences($event)"/>
										<label
											*ngIf="this.errorDaysExpired === true"
											class="errorText">
											ERROR-{{this.typeMsgDaysExpiredError}}
										</label>
									</div>
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep2.credentialsExpired' | translate}}
										</label>
										<input
											type="number"
											class="formInput"
											[ngClass]="{ 'borderError' : errorExpiredCredentials === true}"
											formControlName="whenExpiredCredentials"
											(ngModelChange)="modelChangedPreferences($event)"/>
										<label
											*ngIf="errorExpiredCredentials === true"
											class="errorText">
											ERROR-{{this.typeMsgExpiredCredentials}}
										</label>
									</div>
									<div class="formBoxApi">
										<label class="formLabel">
											{{'registerCarrier.formStep2.Api' | translate}}
										</label>
										<mat-slide-toggle formControlName="apiValue" [disableRipple]="true">
										</mat-slide-toggle>
									</div>

								</form>
								<div class="boxButtons">
									<app-button
										[btnNombre]="this.btnCancelar"
										btnType="secondaryMedium"
										(click)="closeModal()">
									</app-button>
									<div *ngIf="this.validationPreferencesCarrierForm === true">
										<app-button
											[btnNombre]="this.btnAceptar"
											btnType="primaryMedium"
											[activo]="false"
											(click)="saveInformationCarrier()">
										</app-button>
									</div>
									<div *ngIf="this.validationPreferencesCarrierForm === false">
										<app-button
											[btnNombre]="this.btnAceptar"
											btnType="primaryMedium"
											[activo]="true">
										</app-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-step>
		<mat-step [completed]="currentStep > 2" [ngClass]="{'select-step': currentStep === 2}">
			<ng-template matStepLabel>
				{{ "registerCarrier.step3" | translate }}
			</ng-template>
			<div class="container" style="text-align: -webkit-center;">
				<div class="formContainer">
					<div class="row">
						<div class="col-12">
							<div class="boxPrincipal">
								<form [formGroup]="addUserCarrierForm" class="formGroupBox">
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep3.nameUser' | translate}}
										</label>
										<input
											type="text"
											class="formInput"
											[ngClass]="{ 'borderError' : errorNameUser === true}"
											formControlName="nameUser"
											(ngModelChange)="modelChangedAddUser($event)"/>
										<label
											*ngIf="errorNameUser === true"
											class="errorText">
											ERROR-{{this.typeMsgNameUserError}}
										</label>
									</div>
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep3.typeUser' | translate}}
										</label>
										<p-dropdown class="formInputTypeUser" [options]="[defaultValue]" formControlName="typeUser">

										</p-dropdown>
										<label *ngIf="errortypeUser === true" class="errorText">
											ERROR-{{this.typeMsgTypeUserError}}
										</label>
									</div>
									<div class="formBox">
										<label class="formLabel">
											{{'registerCarrier.formStep3.emailUser' | translate}}
										</label>
										<input
											type="text"
											class="formInput"
											[ngClass]="{ 'borderError' : errorEmail === true}"
											formControlName="emailUser"
											(ngModelChange)="modelChangedAddUser($event)"/>
										<label
											*ngIf="errorEmail === true"
											class="errorText">
											ERROR-{{this.typeMsgEmail}}
										</label>
									</div>
									<div class="passwordInformation">
										<div class="icon-info">
										</div>
										<label class="textPasswordInformation">
											La contraseña para el acceso a Dashboard
											será enviada al correo que proporcionaste.
										</label>
									</div>
								</form>
								<div class="boxButtons">
									<app-button
										[btnNombre]="this.btnCancelar"
										btnType="secondaryMedium"
										(click)="closeModal()">
									</app-button>
									<div *ngIf="this.validationAddUserCarrierForm === true">
										<app-button
											[btnNombre]="this.btnAceptar"
											btnType="primaryMedium"
											[activo]="false"
											(click)="saveCarrier()">
										</app-button>
									</div>
									<div *ngIf="this.validationAddUserCarrierForm === false">
										<app-button
											[btnNombre]="this.btnAceptar"
											btnType="primaryMedium"
											[activo]="true">
										</app-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-step>
	</mat-stepper>
</div>
