import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-estatus-table-access-point',
	templateUrl: './estatus-table-access-point.component.html',
	styleUrls: ['./estatus-table-access-point.component.scss']
})
export class EstatusTableAccessPointComponent implements OnInit,ICellRendererAngularComp {
	statusID:number;
	constructor() { }
	agInit(params: ICellRendererParams): void {
		this.statusID = params.value;
	}
	refresh(params: ICellRendererParams): boolean {
		return false;
	}

	ngOnInit(): void {
	}

}
