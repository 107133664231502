import { Component } from '@angular/core';
import { CardData } from 'src/app/core/models';
import { DataService } from 'src/app/core/services/data.service';

@Component({
	selector: 'app-grid2',
	templateUrl: './grid2.component.html',
	styleUrls: ['./grid2.component.scss'],
})
export class Grid2Component {
	//USERNAME
	username: string;
	//? KPIS SETTINGS ---------------------->
	// GRID STYLE
	gridOverview: string = 'grid-card-1';

	dataCardSm: CardData[] = [
		// CARD 1
		{
			header: '',
			data: 0,
			percent: 0,
			footer: '',
			footerData: 0,
			toolTip: '',
		},
		// CARD 1
		{
			header: '',
			data: 0,
			percent: 0,
			footer: '',
			footerData: 0,
			toolTip: '',
		},
		// CARD 1
		{
			header: '',
			data: 0,
			percent: 0,
			footer: '',
			footerData: 0,
			toolTip: '',
		},
		// CARD 1
		{
			header: '',
			data: 0,
			percent: 0,
			footer: '',
			footerData: 0,
			toolTip: '',
		},
		// CARD 1
		{
			header: '',
			data: 0,
			percent: 0,
			footer: '',
			footerData: 0,
			toolTip: '',
		},
		// CARD 1
		{
			header: '',
			data: 0,
			percent: 0,
			footer: '',
			footerData: 0,
			toolTip: '',
		},
	];

	constructor(public ds: DataService) {
		this.username = this.ds.alias_username;
	}
}
