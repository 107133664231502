<div class="date-class" *ngIf="this.estatus === true">

    <div class="hora" style="height: 20px;">
        {{this.fecha | hourOriginal}}
    </div>
    <div class="fecha" style="height: 26px;">
        {{this.fecha | dateOriginal}}
    </div>

</div>
<div class="date-class" *ngIf="this.estatus === false">
    ---
</div>

