<!-- ? CONTAINER MAIN -->
<div class="container-main">
    <!-- ? HEADER -->
    <header>
        <!-- LOGO -->
        <img src="../../../../../assets/images/logo_ticket.svg" class="logo_ticket" alt="logo">
        <!-- CONTAINER IMAGES -->
        <div class="img_fragil" *ngIf="is_fragile">
            <img src="../../../../../assets/icons/fragile.svg" class="grafile_icon" alt="fragile">
        </div>
        <div class="img_size">
            <span>{{this.TicketResponse.locker.compartment.compartment_name}}</span>
        </div>
    </header>
    <!-- ? ORIGIN | DESTINY -->
    <div class="grid_origin_destiny">
        <!-- ? ORIGIN -->
        <div class="container_origin">
            <!-- TITLE -->
            <h2> {{'Origen'}} </h2>
            <!-- DESCRIPTION -->
            <div class="container_p">
				<p> {{this.TicketResponse.consumer.name_consumer_source}} </p>
                <p> {{this.TicketResponse.locker.locker_origin.locker_name}} </p>
                <p> {{this.TicketResponse.locker.locker_origin.address}} </p>
            </div>
        </div>
        <div class="hr_vertical"></div>
        <!-- ? DESTINY -->
        <div class="container_destiny">
            <!-- TITLE -->
            <h2> {{'Destino'}} </h2>
            <!-- DESCRIPTION -->
            <div class="container_p">
				<p> {{this.TicketResponse.consumer.name_consumer_destination}} </p>
                <p> {{this.TicketResponse.locker.locker_destination.locker_name}} </p>
                <p> {{this.TicketResponse.locker.locker_destination.address}} </p>
            </div>
        </div>
    </div>



    <!-- ? CONTAINER W01 -->
    <div class="container_w01">
        <div class="w01">
            <!-- TITLE -->
            <h2>
				{{this.TicketResponse.warehouse.warehouse_name}}
			</h2>
            <!-- CODE -->
            <p class="date_imp">  {{'Fecha de impresion:'}} {{this.TicketResponse.printing_date}} </p>
            <p class="date_imp">  {{'Dimensiones:'}} {{this.TicketResponse.locker.compartment.compartment_dimension}} </p>
            <p class="date_imp">  {{'Peso aprox:'}} {{this.TicketResponse.locker.compartment.approximate_weight}}kg </p>
        </div>
        <!-- IMG QR -->
		<div class="qr_img">
			<canvas class="canvasQr" width="55" height="55" #qrCodeCanvasCEDIS  style="width: 55px; height: 55px;"></canvas>
		</div>
    </div>
    <!-- ? W01 CODE FOOTER -->
    <div class="w01_block2">
        <h3> {{this.TicketResponse.package.qr_cedis}} </h3>
    </div>
    <!-- ? FOOTER QRS -->
    <div class="footerTicket">
        <!-- ? QR 1 -->
        <div class="container_qr_1">
			<div class="qr_img_footer">
				<canvas class="canvasQrOrigin" #qrCodeCanvasOrigen width="55" height="55" style="width: 55px; height: 55px;"></canvas>
				<!-- TXT FOOT -->
				<div class="boxText">
					<div class="text">
						O
					</div>
				</div>
			</div>

        </div>
        <!-- ? QR 2 -->
        <div class="container_qr_2">
            <canvas class="canvasQrOrigin" #qrCodeCanvasDestino width="55" height="55" style="width: 55px; height: 55px;"></canvas>
            <!-- TXT FOOT -->
            <div class="boxText">
				<div class="text">
					D
				</div>
			</div>
        </div>
    </div>
</div>
