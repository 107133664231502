import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Route, Router, RouterLink } from '@angular/router';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';

@Component({
	selector: 'app-filter-orders',
	templateUrl: './filter-orders.component.html',
	styleUrls: ['./filter-orders.component.scss']
})
export class FilterOrdersComponent implements OnInit {
	@Input() filters:boolean=false;
	@Input() status:boolean;
	@Input() rate:boolean;
	checkboxReturn					: boolean = false;
	checkboxCompleted 				: boolean = false;
	checkboxInLocker 				: boolean = false;
	checkboxExpired 				: boolean = false;
	checkboxExtended 				: boolean = false;



	checkboxOne						: boolean = false;
	checkboxTwo						: boolean = false;
	checkboxThree 					: boolean = false;
	checkboxFour					: boolean = false;
	checkboxFive					: boolean = false;
	arrayFilterStars: Set<number> = new Set<number>();
	urlString;
	partsURL = [];
	urlActual: string;
	arrayFilterEstatus: Set<number> = new Set<number>();


	constructor(private filterService: FilterService,private router: Router) {
		this.urlString = router.url;
		this.partsURL = this.urlString.split('/');
		this.urlActual = this.partsURL[2];
	}
	ngOnInit(): void {
	}
	actualizarValorEnArreglo(valor: number, isChecked: boolean) {
		if (isChecked) {
			this.arrayFilterEstatus.add(valor);
		} else {
			this.arrayFilterEstatus.delete(valor);
		}
		this.filterService.updateEstatusFilter(this.arrayFilterEstatus);
	}

	actualizarStarsEnArreglo(valor: number, isChecked: boolean) {
		if (isChecked) {
			this.arrayFilterStars.add(valor);
		} else {
			this.arrayFilterStars.delete(valor);
		}
		this.filterService.updateStarsFilter(this.arrayFilterStars);
	}
	validarCasillasSeleccionadas() {


		this.actualizarValorEnArreglo(1, this.checkboxReturn); 		//LOCKER 	->  status_package :33,status_code_package: 32, extended_package : 0
		this.actualizarValorEnArreglo(2, this.checkboxCompleted);	//COMPLETED ->  status_package :16,status_code_package: 18, extended_package : 0
		this.actualizarValorEnArreglo(3, this.checkboxInLocker);	//INLOCKER 	->  status_package :14,status_code_package: 17, extended_package : 0
		this.actualizarValorEnArreglo(4, this.checkboxExtended);	//EXTENDED 	->  status_package :14,status_code_package: 17, extended_package : 1
		this.actualizarValorEnArreglo(5, this.checkboxExpired);		//EXPIRED 	->  status_package :14,status_code_package: 32, extended_package : 0
	}
	validarStars(){
		this.actualizarStarsEnArreglo(1, this.checkboxOne);
		this.actualizarStarsEnArreglo(2, this.checkboxTwo);
		this.actualizarStarsEnArreglo(3, this.checkboxThree);
		this.actualizarStarsEnArreglo(4, this.checkboxFour);
		this.actualizarStarsEnArreglo(5, this.checkboxFive);
	}

	openStatus:boolean=false;
	openStatusDiv(){
		this.openStatus = !this.openStatus;
		this.openInLocker = false;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = false;
		this.openRate = false;
		this.openCoverage = false;
	}
	openInLocker:boolean=false;
	openStatusInLocker(){
		this.openInLocker = !this.openInLocker;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = false;
	}

	openInProcess:boolean=false;
	openStatusInProcess(){
		this.openInLocker = false;
		this.openInProcess = !this.openInProcess;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = false;
	}

	openExpired:boolean=false;
	openStatusExpired(){
		this.openInLocker = false;
		this.openInProcess = false;
		this.openExpired = !this.openExpired;
		this.openScheduled = false;
		this.openReturns = false;
	}

	openScheduled:boolean=false;
	openStatusScheduled(){
		this.openInLocker = false;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = !this.openScheduled;
		this.openReturns = false;
	}

	openReturns:boolean=false;
	openStatusReturns(){
		this.openInLocker = false;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = !this.openReturns;
	}

	openRate:boolean=false;
	openRateDiv(){
		this.openRate = !this.openRate;
	}

	openCoverage:boolean=false;
	openCoverageDiv(){
		this.openStatus = false;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = false;
		this.openRate = false;
		this.openCoverage = !this.openCoverage;
	}

	closeModal(){
		this.filters = false
	}
}
