import { HttpClient } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { EstatusTableOrderAdminComponent } from '../estatus-table-order-admin/estatus-table-order-admin.component';
import { Component, Input, OnInit } from '@angular/core';
import { ModuleCarrierService } from 'src/app/core/http/carriers/module_carrier/module-carrier.service';
import { QueryCarrierOrder, ReturnDataOrder } from 'src/app/core/models/carriers/module-carrier/carrier-mod.interface';
import { EstatusTableCarrierOrderComponent } from '../estatus-table-carrier-order/estatus-table-carrier-order.component';

@Component({
	selector: 'app-table-carrier-order',
	templateUrl: './table-carrier-order.component.html',
	styleUrls: ['./table-carrier-order.component.scss']
})
export class TableCarrierOrderComponent implements OnInit {
	@Input() CarrierID:number;
	gridOptions: GridOptions;
	rowData: ReturnDataOrder[];
	public columnDefs: ColDef[];
	constructor(
		private translate		:TranslateService,
		private http			: HttpClient,
		private modCarrierService: ModuleCarrierService
	){}
	ngOnInit(): void {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateTableCarrierOrder();
		this.getTableCompleted();
		this.translate.onLangChange.subscribe((event:LangChangeEvent)=>{
			this.translate.use(event.lang);
			this.translate.get([
				'tableCarrierOrder.tracking',
				'tableCarrierOrder.lockerID',
				'tableCarrierOrder.status'
			]).subscribe((t:any) =>{
				this.columnDefs=[
					{
						headerName: t['tableCarrierOrder.tracking'],
						field:'tracking_number',
						width:204,
						cellClass:'carriersOrdersClass',
						headerClass:'borderHeaderCarriersOrdersAdmin',
					},
					{
						headerName: t['tableCarrierOrder.lockerID'],
						field:'locker_name',
						width:204,
						cellClass:'carriersOrdersClass',
						headerClass:'borderHeaderCarriersOrdersAdmin',
					},
					{
						headerName: t['tableCarrierOrder.status'],
						field:'status_package',
						width:208,
						cellClass:'carriersOrdersClass',
						headerClass:'borderHeaderCarriersOrdersAdmin',
						cellRenderer: EstatusTableCarrierOrderComponent
					}
				]
			})
			this.getTableCompleted();
		})


	}
	updateTableCarrierOrder(){
		this.gridOptions = {
			columnDefs:[
				{
					headerName:this.translate.instant('tableCarrierOrder.tracking'),
					field:'tracking_number',
					width:204,
					cellClass:'carriersOrdersClass',
					headerClass:'borderHeaderCarriersOrdersAdmin',
				},
				{
					headerName:this.translate.instant('tableCarrierOrder.lockerID'),
					field:'locker_name',
					width:204,
					cellClass:'carriersOrdersClass',
					headerClass:'borderHeaderCarriersOrdersAdmin',
				},
				{
					headerName:this.translate.instant('tableCarrierOrder.status'),
					field:'status_package',
					width:208,
					cellClass:'carriersOrdersClass',
					headerClass:'borderHeaderCarriersOrdersAdmin',
					cellRenderer: EstatusTableCarrierOrderComponent
				}
			],
			animateRows: true,
			headerHeight:60,
			rowHeight:60,
			rowClass:'rowCarriersOrderClass',
			scrollbarWidth:0,
			alwaysShowVerticalScroll:false,
			alwaysShowHorizontalScroll:false,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}
	getTableCompleted(){
		// this.rowData = [];
		// this.http.get<any[]>('../../../../../assets/dummy/ordenes-recientes.json').subscribe((data) => {
		// 	this.rowData = data;
		// });
		this.rowData = [];
		let object ={
			id_courier: this.CarrierID,
			language: localStorage.getItem('language').toLowerCase(),
			limit:5
		}
		this.modCarrierService.getCarrierDataOrders(object).then((res:QueryCarrierOrder)=>{
			if(res.mensaje_return.CODE === 200){
				res.return_data_order.forEach((res)=>{
					this.rowData.push(res);

				})
				this.rowData = [...this.rowData];
			}
		})
	}
}
