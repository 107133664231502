<div class="card" style="height: 500px; width: 100%;">
    <div class="card-body">
        <div class="card-title" style="text-align: initial;">
            {{ 'lockers.orders_delivered' | translate }}
            <i class="fas fa-info-circle" matTooltip="{{'info.lockers.historical.orders_delivered_compartment_size' | translate }}" matTooltipPosition="above">
            </i>
        </div>
        <app-chart-bar-admin type="metrics" arrayName="{{compSizes}}"></app-chart-bar-admin>    
    </div>
</div>
