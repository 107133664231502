<div class="container-table">
    <div class="grid-header">
        <div class="grid-header-column-1">
            <div class="input-container">
                <input type="text" (keyup)="onFilterTextChange($event)" class="input-gbl" placeholder="{{'dashboard.search_user' | translate}}">
                <button type="submit" class="btn-search"> <div class="icon-search"></div></button>
            </div>
        </div>
        <div class="grid-header-column-2">
			<app-button-icon
				*ngIf="this.mode ==='light'"
				[activo]="false"
				btnNombre="Filtros"
				btnType="secondaryMedium"
				icon="icon-funnel"
				(click)="showFilters()">
			</app-button-icon>
			<app-button-icon
				*ngIf="this.mode ==='dark'"
				[activo]="false"
				btnNombre="Filtros"
				btnType="secondaryMedium"
				icon="icon-funnel-dark"
				(click)="showFilters()">
			</app-button-icon>
            <button-export (click)="onExport()" class="{{btnExport}}"></button-export>
        </div>
    </div>
    <!--? TABLE GRID -->
    <div class="row-2">
        <ag-grid-angular
			#agGrid
            class="ag-theme-alpine"
            [gridOptions]="gridOptions"
			[columnDefs]="this.columnDefs"
            [rowData]="this.rowData"
            style="width: 100%; height: 750px;"
			(gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>



<app-filter-clients [filters]="this.filters">

</app-filter-clients>

