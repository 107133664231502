import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-footer-admin',
	templateUrl: './footer-admin.component.html',
	styleUrls: ['./footer-admin.component.scss'],
})
export class FooterAdminComponent implements OnInit {
	version: string;

	constructor() {
		this.version = localStorage.getItem('version');
	}

	ngOnInit() {}
}
