import { Component, Input } from '@angular/core';

@Component({
	selector: 'stars',
	templateUrl: './stars.component.html',
	styleUrls: ['./stars.component.scss'],
})
export class StarsComponent {
	// STARS
	@Input() stars: number[];
	// STAR
	@Input() rating: number;
	@Input() gap: number;

	getStarClass(index: number): string {
		if (index < this.rating) {
			return 'star-fill';
		} else {
			return 'star-outline';
		}
	}
}
