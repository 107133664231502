import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-compartment-size',
	templateUrl: './compartment-size.component.html',
	styleUrls: ['./compartment-size.component.scss'],
})
export class CompartmentSizeComponent {
	@Input() metrics!: any[];
}
