import {
	Component,
	Input,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientSign } from '../dialogs-admin/client-dialog';

@Component({
	selector: 'app-audit-table-admin',
	templateUrl: './audit-table-admin.component.html',
	styleUrls: ['./audit-table-admin.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition(
				'expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
			),
		]),
	],
})
export class AuditTableAdminComponent implements OnInit {
	@Input() height: number = 650;
	@Input() data;
	@Input() columns: string;
	@Input() view_detail: boolean = false;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataSource: MatTableDataSource<[]>;
	displayedColumns: string[];
	date_currient = new Date();
	name_file =
		'data_' +
		this.date_currient.getDate() +
		'_' +
		(this.date_currient.getMonth() + 1) +
		'_' +
		this.date_currient.getFullYear();

	expandedElement: PeriodicElement | null;

	constructor(
		private translate: TranslateService,
		private dialog: MatDialog
	) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (this.data) {
			this.updateDataList(JSON.parse(this.data));
			this.displayedColumns = this.columns.split(',');
		}
	}

	updateDataList(data_list: any) {
		this.dataSource = new MatTableDataSource(data_list);
		/*this.dataSource.forEach((d:any) => {
      expandedElement : 
    })*/
		this.dataSource.paginator = this.paginator;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	compare(
		a: number | string | Date,
		b: number | string | Date,
		isAsc: boolean
	) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	sortData(sort: Sort) {
		const data = this.dataSource.filteredData.slice();

		var data_soure: any = data.sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'idEvent':
					return this.compare(
						Number(a.idEvent),
						Number(b.idEvent),
						isAsc
					);
				case 'orderNumberEvent':
					return this.compare(
						Number(a.orderNumberEvent),
						Number(b.orderNumberEvent),
						isAsc
					);
				case 'topicNameEvent':
					return this.compare(
						String(a.topicNameEvent),
						String(b.topicNameEvent),
						isAsc
					);
				case 'trackingNumberEvent':
					return this.compare(
						String(a.trackingNumberEvent),
						String(b.trackingNumberEvent),
						isAsc
					);
				case 'lockerNameEvent':
					return this.compare(
						String(a.lockerNameEvent),
						String(b.lockerNameEvent),
						isAsc
					);
				case 'employeeDeliveryEvent':
					return this.compare(
						String(a.employeeDeliveryEvent),
						String(b.employeeDeliveryEvent),
						isAsc
					);
				case 'employeeRefundEvent':
					return this.compare(
						String(a.employeeRefundEvent),
						String(b.employeeRefundEvent),
						isAsc
					);
				case 'registrationDateEvent':
					return this.compare(
						new Date(a.registrationDateEvent),
						new Date(b.registrationDateEvent),
						isAsc
					);
				default:
					return 0;
			}
		});
		this.updateDataList(data_soure);
	}

	pickedModal(img) {
		this.dialog.open(ClientSign, {
			width: window.innerHeight < window.innerWidth ? '35%' : '60%',
			height: window.innerHeight < window.innerWidth ? '65%' : '50%',
			data: {
				image: 'https://my.vivipost.com/qr_vivipost/' + img + '.png',
				type: 'qr',
			},
		});
	}
}

export interface PeriodicElement {
	idEvent: string;
	topicNameEvent: string;
	trackingNumberEvent: string;
	lockerNameEvent: string;
	registrationDateEvent: string;
}
