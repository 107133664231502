import {
	Component,
	ElementRef,
	Input,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GoogleMapsService } from '../../../../core/services/google-maps/google-maps.service';
import { SharedService } from '../../../../core/services/table_to_map.service';

@Component({
	selector: 'app-locker-map',
	templateUrl: './locker-map.component.html',
	styleUrls: ['./locker-map.component.scss'],
})
export class LockerMapComponent implements OnInit {
	@ViewChild('google_map', { static: true }) mapElementRef: ElementRef;
	@Input() latitud;
	@Input() longitud;
	@Input() zoom;
	@Input() coords: any;

	googleMaps: any;
	map: any;
	marker: any;
	clickEventsubscription: Subscription;
	count: number = 0;

	constructor(
		private maps: GoogleMapsService,
		private renderer: Renderer2,
		private sharedService: SharedService
	) {
		this.clickEventsubscription = this.sharedService
			.getClickEvent()
			.subscribe((data) => {
				this.latitud = Number(data.lat);
				this.longitud = Number(data.lng);
				this.zoom = data.zoom;
			});
	}

	ngOnInit(): void {
		this.loadMap().catch((err) => {
			console.log(err);
		});
	}

	async loadMap() {
		try {
			let googleMaps: any = await this.maps.loadGoogleMaps();
			this.googleMaps = googleMaps;
			const mapEl = this.mapElementRef.nativeElement;

			const location = new googleMaps.LatLng(this.latitud, this.longitud);
			this.map = new googleMaps.Map(mapEl, {
				center: location,
				zoom: this.zoom,
				scaleControl: false,
				streetViewControl: false,
				zoomControl: false,
				overviewMapControl: false,
				mapTypeControl: false,
				mapTypeControlOptions: {
					mapTypeIds: [googleMaps.MapTypeId.ROADMAP, 'mapId'],
				},
			});
			this.renderer.addClass(mapEl, 'visible');

			for (const element of this.coords) {
				this.addMarker(new googleMaps.LatLng(element.lat, element.lng));
			}
		} catch (e) {
			console.log(e);
		}
	}

	addMarker(location) {
		let googleMaps: any = this.googleMaps;
		this.marker = new googleMaps.Marker({
			position: location,
			map: this.map,
			draggable: false,
			animation: googleMaps.Animation.DROP,
		});
	}
}
