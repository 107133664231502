<div class="row">
	<div class="col-6">
		<div class="input-container">
			<input type="text" (keyup)="onFilterTextChange($event)" class="input-gbl" placeholder="{{'dashboard.searchLocker' | translate}}">
			<button type="submit" class="btn-search"> <div class="icon-search"></div></button>
		</div>
	</div>
    <div class="col-6" style="text-align: end;">
        <p-dropdown [options]="this.comparment" (ngModelChange)="onLockerChange($event)" [(ngModel)]="selectedLocker" optionLabel="label" placeholder="Select a Comparment" [showClear]="true">
        </p-dropdown>
    </div>


    <div class="col-12 mt-3 selectLocker">
        <div class="table-responsive">
            <ag-grid-angular 	#agGrid
                                    (gridReady)="onGridReady($event)"
                                    style="width: 100%; height: 408px"
                                    class="ag-theme-alpine"
                                    [gridOptions]="gridOptions"
                                    [rowData]="lockers"
                                    [animateRows]="true">
            </ag-grid-angular>
        </div>
    </div>
</div>
