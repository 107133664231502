import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApexLegend, ApexPlotOptions, ChartComponent, ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexStroke } from 'ng-apexcharts';
@Component({
	selector: 'app-chart-donut-summary',
	templateUrl: './chart-donut-summary.component.html',
	styleUrls: ['./chart-donut-summary.component.scss'],
})
export class ChartDonutSummaryComponent implements OnInit, OnChanges  {
	@ViewChild('chart') chart: ChartComponent;
	@Input() delivery: number;
	@Input() pickup: number;
	@Input() refund: number;
	public chartOptionsDonut: Partial<ChartOptions>;

	tooltipDelivery: string = 'Tooltip delivery';
	tooltipChart: string = 'Tooltip chart';
	totalSeries: number;

	constructor(private translate: TranslateService) {
		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
	}
	ngOnInit() {
		this.loadDataChart();
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
		})
	}

	ngOnChanges(changes: SimpleChanges):void {
		this.loadDataChart();
	}

	private loadDataChart() {
		this.chartOptionsDonut = {
			chart: {
				height:180,
				width:'100%',
				type: 'donut',
				toolbar: {
					show: false,
					tools:{
						download:`
						<div class="downloadClassFrequencyUse">
							<label class="textExport">
								${this.translate.instant('buttons.export')}
							</label>
							<div class="icon">
							</div>
						</div>
						`,
						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:true
					},
				},
			},
			series: [this.delivery, this.pickup, this.refund],
			plotOptions: {
				pie: {
					offsetY: 20,
					dataLabels: {
						offset: 9999999999,
					},
					donut:{
						labels:{
							show:true,
							total:{
								show:true,
								color:'var(--color-primary)',
								fontSize:'14px',
								fontWeight:'500',
								fontFamily:'var(--primary-font)',
								showAlways:true,
								formatter: (w: any) =>
									w.globals.seriesTotals.reduce(
										(a: number, b: number) => a + b,
										0
									),
							},
							value: {
								show: true,
								color: 'var(--color-primary)',
								fontSize: '14px',
								fontWeight: '500',
								fontFamily: 'Inter',
								offsetY: 0,
							}
						}
					}
				},
			},
			colors: [
				'var(--color-chart-300)',
				'var(--color-chart-200)',
				'var(--color-chart-100)',
			],
			labels: ['Entregados', 'Retirados', 'Devueltos'],
			legend: {
				show: false,
				horizontalAlign: 'center',
				fontSize: 'var(--font-c3)',
				width: 200,
				position: 'bottom',
				fontFamily: 'var(--primary-font)',
				offsetY: 10,
				labels: {
					useSeriesColors: true,
				},
				itemMargin: {
					horizontal: 3,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 0,
						},
					},
				},
			],
			stroke: {
				show: true,
				width: 2,
				colors: ['var(--color-container)'],
			},
		};
	}
}

export type ChartOptions = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	labels: string[];
	colors: string[];
	legend: ApexLegend;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive | ApexResponsive[];
	stroke: ApexStroke;
};
