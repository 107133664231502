<div class="card" style="height: 100%; width: auto;">
    <div class="card-body">
        <div class="card-title" style="text-align: initial;">
            {{ 'lockers.orders_refund' | translate }}
            <i class="fas fa-info-circle" matTooltip="{{'info.lockers.historical.orders_refund_month' | translate }}" matTooltipPosition="above">
            </i>
        </div>
        <app-chart-client-admin type="dashboard" data="{{datar}}"></app-chart-client-admin>
    </div>
</div>

