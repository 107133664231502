import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { GoogleMapsService } from '../../../core/services/google-maps/google-maps.service';
import { SharedService } from '../../../core/services/table_to_map.service';

@Component({
	selector: 'app-google-maps',
	templateUrl: './google-maps.component.html',
	styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit, AfterViewInit {
	@ViewChild('google_map', { static: true }) mapElementRef: ElementRef;
	@Input() latitud;
	@Input() longitud;
	@Input() zoom;
	@Input() coords: any;
	@Output() eventAddress = new EventEmitter<string>();

	googleMaps: any;
	map: any;
	clickEventsubscription: Subscription;
	markers: any;
	combinedMarker: any;
	geocoder: any;
	lstCombinedMarker: google.maps.Marker[] = [];
	// count: number = 0;

	constructor(private maps: GoogleMapsService,private renderer: Renderer2,private sharedService: SharedService) {



	}

	ngOnInit(): void {
		this.getMap();
	}
	ngAfterViewInit() {
		this.getMap();
	}

	clickChange() {
		this.ngAfterViewInit();
	}

	getMap(){
		if(localStorage.getItem('lat') !== null && localStorage.getItem('lng') !== null){
			// this.clickEventsubscription = this.sharedService.getClickEvent().subscribe((data) => {
			// 	this.zoom = data.zoom;
			// });
			this.loadMap();
		}
		else{
			this.clickEventsubscription = this.sharedService.getClickEvent().subscribe((data) => {
				this.latitud = Number(data.lat);
				this.longitud = Number(data.lng);
				this.zoom = data.zoom;
			});
			this.loadMap();
		}
	}
	async loadMap() {


		try {
			let googleMaps: any = await this.maps.loadGoogleMaps();
			this.googleMaps = googleMaps;
			const mapEl = this.mapElementRef.nativeElement;
			const location = new googleMaps.LatLng(this.latitud, this.longitud);
			this.map = new googleMaps.Map(mapEl, {
				center: location,
				zoom: this.zoom,
				scaleControl: false,
				streetViewControl: false,
				zoomControl: false,
				overviewMapControl: false,
				mapTypeControl: false,
				mapTypeControlOptions: {
					mapTypeIds: [googleMaps.MapTypeId.ROADMAP, 'mapId'],
				},
			});

			this.renderer.addClass(mapEl, 'visible');
			this.createMarkers();
			this.fusionMarkers();
			this.handlerClickMarkers();
			this.map.addListener('zoom_changed', () => {
				this.fusionMarkers();
			});
		} catch (e) {
			console.log(e);
		}
	}

	createMarkers() {
		this.markers = this.coords.map((marker, index) => {
			const lockerId = marker.lockerID ? marker.lockerID : '';
			if ((marker.lat === this.latitud, marker.lng === this.longitud)) {
				const markerOptions = {
					position: marker,
					map: this.map,
					draggable: false,
					label: {
					color: '#ffffff',
					text: '•',
					fontWeight: 'bold',
					fontSize: '40px',
					},
					zIndex: 1000,
				};

				const googleMarker = new google.maps.Marker(markerOptions);
				googleMarker.set('lockerId', lockerId); // Almacena el locker_id utilizando el método 'set'

				return googleMarker;
				}
				else {
				const markerOptions = {
					position: marker,
					map: this.map,
					draggable: false,
				};

				const googleMarker = new google.maps.Marker(markerOptions);
				googleMarker.set('lockerId', lockerId); // Almacena el locker_id utilizando el método 'set'

				return googleMarker;
				}
		});
	}

	fusionMarkers() {
		const groupedMarkers = {};
		const radius = 100; // Radio en kilómetros para agrupar los marcadores

		// Agrupar los marcadores por proximidad geográfica
		this.markers.forEach((marker) => {
			const lat1 = marker.getPosition().lat();
			const lng1 = marker.getPosition().lng();

			let closestCity = null;
			let closestDist = null;
			for (const city in groupedMarkers) {
				const lat2 = groupedMarkers[city][0].position.lat();
				const lng2 = groupedMarkers[city][0].position.lng();
				const dist = this.haversine(lat1, lng1, lat2, lng2); // Calcular la distancia entre los dos puntos

				if (closestDist === null || dist < closestDist) {
					closestCity = city;
					closestDist = dist;
				}
			}

			if (closestDist !== null && closestDist <= radius) {
				groupedMarkers[closestCity].push(marker);
			} else {
				const newCity = `City ${
					Object.keys(groupedMarkers).length + 1
				}`;
				groupedMarkers[newCity] = [marker];
				marker.city = newCity;
			}
		});

		if (this.map.getZoom() < 10) {
			// Iterar sobre los grupos de marcadores y crear marcadores combinados para cada grupo
			// this.lstCombinedMarker=[];
			for (const city in groupedMarkers) {
				const markers = groupedMarkers[city];
				const latLng = new this.googleMaps.LatLng(
					markers.reduce((a, b) => a + b.getPosition().lat(), 0) /
						markers.length,
					markers.reduce((a, b) => a + b.getPosition().lng(), 0) /
						markers.length
				);

				let combinedMarker = new google.maps.Marker({
					position: latLng,
					map: this.map,
					label: {
						color: '#ffffff',
						text: markers.length.toString(),
						fontWeight: 'bold',
					},
				});

				this.lstCombinedMarker.push(combinedMarker);

				markers.forEach((marker) => {
					marker.setMap(null);
				});
			}
		} else {
			this.markers.forEach((marker) => marker.setMap(this.map));
			// Eliminar los marcadores combinados de ciudad
			// for (const city in groupedMarkers) {
			//   const markers = groupedMarkers[city];
			//   markers.forEach((marker) => {
			//     marker.setMap(this.map);
			//   });
			//   if (this.combinedMarker) {
			//     this.combinedMarker.setPosition(null);
			//   }
			// }
			this.lstCombinedMarker.forEach((marker) => {
				marker.setMap(null);
			});
		}
	}

	// Función para calcular la distancia entre dos puntos geográficos utilizando la fórmula Haversine
	haversine(lat1, lng1, lat2, lng2, unit = 'km') {
		const R = unit === 'km' ? 6371 : 3956;
		const dLat = this.toRad(lat2 - lat1);
		const dLng = this.toRad(lng2 - lng1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.toRad(lat1)) *
				Math.cos(this.toRad(lat2)) *
				Math.sin(dLng / 2) *
				Math.sin(dLng / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;
		return d;
	}

	// Función para convertir grados a radianes
	toRad(degrees) {
		return (degrees * Math.PI) / 180;
	}

	getAddressMap() {
		this.geocoder.geocode(
			{ location: { lat: this.latitud, lng: this.longitud } },
			(results, status) => {
				if (status === 'OK') {
					if (results[0]) {
						this.eventAddress.emit(results[0].formatted_address);
					} else {
						console.log('No results found');
					}
				} else {
					this.eventAddress.emit('loading...');
				}
			}
		);
	}

	handlerClickMarkers() {
		for (let marker of this.markers) {
			marker.addListener('click', (event) => {
				this.latitud = event.latLng.lat();
				this.longitud = event.latLng.lng();
				for (let m2 of this.markers) {
					m2.setLabel();
					m2.setZIndex();
				}

				marker.setLabel({
					color: '#ffffff',
					text: '•',
					fontWeight: 'bold',
					fontSize: '40px',
				});
				marker.setZIndex(1000);

				// Recuperar el valor de lockerId utilizando el método 'get'
				const lockerId = marker.get('lockerId');
				this.eventAddress.emit(lockerId);
			});
		}
	}

	// addMarker(location) {
	//   let googleMaps: any = this.googleMaps;
	//   // const icon = {
	//   //   url: 'assets/icons/location-pin.png',
	//   //   scaledSize: new googleMaps.Size(50, 50),
	//   // };
	//   this.markers = new googleMaps.Marker({
	//     position: location,
	//     map: this.map,
	//     //   icon: icon,
	//     draggable: false,
	//     animation: googleMaps.Animation.DROP,
	//   });
	// }
}
