import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
	private token: string;
	public temp: any;
	public data;
	public baseUrl: string = environment.url_server_backend;

	constructor(private http: HttpClient){}

	setToken(token: string) {
		this.token = token;
	}
	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}

	async updateLockerWarehouse(params){
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		})
		let url =  this.baseUrl + '/server/locker/update-locker-warehouse';
		const body = JSON.stringify(params);
		const request = new HttpRequest('PUT',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async registerPackageCedis(params){
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		})
		let url =  this.baseUrl + '/server/package/register-package-cedis';
		const body = JSON.stringify(params);
		const request = new HttpRequest('PUT',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async assignLockerPackage(params){
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		})
		let url =  this.baseUrl + '/server/package/assign-locker-package';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async queryLockerWarehouse(params){
		let options ={
			headers:{
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params: params
		}
		let url = this.baseUrl +'/server/locker/query-locker-warehouse';
		return await this.http.get(url,options).toPromise();
	}

	async queryPackageWarehouse(params){
		let options ={
			headers:{
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params: params
		}
		let url = this.baseUrl +'/server/package/query-package-warehouse';
		return await this.http.get(url,options).toPromise();
	}

	async getDataOfTicket(tokenWarehouse,params){
		const headers = new HttpHeaders();
		headers.set('token',tokenWarehouse);
		let url = this.baseUrl +'/server/integration/create-tickets';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}


}
