<div class="row">
    <!--* SEARCH LOCKER -->
    <div class="col-10">
		<div class="input-container">
			<input type="text" class="input-gbl" (keyup)="onFilterTextChange($event)" placeholder="{{'dashboard.search_user' | translate}}">
			<button type="submit" class="btn-search"> <div class="icon-search"></div></button>
		</div>
    </div>
    <div class="col-2 text-end">
        <button-export (click)="onExport()"></button-export>
    </div>

</div>

<div class="row">
    <!-- TABLE -->
    <div class="table-responsive">
        <ag-grid-angular #agGrid
            class="ag-theme-alpine"
            [gridOptions]="this.gridOptions"
            [columnDefs]="this.columnDefs"
            [rowData]="this.supervisors"
            [quickFilterText]="this.filterText"
            style="width: 100%; height: 714px;  margin-top:24px"
			(gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>
