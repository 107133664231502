<!-- GRID HEADER -->
<div class="grid-header">

  <!-- TITLE CONTAINER -->
  <div class="span-txt">
    <!-- TITLE -->
    <span class="txt-title">{{'overview.cardsOverview.trackExpiredByDeliveryType' | translate}}</span>
    <!-- ICON INFO -->
    <div class="icon-info">
      <!-- TOOLTIP -->
      <app-tooltip-center-sm
          class="tooltip-span" 
          [tooltip]="tooltipTracking">
      </app-tooltip-center-sm>
    </div>
  </div>
    <!-- BTN EXPORTAR -->
    <dropdown-export></dropdown-export>
</div>
  
<div class="chart" id="chart">
  <apx-chart
      [series]="dataChartExpiredOrders.series"
      [chart]="dataChartExpiredOrders.chart"
      [dataLabels]="dataChartExpiredOrders.dataLabels"
      [plotOptions]="dataChartExpiredOrders.plotOptions"
      [responsive]="dataChartExpiredOrders.responsive"
      [xaxis]="dataChartExpiredOrders.xaxis"
      [legend]="dataChartExpiredOrders.legend"
      [fill]="dataChartExpiredOrders.fill">
  </apx-chart>
   
  <div class="{{changeStyles}}">
    <!-- GRID HEADER -->
    <div class="grid-header">
      <span class="span-base">{{periodBase}}</span>
    </div>
  
    <!-- GRID HEADER -->
    <div class="grid-header">
      <span class="span-comparacion">{{periodComp}}</span>
    </div>
  </div>
</div>