import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordSlice'
})
export class WordSlicePipe implements PipeTransform {

  transform(text:any, limit:number):any[] {
    const words = text.split(',');

    if(words.length > limit ) {
      words.slice(limit);
    }

    return words;
  }

}
