<div class="container-grid-locker">
	<div fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">

		<div fxLayout="row" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="grid" *ngIf="lockerID !== '1'">
			<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
				<div *ngFor="let m of module" class="grid_lockers">
					<mat-grid-list
					fxFlex="100%"
					rowHeight="0.9vw"
					fxFlex="{{ (m.locker.name_locker_module==='CM-2') ? 43 : (m.locker.name_locker_module==='LOCKER_BLOCK') ? 13 : 98 }}px"
					[cols]="(m.locker.name_locker_module==='CM-2') ? 1 : 6"
					gutterSize="{{ (m.locker.name_locker_module==='CM-2' || m.locker.name_locker_module==='LOCKER_BLOCK') ? 1 : 2 }}"
					class="(m.locker.name_locker_module==='LOCKER_BLOCK') ? grid_lockers_block : grid_locker ?"
					fxFlex.sm="6vw"
					fxFlex.xs="7vw"
					>
						<div *ngFor="let l of m.locker.template_module">
						<mat-grid-tile
							[colspan]="l.cols"
							[rowspan]="l.rows"
							[ngClass]="l.class"
							[matTooltipClass]="safari?'safari-tip':'grid-tip'"
							matTooltipPosition="above"
							[matTooltip]="itemToolInfo(l)"
							(click)="(l.class=='LOCKER_CERRADO' && select_compartment) ?  onSelectCompartment($event, l) : ''"
							>
							<div *ngIf="l.class !== 'SCREEN' || l.class !== 'LOCKER_BLOCK' " class="number_locker">
								<div *ngIf="l.text !== 'null' ">
									{{l.text}}
								</div>
							</div>
							<div *ngIf="l.class == 'SCREEN'" style="border: 0.5px solid #141414; width: 35px; height: 60px; padding-top: 4px;" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
							<div style="height: 20px; width: 65%; border: 0.5px solid #141414;"></div>
							<div style="height: 2px; width: 11px; border: 0.5px solid #141414;"></div>
							</div>
						</mat-grid-tile>
						</div>
					</mat-grid-list>
				</div>
			</div>
		</div>
	</div>
	<div class="grid-leyends">
		<span class="txt-status">
			<div class="circle-available"></div>
			{{ 'lockers.available_door' | translate }} ({{tt_available}})
		</span>
		<span class="txt-status">
			<div class="circle-occupied"></div>
			{{ 'lockers.busy_door' | translate }} ({{tt_occupied}})
		</span>
		<span class="txt-status">
			<div class="circle-defective"></div>
			{{ 'lockers.fail_door' | translate }} ({{tt_damaged}})
		</span>
		<span class="txt-status">
			<div class="circle-open"></div>
			{{ 'lockers.door_open' | translate }} ({{tt_open}})
		</span>
		<span class="txt-status">
			<div class="circle-expired"></div>
			{{ 'lockers.expired' | translate }} ({{tt_expired}})
		</span>
		<span class="txt-status">
			<div class="circle-schedule"></div>
			{{ 'lockers.tt_schedule' | translate }} ({{tt_schedule}})
		</span>
	</div>
</div>


