<div *ngIf="statusID === 'INSTALLED'">
	<div class="Status_{{statusID}}">
		{{statusID}}
	</div>
</div>
<div *ngIf="statusID !== 'INSTALLED'">
	<div class="Status_IN_STOCK">
		{{statusID}}
	</div>
</div>

