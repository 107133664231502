<!-- GRID HEADER -->
<div class="grid-header">
    <!-- TITLE CONTAINER -->
    <div class="span-txt">
      <!-- TITLE -->
      <span class="txt-title">
        Promedio de calificaciones
      </span>
      <!-- ICON INFO -->
      <div class="icon-info">
        <!-- TOOLTIP -->
        <app-tooltip 
            class="tooltip-span" 
            [tooltip]="tooltipHistorical">
        </app-tooltip>
        
      </div>
  
    </div>
      <!-- BTN EXPORTAR -->
      <dropdown-export></dropdown-export>
</div>
    