// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
	production: false,
	public_key_recaptcha: '6Lfx29EUAAAAAHq-9kv5WaObm6Ifxexu-U-YWNL8',
	domain: 'http://localhost:4200',
	key_google_maps: 'AIzaSyBq3BL-vyLER2ILrUCzFKMslE20MmGxoPs',
	url_server_backend: 'https://bkn-dev-fetch.vivipost.com'
};
