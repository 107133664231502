<div class="row"  *ngIf="this.orderData.status!=='SCHEDULED'">
    <div class="col-10">
        <label class="header-user-detail">
            {{'orderDetail.title-orderDetail'|translate}}
        </label>
    </div>
    <div class="col-2" style="text-align: end; cursor: pointer;" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
        </svg>
    </div>
    <div class="col-12">
        <hr style="color: #e5e5e5;opacity: 100;margin: 12px 0px;">
    </div>

    <!--!Informacion de la Orden  -->
    <div class="col-6" style="height: 800px;">
        <div class="header-informacion-general">
            <div class="header-informacion-general-text">
                {{'orderDetail.subtitle-informationGeneral'|translate}}
            </div>
        </div>
        <div class="table-informacion-general">
            <div class="row">
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.trackingNumber'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.order_number}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.emailClient'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.email}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.phoneClient'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.phone_used}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.nameEmployee'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.nameEmploye}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.numberOfDoor'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.doorNumber}} ({{this.orderData.compartmentSize}})
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.orderStatus'|translate}}
                        </div>
                        <div class="field-value">
                            <app-estatus-order  [orderData]="this.orderData">
                            </app-estatus-order>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.typeDelivery'|translate}}
                        </div>
                        <div class="field-value">
							{{this.orderData.type_delivery}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.inLocker'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.inLocker}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.expirationDate'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.expiration_date}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.removedLocker'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.send_date}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.deliverDate'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.dateCollected}}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.qualificationClient'|translate}}
                        </div>
                        <div class="field-value">
                            <app-star-rating [rating]="this.orderData.rate_start">

                            </app-star-rating>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--! SOY EL QR Y LOS RECORDATORIOS  -->
    <div class="col-6" style="height: 800px;" *ngIf="this.id_status_code_package !== 18 && this.status_package !== 16">
        <div class="informacion-qr">
            <div class="header-informacion-qr">
                <div class="header-informacion-qr-text">
                    {{'orderDetail.subtitle-accessQR'|translate}}
                </div>
                <app-button-icon *ngIf="!url_view_image"
						[activo]="true"
						btnNombre="userDetail.btnDownloadQR"
						btnType="primaryMedium"
						icon="icon-qr">
				</app-button-icon>
				<app-button-icon *ngIf="url_view_image"
						(click)="descargarPNG()"
						[activo]="false"
						btnNombre="userDetail.btnDownloadQR"
						btnType="primaryMedium"
						icon="icon-qr">
				</app-button-icon>
            </div>
            <div class="row" style="width: 100%; height: 283px;">
                <div class="col-12" style="text-align: center;">
                    <img [src]="this.orderData.qr_order" (error)="url_view_image=false" *ngIf="url_view_image" [width]="283" height="auto" alt="imagen-order-detail">
					<ng-container *ngIf="!url_view_image">
						<div class="row">
							<div class="col-12" style="display: flex;justify-content: center;">
								<div class="container-image">
									<div class="container-image-panding">
										<img src="../../../../assets/icons/icon-qr-dark.svg" style="margin-bottom:1rem" width="20%" alt="">
										<h2>{{'message.title_err_img_404' | translate }}</h2>
										<p>{{'message.err_img_404' | translate }}</p>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
                </div>
            </div>
        </div>
		<div class="txtbox-info-recordatorio">
            <div class="row">
                <div class="col-12">
                    <div class="header-info-recordatorio">
                        <div class="header-info-recordatorio-text">
                            {{'orderDetail.subtitleReminder'|translate}}
                        </div>
                        <button class="btn-agregar-recordatorio" (click)="openModalRecordatorios()">
                            <div class="btn-text">
                                {{'orderDetail.Reminder.addReminder'|translate}}
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="auto" fill="#212121" class="bi bi-alarm" viewBox="0 0 16 16" style="margin-left:12px">
                                <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"/>
                                <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="col-12">
                    <div class="content-recordatorios" *ngIf="this.recordatoriosNotification.length > 0">
                        <div class="row" *ngFor="let item of this.recordatoriosNotification.slice(0, 6)">
                            <div class="col-6" style="border-bottom: 1px solid #e5e5e5;" >
                                <div class="item-content-recordatorios" >
                                    <div class="item-content-text-recordatorios">
                                        {{item.name_notifification}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="border-bottom: 1px solid #e5e5e5;">
                                <div class="item-content-recordatorios" >
                                    <div class="item-content-text-recordatorios">
                                        {{item.date_sent | dateFormat}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="no-content-recordatorios" *ngIf="this.recordatoriosNotification.length === 0">
                        <div class="sin-contenido">
                            <div class="sin-contenido-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="auto" fill="#bababa" class="bi bi-info-circle" viewBox="0 0 16 16" style="margin-left:12px">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
								{{'orderDetail.Reminder.noReminder'|translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--! SOY LA EVIDENCIA  -->
    <div class="col-6" style="height: 800px;" *ngIf="this.id_status_code_package === 18 && this.status_package === 16">
        <div class="sign-header-box">
            <div class="sign-header-box-textbox">
                {{'orderDetail.subtitleEvidence' |translate}}
            </div>
        </div>
        <div class="sign-box">
            <div class="sign-text">
                {{'orderDetail.Evidence.signEvidence' |translate}}
            </div>
            <img [src]="this.img_sign" alt="" width="604px" height="275px" style="border:1px solid #efefef; border-radius: 20px;">
        </div>
        <div class="image-box" style="margin-top:24px">
            <div class="image-text">
                {{'orderDetail.Evidence.recollectionEvidence' |translate}}
            </div>
            <img [src]="this.img_pickup" alt="" width="604px" height="381px" style="border-radius: 20px;">
        </div>

    </div>

</div>
<div class="row"  *ngIf="this.orderData.status==='SCHEDULED'">
    <div class="col-11">
        <label class="header-user-detail">
            {{'orderDetail.title-orderDetail' |translate}}
        </label>
    </div>
    <div class="col-1" style="text-align: end; cursor: pointer;" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#212121" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
        </svg>
    </div>
    <div class="col-12">
        <hr style="color: #e5e5e5;opacity: 100;margin: 12px 0px;">
    </div>
    <div class="col-6" style="height: 800px;">
        <div class="header-informacion-general">
            <div class="header-informacion-general-text">
                {{'orderDetail.subtitle-informationGeneral' |translate}}
            </div>
        </div>
        <div class="table-informacion-general">
            <div class="row">
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.trackingNumber' |translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.tracking}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.emailClient' |translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.email}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.phoneClient' |translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.phone}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.nameEmployee' |translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.nameEmploye}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.numberOfDoor' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.orderStatus' |translate}}
                        </div>
                        <div class="field-value">
                            <app-estatus-order  [orderData]="this.orderData">
                            </app-estatus-order>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.typeDelivery' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.inLocker' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.expirationDate' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.removedLocker' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.qualificationClient' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.expirationDate' |translate}}
                        </div>
                        <div class="field-value">
                            <app-star-rating [rating]="this.orderData.rate_start">

                            </app-star-rating>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6" style="height: 800px;" *ngIf="this.id_status_code_package !== 18 && this.status_package !== 16">
        <div class="informacion-qr">
            <div class="header-informacion-qr">
                <div class="header-informacion-qr-text">
                    {{'orderDetail.subtitle-accessQR' |translate}}
                </div>
                <a href="{{this.orderData.qr}}" target="_blank" style="color: white; text-decoration: none;">
					<app-button-icon icon="{{iconBtnQR}}" btnType="primaryMedium" btnNombre="orderDetail.qrAccess.btnDownload" [activo]="false">
					</app-button-icon>
                </a>
            </div>
            <div class="row" style="width: 100%; height: 283px;">
                <div class="col-12" style="text-align: center;">
                    <img id="imgQR" [src]="this.orderData.qr" style="width:283px; height: 283px;">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="this.orderData.status === 'SCHEDULED' && this.idUserType ===9">
    <div class="col-12" style="display: flex; justify-content: end;">

		<app-button-icon (click)="openModalEliminarOrder()" [icon]="'icon-erase'" btnType="secondaryDangerMedium" btnNombre="orderDetail.eraseOrder" [activo]="false">

		</app-button-icon>
    </div>
</div>
<div class="row" *ngIf="this.orderData.status === 'DISPONIBLE' && this.idUserType === 9 && this.orderData.status_package === 14 && this.orderData.id_status_code_package === 17 && this.orderData.extended_package === 0">
    <div class="col-12" style="display: flex; justify-content: end;">

		<app-button-icon (click)="openModalEliminarOrder()" [icon]="'icon-erase'" btnType="secondaryDangerMedium" btnNombre="orderDetail.eraseOrder" [activo]="false">

		</app-button-icon>
    </div>
</div>




