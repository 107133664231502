<div class="container-fluid">
	<div class="row">
		<!--* SEARCH LOCKER -->
		<div class="col-6 text-start">
			<div class="input-container">
				<input type="text" class="input-gbl" (keyup)="onFilterTextChange($event)" placeholder="{{'dashboard.search_user' | translate}}">
				<button type="submit" class="btn-search"> <div class="icon-search"></div></button>
			</div>
		</div>
		<div class="col-6 buttons-and-ranges">
			<p-calendar class="selectYear"
						[showIcon]="true"
						[(ngModel)]="rangeDates"
						(ngModelChange)="addEventRangeDates()"
						selectionMode="range"
						[readonlyInput]="true"
						inputId="range" >
			</p-calendar>
			<app-button-icon
				*ngIf="this.mode ==='light'"
				[activo]="false"
				btnNombre="Filtros"
				btnType="secondaryMedium"
				icon="icon-funnel"
				(click)="showFilters()">
			</app-button-icon>
			<app-button-icon
				*ngIf="this.mode ==='dark'"
				[activo]="false"
				btnNombre="Filtros"
				btnType="secondaryMedium"
				icon="icon-funnel-dark"
				(click)="showFilters()">
			</app-button-icon>
			<button-export (click)="onExport()"></button-export>
		</div>
	</div>

	<div class="row">
		<!-- SEARCH -->
		<div class="col-12">
			<ag-grid-angular 	#agGrid
								class="ag-theme-alpine"
								[gridOptions]="gridOptions"
								[rowData]="rowData"
								suppressScrollOnNewData = true
								[columnDefs]="this.columnDefsExpired"
								style="width: 100%; height: 714px; border: none; margin-top:24px"
								[quickFilterText]="this.filterText"
								(gridReady)="onGridReady($event)">
			</ag-grid-angular>
		</div>
	</div>
</div>



<div *ngIf="display && (idUserType === 9 || idUserType === 7)" class="extendCard">
    <div class="row">
        <div class="col-12">
            <p-calendar #calendar [inline]="true" [minDate]="minDate" [maxDate]="maxDate"></p-calendar>
        </div>
        <div class="col-6" style="text-align: start;">
            <button type="button" class="btn-cancelar font-primary" (click)="cerrarExtender()">
                {{'general.cancel'| translate}}
            </button>
        </div>
        <div class="col-6" style="text-align: end;">
            <button type="button" class="btn-extend font-primary" (click)="extenderFechas(calendar)">
                {{'table.extend' | translate}}
            </button>
        </div>
    </div>
</div>

<app-filter-orders [filters]="this.filters" [status]="false" [rate]="true">

</app-filter-orders>
