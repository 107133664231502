
<div *ngIf="status_package === 14 && id_status_code_package === 32 && (idUserType === 9)">
    <div class="expired" (click)="openDialog()">
		{{'estatus.EXPIRED' | translate}}
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#e50f0f" class="bi bi-chevron-down" viewBox="0 0 16 16">
			<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
		</svg>
    </div>
</div>

<div *ngIf="status_package === 14 && id_status_code_package === 17 && this.estatus === 'DISPONIBLE' && extend_package ===1">
	<div class="extended">
		{{'estatus.EXTENDED'|translate}}
	</div>
</div>

<div *ngIf="status_package === 33 && id_status_code_package === 32 && this.estatus === 'RECOLECTADO'">
	<div class="refund" (click)="openDialogDelivery()">
		{{'estatus.RETURN'|translate}}
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--color-purple)" class="bi bi-chevron-down" viewBox="0 0 16 16">
			<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
		</svg>
	</div>
</div>

<div *ngIf="this.estatus === 'ACTIVE'">
	<div class="active" (click)="SaberQueEstatusEs(this.estatus)">
		{{'estatus.ACTIVE'|translate}}
	</div>
</div>

<div *ngIf=" this.estatus === 'DESACTIVE'">
	<div class="desactive" (click)="SaberQueEstatusEs(this.estatus)">
		{{'estatus.DESACTIVE'|translate}}
	</div>
</div>

<div *ngIf=" this.estatus === 'PASSWORD_EXPIRADO'">
	<div class="password" (click)="SaberQueEstatusEs(this.estatus)">
		{{'estatus.PASSWORD_EXPIRADO'|translate}}
	</div>
</div>

<div *ngIf="status_package === 14 && id_status_code_package === 17 && this.estatus === 'DISPONIBLE' && extend_package ===0">
	<div class="inlocker" (click)="SaberQueEstatusEs(this.estatus)">
		{{'estatus.LOCKER'|translate}}
	</div>
</div>





<div *ngIf="status_package === 16 && id_status_code_package === 18 && this.estatus === 'ENTREGADO'">
	<div class="collected">
		{{'estatus.ENTREGADO'|translate}}
	</div>
</div>

<div *ngIf="status_package === undefined && id_status_code_package ===undefined && this.estatus === 'SCHEDULED'">
	<div class="scheduled">
		{{'estatus.SCHEDULED'|translate}}
	</div>
</div>


<!--?ESTATUS PARA LA TABLA DE DETALLE DE USUARIO EN AUDITORIA DE PAQUETE  -->
<div *ngIf="id_status_auditoria === 33 && this.description_status_auditoria === 'RECOLECTADO'">
	<div class="refund">
		{{'estatus.RECOLECTADO'|translate}}
	</div>
</div>

<div *ngIf="id_status_auditoria === 16 && this.description_status_auditoria === 'ENTREGADO'">
	<div class="collected">
		{{'estatus.ENTREGADO'|translate}}
	</div>
</div>




<!--! ESTATUS DE ACTIVIDAD-->
<div *ngIf="this.activity_topic === 8">
	<div class="paquete-collected">
		{{'estatus.PackageCollected'|translate}}
	</div>
</div>

<div *ngIf="this.activity_topic === 9">
	<div class="paquete-delivered">
		{{'estatus.PackageInLocker'|translate}}
	</div>
</div>

<div *ngIf="this.activity_topic === 11">
	<div class="paquete-expirado">
		{{'estatus.PackageExpired'|translate}}
	</div>
</div>



<div *ngIf="this.activity_topic === 14">
	<div class="paquete-almacen">
		{{'estatus.Warehouse'|translate}}
	</div>
</div>



<div *ngIf="this.activity_topic === 12">
	<div class="paquete-agendada">
		{{'estatus.ScheduledDoor'|translate}}
	</div>
</div>

<div *ngIf="this.activity_topic === 15">
	<div class="paquete-collected">
		{{'estatus.OpenDoor'|translate}}
	</div>
</div>

<div *ngIf="this.activity_topic === 16">
	<div class="paquete-expirado">
		{{'estatus.CloseDoor'|translate}}
	</div>
</div>

<div *ngIf="this.activity_topic === 17">
	<div class="falla-puerta">
		{{'estatus.FailDoor'|translate}}
	</div>
</div>

<div *ngIf="this.activity_topic === 18">
	<div class="paquete-almacen">
		{{'estatus.BusyDoor'|translate}}
	</div>
</div>

<div *ngIf="this.activity_topic === 22">
	<div class="access-testing-app">
		ACCESS TESTING APP
	</div>
</div>

<div *ngIf="this.activity_topic === 23">
	<div class="error-access-testing-app">
		ERROR ACCESS TESTING APP
	</div>
</div>


<div *ngIf="this.topic_name === 'LOGIN IN LOCKER'">
	<div class="login-locker">
		{{'estatus.loginSuccess'|translate}}
	</div>
</div>

<div *ngIf="this.topic_name === 'LOGIN ERROR'">
	<div class="login-error">
		{{'estatus.loginSuccess'|translate}}
	</div>
</div>

<div *ngIf="this.topic_name === 'USE OF MASTER CODE'">
	<div class="useMasterCode">
		{{'estatus.useMasterCode'|translate}}
	</div>
</div>

<div *ngIf="this.topic_name === 'LOG OUT'">
	<div class="logout">
		{{'estatus.logout'|translate}}
	</div>
</div>

<div *ngIf="status_package === 14 && id_status_code_package === 32 && (idUserType !== 9 && idUserType !== 7)">
    <div class="expired">
		{{'estatus.EXPIRED' | translate}}
    </div>
</div>


