<!-- <img class="img_screen" [src]="imageScreenShot"> -->






 <mat-card class="card">
  <!-- Inicio de titulo-->
  <mat-card-title class="text-center">
    <h1 *ngIf="data.type=='sign'" mat-dialog-title>{{'text.signature_photograph' | translate}}</h1>
    <h1 *ngIf="data.type=='evidence'" mat-dialog-title>{{'text.evidence' | translate}} <span style="float: right !important; font-weight: normal; font-size: 16px;">{{tracking}}</span></h1>
    <h1 *ngIf="data.type=='qr'" mat-dialog-title>{{'text.qr' | translate}}</h1>
    <h1 *ngIf="data.type=='qr_schedule'" mat-dialog-title>{{'text.qr_schedule' | translate}}</h1>
    <h1 *ngIf="data.type=='qrAccess'" mat-dialog-title>{{'text.qrAccess' | translate}}</h1>
    <h1 *ngIf="data.type=='configCreateQr'" mat-dialog-title>{{'text.create_qr' | translate}}</h1>
    <h1 *ngIf="data.type=='loading' || data.type=='video' || data.type=='audit_user' || data.type=='screenShot' || data.type=='confirm_package' || data.type=='confirm_clients' || data.type=='package_information'" mat-dialog-title>{{ title }} <span *ngIf="nameUserLog!=''" style="font-weight: normal; font-size:14px; text-transform: uppercase !important; "> - {{nameUserLog}}</span></h1>
    <h1 *ngIf="data.type=='message'" class="error-message" >{{ title }}</h1>



  </mat-card-title>
  <!-- Fin de titulo-->
  <!-- Inicio de Contenido-->
  <mat-card-content *ngIf="data.type!='evidence' && data.type!='loading' && data.type!='video' && data.type!='audit_user' && data.type!='message' && data.type!='screenShot' && data.type!='confirm_package' && data.type!='package_information' && data.type!='confirm_clients'" >
    <div mat-dialog-content style="text-align: center;">
      <div class="caja">
        <div class="box">
          <img src="{{data.image}}" style="width: 50% !important; margin: auto !important;" alt="Cargando imagen..." (error)="url_view_image=false" *ngIf="url_view_image">
          <ng-container *ngIf="!url_view_image">
            <div class="container-image">
              <div class="container-image-panding">
                <h2>{{'message.title_err_img_404' | translate }}</h2>
                <p>{{'message.err_img_404' | translate }}</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="data.type=='loading'">
    <div mat-dialog-content style="text-align: center;">
      <div class="caja">
        <div class="box">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </div>
  </mat-card-content>


  <mat-card-content *ngIf="data.type=='package_information'" style="height: 550px;">
    <div mat-dialog-content  class="table-card"fxFlex="100%" fxLayout="column">
      <table mat-table [dataSource]="transactions" class="colortable">
        <!-- Item Column -->
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef class="color">{{ 'package_information.item' | translate }}</th>
          <td mat-cell *matCellDef="let transaction" class="field"> {{'package_information.'+transaction.item.toLowerCase() | translate}}
           </td>
        </ng-container>

        <!-- Cost Column -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef class="color"> {{ 'package_information.value' | translate }} </th>
          <td mat-cell *matCellDef="let transaction; let i = index;">
            <!-- <span *ngIf="i==1"><app-star-rating start="{{transaction.value}}"></app-star-rating></span> -->
            <span *ngIf="i!=1">{{transaction.value}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card-content>

	<mat-card-content class="mat-card-carousel" *ngIf="data.type=='evidence' && data.type!='video' && data.type!='audit_user' && data.type!='screenShot'">
		<div class="mat-card" *ngIf="data.type=='evidence'">
			<div mat-dialog-content class="carousel-mat-dialog-content">
				<div class="caja">
					<div class="box container-carousel">
						{{images|json}}-aa
						<form>
						<ng-container *ngFor="let img of images; let i=index">
							<input type="radio" name="fancy" [autofocus]="i===0?true:''" [value]=i+1 [id]=i+1  />
						</ng-container>
						<ng-container *ngFor="let img of images; let i=index">
							<label [for]=i+1 data-test="i+1">
							<img [src]="img.path"  (error)="url_view_image=false" *ngIf="url_view_image">
							<ng-container *ngIf="!url_view_image">
								<div class="container-image">
								<div class="container-image-panding">
									<h2>{{'message.title_err_img_404' | translate }}</h2>
									<p>{{'message.err_img_404' | translate }}</p>
								</div>
								</div>
							</ng-container>
							</label>
						</ng-container>
						</form>
					</div>
				</div>
			</div>
		</div>
	</mat-card-content>

	<mat-card-content *ngIf="data.type=='screenShot'">
		<div mat-dialog-content style="text-align: center;">
			<div class="caja">
				<div class="box">
					<img src="{{imageScreenShot}}" [width]="200" [height]="300" alt="Cargando imagen...">
				</div>
			</div>
		</div>
	</mat-card-content>


  <mat-card-content *ngIf="data.type=='video'">
    <div mat-dialog-content style="text-align: center;">
      <div class="caja">
        <div class="box">
           <video width="70%" controls autoplay>
            <source src="{{url_video_play}}" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- Inicio de Auditoria de usuario-->
  <mat-card-content *ngIf="data.type=='audit_user'">
    <div class="content-body" fxLayout="column" fxLayoutGap="36px">
      <div id="body-tabs" class="tab-body" fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
        <mat-tab-group class="tabs" (selectedTabChange)="onTabChanged($event);">
          <mat-tab>
            <ng-template mat-tab-label>
                <span >{{ 'audit.tab_audit_package' | translate }}</span>
              </ng-template>
              <mat-card class="table-card"fxFlex="100%" fxLayout="column">
                  <app-audit-table-admin data="{{dataPackage}}" columns="{{columnsPackage}}" [height]="400"></app-audit-table-admin>
              </mat-card>
          </mat-tab>
          <mat-tab >
             <ng-template mat-tab-label>
                <span >{{ 'audit.tab_audit_usuario' | translate }}</span>
              </ng-template>
              <mat-card class="table-card" fxFlex="100%">
                  <app-audit-table-admin data="{{dataUser}}" columns="{{columnsUser}}" [height]="400"></app-audit-table-admin>
              </mat-card>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-card-content>
  <!-- Fin de Auditoria de usuario-->

  <!-- Inicio de Confirmar data de cliente para guardar-->
  <mat-card-content *ngIf="data.type=='confirm_clients'">
    <div class="content-body" fxLayout="column" fxLayoutGap="36px">
      <div id="body-tabs" class="tab-body" fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
        <mat-tab-group class="tabs" (selectedTabChange)="onTabChanged($event);">
          <mat-tab>
            <ng-template mat-tab-label>
                <span class="bg-badge" matBadge="{{total_Client}}" matBadgeOverlap="false">{{ 'confirm_client.table.title' | translate }}</span>
            </ng-template>
            <mat-card class="table-card"fxFlex="100%" fxLayout="column">
                <app-audit-table-admin data="{{data_clients}}" columns="{{columnsClient}}" [height]="400"></app-audit-table-admin>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-card-content>
  <!-- Fin de Confirmar data de cliente para guardar -->


   <!-- Inicio de Confirm Package-->
  <mat-card-content *ngIf="data.type=='confirm_package'">
    <div class="content-body" fxLayout="column" fxLayoutGap="36px">
      <div id="body-tabs" class="tab-body" fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
        <mat-tab-group class="tabs" (selectedTabChange)="onTabChanged($event);">
          <mat-tab>
            <ng-template mat-tab-label>
                <span class="bg-badge" matBadge="{{total_success}}" matBadgeOverlap="true">{{ 'tabs.packets_to_register' | translate }}</span>
            </ng-template>
            <mat-card class="table-card"fxFlex="100%" fxLayout="column">
                <app-audit-table-admin data="{{retunr_package_success}}" columns="{{columnsPackageSave}}" [height]="400"></app-audit-table-admin>
            </mat-card>
          </mat-tab>
          <mat-tab >
             <ng-template mat-tab-label>
                <span class="bg-badge" matBadge="{{total_failure}}" matBadgeOverlap="true">{{ 'tabs.failed_packages' | translate }}</span>
              </ng-template>
              <mat-card class="table-card" fxFlex="100%">
                  <app-audit-table-admin data="{{retunr_package_failure}}" columns="{{columnsPackageSave}}" [height]="400"></app-audit-table-admin>
              </mat-card>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-card-content>
  <!-- Fin de Confirm Package-->

  <mat-card-content *ngIf="data.type=='message'">
    <div mat-dialog-content style="text-align: center;">
      <div class="caja">
        <div class="box">
          <p>{{message}}</p>
        </div>
      </div>
    </div>
  </mat-card-content>


  <!-- Fin de Contenido-->
  <!-- Inicio de Botones-->

  <mat-card-actions *ngIf="data.type=='evidence' || data.type=='qr_schedule'" >
    <button mat-button class="button-close" mat-dialog-close>{{'general.close' | translate}}</button>
  </mat-card-actions>

  <mat-card-actions *ngIf="data.type=='confirm_clients'" >
    <button mat-button (click)="close()" class="button-close" mat-dialog-close >{{'confirm_client.buttons.cancel' | translate}}</button>
    <button mat-button (click)="saveClient()" class="button-ikea" style="float:right !important; bottom: 0;" mat-dialog-close>{{'confirm_client.buttons.next' | translate}}</button>
  </mat-card-actions>

  <mat-card-actions *ngIf="data.type=='qrAccess'">
    <button (click)="updateQR()" class="button-update {{status}}" mat-button>Update QR</button>
    <!-- BUTTON DOWNLOAD -->
    <a  mat-button [href]="data.image"
        class="button-download"
        download target="_blank"
        style="text-decoration: none;">
        {{'general.download' | translate}}
         <i  class="fas fa-download gray-color " style="margin-left: 3px;"></i>
    </a>

    <button mat-button class="button-close" mat-dialog-close>Close</button>
  </mat-card-actions>

  <mat-card-content *ngIf="data.type=='configCreateQr'">
    <div mat-dialog-content style="text-align: center; font-size: 18px,">{{'user.mensajeQR' | translate}}</div>
  </mat-card-content>
  <mat-card-actions *ngIf="data.type=='configCreateQr'" style="margin:10px;">
    <button mat-button (click)="crearQr()" class="button-ikea" style="float: left !important; bottom: 0;">{{'user.ok' | translate}}</button>
    <button mat-button mat-dialog-close id="close"  class="button-close" style="float: right !important;  bottom: 0;">{{'general.cancel' | translate}}</button>
  </mat-card-actions>

  <mat-card-footer *ngIf="data.type=='confirm_package'" class="message-alert">{{ 'tabs.message.packets_fail' | translate}}</mat-card-footer>
  <mat-card-actions *ngIf="data.type=='confirm_package' "style="width: 40%; bottom: 0; margin: 10px auto !important;">
    <button mat-button mat-dialog-close id="close" class="button-close" style="float: right !important; width:45% !important;">{{'general.cancel' | translate}}</button>

    <button mat-button class="button-ikea {{ ( total_success == 0 ) ? 'disabled' : '' }}" style="float: left !important; width:45% !important;" (click)="( total_success > 0 ) ? dasvePackage() : null " >
    {{'user.ok' | translate}}</button>

  </mat-card-actions>

  <mat-card-actions *ngIf="data.type=='video' || data.type=='audit_user' || data.type=='screenShot' || data.type=='message' || data.type=='package_information'">
    <button mat-button class="button-close" mat-dialog-close id="close">{{'general.close' | translate}}</button>
  </mat-card-actions>

  <!-- Inicio de Botones-->
</mat-card>
