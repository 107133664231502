import { Pipe, PipeTransform } from '@angular/core';
import { tableData } from 'src/app/core/models';

@Pipe({
	name: 'search',
})
export class SearchPipe implements PipeTransform {
	transform(
		value: tableData[],
		args: any,
		page: number = 0,
		pageLenght: number
	): tableData[] {
		if (!value) return args.slice(page, page + pageLenght);
		if (!args) return null;

		args = args.toLowerCase();

		return value.filter((item: any) => {
			return JSON.stringify(item).toLowerCase().includes(args);
		});
	}
}
