import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-estatus-table-logs',
	templateUrl: './estatus-table-logs.component.html',
	styleUrls: ['./estatus-table-logs.component.scss']
})
export class EstatusTableLogsComponent  implements ICellRendererAngularComp, OnInit{
	activity_topic;

	constructor() { }
	agInit(params: ICellRendererParams) {
		this.activity_topic = params.data.id_topic_audit;
	}
	refresh(params: ICellRendererParams): boolean {
		return false;
	}
	ngOnInit(): void {
	}
}
