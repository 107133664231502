import { Component,Input, OnInit } from '@angular/core';
import { CardData } from 'src/app/core/models';


@Component({
  selector: 'cards-overview-users',
  templateUrl: './cards-overview.component.html',
  styleUrls: ['./cards-overview.component.scss']
})
export class CardsOverviewComponent  implements OnInit {
	// CARD STYLE
	/*
    null  : display none
    sm    : small size
    md    : medium size
  */
	@Input() colorBg: string = '--color-container';
	// DATA CARD
	@Input() dataCard: CardData[] = [];
	@Input() footer: boolean;
	@Input() header: boolean;
	@Input() body: boolean;
	// LocalStorage set IDS
	private storeCardsIds: string | null = null;
	showTooltip:boolean=false;
	idCard:number;

	constructor(){}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		if (this.storeCardsIds) {
			const cardsIds = this.dataCard.map((card) => card.id);
			localStorage.setItem('cardsIds', JSON.stringify(cardsIds));
		}
	}

	activateTooltip(i){
		this.idCard = i;
		this.showTooltip = true;
	}
	desactivateToolTip(i){
		this.idCard = null;
		this.showTooltip = false;
	}
}
