import { Component, ViewChild } from '@angular/core';
import {
	ApexDataLabels,
	ApexLegend,
	ApexPlotOptions,
	ChartComponent,
} from 'ng-apexcharts';

import {
	ApexNonAxisChartSeries,
	ApexResponsive,
	ApexChart,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	labels: string[];
	colors: string[];
	legend: ApexLegend;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive | ApexResponsive[];

	dataLabels: ApexDataLabels;
};

@Component({
	selector: 'app-card-doors',
	templateUrl: './card-doors.component.html',
	styleUrls: ['./card-doors.component.scss'],
})
export class CardDoorsComponent {
	@ViewChild('chart') chart: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	totalSeries: number;

	constructor() {
		this.chartOptions = {
			chart: {
				type: 'donut',
			},
			series: [5091, 2390, 3422],
			plotOptions: {
				pie: {
					offsetY: 20,
					dataLabels: {
						minAngleToShowLabel: 999,
					},
				},
			},
			colors: [
				'var(--color-chart-300)',
				'var(--color-chart-200)',
				'var(--color-chart-100)',
			],
			labels: ['Entregados', 'Retirados', 'Devueltos'],
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 100,
						},
					},
				},
			],
		};
		this.totalSeries = this.chartOptions.series.reduce((a, b) => a + b, 0);
	}
}
