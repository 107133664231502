import { Component, Input } from '@angular/core';

@Component({
	selector: 'tooltip',
	templateUrl: './tooltip-test.component.html',
	styleUrls: ['./tooltip-test.component.scss'],
})
export class TooltipTestComponent {
	/*
	//* Add styles
	.tooltip {
		display: none;
	}
	.icon-info:hover .tooltip {
		display: contents;
	}
	*/
	// Put your description in this property
	@Input() tooltip: string = 'Hi';
	// Now call this proporty to align the spike ----> start | center | end
	@Input() position: string = 'center';
}
