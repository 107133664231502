// ANGULAR
import { Component,EventEmitter,Input,Output} from '@angular/core';
import {  Router } from '@angular/router';
@Component({
	selector: 'notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
	userType:number;
	@Input() orders: any;
	@Input() users: any;
	@Input() all;
	@Input() statusOrder: string;
	@Input() sizeOrders: number;
	@Input() sizeUser: number;
	@Input() sizeAll: number;
	@Output() orderSelected: EventEmitter<string> = new EventEmitter<string>();
	// Set page lenght
	public pageOrdersLenght: number = 6;
	public pageUsersLenght: number = 6;
	public pageAllLenght: number = 6;
	// Set pagination
	public pageOrders: number = 0;
	public pageUsers: number = 0;
	public pageAll: number = 0;
	// See all
	public markAllread: boolean = true;
	markRead() {
		this.markAllread = false;
	}
	loadMoreOrders() {
		this.pageOrdersLenght += 6;
	}
	loadMoreUsers() {
		this.pageUsersLenght += 6;
	}
	loadMoreAll() {
		this.pageAllLenght += 6;
	}
	// NOTIFICATIONS
	order: string = '625-894';
	locker: string = 'OXXOMTY61565';
	time: number = 5;
	tabOrderSeen: boolean = true;
	tabUserSeen: boolean = true;
	tabAllSeen: boolean = true;
	orderSeen() {
		this.tabOrderSeen = false;
	}
	userSeen() {
		this.tabUserSeen = false;
	}
	allSeen() {
		this.tabAllSeen = false;
	}
	constructor( private router: Router) {}
	ngOnInit(){
		this.userType = parseInt(localStorage.getItem('user_type'));
	}
	ngOnChanges() {}
	openOrder(item) {
		if(this.userType === 7){
			this.router.navigateByUrl('/warehouse/orders/' + item.tracking_number);
			if (this.router.url !== '/warehouse/orders/' + item.tracking_number) {
				this.sizeOrders--;
				localStorage.setItem('searchOrderNotification', item.tracking_number);
				this.router.navigateByUrl('/warehouse/orders/' + item.tracking_number)
			}
		}
		else if(this.userType === 9){
			this.router.navigateByUrl('/l-retail/orders/' + item.tracking_number);
			if (this.router.url !== '/l-retail/orders/' + item.tracking_number) {
				this.sizeOrders--;
				localStorage.setItem('searchOrderNotification', item.tracking_number);
				this.router.navigateByUrl('/l-retail/orders/' + item.tracking_number)
			}
		}

	}
	openUser(item){
	}
	navigate() {
		this.router.navigateByUrl('/');
	}
}
