//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
//Modules
import { MaterialModule } from './libraries/angular-material/material.module';
// Libraries
import { WordSlicePipe } from './pipes/word-slice.pipe';
//Pipe
//Components
import { GoogleMapsComponent } from './libraries/google-maps/google-maps.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule } from '@angular/router';
import { TableComponent } from './components/table/table.component';
import { TranslateModule } from '@ngx-translate/core';
import { CardDoorsComponent } from './components/card-doors/card-doors.component';
import { CardKpiComponent } from './components/card-kpi/card-kpi.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CardsOverviewComponent } from './components/cards-overview/cards-overview.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ButtonExportComponent } from './components/button-export/button-export.component';
import { FooterComponent } from './components/footer/footer.component';
import { CardComponent } from './components/card/card.component';
import { DropdownExportComponent } from './components/dropdown-export/dropdown-export.component';
import { ButtonUploadComponent } from './components/button-upload/button-upload.component';
import { ButtonCreateOrderComponent } from './components/button-create-order/button-create-order.component';
import { FilterFunnelComponent } from './components/filter-funnel/filter-funnel.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ButtonCreateUserComponent } from './components/button-create-user/button-create-user.component';
import { TooltipCenterSmComponent } from './components/tooltip-center-sm/tooltip-center-sm.component';
import { TooltipEndSmComponent } from './components/tooltip-end-sm/tooltip-end-sm.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { DropdownNavbarComponent } from './components/dropdown-navbar/dropdown-navbar.component';
import { SearchPipe } from './pipes/search.pipe';
import { StarsComponent } from './components/stars/stars.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { ButtonCreateCarrierComponent } from './components/button-create-carrier/button-create-carrier.component';
import { ButtonComponent } from './components/button/button.component';
import { SidebarSettingsComponent } from './components/sidebar-settings/sidebar-settings.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CardsLockersComponent } from './components/cards-lockers/cards-lockers.component';
import { CardBrandComponent } from './components/card-brand/card-brand.component';
import { ButtonIconComponent } from './components/button-icon/button-icon.component';
import { TooltipCollectionComponent } from './components/tooltip-collection/tooltip-collection.component';
import { TooltipTableComponent } from './components/tooltip-table/tooltip-table.component';
import { OrderNumberPipe } from './pipes/order-number.pipe';
import { CustomLoadingCellRenderedComponent } from './components/custom-loading-cell-rendered/custom-loading-cell-rendered.component';
import { CreateTicketsComponent } from './components/create-tickets/create-tickets.component';
import { PhoneFormatPipe } from './pipes/phoneFormat.pipe';
import { DateOriginalPipe } from './pipes/date-original.pipe';
import { HourOriginalPipe } from './pipes/hour-original.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DateFormatWithoutHourPipe } from './pipes/date-format-without-hour.pipe';
import { DateTextTablesComponent } from './components/date-text-tables/date-text-tables.component';
import { DeleteIconComponent } from './components/delete-icon/delete-icon.component';

@NgModule({
	declarations: [
		AlertMessageComponent,
		GoogleMapsComponent,
		StarRatingComponent,
		TableComponent,
		CardDoorsComponent,
		CardKpiComponent,
		CardsOverviewComponent,
		ToggleComponent,
		CalendarComponent,
		ButtonExportComponent,
		FooterComponent,
		CardComponent,
		DropdownExportComponent,
		ButtonUploadComponent,
		ButtonCreateOrderComponent,
		FilterFunnelComponent,
		NavbarComponent,
		TooltipComponent,
		ButtonCreateUserComponent,
		TooltipCenterSmComponent,
		TooltipEndSmComponent,
		NotificationsComponent,
		DropdownNavbarComponent,
		StarsComponent,
		ButtonCreateCarrierComponent,
		ButtonComponent,
		SidebarSettingsComponent,
		SidebarComponent,
		CardsLockersComponent,
		CardBrandComponent,
		ButtonIconComponent,
		TooltipCollectionComponent,
		TooltipTableComponent,
		CustomLoadingCellRenderedComponent,
		CreateTicketsComponent,
		DateTextTablesComponent,

		OrderNumberPipe,
		WordSlicePipe,
		SearchPipe,
		PhoneFormatPipe,
		DateOriginalPipe,
		HourOriginalPipe,
		DateFormatPipe,
		DateFormatWithoutHourPipe,
  DeleteIconComponent,

	],
	exports: [
		AlertMessageComponent,
		GoogleMapsComponent,
		StarRatingComponent,
		TableComponent,
		CardDoorsComponent,
		CardKpiComponent,
		CardsOverviewComponent,
		ToggleComponent,
		CalendarComponent,
		ButtonExportComponent,
		FooterComponent,
		CardComponent,
		DropdownExportComponent,
		ButtonUploadComponent,
		ButtonCreateOrderComponent,
		FilterFunnelComponent,
		NavbarComponent,
		TooltipComponent,
		ButtonCreateUserComponent,
		TooltipCenterSmComponent,
		TooltipEndSmComponent,
		NotificationsComponent,
		DropdownNavbarComponent,
		StarsComponent,
		ButtonCreateCarrierComponent,
		ButtonComponent,
		SidebarSettingsComponent,
		SidebarComponent,
		CardsLockersComponent,
		CardBrandComponent,
		ButtonIconComponent,
		TooltipCollectionComponent,
		TooltipTableComponent,
		CustomLoadingCellRenderedComponent,
		CreateTicketsComponent,
		DateTextTablesComponent,

		OrderNumberPipe,
		WordSlicePipe,
		SearchPipe,
		PhoneFormatPipe,
		DateOriginalPipe,
		HourOriginalPipe,
		DateFormatPipe,
		DateFormatWithoutHourPipe
	],
	imports: [
		CommonModule,
		RouterModule,
		MaterialModule,
		NgxPaginationModule,
		AgGridModule,
		TranslateModule,
		NgApexchartsModule,
	],
})
export class SharedModule {}
