import { Component, Input } from '@angular/core';

export interface SideBarSettings {
  txt:string;
  route:string;
}

@Component({
  selector: 'sidebar-settings',
  templateUrl: './sidebar-settings.component.html',
  styleUrls: ['./sidebar-settings.component.scss']
})
export class SidebarSettingsComponent {

  @Input()sideBarSettings:SideBarSettings[]

}
