import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-client-grid',
	templateUrl: './client-grid.component.html',
	styleUrls: ['./client-grid.component.scss'],
})
export class ClientGrid implements OnInit {
	coords = [];
	centroide_lat: number;
	centroide_lng: number;

	constructor(
		public dialogRef: MatDialogRef<ClientGrid>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.centroide_lat = Number(this.data.lat);
		this.centroide_lng = Number(this.data.lng);
		this.coords.push({ lat: this.data.lat, lng: this.data.lng });
	}
}
