import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root',
})
export class CCCGuard implements CanActivate {
	constructor(private us: UserService, private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		// Si el valor de la propiedad role del objeto us es 9, se permite el acceso
		if (this.us.role === 10) {
			return true;
		} else {
			return false;
		}
	}
}
