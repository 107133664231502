import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
	private arrayFilterEstatusSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatusOrdersSubject = new BehaviorSubject<Set<Object>>(new Set<Object>());
	private arrayFilterStarsSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterCoverageSubject = new BehaviorSubject<Set<string>>(new Set<string>());

	private arrayFilterEstatusWarehouseGeneralSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatusWarehouseNoAsignadosSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatusWarehouseReturnsSubject = new BehaviorSubject<Set<number>>(new Set<number>());


	updateEstatusFilter(filter: Set<number>) {
		this.arrayFilterEstatusSubject.next(filter);
	}
	updateEstatusOrdersFilter(filter: Set<Object>) {
		this.arrayFilterEstatusOrdersSubject.next(filter);
	}
	updateEstatusWarehouseGeneralFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehouseGeneralSubject.next(filter);
	}
	updateEstatusWarehouseNoAsignadosFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehouseNoAsignadosSubject.next(filter);
	}
	updateEstatusWarehouseReturnsFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehouseReturnsSubject.next(filter);
	}
	updateStarsFilter(filter: Set<number>) {
		this.arrayFilterStarsSubject.next(filter);
	}

	updateCoverageFilter(filter: Set<string>) {
		this.arrayFilterCoverageSubject.next(filter);
	}

	getEstatusOrderFilter(): Observable<Set<Object>> {
		return this.arrayFilterEstatusOrdersSubject.asObservable();
	}

	getEstatusFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusSubject.asObservable();
	}

	getStarsFilter(): Observable<Set<number>> {
		return this.arrayFilterStarsSubject.asObservable();
	}

	getCoverageFilter(): Observable<Set<string>> {
		return this.arrayFilterCoverageSubject.asObservable();
	}

	getEstatusWarehouseGeneralFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehouseGeneralSubject.asObservable();
	}
	getEstatusWarehouseNoAsignadosFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehouseNoAsignadosSubject.asObservable();
	}
	getEstatusWarehouseReturnsFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehouseReturnsSubject.asObservable();
	}

}
