import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-estatus-table-inventory',
	templateUrl: './estatus-table-inventory.component.html',
	styleUrls: ['./estatus-table-inventory.component.scss']
})
export class EstatusTableInventoryComponent implements OnInit,ICellRendererAngularComp {
	statusID:string;
	constructor() { }

	agInit(params: ICellRendererParams): void {
		console.log(params.value)
			this.statusID = params.value
	}

	refresh(params: ICellRendererParams) {
		return false;
	}

	ngOnInit(): void {

	}

}
