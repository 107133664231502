
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TableUserService } from 'src/app/core/http/user.service';
import { SupervisoresInterface } from 'src/app/core/models/users.interfaces';
import * as XLSX from 'xlsx';
import { EstatusTableOrderAdminComponent } from '../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../date-text/date-text.component';
import { UserDetailModalComponent } from '../user-detail-modal/user-detail-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FetchService } from 'src/app/core/http/fetch/fetch.service';
@Component({
	selector: 'app-table-properties',
	templateUrl: './table-properties.component.html',
	styleUrls: ['./table-properties.component.scss'],
})
export class TablePropertiesComponent implements OnInit, OnChanges {
	@Input() rowData: any[] = [];
	valueInput;
	filterText: string = '';
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	supervisors: SupervisoresInterface[];
	language: string;
	public columnDefs: ColDef[];

	constructor(
		private translate: TranslateService,
		private userService: TableUserService,
		private dialog: MatDialog,
		private http: HttpClient,
		private router: Router,
		private fetchService: FetchService
	) {}
	ngOnChanges(changes: SimpleChanges){
		this.loadData();
	}

	ngOnInit() {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataUser();
		this.loadData();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.language = event.lang;
			this.translate.use(event.lang);
			this.translate.get([
					'properties.table.property',
					'properties.table.city',
					'properties.table.address',
					'properties.table.accessPoint',
					'properties.table.modulesInstalled',
					'properties.table.totalDoor'
				])
				.subscribe((t: any) => {
					this.columnDefs = [
						{
							headerName: t['properties.table.property'],
							field: 'name_propierty',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClassLink',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer,
							onCellClicked: (params) => {
								this.verAccessPoint(params)
							}
						},
						{
							headerName: t['properties.table.city'],
							field: 'city',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer
						},
						{
							headerName: t['properties.table.address'],
							field: 'address',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClassAddress',
							headerClass:'borderHeaderProperty',
							cellRenderer: (params) => {
								const maxLength = 100; // Número máximo de caracteres antes de mostrar los puntos suspensivos
								const value = params.value;
								if (value.length > maxLength) {
									return value.slice(0, maxLength) + '...';
								}
								return value;
							},
						},
						{
							headerName: t['properties.table.accessPoint'],
							field: 'access_points',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer
						},
						{
							headerName: t['properties.table.modulesInstalled'],
							field: 'modules_installed',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer
						},
						{
							headerName: t['properties.table.totalDoor'],
							field: 'total_doors',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'PropertyClass',
							headerClass:'borderHeaderProperty',
							cellRenderer: this.centeredCellRenderer
						}

					];
				});
		});
	}

	loadData() {
		// this.fetchService.getAllProperties().then((res:any)=>{
		// 	if(res.mensaje_return.CODE === 200){
		// 		console.log(res.return_data_properties);
		// 		this.rowData = res.return_data_properties;
		// 	}
		// })
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'left';
		cellDiv.style.justifyContent = 'left';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		console.log('HOLA')
		const fileName = 'Properties.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Properties',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_status,audit_package,audit_employee,url_token,id_language,id_user,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			// Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (
			this.rowData.length > 0 &&
			this.filteredData.length === 0
		) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				const { id_status,audit_package,audit_employee,url_token,id_language,id_user,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			// Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
	onGridReady(params: any) {
		const { api } = params;
	}
	verAccessPoint(params:any){
		console.log(params);
		localStorage.setItem('latitudAccessPoint', params.data.latitud);
		localStorage.setItem('longitudAccessPoint', params.data.longitud);
		this.router.navigateByUrl(`/fetch/access-point/`+params.data.id_propierty);
	}
	updateDataUser(): void {
		this.translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('properties.table.property'),
					field: 'name_propierty',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClassLink',
					headerClass:'borderHeaderProperty',
					cellRenderer: this.centeredCellRenderer,
					onCellClicked: (params) => {
						this.verAccessPoint(params)
					},
				},
				{
					headerName: this.translate.instant('properties.table.city'),
					field: 'city',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('properties.table.address'),
					field: 'address',
					width:450,
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClassAddress',
					headerClass:'borderHeaderProperty',
					cellRenderer: (params) => {
						const maxLength = 100; // Número máximo de caracteres antes de mostrar los puntos suspensivos
						const value = params.value;
						if (value.length > maxLength) {
							return value.slice(0, maxLength) + '...';
						}
						return value;
					},
				},
				{
					headerName: this.translate.instant('properties.table.accessPoint'),
					field: 'access_points',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
				},
				{
					headerName: this.translate.instant('properties.table.modulesInstalled'),
					field: 'modules_installed',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
				},
				{
					headerName: this.translate.instant('properties.table.totalDoor'),
					field: 'total_doors',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'PropertyClass',
					headerClass:'borderHeaderProperty',
				},
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			headerHeight:62,
			rowHeight:62,
			paginationAutoPageSize: true,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					${this.translate.instant('tableWithoutContent.text')}
				</div>
			</div>`,
			rowClass:'rowPropertyClass',

		}
	}

}

