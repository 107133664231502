import { Component } from '@angular/core';

@Component({
	selector: 'app-ratings',
	templateUrl: './ratings.component.html',
	styleUrls: ['./ratings.component.scss'],
})
export class RatingsComponent {
	tooltipHistorical: string = 'oche de de de de';
}
