import { Component, Input, OnInit } from '@angular/core';
import { CardData } from 'src/app/core/models';

@Component({
  selector: 'card-brand',
  templateUrl: './card-brand.component.html',
  styleUrls: ['./card-brand.component.scss']
})
export class CardBrandComponent implements OnInit {
	// CARD STYLE
	/*
    null  : display none
    sm    : small size
    md    : medium size
  */
	@Input() cardSize: string;
	@Input() colorBg: string = '--color-container';
	// DATA CARD
	@Input() dataCard: CardData[] = [];
	@Input() footer: boolean;
	@Input() header: boolean;
	@Input() body: boolean;
	@Input() cardsCol: number;
	@Input() cardsRow: number;
	@Input() MaxCol: number;
	// LocalStorage set IDS
	private storeCardsIds: string | null = null;

	constructor(){}

	ngOnInit(): void {
		this.cardsCol = Math.min(this.dataCard.length, this.MaxCol);
		this.cardsRow = Math.ceil(this.cardsCol / this.MaxCol);
	}
}
