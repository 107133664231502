import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-estatus-oxxo-to-oxxo',
  templateUrl: './estatus-oxxo-to-oxxo.component.html',
  styleUrls: ['./estatus-oxxo-to-oxxo.component.scss']
})
export class EstatusOxxoToOxxoComponent implements OnInit,ICellRendererAngularComp {
	statusID:number;
	constructor() { }

	agInit(params: ICellRendererParams): void {
		this.statusID = params.value;
	}

	refresh(params: ICellRendererParams) {
		return false;
	}

	ngOnInit(): void {

	}

}
