// code-with-line-numbers.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-code-with-line-numbers',
  templateUrl: './code-with-line-numbers.component.html',
  styleUrls: ['./code-with-line-numbers.component.scss']
})
export class CodeWithLineNumbersComponent implements OnInit {
  @Input() codeContent: string;
  mostrarButtonCopiado:boolean = false;
  numberedCodeLines: string[];

  constructor(private clipboard: Clipboard){}
  ngOnInit() {
    this.addLineNumbers();
  }

  addLineNumbers() {
    const codeLines = this.codeContent.split('\n');
    this.numberedCodeLines = codeLines.map((line, index) => `${line}`);
  }
  copyJson(){
	this.mostrarButtonCopiado = true;
	const jsonContent = this.numberedCodeLines.join('\n');
    this.clipboard.copy(jsonContent);
	setTimeout(() => {
		this.mostrarButtonCopiado = false;
	},5000)
  }
}
