<!--? CONTAINER MAIN -->
<div class="container-main">
	<div class="grid-title">
        <span class="title">
			{{'configWebhook.title' | translate}}
        </span>
    </div>
	<!-- <div class="grid-header">
		<btn-activity class="btn-activity"></btn-activity>
	</div> -->
	<!-- <div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="card">
				<div class="containerConfigWebhookTitle">
					<div class="titleURL">
						<div class="text">
							{{'configWebhook.config.URL' | translate}}
						</div>
					</div>
					<div class="titleDateEdition">
						<div class="text">
							{{'configWebhook.config.editionDate' | translate}}
						</div>
					</div>
					<div class="titleStatusSubscription">
						<div class="text">
							{{'configWebhook.config.statusSubcription' | translate}}
						</div>
					</div>

				</div>
				<div class="containerConfigWebhookValues">
					<div class="titleValueURL">
						<input type="text" class="url" value="{{urlDocumentation}}">
					</div>
					<div class="titleValueDateEdition">
						<div class="text">
							{{updateDate}}
						</div>
					</div>
					<div class="titleValueSuscrito">
						<div class="text">
							{{'configWebhook.config.subscribe' | translate}}
						</div>
					</div>
					<div class="titleValueUpdate">
						<app-button btnNombre="configWebhook.config.updateButton" [activo]="false" btnType="secondaryMedium">
						</app-button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<hr style="margin:24px 0px 0px 0px; color: var(--color-primary-200 )">
		</div>
	</div> -->

	<div class="row" style="gap: 24px 48px;	margin-bottom: 32px;">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: flex-end;">
			<!-- <span class="titleEventosWebhook">
				{{'configWebhook.eventWebhookTitle' | translate}}
			</span> -->
			<app-button-icon
				btnType="primaryMedium"
				btnNombre="configWebhook.saveEventWebhook"
				icon="icon-save"
				(click)="saveChanges()">

			</app-button-icon>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="cardEventWebhook">
				<ag-grid-angular
					#agGrid
					style="width: 100%; height: auto;"
					class="ag-theme-alpine"
					[gridOptions]="gridOptions"
					[columnDefs]="columnDefs"
					[rowData]="rowData"
					[overlayNoRowsTemplate]="overlayNoRowContent">
				</ag-grid-angular>
			</div>
		</div>
	</div>

</div>
