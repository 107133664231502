<div id="body-tabs-badge" class="content-body" fxLayout="column" fxLayoutGap="36px">
	<mat-tab-group class="tabs-client" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
		<!-- ? Completed TAB -->
		<mat-tab label="{{ 'general.completed' | translate }}" >
			<!-- <div class="card-filter">
				<div class="row mt-3">
					<div class="col-4 mt-2 mb-2"></div>
					<div class="col-2  mt-2 mb-2">
						<span class="p-float-label">
							<p-calendar class="selectYear" 
								[showIcon]="true" 
								[(ngModel)]="rangeDates" 
								(ngModelChange)="addEventRangeDates()" 
								selectionMode="range" 
								[readonlyInput]="true" 
								inputId="range" >
							</p-calendar>
							<label>{{'log_page.selection' | translate }}</label>
						</span>
					</div>
					<div class="col-2  mt-2 mb-2">
						<span class="p-float-label">
							<p-calendar placeholder="Selecciona un mes" 
								class="selectYear" 
								[showIcon]="true" [(ngModel)]="selectMonth" 
								(ngModelChange)="selectMonthEvent()" 
								view="month" 
								dateFormat="mm/yy" 
								[yearNavigator]="true" 
								yearRange="2000:2030" 
								[readonlyInput]="true" 
								inputId="monthpicker">
							</p-calendar>
							<label>{{'log_page.month' | translate }}</label>
						</span>
					</div>
					<div class="col-2  mt-2 mb-2">
						<button onclick="document.getElementById('myFile').click();" class="btn-secondary-gbl">
							{{'buttons.up_order' | translate}}
							<i  class="fas fa-upload"></i>
						</button>
					</div>
					<div class="col-2  mt-2 mb-2">
						<button (click)="createPackageSchedule()" class="btn-primary-gbl">
							{{'buttons.create_orden' | translate}}
						</button>
					</div>
				</div>
			</div> -->
			<div class="card-filter">
				<div class="row mb-3" >
					<div class="col-4">

					</div>
					<div class="col-8 mt-3 mb-3" style="text-align: right;">
						<span class="p-float-label" style="display: inline-block; width: 100px; text-align: right; width: 250px; margin-right:18px">
							
							<p-calendar class="selectYear" 
								[showIcon]="true" 
								[(ngModel)]="rangeDates" 
								(ngModelChange)="addEventRangeDates()" 
								selectionMode="range" 
								[readonlyInput]="true" 
								inputId="range" >
							</p-calendar>
							<!-- TITLE -->
							<label>{{'log_page.selection' | translate }}</label>
						</span>
						<span class="p-float-label" style="display: inline-block; width: 100px; text-align: right; width: 250px; margin-right:18px">
							<p-calendar placeholder="Selecciona un mes" 
								class="selectYear" 
								[showIcon]="true" [(ngModel)]="selectMonth" 
								(ngModelChange)="selectMonthEvent()" 
								view="month" 
								dateFormat="mm/yy" 
								[yearNavigator]="true" 
								yearRange="2000:2030" 
								[readonlyInput]="true" 
								inputId="monthpicker">
							</p-calendar>
							<!-- TITLE -->
							<label>{{'log_page.month' | translate }}</label>
						</span>
						<!-- <button onclick="document.getElementById('myFile').click();" class="btn-secondary-gbl" style="display: inline-block; width: 100px; text-align: right; width: 250px; margin-right:18px">
							{{'buttons.up_order' | translate}}
							<i  class="fas fa-upload"></i>
						</button> -->
						<button (click)="createPackageSchedule()" class= " mt-2 btn-primary-gbl" style="display: inline-block; width: 100px; width: 220px;">
							{{'buttons.create_orden' | translate}}
						</button>
					</div>
				</div>
			</div>
			<div id="body-tabs" class="tab-body" fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
				<mat-tab-group class="tabs tabs-client">
					<!--Tabs Completed2 -->
					<!-- COMPLETED ----------------------------------------------------------------------------->
					<mat-tab>
						<ng-template mat-tab-label> 
							<span class="bg-badge" matBadge="{{comple}}" matBadgeOverlap="false">
								{{ 'general.completed' | translate }}
							</span>  
						</ng-template>
						<div style="margin-top: 2px;"></div>
						<mat-card fxFlex="100%" fxLayout="column">
							<app-orders-table-admin (EmitDate)="getMensaje($event)"  header="{{consultorTabCompleted}}" [data]="data" columns="{{columns}}"></app-orders-table-admin>
						</mat-card>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<!--Tabs SUMARY -->
							<span (click)="generalChart()" class="bg-badge">{{ 'general.sumary' | translate }}</span>
						</ng-template>
						<!--Row 1-->  
						<div class="tab-body" fxFlex="100%" fxLayout="row" fxLayoutGap="20px" style="margin-top: 2px;">
							<mat-card fxFlex="97.5%" class="chartcards">
								<mat-card-header>
									<mat-card-title class="title-card">{{ 'deliveries.summary' | translate }}</mat-card-title>
									<i class="fas fa-info-circle" matTooltip="{{ 'info.orders.completed.summary.order_summary' | translate }}" matTooltipPosition="above"></i>
								</mat-card-header>
								<mat-card-content id="general-chart">
									<app-chart-bar-admin type="orders" [arrayName]="mostActiveLockers"></app-chart-bar-admin>
									<!--<div id="chartdiv"></div>-->
								</mat-card-content>
							</mat-card>
						</div>
						<!--Row 2-->  
						<div fxFlex="100%" fxLayout="row" fxLayoutGap="20px" fxLayout.sm="column" fxLayout.xs="column">
							<!--Chart 1-->  
							<mat-card fxFlex="60" class="chartcards">
								<mat-card-header>
									<mat-card-title class="title-card" >{{ 'deliveries.total_deliveries' | translate }} {{'deliveries.byMonth' | translate}}</mat-card-title>
									<i class="fas fa-info-circle" matTooltip="{{ 'info.orders.completed.summary.total_orders_delivered_month' | translate }}" matTooltipPosition="above"></i>
								</mat-card-header>
								<mat-card-content>
									<app-chart-client-admin *ngIf="chart" data="{{chart}}" months="{{months}}" type="orders"></app-chart-client-admin>
								</mat-card-content>
							</mat-card>
							<!--Chart 2-->  
							<mat-card fxFlex="37%" class="chartcards">
								<mat-card-header>
									<mat-card-title class="title-card">{{ 'deliveries.deliver_size' | translate }}</mat-card-title>
									<i class="fas fa-info-circle" matTooltip="{{ 'info.orders.completed.summary.orders_delivered_size' | translate }}" matTooltipPosition="above"></i>
								</mat-card-header>
								<mat-card-content>
									<app-pie-chart-admin [sizes]="sizes"></app-pie-chart-admin>
								</mat-card-content>
							</mat-card>
						</div>
						<!--Row 3-->  
						<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around strech" id="cards-dash">
								<!--card 1-->  
								<mat-card class="small-card">
									<mat-card-header class="headorder">
										<mat-card-title class="title-card">{{ 'general.totalCustomers' | translate }}</mat-card-title>
										<i class="fas fa-info-circle" matTooltip="{{ 'info.orders.completed.summary.total_unique_customers' | translate }}" matTooltipPosition="above"></i>
									</mat-card-header>
									<mat-card-content class="small-content">
										<div class="orders-content">
											<p>{{'general.customers' | translate}}</p>
											<!--<span class="big">{{consumerCount}} </span>-->
											<span class="big">{{total_unique_costumers}}</span>
											<div class="cardfoot"><img src="\assets\images\HomeDepot\arrow.svg" alt="" >
												<p>
													+ {{datos[1].percentupunique}}%
												</p>
											</div>
										</div>
									</mat-card-content>
								</mat-card>
								<!--card 2-->  
								<mat-card class="small-card">
									<mat-card-header class="headorder">
										<mat-card-title class="title-card">{{ 'lockers.use_total' | translate }}</mat-card-title>
										<i class="fas fa-info-circle" matTooltip="{{ 'info.orders.completed.summary.total_orders_delivered' | translate }}" matTooltipPosition="above"></i>
									</mat-card-header>
									<mat-card-content class="small-content">
										<div class="orders-content">
											<p>{{'general.customers' | translate}}</p>
											<!--<span class="big">{{total}} </span>-->
											<span class="big">{{total_locker_usage}} </span>
											<div class="cardfoot"><img src="\assets\images\HomeDepot\arrow.svg" alt="" >
												<p>
													+ {{datos[1].percentlockerusage}}%
												</p>
											</div>
										</div>
									</mat-card-content>
								</mat-card>
								<!--card 3-->  
								<mat-card class="small-card">
									<mat-card-header class="headorder">
										<mat-card-title class="title-card">{{ 'lockers.returning' | translate }}</mat-card-title>
										<i class="fas fa-info-circle" matTooltip="{{ 'info.orders.completed.summary.orders_delivered_today' | translate }}" matTooltipPosition="above"></i>
									</mat-card-header>
									<mat-card-content class="small-content">
										<p class="average_time">{{'lockers.returning2' | translate}}</p>
											<div class="orders-content">
											<p>{{'general.customers' | translate}}</p>
											<!--<span class="big">{{today}} </span>-->
											<span class="big">{{returning}} </span>
											<div class="cardfoot"><img src="\assets\images\HomeDepot\arrow.svg" alt="" >
												<p>
													+ {{datos[1].percentorderreturn}}%
												</p>
											</div>
										</div>
									</mat-card-content>
								</mat-card>
								<!--card 4-->  
								<mat-card class="small-card">
									<mat-card-header class="headorder">
										<mat-card-title class="title-card">{{ 'general.orders' | translate }}<br>{{ 'lockers.orders-delivered' | translate }}</mat-card-title>
										<i class="fas fa-info-circle" matTooltip="{{ 'info.orders.completed.summary.total_expired_orders' | translate }}" matTooltipPosition="above"></i>
									</mat-card-header>
									<mat-card-content class="small-content">
										<div class="orders-content">
											<p>{{'general.Orders' | translate}}</p>
											<span class="big">{{exp}} </span>
											<div class="cardfoot"><img src="\assets\images\HomeDepot\arrow.svg" alt="" >
												<p>
													+{{datos[1].percentorderdeliver}}%
												</p>
											</div>
										</div>
									</mat-card-content>
								</mat-card>
								<!--card 5-->  
								<mat-card  class="small-card">
									<mat-card-header class="headorder">
										<mat-card-title class="title-card">{{ 'lockers.success_rate' | translate }}</mat-card-title>
										<i class="fas fa-info-circle" matTooltip="{{ 'info.orders.completed.summary.most_used_compartment_size' | translate }}" matTooltipPosition="above"></i>
									</mat-card-header>
									<mat-card-content class="small-content">
										<div id="chart-orders">
											<apx-chart [series]="chartOptions.series"[chart]="chartOptions.chart" [plotOptions]="chartOptions.plotOptions" [labels]="chartOptions.labels" [stroke]="chartOptions.stroke" [fill]="chartOptions.fill">

											</apx-chart>
											<p style="margin-top: -15px;">
												{{'general.Orders' | translate}}
											</p>
										</div>
										<!--<p><span  *ngIf="mostUsed[0]"  class="big">{{mostUsed[0].size}}</span></p>-->
									</mat-card-content>
								</mat-card>
								<!--card 6-->  
								<mat-card class="small-card">
									<mat-card-header>
										<mat-card-title class="title-card">
											{{ 'general.averagecustomer' | translate }}
										</mat-card-title>
										<i class="fas fa-info-circle" matTooltip="{{ 'general.averagecustomer' | translate }}" matTooltipPosition="above"></i>
									</mat-card-header>
									<mat-card-content class="small-content">
										<!-- Rating- Start -->
										<div class="rate">
											<app-star-rating
											[rating]="rating"
											[starCount]="starCount"
											[color]="starColor"
											></app-star-rating>
										</div>
										<p class="number">
											<span class="big">
												{{ consumerMetrics[0].package_avg_rating }}
											</span>
											<br>
										</p>
										<div style="margin-top: 20px;">
											{{'general.rate1' | translate}}
											<strong>
												{{ consumerMetrics[0].package_rating_total }}
											</strong>
											{{'general.rate2' | translate}}
										</div>
									</mat-card-content>
								</mat-card>
							</div>
							<!--Row 4-->
							<div  fxFlex="100%" fxLayout="row wrap"   fxLayout.sm="column" fxLayout.xs="column" id="cards-order-client-large">
								<!--Card 1-->
								<mat-card fxFlex.xs="calc(100%-16px)">
									<mat-card-title-group>
										<mat-card-title class="title-card titlehour">
											{{ 'lockers.average_collection' | translate }}
										</mat-card-title>
										<p class="bighour">
											<span class="big">
												{{datos[0].average_collection_time}}
											</span><!--{{'deliveries.hours' | translate}}-->
										</p>
										<!--<p class="sub">({{'deliveries.dtoc' | translate}})</p>-->
										<div style="width: 25px; margin-top: 8px;">
											<i class="fas fa-info-circle" matTooltip="{{'info.lockers.historical.avg_collection_time' | translate }}" matTooltipPosition="above"></i>
										</div>
									</mat-card-title-group>
								</mat-card>
								<!--Card 2 -->
								<mat-card fxFlex.xs="calc(100%-16px)">
									<mat-card-title-group>
										<mat-card-title class="title-card titlehour" style="margin-top: 20px;">
											{{ 'lockers.average_time_byIkea' | translate }}<br><p>{{ 'lockers.average_time_byIkea2' | translate }}</p>
										</mat-card-title>
										<p class="bighour"><span class="ms-3 big">{{datos[0].average_time_taken}}</span></p>
										<!--<p class="sub">({{'deliveries.dtoc' | translate}})</p>-->
										<div style="width: 25px; margin-top: 8px;">
										<i class="fas fa-info-circle" matTooltip="{{'info.lockers.historical.avg_collection_time_refund' | translate }}" matTooltipPosition="above"></i>
										</div>
									</mat-card-title-group>
								</mat-card>
							</div>
					</mat-tab>
				</mat-tab-group>
			</div>
		</mat-tab>


		<!-- ? General TAB -->
		<mat-tab>
			<ng-template mat-tab-label >
				<span class="bg-badge" *ngIf="all>0" matBadge="{{all}}" matBadgeOverlap="false">
					{{ 'general.general' | translate }}
				</span>
				<span class="bg-badge" *ngIf="all<=0">
					{{ 'general.general' | translate }}
				</span>
			</ng-template>

			<div style="margin-top: 85px;"></div> 

			<mat-card fxFlex="100%" fxLayout="column" style="border: 0px !important; box-shadow: none !important; background-color: transparent !important;">    
				<div class="legend">
					<div class="title">
						{{ 'table.entregado' | translate }}
					</div>
					<div class="icono ENTREGADO"></div>          

					<div class="title">
						{{ 'table.expired' | translate }}
					</div>
					<div class="icono VENCIDO"></div> 

					<div class="title">
						{{ 'table.disponible' | translate }}
					</div>
					<div class="icono DISPONIBLE"></div> 

					<div class="title">
						{{ 'table.devuelto' | translate }}
					</div>
					<div class="icono DEVUELTO"></div>          
				</div>
			</mat-card>

			<mat-card fxFlex="100%" fxLayout="column">
				<app-orders-table-admin (EmitDate)="getMensaje($event)" data="{{generalData}}" columns="{{columnsGeneral}}"></app-orders-table-admin>
			</mat-card>
		</mat-tab>


		<!-- ? Pending TAB -->
		<mat-tab>
			<ng-template mat-tab-label>
				<span class="bg-badge" *ngIf="pend>0" matBadge="{{pend}}" matBadgeOverlap="false">
					{{ 'general.pending' | translate }}
				</span>
				<span class="bg-badge" *ngIf="pend<=0">
					{{ 'general.pending' | translate }}
				</span>
			</ng-template>
			<div style="margin-top: 85px;"></div>
			<mat-card fxFlex="100%" fxLayout="column">
				<app-orders-table-admin (EmitDate)="getMensaje($event)" data="{{pendingData}}" columns="{{columns1}}"></app-orders-table-admin>
			</mat-card>
		</mat-tab>

		<!-- ? Expired TAB -->
		<mat-tab>
			<ng-template mat-tab-label>
				<span class="bg-badge" *ngIf="exp>0" matBadge="{{exp}}" matBadgeOverlap="false">
					{{ 'general.expired' | translate }}
				</span>
				<span class="bg-badge" *ngIf="exp<=0">{{ 'general.expired' | translate }}</span>
			</ng-template>
			<div style="margin-top: 85px;"></div>
			<mat-card fxFlex="100%" fxLayout="column">
				<app-orders-table-admin (EmitDate)="getMensaje($event)" data="{{expiredData}}" columns="{{columns2}}"></app-orders-table-admin>
			</mat-card>
		</mat-tab>
		
		<!-- ? Refund TAB -->
		<mat-tab>
			<ng-template mat-tab-label>
				<span class="bg-badge" *ngIf="refund>0" matBadge="{{refund}}" matBadgeOverlap="false">
					{{ 'general.refund' | translate }}
				</span>
				<span class="bg-badge" *ngIf="refund<=0">{{ 'general.refund' | translate }}</span>
			</ng-template>
			<div style="margin-top: 85px;"></div>
			<mat-card fxFlex="100%" fxLayout="column">
				<app-orders-table-admin (EmitDate)="getMensaje($event)" data="{{refundData}}" columns="{{columns3}}"></app-orders-table-admin>
			</mat-card>
		</mat-tab>


		<!-- ? schedule TAB -->
		<mat-tab>
			<ng-template mat-tab-label>
				<span class="bg-badge" *ngIf="scheduled>0" matBadge="{{scheduled}}" matBadgeOverlap="false">
					{{ 'general.scheduled' | translate }}
				</span>
				<span class="bg-badge" *ngIf="scheduled<=0">
					{{ 'general.scheduled' | translate }}
				</span>
			</ng-template>
			<div style="margin-top: 75px;"></div>

			<mat-card fxFlex="100%" fxLayout="column" style="border: 0px !important; box-shadow: none !important; background-color: transparent !important;">    
				<div class="legend">
					<div class="title">
						{{ 'table.scheduled' | translate }}
					</div>
					<div class="icono schedule"></div>          

					<div class="title">
						{{ 'table.finish' | translate }}
					</div>
					<div class="icono finish"></div> 

					<div class="title">
						{{ 'table.in_process' | translate }}
					</div>
					<div class="icono process"></div> 
				</div>

			</mat-card>
			<mat-card fxFlex="100%" fxLayout="column">        
				<app-orders-table-admin (EmitDate)="getMensaje($event)" 
										data="{{scheduledData}}" 
										columns="{{scheduledColumn}}" 
										(updateEvent)="updateEvent($event)">
				</app-orders-table-admin>
			</mat-card>
		</mat-tab>

	</mat-tab-group>

</div>