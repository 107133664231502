import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-edit-user-renderer',
  templateUrl: './edit-user-renderer.component.html',
  styleUrls: ['./edit-user-renderer.component.scss']
})
export class EditUserRendererComponent implements OnInit, ICellRendererAngularComp {

  constructor() { }
	agInit(params: ICellRendererParams): void {
	}
	refresh(params: ICellRendererParams): boolean {
		return false;
	}

  ngOnInit(): void {
  }

}
