
<div class="row container-graphic">
    <!-- HEADER -->
    <div class="row header-graphic">
        <div class="col">
            <h5 class="header-txt">
                {{ 'table-year.title' | translate }}
            </h5>
        </div>
        
    </div>
    <!-- BUTTONS -->
    <div class="row">
        <div class="col">
            <p-calendar [(ngModel)]="yearSelected" 
                        (ngModelChange)="updateTable()" 
                        [showIcon]="true" 
                        class="selectYear input-gbl" 
                        view="year" 
                        dateFormat="yy" 
                        [readonlyInput]="true" 
                        inputId="pickYear" 
                        placeholder="{{ 'table-year.placeholder' | translate }}">
            </p-calendar>
            <button class="btn-primary-gbl" 
                    *ngIf="convertir === false" 
                    (click)="convertirPorcentaje()">
                {{ 'buttons.percentage' | translate }}
            </button>
            <button class="btn-primary-gbl" 
                    *ngIf="convertir === true" 
                    (click)="convertirPorcentaje()">
                {{ 'buttons.convert' | translate }}
            </button>
        </div>
    </div>
    <!-- GRAPHIC -->
    <div class="row">
        <highcharts-chart 
            [Highcharts]="Highcharts" 
            [options]="chartOptions" 
            style="display: block; width: 95%; height: 350px;" 
            id="chart">
        </highcharts-chart>
    </div>
</div>




