<div class="container-activity-webhook animate__animated animate__fadeIn">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="grid-title">
				<span class="grid-title-text-lockers" (click)="backPage()">
					{{'WebHook.breadscrumbs.lastPage' | translate}}
				</span>
				<div class="icon-chevron-right"></div>
				<span class="grid-title-text-selected">
					{{'WebHook.breadscrumbs.page' | translate}}
				</span>
			</div>
		</div>
	</div>
	<div class="row" style="height: auto;">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="card-table-carrier">
				<div class="row" style="row-gap: 24px;">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">
						<div class="input-container">
							<input
								type="text"
								class="input-gbl"
								placeholder="{{'WebHook.inputPlaceholder' | translate}}"/>
							<button class="btn-search">
								<div class="icon-search">
								</div>
							</button>
						</div>
					</div>
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">
						<ag-grid-angular
							class="ag-theme-alpine"
							[columnDefs]="this.columnDefs"
							[gridOptions]="this.gridOptions"
							[rowData]="this.eventsWebHook"
							(cellClicked)="onCellClicked($event)">
						</ag-grid-angular>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div class="container-main">
    <div class="grid-breadcrumb">
        <div>
            <span routerLink="../../settings/config-webhook"> {{'activityPage.span1' | translate }} </span>
            <div class="icon-chevron-right"></div>
            <span> {{'activityPage.span2' | translate }} </span>
        </div>
    </div>
        <div class="container-table">
            <div class="btn-table">
                <span class="th">URL</span>
                <span class="th">{{'activityPage.table.type' | translate }}</span>
                <span class="th">{{'activityPage.table.date' | translate }}</span>
                <span class="th"></span>
            </div>
            <div id="accordion" *ngFor="let item of dataTable;  let i = index " >
                <div class="hr" ></div>
                <label for="radio{{i}}" class="btn-table-td">
                    <span class="td-consolas"> {{item.url}} </span>
                    <span class="td-consolas"> {{item.type}} </span>
                    <span > {{item.date}} </span>
                    <div class="icon-container">
                        <span> {{'systemVersionPage.table.json' | translate }} </span>
                        <div [ngClass]="{
                             'icon-chevron-up' : dataVersion === i,
                             'icon-chevron-down'   : dataVersion !== i }">
                        </div>
                    </div>
                </label>
                <input
                    type="radio"
                    name="accordion"
                    [id]="'radio' + i"
                    [checked]="dataVersion === i ? true : null"
                    (click)="toggleAccordion(i)">
                <pre class="cont" style="margin-bottom: 0;">
                    {{item.json}}
                </pre>
           </div>
        </div>
</div> -->
