<div class="row">
    <div class="col-11">
        <label class="header-order-recordatorio">
            {{'recordatorios.title' | translate}}
        </label>
    </div>
    <div class="col-1" style="text-align: end; cursor: pointer;" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#212121" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
        </svg>
    </div>  
    <div class="col-12" style="height: 1px;border-bottom: 1px solid #e5e5e5;margin-top:11px">
    </div>


    <div class="col-12">
        <form [formGroup]="recordatorioFormulario">
            <div class="row">
                <!-- <div class="col-12">
                    <div>
                        <label for="nombreEmpleado" class="header-input">Nombre del empleado</label>
                        <input type="text" class="form-group-input" id="nombreEmpleado" formControlName="nombreEmpleado">
                    </div>
                </div> -->

                <div class="col-6" style="margin-top:18px;height: 462px;">
                    <div>
                        <label for="hora" class="header-input">
                            {{'recordatorios.fieldHour' | translate}}
                        </label>
                        <p-calendar class="form-group-calendar" dateFormat="hh:mm" formControlName="hora" [showIcon]="true"  [showTime]="true" [timeOnly]="true">

                        </p-calendar>
                    </div>
                </div>
                <div class="col-6" style="margin-top:18px; height: 462px;">
                    <div>
                        <label for="hora" class="header-input">
                            {{'recordatorios.fieldDate' | translate}}
                        </label>
                        <p-calendar class="form-group-calendar" formControlName="fecha" [showIcon]="true" [readonlyInput]="true"  [maxDate]="maxDate" [minDate]="minDate">
                        </p-calendar>
                    </div>
                </div>
                <div class="col-10" style="height: 42px;justify-content: end; align-items: end; display: flex;">
                    <button class="cancelar-notificacion" (click)="closeModal()">
                        <div class="cancelar-notificacion-text">
                            {{'recordatorios.buttonCancel' | translate}}
                        </div>
                    </button>
                </div>
                <div class="col-2" style="height: 42px; justify-content: end; align-items: end; display: flex;">
                    <button *ngIf="!this.recordatorioFormulario.valid" class="crear-notificacion-disabled">
                        <div class="crear-notificacion-disabled-text">
                            {{'recordatorios.buttonAdd' | translate}}
                        </div>
                    </button>

                    <button *ngIf="this.recordatorioFormulario.valid" class="crear-notificacion-abled" (click)="agregarRecordatorio()">
                        <div class="crear-notificacion-disabled-text">
                            {{'recordatorios.buttonAdd' | translate}}
                        </div>
                    </button>

                </div>
            </div>
        </form>
    </div>
</div>
