<div class="row" style="margin-top:18px">
	<div class="col-7" style="margin-bottom:18px">
		<label for="input-orden" class="input-label">
			<input (keyup)="onFilterTextChange($event)" placeholder="{{'dashboard.search_user' | translate}}" class="input-search" >
			<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#212121" class="bi bi-search search" viewBox="0 0 16 16">
				<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
		  	</svg>
		</label>
	</div>
	<div class="col-3" style="margin-bottom:18px">
		<p-calendar class="selectYear input-gbls float-end "
					[showIcon]="true" 
					[(ngModel)]="rangeDates" 
					(ngModelChange)="addEventRangeDates()" 
					selectionMode="range" 
					[readonlyInput]="true" 
					placeholder="Selecciona una fecha..."
					inputId="range" >
		</p-calendar>
	</div>
	<div class="col-2" style="margin-bottom:18px">
		<button class="btn-primary-gbl float-end" (click)="onExport()">
			Exportar .xls
			<svg xmlns="http://www.w3.org/2000/svg" width="12.8" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
				<path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
				<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
			</svg>
		</button>
	</div> 
	<div class="col-12">
		<div class="user-table-contain">
			<ag-grid-angular class="ag-theme-alpine" [gridOptions]="gridOptions" [rowData]="rowData" style="width: 100%; height: 750px;">
			</ag-grid-angular>
		</div>
	</div>
</div>
