import { Component, Input, OnInit } from '@angular/core';
import { CardData } from 'src/app/core/models';

@Component({
  selector: 'cards-lockers-orders',
  templateUrl: './cards-lockers.component.html',
  styleUrls: ['./cards-lockers.component.scss']
})
export class CardsLockersComponent  implements OnInit {
	// CARD STYLE
	/*
    null  : display none
    sm    : small size
    md    : medium size
  */
	@Input() cardSize: string;
	@Input() colorBg: string = '--color-container';
	// DATA CARD
	@Input() dataCard: CardData[] = [];
	@Input() footer: boolean;
	@Input() header: boolean;
	@Input() body: boolean;
	// LocalStorage set IDS
	private storeCardsIds: string | null = null;

	constructor(){}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		if (this.storeCardsIds) {
			const cardsIds = this.dataCard.map((card) => card.id);
			localStorage.setItem('cardsIds', JSON.stringify(cardsIds));
		}
	}
}
