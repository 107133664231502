import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
	transform(phoneNumber: string): string {
		if (!phoneNumber) {
			return '';
		}

		const countryCode = phoneNumber.substring(1, 3); // Extraer el código de país (+52)
		const areaCode = phoneNumber.substring(3, 5); // Extraer el código de área (8129)
		const areaCode2 = phoneNumber.substring(5,9)
		const remainingDigits = phoneNumber.substring(9,13); // Extraer los dígitos restantes (342304)

		// Dar formato al número de teléfono
		const formattedPhone = `${areaCode}-${areaCode2}-${remainingDigits}`;

		return formattedPhone;
	}
}
