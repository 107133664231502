<div class="content-body user">
    <mat-card>
        <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-title class="title-card">
              <span>{{ 'user.registerUser' | translate }}</span>
          </mat-card-title>
            <div (click)="back();" id="back">
              <i class="fas fa-chevron-left"></i>
              {{'user.back' | translate }}
            </div>
        </mat-card-header>
     <!-- FORM CONTROL -->
     <form  class="ms-4 needs-validation" 
            autocomplete="off"
            [formGroup]="miRegistro"
            (ngSubmit)="submitRegistro()"
            novalidate>
        
        <!-- FIRST NAME -->
        <div class="col-10 mb-3 position-relative">
            <strong> {{'register_client.firstName' | translate | titlecase }} </strong>
            <input  formControlName="firstName"
                    type="text" 
                    maxlength="50"
                    [ngClass]="{'is-invalid': submitted && miRegistro.controls['firstName'].errors}"
                    placeholder="{{'inputs.firstName' | translate }}"
                    autocomplete="off"
                    class="form-control">
            <span class="invalid-tooltip" *ngIf="invalidForm('firstName')">
                Validation First name
            </span>
        </div>

        <!-- LAST NAME -->
        <div class="col-10 mb-3 position-relative">
            <strong> {{'register_client.lastName' | translate | titlecase }} </strong>
            <input  formControlName="lastName"
                    type="text" 
                    maxlength="50"
                    [ngClass]="{'is-invalid': submitted && miRegistro.controls['lastName'].errors}"
                    placeholder="{{'inputs.lastName' | translate }}"
                    autocomplete="off"
                    class="form-control">
            <span class="invalid-tooltip" *ngIf="invalidForm('lastName')">
                Validation Last name
            </span>
        </div>

        <!-- PHONE NUMBER -->
        <div class="col-10 mb-3 position-relative">
            <strong> {{'register_client.phoneNumber' | translate | titlecase }} </strong>
            <input  formControlName="phoneNumber"    
                    type="number" 
                    maxlength="50"
                    [ngClass]=" {'is-invalid': submitted && miRegistro.controls['phoneNumber'].errors}"
                    placeholder="{{'phone number'| titlecase}}"
                    autocomplete="off"
                    class="form-control">
            <span class="invalid-tooltip" *ngIf="invalidForm('phoneNumber')">
                Validation Phone Number
            </span>
        </div>

        <!-- EMAIL -->
        <div class="col-10 mb-3 position-relative">
            <strong> {{'register_client.email' | translate | titlecase }} </strong>
            <input 
                type="text" 
                formControlName="email"
                maxlength="50"
                [ngClass]=" {'is-invalid': submitted && miRegistro.controls['email'].errors} "
                placeholder="{{'inputs.emailAddress' | translate }}"
                autocomplete="off"
                class="form-control">
            <span class="invalid-tooltip" *ngIf="invalidForm('email')">
                Validation Email
            </span>
        </div>

        <!-- SECTOR -->
        <div class="col-10 mb-3 position-relative">
            <strong> {{'register_client.sector' | translate | titlecase }} </strong>
            <mat-select formControlName="sector" class="form-control" (selectionChange)="getPropierty($event)" placeholder="{{'register_client.sector' | translate }}">
                 <mat-option [value]="sector.id_propierty" *ngFor="let sector of sectors">{{sector.code_propierty}} - {{sector.name_propierty}}</mat-option>
            </mat-select>
            <!--<mat-select class="form-control text-center" (selectionChange)="changeResidentialUnit($event)" placeholder="{{'step_1.selectProp' | translate }}">
                <mat-option [value]="prop.id_propierty_unit" *ngFor="let prop of propiedades"> {{prop.name_propierty_unit}} </mat-option>
            </mat-select>

            
            <input 
                type="text" 
                formControlName="email"
                maxlength="50"
                [ngClass]=" {'is-invalid': submitted && miRegistro.controls['email'].errors} "
                placeholder="{{'register_client.sector' | translate }}"
                autocomplete="off"
                class="form-control">
            <span class="invalid-tooltip" *ngIf="invalidForm('email')">
                Validation Email
            </span>
            -->

        </div>

        <!-- PROPIEDAD -->
        <div class="col-10 mb-3 position-relative">
            <strong> {{'register_client.propiedad' | translate | titlecase }} </strong>
            <mat-select formControlName="propiedad" class="form-control" (selectionChange)="getUnit($event)" placeholder="{{'register_client.propiedad' | translate }}">
                 <mat-option [value]="propiedad.id_propierty_unit" *ngFor="let propiedad of propiedades">{{propiedad.name_propierty_unit}}</mat-option>
            </mat-select>
            <!-- 
            <input 
                type="text" 
                formControlName="email"
                maxlength="50"
                [ngClass]=" {'is-invalid': submitted && miRegistro.controls['email'].errors} "
                placeholder="{{'register_client.propiedad' | translate }}"
                autocomplete="off"
                class="form-control">
            <span class="invalid-tooltip" *ngIf="invalidForm('email')">
                Validation Email
            </span>
            -->
        </div>

        <!-- UNIDAD -->
        <div class="col-10 mb-3 position-relative">
            <strong> {{'register_client.unidad' | translate | titlecase }} </strong>
            <mat-select formControlName="unidad" class="form-control" placeholder="{{'register_client.unidad' | translate }}">
                <mat-option [value]="unit.id_unit" *ngFor="let unit of units">{{unit.unit}} - {{unit.description_unit}}</mat-option>
            </mat-select>
             <!--
            <input 
                type="text" 
                formControlName="email"
                maxlength="50"
                [ngClass]=" {'is-invalid': submitted && miRegistro.controls['email'].errors} "
                placeholder="{{'register_client.unidad' | translate }}"
                autocomplete="off"
                class="form-control">
            <span class="invalid-tooltip" *ngIf="invalidForm('email')">
                Validation Email
            </span>
            -->
        </div>

        <!-- BUTTON SUBMIT -->
        <div class="text-center mb-4">
            <button
                type="submit"
                class="btn-primary-vivipost">
                {{ 'register_client.buttons.register' | translate | uppercase }}
            </button>
        </div>

    </form>
      </mat-card>