import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-clip-video',
	templateUrl: './clip-video.component.html',
	styleUrls: ['./clip-video.component.scss'],
})
export class ClipVideoComponent implements OnInit {
	id_company = localStorage.getItem('id_company');
	urlSafe = '';

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { id_event: number; id_locker: number; id_cam: number }
	) {}

	ngOnInit(): void {
		this.urlSafe = `https://oxxopost-backend-dev.vivipost.com/server/locker/transmit-event?id_locker=${this.data.id_locker}&id_event=${this.data.id_event}&language=es&id_camera=${this.data.id_cam}&id_company=${this.id_company}`;
	}
}
