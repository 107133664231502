import { Component, Input, OnInit } from '@angular/core';
import { AdminClientService } from '../../../../core/http/admin-client.service';


@Component({
  selector: 'app-door-status-generate-tocken',
  templateUrl: './door-status-generate-tocken.component.html',
  styleUrls: ['./door-status-generate-tocken.component.scss']
})
export class DoorStatusGenerateTockenComponent implements OnInit {
	@Input() idLocker!: any;

	s: number = null;
	m: number = null;
	g: number = null;
	eg: number = null;
	l: number = null;
	xl: number = null;
	xxl: number = null;
	xxxl: number = null;
	ml: number = null;
	dataGrid;
	modulo;
	cStatus;

	ngOnInit() {
		this.getLockerGrid().catch((err) => {
			console.log(err);
		});
	}

	compartment = [];

	constructor(private adminService: AdminClientService) {}

	async getLockerGrid() {
		let data = {
			language: localStorage.getItem('language').toLowerCase(),
			id_locker: this.idLocker,
		};

		this.dataGrid = await this.adminService.getLockersGrid(data);

		const compartmentMapping = {
			S: 's',
			'M/L': 'ml',
			G: 'g',
			EG: 'eg',
			XXXL: 'xxxl',
			XXL: 'xxl',
			XL: 'xl',
			L: 'l',
			M: 'm',
		};

		this.dataGrid.quantity_compartment.compartment.forEach(
			(compartment: any) => {
				this.compartment.push(compartment);
				const compartmentName = compartment.compartment_name;
				if (compartmentMapping.hasOwnProperty(compartmentName)) {
					this[compartmentMapping[compartmentName]] =
						compartment.quantity;
				}
			}
		);

		const decrementCompartment = (compartmentName: string) => {
			if (this[compartmentName]) {
				this[compartmentName] = this[compartmentName] - 1;
			}
		};

		this.dataGrid.modulo_status.forEach((modulo: any) => {
			modulo.DOOR.forEach((door: any) => {
				const { compartment_name, name_status } = door;

				if (
					name_status === 'OCUPADO' ||
					name_status === 'VECIDO' ||
					name_status === 'FALLO'
				) {
					if (compartmentMapping.hasOwnProperty(compartment_name)) {
						decrementCompartment(
							compartmentMapping[compartment_name]
						);
					}
				}
			});
		});

		const compartmentsToNullify = [
			's',
			'ml',
			'g',
			'eg',
			'xxxl',
			'xxl',
			'xl',
			'l',
			'm',
		];
		compartmentsToNullify.forEach((compartmentName: string) => {
			if (this[compartmentName] === 0) {
				this[compartmentName] = null;
			}
		});

		this.modulo = JSON.stringify(this.dataGrid.modulo_status);
		this.cStatus = JSON.stringify(
			this.dataGrid.quantity_compartment.compartment_status
		);
	}
}
