<div class="container-overview">
    <div class="grid-header">
        <div class="txt-header">
            <span class="title">{{'overview.welcomeHeader' | translate}}</span>
            <span class="username">{{alias_username | lowercase | titlecase }}</span>
        </div>
        <div class="grid-inputs">
            <p-dropdown
                formControlName="lockers"
                [options]="completedDataCompleted"
                optionLabel="lockerID"
                [placeholder]="this.placeholderSelectLocker"
                class="selectLocker">
            </p-dropdown>
    
            <p-calendar
                class="selectYear input-gbls float-end"
                [showIcon]="true"
                [(ngModel)]="rangeDates"
                (ngModelChange)="addEventRangeDates()"
                selectionMode="range"
                [readonlyInput]="true"
                [placeholder]="this.placeholderBasePeriod"
                inputId="range" >
            </p-calendar>
    
            <p-calendar
                class="selectYear input-gbls float-end"
                [showIcon]="true"
                [(ngModel)]="rangeDatesComparacion"
                (ngModelChange)="addEventRangeDates2()"
                selectionMode="range"
                [readonlyInput]="true"
                inputId="range"
                [placeholder]="this.placeholderComparisionPeriod"
                [maxDate]="this.MaxFinalDateComparacion">
            </p-calendar>
        </div>
    </div>
    
    <app-card
        class="animate__animated animate__fadeIn"
        [gridStyle]="gridOverview"
        [dataCardSm]="dataCardSm">
    </app-card>
    
    <div class="grid-charts">
        <!-- CHARTS -->
        <!-- CARD-7 -->
        <div class="card-grid card-7">
            <app-use-lockers-operation
                [periodBase]="periodBase"
                [periodComp]="periodComp">
            </app-use-lockers-operation>
        </div>
    
        <!-- CARD-8 -->
        <div class="card-grid card-8">
            <app-tracking-expired-orders
                [periodBase]="periodBase"
                [periodComp]="periodComp">
            </app-tracking-expired-orders>
        </div>
    
        <!-- CARD-9 -->
        <div class="card-grid card-9">
            <app-frequency-use
                [periodBase]="periodBase"
                [periodComp]="periodComp">
            </app-frequency-use>
        </div>
    
        <!-- CARD-10-->
        <div class="card-grid card-10">
            <app-use-lockers-compartment></app-use-lockers-compartment>
        </div>
    
        <!-- CARD-11-->
        <div class="card-grid card-11">
            <app-rating-average></app-rating-average>
    
        </div>
    
        <!-- CARD-12-->
        <div class="card-grid card-12">
            <app-headquarters></app-headquarters>
        </div>
    
        <!-- CARD-13-->
        <div class="card-grid card-13">
            <app-historical-orders></app-historical-orders>
        </div>
    </div>
    
</div>
