import { Component, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import { SystemService } from 'src/app/core/http/system.service';
import { WebhookService } from 'src/app/core/http/webhook.service';
import { DataConfig, tableHeader } from 'src/app/core/models';
import { QueryWebhook, ServiceWebHook } from 'src/app/core/models/webhook/webhook.interface';
import { CheckboxSelectionEventWebhookComponent } from '../checkbox-selection-event-webhook/checkbox-selection-event-webhook.component';
import { EventSelectionService } from 'src/app/shared/services/event-selection.service';
import { ToastrService } from 'ngx-toastr';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'app-config-webhook',
	templateUrl: './config-webhook.component.html',
	styleUrls: ['./config-webhook.component.scss']
})
export class ConfigWebhookComponent {
	@ViewChild('agGrid', { static: false }) agGrid: any;
	pageLenght: number = 3;
	urlDocumentation:string;
	updateDate: string;
	gridOptions: GridOptions;
	public columnDefs: ColDef[];
	rowData: any[];
	selectedEvents: any[] = [];
	updateRegister:any[] = [];
	showApiText:boolean=false;
	showTooltipApiValue:boolean=false;
	overlayNoRowContent:string;
	apiText;
	anyRecords:string;
	public dataConfig:DataConfig[] = [
		{
			url_config:"http://url.com",
			date_edition:"2 de Abril de 2023",
			sub_state:"*********",
			activity:"Actividad de la URL",
		},
	];
	thConfig:tableHeader[] = [
		{
			th:'tableConfigWebhook.url',
			filtro:'url_config',
			input:true,

		},
		{
			th:'tableConfigWebhook.date',
			filtro:'date_edition'
		},
		{
			th:'tableConfigWebhook.suscription',
			filtro:'sub_state'
		}
	]
	constructor(
		private translate: TranslateService,
		private integrationService: WebhookService,
		private systemService: SystemService,
		private webhookService: WebhookService,
		private eventSelectionService: EventSelectionService,
		private toastr: ToastrService,
		private clipboard: Clipboard
	){
		this.dataConfig;
	}
	ngOnInit(){
		this.rowData = [];
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateOverlayNoRowContent();
		this.getTable();
		this.getUrl();
		this.getWebHookItems();
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=>{
			this.translate.use(event.lang);
			this.updateOverlayNoRowContent();
			this.translate.get([
				'configWebhook.events.item',
				'configWebhook.events.event',
				'configWebhook.events.description'
			]).subscribe((t:any)=>{
				this.columnDefs = [
					{
						headerName:t['configWebhook.events.item'],
						field: 'id_status_company_webhook_service',
						width:68,
						autoHeight:true,
						sortable: true,
						headerClass:'borderconfigWebHook',
						cellClass:'configWebHookClass',
						cellRenderer: CheckboxSelectionEventWebhookComponent,
					},
					{
						headerName:t['configWebhook.events.event'],
						field: 'service',
						width:371,
						autoHeight:true,
						sortable: true,
						headerClass:'borderconfigWebHook',
						cellClass:'configWebHookClassCode'
					},
					{
						headerName:t['configWebhook.events.description'],
						field: 'description',
						width:753,
						autoHeight:true,
						sortable: true,
						headerClass:'borderconfigWebHook',
						cellClass:'configWebHookClass'
					}
				]
			})


		});
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		// this.eventSelectionService.selectedEvents$.subscribe((selectedEvents) => {
		// 	this.selectedEvents = selectedEvents;
		// });
	}

	updateOverlayNoRowContent() {
		this.translate.get('tableWithoutContent.text').subscribe((translation: string) => {
			this.overlayNoRowContent = `
			<div>
				<div>
				<!-- Your SVG and other HTML elements here -->
				</div>
				<div class="labelNoRegistros">
				${translation}
				</div>
			</div>`;
			});

		if (this.gridOptions) {
		  this.gridOptions.overlayNoRowsTemplate = this.overlayNoRowContent; // Set the overlay template on gridOptions
		}
	}
	onGridReady(params: any) {
		const { api } = params;
		api.sizeColumnsToFit();
	}
	getTable(){
		this.gridOptions = {
			columnDefs: [
				{
					headerName:this.translate.instant('configWebhook.events.item'),
					field: 'id_status_company_webhook_service',
					width:68,
					autoHeight:true,
					sortable: true,
					headerClass:'borderconfigWebHook',
					cellClass:'configWebHookClass',
					cellRenderer: CheckboxSelectionEventWebhookComponent,
				},
				{
					headerName:this.translate.instant('configWebhook.events.event'),
					field: 'service',
					width:371,
					autoHeight:true,
					sortable: true,
					headerClass:'borderconfigWebHook',
					cellClass:'configWebHookClassCode'
				},
				{
					headerName:this.translate.instant('configWebhook.events.description'),
					field: 'description',
					width:753,
					autoHeight:true,
					sortable: true,
					headerClass:'borderconfigWebHook',
					cellClass:'configWebHookClass'
				}
			],
			animateRows: true,
			pagination: true,
			paginationPageSize:10,
			headerHeight:59,
			rowHeight:59,
			rowClass:'rowConfigWebhookClass',
			scrollbarWidth:0,
			alwaysShowVerticalScroll:false,
			alwaysShowHorizontalScroll:false,
			domLayout: 'autoHeight',
			defaultColDef: {
				flex: 1,
			}
		}
	}

	getUrl(){
		let obj ={
			language: localStorage.getItem('language').toLowerCase()
		}
		this.systemService.queryCourierApiDocumentation(obj).then((res:any)=>{
			if(res.mensaje_return.CODE ===200){
				this.dataConfig[0].url_config = res.url_api_courrier;
				this.thConfig[0].route = res.url_api_courrier
			}
		});
		this.integrationService.getWebHook(obj).then((res:any)=>{
			if(res.mensaje_return.CODE === 200){
				this.urlDocumentation = res.return_data_webhooks[0].url;
				this.updateDate =  new Date(res.return_data_webhooks[0].update_date).toLocaleString("es-MX",{
					day:'2-digit',
					month:'long',
					year:'numeric',
				});
			}
		})
	}

	getWebHookItems(){
		let obj ={
			language: localStorage.getItem('language').toLowerCase()
		};
		// this.webhookService.getQueryServiceWebHook(obj).then((res:ServiceWebHook)=>{
		// 	this.rowData = res.data_webhook.services_active;
		// })
	}

	saveChanges(){
		let obj ={
			language: localStorage.getItem('language').toLowerCase(),
			update_register: this.selectedEvents
		}
		this.webhookService.updateWebhookService(obj).then((res:any)=>{
			if(res.status === 200){
				if(res.body.data_update_fail.length > 0){
					this.toastr.success('no se actualizo los campos seleccionados', 'Oh, hubo un error', {
						toastClass: 'toast-erronea',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					});
					this.getWebHookItems();
				}
				else if(res.body.data_update_success.length > 0){
					this.toastr.success('los campos seleccionados', 'Se han actualizado', {
						toastClass: 'toast-extendida',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					});
				}
			}
		});

	}
	openLink(){
		window.open(this.urlDocumentation, "_blank");
	}
	copiarUrl(){
		this.clipboard.copy(this.urlDocumentation);
	}
	copiarApi(){
		this.clipboard.copy(this.apiText);
	}
	showApi(){
		this.showApiText = !this.showApiText;
	}
	showTooltipApi(){
		this.showTooltipApiValue = true;
	}
	hideTooltipApi(){
		this.showTooltipApiValue = false;
	}
}
