import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/services/data.service';
import { LogsService } from 'src/app/core/http/logs.service';
import { MenuDropdownService } from '../../services/menu-dropdown.service';
import { NotificationMenu, UsersMenu, Data, ReturnMenu } from 'src/app/core/models';
import { Router } from '@angular/router';
@Component({
	selector: 'navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	log_package = [];
	all = [];
	count_register_log;
	allSize;
	// USERNAME
	username: string;
	// ROLE
	role: string;
	sizeOrders: number;
	sizeUsers: number;
	sizeAll: number;
	// MENU HEADER
	notificationOrders: NotificationMenu[] = [];
	notificationUser: UsersMenu[] = [];
	// NAVBAR MENU
	navMenu: ReturnMenu[];
	// GET COLUMN LENGHT FOR THE MENU
	get cantidadColumnsArr() {
		return this.navMenu.length;
	}
	// NOTIFICATION
	notificationSeen = true;
	seenNot() {}

	menu_txt: any;
	menu_route: any;

	objNotificacion = {
		language: localStorage.getItem('language'),
		limit: 10,
	};

	constructor (
	public translate		:TranslateService,
	public ds				:DataService,
	public logsService		:LogsService,
	public dropdownService	:MenuDropdownService,
	public router			:Router)
	{
		const data = localStorage.getItem('data');
		const userData:Data = JSON.parse(data);
		this.username = userData.data_company.alias_username;
		// ROLE
		this.role = this.ds.role;
		// NAVBAR MENU
		this.navMenu = this.ds.menu_navbar;
	}


	// DROPDOWN NOTIFICATION
	changeStatus() {
		localStorage.removeItem('searchOrderNotification');
		this.dropdownService.status = !this.dropdownService.status;
		this.allSize = null;
		this.notificationSeen = false;
	}
	eraseTracking() {
		localStorage.removeItem('searchOrderNotification');
		localStorage.removeItem('id_locker');
	}
	async getNotification() {
		let objNotificacion = {
			language: localStorage.getItem('language'),
			limit: 10,
		};
		this.logsService.getNotifications(objNotificacion).then((res: any) => {
			if (res.return_logs) {
				this.sizeOrders = res.return_logs.length;
				res.return_logs.forEach((item: NotificationMenu) => {
					this.notificationOrders.push({
						id_topic_audit: item.id_topic_audit,
						locker_name: item.locker_name,
						order_number: item.order_number,
						registration_date: item.registration_date,
						time_elapsed: item.time_elapsed,
						topic_name: item.topic_name,
						tracking_number: item.tracking_number,
					});
					this.all.push({
						id_topic_audit: item.id_topic_audit,
						locker_name: item.locker_name,
						order_number: item.order_number,
						registration_date: item.registration_date,
						time_elapsed: item.time_elapsed,
						topic_name: item.topic_name,
						tracking_number: item.tracking_number,
						isOrder: true,
						isUser: false,
					});
				});
			}
		})
		.catch((error) => {
			this.router.navigate(['/auth/login']);
		});
	}
	async getUsers() {
		let objNotificacion = {
			language: localStorage.getItem('language'),
			limit: 10,
		};
		this.logsService.getUsers(objNotificacion).then((res: any) => {
			if (res.return_logs) {
				this.sizeOrders = res.return_logs.length;
				res.return_logs.forEach((item: UsersMenu) => {
					this.notificationUser.push({
						company_employee_id: item.company_employee_id,
						description_audit: item.description_audit,
						full_name: item.full_name,
						id_topic_audit: item.id_topic_audit,
						locker_name: item.locker_name,
						registration_date: item.registration_date,
						time_elapsed: item.time_elapsed,
						topic_name: item.topic_name,
					});
					this.all.push({
						company_employee_id: item.company_employee_id,
						description_audit: item.description_audit,
						full_name: item.full_name,
						id_topic_audit: item.id_topic_audit,
						locker_name: item.locker_name,
						registration_date: item.registration_date,
						time_elapsed: item.time_elapsed,
						topic_name: item.topic_name,
						isOrder: false,
						isUser: true,
					});
				});
			}
		})
		.catch((err) => {
			this.router.navigate(['/auth/login']).catch((err) => {
				console.log(err);
			});
			console.log(err);
		});
	}
	async getAll() {
		let objNotificacion = {
			language: localStorage.getItem('language'),
			limit: 10,
		};
		this.logsService.getNotifications(objNotificacion).then((res: any) => {
			if (res.return_logs) {
				this.sizeAll = this.all.length;
				this.all = [];
				this.sizeOrders = res.return_logs.length;
				this.notificationOrders = [];
				res.return_logs.forEach((item: any) => {
					this.notificationOrders.push({
						id_topic_audit: item.id_topic_audit,
						locker_name: item.locker_name,
						order_number: item.order_number,
						registration_date: item.registration_date,
						time_elapsed: item.time_elapsed,
						topic_name: item.topic_name,
						tracking_number: item.tracking_number,
					});
					this.all.push({
						id_topic_audit: item.id_topic_audit,
						locker_name: item.locker_name,
						order_number: item.order_number,
						registration_date: item.registration_date,
						time_elapsed: item.time_elapsed,
						topic_name: item.topic_name,
						tracking_number: item.tracking_number,
						isOrder: true,
						isUser: false,
					});
				});
			}
		}).catch((err) => {
			console.log(err);
		});
		this.logsService.getUsers(objNotificacion).then((res: any) => {
			if (res.returns_logs) {
				this.sizeUsers = res.return_logs.length;
				this.notificationUser = [];
				res.return_logs.forEach((item: UsersMenu) => {
					this.notificationUser.push({
						company_employee_id: item.company_employee_id,
						description_audit: item.description_audit,
						full_name: item.full_name,
						id_topic_audit: item.id_topic_audit,
						locker_name: item.locker_name,
						registration_date: item.registration_date,
						time_elapsed: item.time_elapsed,
						topic_name: item.topic_name,
					});
					this.all.push({
						company_employee_id: item.company_employee_id,
						description_audit: item.description_audit,
						full_name: item.full_name,
						id_topic_audit: item.id_topic_audit,
						locker_name: item.locker_name,
						registration_date: item.registration_date,
						time_elapsed: item.time_elapsed,
						topic_name: item.topic_name,
						isOrder: false,
						isUser: true,
					});
				});
				this.sizeAll = this.all.length;
			}
		}).catch((err) => {
			console.log(err);
		});
	}

	ngOnInit() {
		const data = localStorage.getItem('data');
		const userData:Data = JSON.parse(data);
		this.username = userData.data_company.alias_username;
		// ROLE
		this.role = JSON.parse(localStorage.getItem('data')).result.user_type;
		// NAVBAR MENU
		this.navMenu = JSON.parse(localStorage.getItem('data')).data_menu.return_menu;
		this.getNotification();
		this.getUsers();
		setInterval(() => {
			this.notificationOrders = [];
			this.notificationUser = [];
			this.all = [];
			if(localStorage.getItem('token') !== undefined || localStorage.getItem('token') !== null || !localStorage.getItem('token')){
				this.getNotification();
				this.getUsers();
			}

		}, 1000);
	}
}
