import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
	providedIn: 'root',
})
export class ClientService {
	private token: string;
	private options: any;
	public temp: any;
	public data;

	constructor(private _http: HttpClient) {}
	public baseUrl: string = environment.url_server_backend;


	setToken(token: string) {
		this.token = token;
	}

	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}

	//? CARDS METRICS ----------------------------------------------->
	//* CARD ----------------------------------------------->
	async getQuantityMonth(params): Promise<any> {
		// Se establecen las opciones de la solicitud HTTP
		let options = {
			params: params,
		};
		// URL de la API
		let url = this.baseUrl + '/server/package/quantity-month';

		// Se realiza la solicitud HTTP usando el método GET del objeto HttpClient
		let data: any = await this._http.get(url, options).toPromise();

		// Se devuelve la propiedad "quantity_month" del objeto "data"
		return data.quantity_month;
	}

	//* CARD  ------------------------------>
	async getMostActiveLocker(params): Promise<any> {
		// Se crea un objeto 'options' que contiene los parámetros de la petición HTTP
		let options = {
			params: params,
		};
		// URL de la API
		let url = this.baseUrl + '/server/package/quantity-locker-company';

		// Se hace una petición HTTP GET con la URL y los parámetros especificados
		let data: any = await this._http.get(url, options).toPromise();

		// Se devuelve el segundo elemento del primer elemento del array 'return_result' de la respuesta 'data'
		return data.return_result[0][1];
	}

	//* CARD  ------------------------------>
	async getConsumerCompanyCount(params): Promise<number> {
		// Se define un objeto con los parámetros que se enviarán en la solicitud HTTP
		let options = {
			params: params,
		};
		// URL de la API
		let url = this.baseUrl + '/server/company/query-consumer-company-count';
		// Se envía la solicitud HTTP y se espera la respuesta
		let data: any = await this._http.get(url, options).toPromise();
		// Se retorna la cantidad de empresas con consumidores obtenida de la respuesta
		return data.result_return.cant_customer;
	}

	//* CARD  ------------------------------>
	async getLockersRetail(params): Promise<any> {
		// Crea un objeto "options" que contiene los parámetros de la solicitud HTTP
		let options = {
			params: params,
		};
		// URL de la API
		let url = this.baseUrl + '/server/locker/query-locker-retail';
		// Realiza la solicitud HTTP utilizando el método "get" de la clase HttpClient
		let data: any = await this._http.get(url, options).toPromise();
		// Verifica si la respuesta de la solicitud contiene errores
		if (!data.mensaje_return.ERROR)
			// Si no hay errores, devuelve el arreglo de objetos "return_result"
			return data.return_result[0][1];
		// Si hay errores, devuelve un arreglo vacío
		else return [];
	}

	//* CARD  ------------------------------>
	async getMetricsConsumer(params) {
		// Configura las opciones de la solicitud, incluyendo el encabezado de "Content-Type" y los parámetros
		let options = {
			params: params,
		};
		// URL de la API
		let url = this.baseUrl + '/server/package/metric-dasboard';

		// Realiza la solicitud a la API y espera a que se resuelva la promesa
		let data: any = await this._http.get(url, options).toPromise();

		// Retorna los datos de las métricas
		return data.metric_return;
	}

	//? TABLE METRICS ----------------------------------------------->
	// Método para obtener órdenes
	getOrdenes(params: any) {
		// Configuración de las opciones para la solicitud HTTP
		let options = {
			params: params,
		};
		// URL de la API
		let url = this.baseUrl + '/server/package/query-package-consumer';
		// Realizar solicitud HTTP utilizando el método get de HttpClient y devolver la respuesta
		return this._http.get(url, options);
	}

	async getMetricClient(params:any){
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params
		};
		let url = this.baseUrl + '/server/metric/query-metric-client';
		return await this._http.get(url,options).toPromise();

	}
}
