// ANGULAR
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { LoginForm } from 'src/app/core/models/users.interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UserAuthService {
	// Declare properties
	public baseurl: string = environment.url_server_backend;
	public data;

	//? CONSTRUCTOR ---------------------------------------------------------------------------------------->
	constructor(private _http: HttpClient) {
		localStorage.removeItem('url_cam_event');
	}

	login(formData: LoginForm) {
		const url = `${this.baseurl}/server/user/validate-user-password`;
		return this._http.post(url, JSON.stringify(formData)).pipe(retry(1)).subscribe((res:any)=>{
			console.log(res);
		});
	}

	async loginAsync(params:LoginForm){
		const url = `${this.baseurl}/server/user/validate-user-password`;
		return await this._http.post(url,JSON.stringify(params)).toPromise();
	}

	async getSystemVersion(){
		let url = this.baseurl + '/server/system/check-current-version'
		return await this._http.get(url).toPromise();
	}
	// Method set data
	setData(data) {
		this.data = data;
	}

	// login(formData:LoginForm) {
	//   const url = `${ this.baseurl }/server/user/validate-user-password`;
	//   return this._http.post(url, formData)
	// }
}
