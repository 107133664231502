import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, GridOptions } from 'ag-grid-community';
import { EstatusTableOrderAdminComponent } from '../estatus-table-order-admin/estatus-table-order-admin.component';
import * as XLSX from 'xlsx';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-return-orders',
	templateUrl: './return-orders.component.html',
	styleUrls: ['./return-orders.component.scss'],
})
export class ReturnOrdersComponent implements OnInit {
	rangeDates: Date[];
	InitialDate = new Date();
	FinalDate = new Date();
	tabOrders = true;
	tabLotes = false;
	tabClientes = false;

	filterText: string = '';
	gridOptions: GridOptions;
	rowData: any[];
	dataTemporal = [];
	public filteredData: any[] = [];

	constructor(private translate: TranslateService, private http: HttpClient) {
		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
		translate.setTranslation(
			'en',
			require('../../../../../assets/languaje/en.json')
		);
		translate.setTranslation(
			'es',
			require('../../../../../assets/languaje/es.json')
		);
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-returns.order'),
					field: 'order',
					sortable: true,
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-returns.clientID'
					),
					field: 'clientID',
					sortable: true,
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-returns.clientEmail'
					),
					field: 'clientEmail',
					sortable: true,
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-returns.city'),
					field: 'city',
					sortable: true,
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-returns.loteID'),
					field: 'loteID',
					sortable: true,
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-returns.status'),
					field: 'status',
					sortable: true,
					cellStyle: {
						'font-family': 'Inter',
						'text-align': '-webkit-center',
						'justify-content': 'center',
						'align-items': 'center',
					},
					cellRenderer: EstatusTableOrderAdminComponent,
				},
				{
					headerName: this.translate.instant(
						'table-returns.registerDate'
					),
					field: 'registerDate',
					sortable: true,
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
			],
			animateRows: true,
			defaultColDef: {
				flex: 1,
			},
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
		}
	}

	ngOnInit() {
		this.http
			.get<any[]>('../../../../../assets/dummy/returns-info.json')
			.subscribe((data) => {
				this.rowData = data;
			});
	}

	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
		}
	}

	onCellClicked(e: CellClickedEvent): void {}

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}

	onExport() {
		const fileName = 'ReturnOrders.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'ReturnOrders',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
}
