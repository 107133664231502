
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EstatusTableOrderAdminComponent } from '../../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../../date-text/date-text.component';
import { DialogOrderDetailComponent } from '../../dialog-order-detail/dialog-order-detail.component';
@Component({
  selector: 'app-table-scheduled-carrier',
  templateUrl: './table-scheduled-carrier.component.html',
  styleUrls: ['./table-scheduled-carrier.component.scss']
})
export class TableScheduledCarrierComponent implements OnInit {
	public columnDefs: ColDef[];
	public defaultColDef: ColDef = {
		sortable: true,
		filter: true,
	};
	private gridApi: GridApi;
	@ViewChild(AgGridAngular) agGrid!: AgGridAngular;
	supportLanguages = ['es', 'en'];
	InitialDate = new Date();
	FinalDate = new Date();
	res;
	completedDataCompleted = [];
	countCompleted = 0;
	rangeDates: Date[];
	carrierID:number;
	filterText: string = '';
	gridOptions: GridOptions;
	rowData: any[];
	dataTemporal = [];
	public filteredData: any[] = [];

	constructor(private translate: TranslateService,private adminService: AdminClientService,private matdialog: MatDialog) {

	}
	ngOnInit() {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.carrierID = parseInt(localStorage.getItem('courier_id'));
		this.getTableCompleted();
		this.updateTable();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.translate.get([
				'table-scheduled.tracking',
				'table-scheduled.lockerID',
				'table-scheduled.phone',
				'table-scheduled.email',
				'table-scheduled.doorSize',
				'table-scheduled.status',
				'table-scheduled.registrationDate',
			]).subscribe((t: any) => {
				this.columnDefs = [
					{
						headerName: t['table-scheduled.package'],
						field: 'tracking',
						cellClass:'scheduledClassLink',
						headerClass:'borderHeader',
						cellRenderer: this.centeredCellRenderer,
						onCellClicked: ($event) => this.openModalUserDetail($event),
					},
					{
						headerName: t['table-scheduled.lockerID'],
						field: 'lockerID',
						cellClass:'scheduledClass',
						headerClass:'borderHeader',
					},
					{
						headerName: t['table-scheduled.phone'],
						field: 'phone',
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeader',
						cellClass:'scheduledClass',
						cellRenderer: (params) => {
							const phoneNumber = params.value;
							const formattedPhone = `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 11)}`;
							return `<div>${formattedPhone}</div>`;
						},
					},
					{
						headerName: t['table-scheduled.email'],
						field: 'email',
						cellClass:'scheduledClass',
						headerClass:'borderHeader',
					},
					{
						headerName: t['table-scheduled.employeeName'],
						field: 'nameEmploye',
						sortable: true,
						cellClass:'scheduledClass',
						headerClass:'borderHeader',
					},
					{
						headerName: t['table-scheduled.doorSize'],
						field: 'compartment',
						cellClass:'scheduledClass',
						headerClass:'borderHeader',
					},
					{
						headerName: t['table-scheduled.status'],
						field: 'status',
						cellClass:'scheduledClass',
						headerClass:'borderHeader',
						cellRenderer: EstatusTableOrderAdminComponent,
					},
					{
						headerName: t['table-scheduled.registrationDate'],
						field: 'registrationDate',
						cellClass:'scheduledClass',
						headerClass:'borderHeader',
						cellRenderer: DateTextComponent
					},
				];
			});
		})



		let today = new Date();
		let month = today.getMonth();
		let year = today.getFullYear();
		let day = today.getDate();
		let lastMonth;
		let endDate;
		let startDate;
		if (month < 0) {
			month = 11;
			year -= 1;
		}
		lastMonth = new Date(year, month - 1, day);
		endDate = new Date(year, month, day);
		startDate = new Date(year, lastMonth.getMonth(), day);
		this.rangeDates = [startDate, endDate];

		this.InitialDate = this.rangeDates[0];
		this.FinalDate = this.rangeDates[1];
	}

	updateTable() {
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-scheduled.package'),
					field: 'tracking',
					sortable: true,
					autoHeight: true,
					cellClass:'scheduledClassLink',
					headerClass:'borderHeader',
					onCellClicked: ($event) => this.openModalUserDetail($event),
				},
				{
					headerName: this.translate.instant('table-scheduled.lockerID'),
					field: 'lockerID',
					sortable: true,
					autoHeight: true,
					cellClass:'scheduledClass',
					headerClass:'borderHeader',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-scheduled.phone'),
					field: 'phone',
					sortable: true,
					autoHeight: true,
					cellClass:'scheduledClass',
					cellRenderer: (params) => {
						const phoneNumber = params.value;
						const formattedPhone = `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6,11)}`;
						return `<div>${formattedPhone}</div>`;
					},
					headerClass:'borderHeader'
				},
				{
					headerName: this.translate.instant('table-scheduled.email'),
					field: 'email',
					sortable: true,
					autoHeight: true,
					cellClass:'scheduledClass',
					headerClass:'borderHeader',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-scheduled.employeeName'),
					field: 'nameEmploye',
					sortable: true,
					autoHeight: true,
					cellClass:'scheduledClass',
					headerClass:'borderHeader',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-scheduled.doorSize'),
					field: 'compartment',
					sortable: true,
					autoHeight: true,
					cellClass:'scheduledClass',
					headerClass:'borderHeader',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-scheduled.status'),
					sortable: true,
					field: 'status',
					autoHeight: true,
					cellClass:'scheduledClass',
					headerClass:'borderHeader',
					cellRenderer: EstatusTableOrderAdminComponent,
				},
				{
					headerName: this.translate.instant('table-scheduled.registrationDate'),
					field: 'registrationDate',
					sortable: true,
					autoHeight: true,
					cellClass:'scheduledClass',
					headerClass:'borderHeader',
					cellRenderer: DateTextComponent,
				},
			],
			rowClass:'rowScheduledClass',
			tooltipShowDelay: 0,
			tooltipHideDelay: 20000,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,			rowHeight:59,
			headerHeight:59,
			animateRows: true,
			defaultColDef: {
				flex: 1,
			}
		}
	}
		onGridReady(params: any) {
			const { api } = params;
		}
	onCellClicked(e: CellClickedEvent): void {}
	getTableCompleted() {
		this.rowData = [];
		this.completedDataCompleted = [];
		let lang = localStorage.getItem('language').toLowerCase();
		let data = {
			language: lang,
			id_courier : this.carrierID,
			is_module_courier: true
		};
		this.adminService.getSchedulePackage(data).then((res) => {
				res.return_result.map((o) => {
					if (o.id_status == 35) {
						this.completedDataCompleted.push({
							id_service: o.id_service,
							id_compartment_dimension:
								o.id_compartment_dimension,
							isScheduleData: true,
							id_status_service: o.id_status,
							order: o.code_qr_service.split('.')[0],
							tracking: o.code_service,
							lockerID: o.locker_name,
							email: o.email,
							phone: o.main_tlf,
							//employeId          : "null",
							nameEmploye: o.nameEmploye,
							compartment:
								o.door_number != 'not_select'
									? o.door_number + '-' + o.compartment_name
									: o.door_number,
							status: o.nombre_status,
							registrationDate: o.registration_date,
							id_locker: o.id_locker,
							actions:
								o.id_status == 35 ? ['far fa-trash-alt'] : [],
							qr: o.id_status == 35 ? o.qr : null,
							id_status_code_package: o.id_status_code_package,
							status_package: o.status_package,
						});
						this.rowData = this.completedDataCompleted;
					}
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}
	showDate: Date;
	showDateFinal: Date;
	fechasByMonth: string;

	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
			this.getTableCompleted();
		}
	}
	onExport() {
		const fileName = 'Users.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'CarriersScheduled',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_service,id_compartment_dimension, id_status_service, id_locker,id_status_code_package,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				const { id_service,id_compartment_dimension, id_status_service, id_locker,id_status_code_package,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}


	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	onOrderDeleted(orderId: number) {
		this.rowData = this.rowData.filter(
			(order) => order.orderId !== orderId
		);
	}

	openModalUserDetail(event) {
		const dialogRef = this.matdialog.open(DialogOrderDetailComponent, {
			width: '1304px',
			height: '960px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.getTableCompleted();
		});
	}

}
