import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-estatus-oxxo-to-oxxo',
  templateUrl: './dialog-estatus-oxxo-to-oxxo.component.html',
  styleUrls: ['./dialog-estatus-oxxo-to-oxxo.component.scss']
})
export class DialogEstatusOxxoToOxxoComponent implements OnInit {
	statusID:number;
	@Input() estatusID:number

  constructor() { }

  ngOnInit(): void {
	this.statusID = this.estatusID;
  }

}
