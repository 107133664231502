import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'app-client-sign',
	templateUrl: './client-sign.html',
	styleUrls: ['./client-sign.component.scss'],
})
export class ClientSign implements OnInit {
	@Output() saveEvent = new EventEmitter<string>();
	imgScreen: string = localStorage.getItem('img_screen');
	url_view_image: boolean = true;

	fileUrl;
	downloadFile;
	status = '';
	images;
	title;
	url_video_play = 'assets/video/TutorialSchedulePackage.mp4';
	dataSourcePackage = [];
	dataPackage;
	dataSourceUser = [];
	dataUser;
	columnsPackage = [
		'idEvent',
		'topicNameEvent',
		'trackingNumberEvent',
		'lockerNameEvent',
		'registrationDateEvent',
	];
	columnsUser = [
		'idEvent',
		'topicNameEvent',
		'lockerNameEvent',
		'registrationDateEvent',
	];
	columnsClient = ['idClienteCompany', 'fullName', 'email', 'phone'];

	imageScreenShot;
	nameUserLog: string = '';
	tracking: string = '';
	message: string = '';

	retunr_package_success: any = [];
	retunr_package_failure: any = [];

	columnsPackageSave = ['code_tracking', 'email_consumer', 'main_tlf'];
	total_success: number = 0;
	total_failure: number = 0;

	button_cancel: string = '';
	button_next: string = '';

	data_clients_array: any = [];
	data_clients;
	total_Client: number = 0;

	displayedColumns = ['item', 'value'];
	transactions: any[] = [];

	constructor(
		public dialogRef: MatDialogRef<ClientSign>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		// private downloadService: DownloadService,
		private sanitizer: DomSanitizer,
		private adminService: AdminClientService
	) {}

	ngOnInit() {
		switch (this.data.type) {
			case 'loading':
				this.title = this.data.title;
				break;
			case 'package_information':
				this.title = this.data.title;
				this.transactions = this.data.data_package;
				break;
			case 'confirm_package':
				this.title = this.data.title;
				this.retunr_package_success = JSON.stringify(
					this.data.retunr_package_success
				);
				this.retunr_package_failure = JSON.stringify(
					this.data.retunr_package_failure
				);
				this.total_success = this.data.retunr_package_success.length;
				this.total_failure = this.data.retunr_package_failure.length;
				break;
			case 'message':
				this.message = this.data.message;
				this.title = this.data.title;
				break;
			case 'screenShot':
				this.imageScreenShot = this.data.image;
				this.title = this.data.title;
				break;
			case 'evidence':
				this.tracking = this.data.tracking;
				this.images = [
					{ path: this.data.url_view_imagen_signature },
					{ path: this.data.url_view_image_pickup },
				];
				break;
			case 'video':
				this.title = this.data.title;
				break;
			case 'confirm_clients':
				this.title = this.data.title;
				JSON.parse(this.data.data_clients).forEach((client: any) => {
					let array_tlf_alternative =
						client.alternative_tlf.split(',');
					array_tlf_alternative = array_tlf_alternative.filter(
						(tlf) => tlf && tlf !== '0' && tlf !== 'null'
					);
					this.data_clients_array.push({
						idClienteCompany: client.id_client,
						email:
							client.email.split('@')[1] === 'vivipost.com'
								? 'NULL'
								: client.email,
						phone: client.main_tlf,
						alternative_tlf: array_tlf_alternative,
						fullName: client.client_name,
					});
				});
				this.data_clients = JSON.stringify(this.data_clients_array);
				this.total_Client = this.data_clients_array.length;
				break;
			case 'audit_user':
				this.title = this.data.title;
				this.nameUserLog = this.data.fullName;
				if (this.data.result_audit_employee.length > 0) {
					this.data.result_audit_employee.forEach((audit: any) => {
						this.dataSourceUser.push({
							idEvent: audit.id_audit_company_employee,
							id_topic_audit: audit.id_topic_audit,
							registrationDateEvent: audit.registration_date,
							topicNameEvent: audit.topic_name,
							lockerNameEvent: audit.locker_name,
							view_detail: false,
						});
					});
				} else {
					this.dataSourceUser.push({
						idEvent: '',
						id_topic_audit: '',
						registrationDateEvent: '',
						topicNameEvent: '',
						lockerNameEvent: '',
						view_detail: false,
					});
				}
				if (this.data.result_package.length > 0) {
					this.data.result_package.forEach((audit: any) => {
						this.dataSourcePackage.push({
							idEvent: audit.id_package_company_employee,
							topicNameEvent: audit.nombre_status,
							registrationDateEvent: audit.registration_date,
							trackingNumberEvent: audit.tracking_number,
							lockerNameEvent:
								audit.locker_name +
								' ' +
								audit.door_number +
								' ' +
								audit.compartment_name,
							email: audit.email,
							locker_name: audit.locker_name,
							door_number: audit.door_number,
							compartment_name: audit.compartment_name,
							main_tlf: audit.main_tlf,
							serial_locker: audit.serial_locker,
							view_detail: true,
						});
					});
				} else {
					this.dataSourcePackage.push({
						idEvent: '',
						topicNameEvent: '',
						registrationDateEvent: '',
						trackingNumberEvent: '',
						lockerNameEvent: '',
					});
				}
				this.dataPackage = JSON.stringify(this.dataSourcePackage);
				this.dataUser = JSON.stringify(this.dataSourceUser);
				break;
			default:
				if (this.data.type != 'configCreateQr') {
					const blob = new Blob([this.data.image], {
						type: 'img/png',
					});
					this.downloadFile =
						this.sanitizer.bypassSecurityTrustResourceUrl(
							window.URL.createObjectURL(blob)
						);
					this.imageScreenShot = this.data.image;
				}
				break;
		}
	}

	async dasvePackage() {
		localStorage.setItem('register_package', 'true');
		document.getElementById('close').click();
	}

	async crearQr() {
		let res: any = await this.adminService.createQr(this.data.body);
		if (!res.mensaje_return.ERROR) {
			document.getElementById('close').click();
		} else {
			document.getElementById('close').click();
		}
	}

	async updateQR() {
		let data = {
			language: localStorage.getItem('language').toLowerCase(),
			data_employee: [
				{ id_company_employee: this.data.id_company_employee },
			],
		};
		let res: any = await this.adminService.updateQRAccess(data);
		if (!res.mensaje_return.ERROR) {
			this.status = 'success';
			setTimeout(() => {
				document.getElementById('close').click();
				this.status = '';
			}, 1000);
		} else {
			this.status = 'err';
			setTimeout(() => {
				this.status = '';
			}, 1000);
		}
	}

	saveClient() {
		this.saveEvent.emit('true');
	}

	close() {
		this.saveEvent.emit('false');
	}

	onTabChanged(event) {
		//Desde aqui se debe hacer para activar los tabuladores y dejarlos activados
	}
}
