import { Component, Inject, OnInit } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';

@Component({
	selector: 'app-dialog-erase-order',
	templateUrl: './dialog-erase-order.component.html',
	styleUrls: ['./dialog-erase-order.component.scss'],
})
export class DialogEraseOrderComponent implements OnInit {
	constructor(
		private toastr: ToastrService,
		private adminService: AdminClientService,
		private dialogRef: MatDialogRef<DialogEraseOrderComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
	}

	async deletePackageSchedule() {

		if(this.data.id_status_code_package ===17 && this.data.status_package ===14 ){
			let deleteOrder = {
				leng: localStorage.getItem('language').toLowerCase(),
				id_package: this.data.id_package,
			};
			this.adminService.deleteOrderPackage(deleteOrder).then((res:any)=>{
				if (res.mensaje_return.CODE === 200) {
					this.toastr.success(
						'La operación ha sido realizada con éxito.',
						'Orden Eliminada',
						{
							toastClass: 'toast-extendida',
							timeOut: 5000,
							positionClass: 'toast-bottom-center',
						}
					);
					this.dialog.closeAll();
				}
				else if(res.mensaje_return.CODE === 1102){
					this.toastr.error(
						'La Orden No ha sido Encontrada',
						'Orden No Eliminada',
						{
							toastClass: 'toast-error',
							timeOut: 5000,
							positionClass: 'toast-bottom-center',
						}
					);
					this.dialog.closeAll();
				}
			})
			.catch(err=>{
				this.toastr.error(
					'La operación no ha sido realizada con éxito.',
					'Orden No Eliminada',
					{
						toastClass: 'toast-error',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					}
				);
			});
		}
		else{
			let deleteOrder = {
				language: localStorage.getItem('language').toLowerCase(),
				id_service: this.data.id_service,
			};

			this.adminService.deleteSchedulePackage(deleteOrder).then((res:any)=>{
				if (res.mensaje_return.CODE === 200) {
					this.toastr.success(
						'La operación ha sido realizada con éxito.',
						'Orden Agendada Eliminada',
						{
							toastClass: 'toast-extendida',
							timeOut: 5000,
							positionClass: 'toast-bottom-center',
						}
					);
					this.dialog.closeAll();
				}
			}).catch(err=>{
				this.toastr.error(
					'La operación no ha sido realizada con éxito.',
					'Título de la notificación',
					{
						toastClass: 'toast-error',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					}
				);
			});
		}


	}

	closeModal() {
		this.dialogRef.close();
	}
}
