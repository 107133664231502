<div class="sidebar" [ngClass]="{'closingSidebar': this.sidebarTransition === false}" *ngIf="this.openSideBar === false">
	<div class="container" style="height: 100%;" [ngClass]="{'reducirHeight' : (this.openTranslateSideBar === true || this.openAparienciaSideBar === true )}">
		<div class="row" style="height: 100%; display:grid;align-items: flex-end;">
			<div class="col-12 noPaddings boxGroup1">
				<div class="boxCompanyName">
					<div class="imageCompany">
						<div class="imageBox">
							<div class="icon-vivipost">

							</div>
						</div>
					</div>
					<div class="profileCompany">
						<div class="textCompany" *ngIf="mostrarTexto">
							<div class="titleTextCompany">
								{{this.companyName}}
							</div>
							<div class="subtitleTextCompany">
								{{this.userType}}
							</div>
						</div>
						<div class="chevron" (click)="hideSideBar()">
						</div>
					</div>
				</div>
				<div class="line" [ngClass]="{'closingSidebar': this.sidebarTransition === false, 'openingSidebar': this.sidebarTransition === true} " >
					<hr class="hrClass" style="margin: 0px 0px;">
				</div>
				<div class="menuBox">
					<div class="menuItem" *ngFor="let main of sideMenu" [ngClass]="{'selected closingSidebar': this.page === main.route && this.sidebarTransition === false} || {'selected openingSidebar': this.page === main.route && this.sidebarTransition === true}" (click)="changePage(main.route)">
						<div class="menuItemIcon">
							<div [class]="main.route"></div>
						</div>
						<div class="menuItemText" *ngIf="mostrarTexto">
							{{'navbar.'+main.txt | translate }}
						</div>
						<div class="menuItemIcon">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 noPaddings boxGroup2">
				<div class="line" [ngClass]="{'closingSidebar': this.sidebarTransition === false, 'openingSidebar': this.sidebarTransition === true} ">
					<hr class="hrClass" style="margin: 0px 0px;">
				</div>
				<div class="configuracionBox">
					<div class="configuracionItemWebHook" [ngClass]="{'selected closingSidebar': this.page === 'webhook' && this.sidebarTransition === false}" (click)="changePage('webhook')">
						<div class="configuracionItemIcon">
							<div class="version"></div>
						</div>
						<div class="configuracionItemText"  *ngIf="mostrarTexto">
							{{'navbar.webhook' | translate}}
						</div>
					</div>
				</div>
				<div class="configuracionBox">
					<div class="configuracionItemTranslate" (click)="openTranslate()">
						<div class="configuracionItemIcon">
							<div class="translate"></div>
						</div>
						<div class="configuracionItemText"  *ngIf="mostrarTexto">
							{{ 'side-bar-panel-control.preferences-page.language' | translate }}
						</div>
						<div class="configuracionItemIconChevron" *ngIf="mostrarTexto">
							<div class="chevronDown" *ngIf="this.openTranslateSideBar === false" ></div>
							<div class="chevronUp" *ngIf="this.openTranslateSideBar === true" ></div>
						</div>
					</div>
					<div class="translateOpen" *ngIf="this.openTranslateSideBar === true">
						<div class="translateCheckboxItem" (click)="changeLanguage('es')">
							<div class="circle-selected" *ngIf="this.language === 'es'">
							</div>
							<div class="circle-unselected" *ngIf="this.language !== 'es'">
							</div>
							<div class="text">
								{{'header-admin.spanish' | translate}}
							</div>
						</div>
						<div class="translateCheckboxItem" (click)="changeLanguage('en')">
							<div class="circle-selected" *ngIf="this.language === 'en'">
							</div>
							<div class="circle-unselected" *ngIf="this.language !== 'en'">
							</div>
							<div class="text">
								{{'header-admin.english' | translate}}
							</div>
						</div>
					</div>
					<div class="configuracionItemApariencia" (click)="openApariencia()">
						<div class="configuracionItemIcon">
							<div class="appearance"></div>
						</div>
						<div class="configuracionItemText"  *ngIf="mostrarTexto">
							{{'header-admin.appearance' | translate}}
						</div>
						<div class="configuracionItemIconChevron" *ngIf="mostrarTexto">
							<div class="chevronDown" *ngIf="this.openAparienciaSideBar === false" ></div>
							<div class="chevronUp" *ngIf="this.openAparienciaSideBar === true" ></div>
						</div>
					</div>
					<div class="AparienciaOpen" *ngIf="this.openAparienciaSideBar === true">
						<div class="AparienciaCheckboxItem" (click)="changeMode('light')">
							<div class="circle-selected" *ngIf="this.mode === 'light'">
							</div>
							<div class="circle-unselected" *ngIf="this.mode !== 'light'">
							</div>
							<div class="text">
								{{'header-admin.light' | translate}}
							</div>
						</div>
						<div class="AparienciaCheckboxItem" (click)="changeMode('dark')">
							<div class="circle-selected" *ngIf="this.mode === 'dark'">
							</div>
							<div class="circle-unselected" *ngIf="this.mode !== 'dark'">
							</div>
							<div class="text">
								{{'header-admin.dark' | translate}}
							</div>
						</div>
					</div>
				</div>





				<div class="line" [ngClass]="{'closingSidebar': this.sidebarTransition === false, 'openingSidebar': this.sidebarTransition === true} ">
					<hr class="hrClass" style="margin: 0px 0px;">
				</div>
				<div class="integracionesBox" [ngClass]="{'selected' : this.page === 'integrations' && this.sidebarTransition === true}" (click)="changePage('integrations')">
					<div class="integracionesItem">
						<div class="integracionesItemIcon">
							<div class="integrations">
							</div>
						</div>
						<div class="integracionesItemText"  *ngIf="mostrarTexto">
							{{'navbar.integrations' | translate}}
						</div>
					</div>
				</div>
				<div class="integracionesBox">
					<div class="versionesItem" [ngClass]="{'selected closingSidebar': this.page === 'version' && this.sidebarTransition === false}" (click)="changePage('version')">
						<div class="versionesItemIcon">
							<div class="version"></div>
						</div>
						<div class="versionesItemText"  *ngIf="mostrarTexto">
							{{'navbar.version' | translate}}
						</div>
						<div class="versionesItemIconChevron">
						</div>
					</div>
				</div>


				<div class="line" [ngClass]="{'closingSidebar': this.sidebarTransition === false, 'openingSidebar': this.sidebarTransition === true} ">
					<hr class="hrClass" style="margin: 0px 0px;">
				</div>
				<div class="profileBox">
					<div class="profileItem" >
						<div class="profileImageBox">
							<div class="circleProfileImageBox">
								{{username.slice(0, 2) | uppercase}}
							</div>
						</div>
						<div class="profileTextBox" >
							<div class="textBox"  *ngIf="mostrarTexto" [ngClass]="{'selected closingSidebar': this.page === 'account-settings/account' && this.sidebarTransition === false}" (click)="changePage('account-settings/account')">
								<div class="textBoxTitle">
									{{username.slice(0, 11)}}
								</div>
								<div class="subtextBoxTitle">
									{{'navbar.account' | translate}}
								</div>
							</div>
							<div class="signOut" (click)="logout()">
								<div class="iconSignOut">
									<div class="signout"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="versionBox"  *ngIf="mostrarTexto">
						Powered by Vivipost {{systemVersion}}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="sidebarMinimized" [ngClass]="{'openingSidebar': this.sidebarTransition === true}" *ngIf="this.openSideBar === true">
	<div class="container" style="height: 100%;">
		<div class="row" style="height: 100%; display:grid;align-items: flex-end;">
			<div class="col-12 noPaddings boxGroup1">
				<div class="boxCompanyName">
					<div class="imageCompany">
						<div class="imageBox">
							<div class="chevron-right" (click)="hideSideBar()">
							</div>
						</div>
					</div>

				</div>
				<div class="line" [ngClass]="{'openingSidebarSelection': this.sidebarTransition === true}">
					<hr class="hrClass" style="margin: 0px 0px;">
				</div>
				<div class="menuBox" >
					<div class="menuItem" *ngFor="let main of sideMenu" [ngClass]="{'selected openingSidebarSelection' : this.page === main.route && this.sidebarTransition === true}"  (click)="changePage(main.route)">
						<div class="menuItemIcon">
							<div [class]="main.route"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 noPaddings boxGroup2">
				<div class="line" [ngClass]="{'openingSidebarSelection': this.sidebarTransition === true}">
					<hr class="hrClass" style="margin: 0px 0px;">
				</div>
				<div class="configuracionBox" >
					<div class="configuracionItem" [ngClass]="{'selected openingSidebarSelection' : this.page === 'settings/config-webhook' && this.sidebarTransition === true}" (click)="changePage('settings/config-webhook')">
						<div class="configuracionItemIcon">
							<div class="webhook"></div>
						</div>
					</div>
					<div class="configuracionTranslateItem" >
						<div class="configuracionItemIcon">
							<div class="translate"></div>
						</div>
					</div>
					<div class="configuracionAppearanceItem">
						<div class="configuracionItemIcon">
							<div class="appearance"></div>
						</div>
					</div>
				</div>
				<div class="line" [ngClass]="{'openingSidebarSelection': this.sidebarTransition === true}">
					<hr class="hrClass" style="margin: 0px 0px;">
				</div>
				<div class="integracionesBox" >
					<div class="integracionesItem" [ngClass]="{'selected' : this.page === 'integrations' && this.sidebarTransition === true}" (click)="changePage('integrations')">
						<div class="integracionesItemIcon">
							<div class="integrations"></div>
						</div>
					</div>
					<div class="integracionesItem" [ngClass]="{'selected' : this.page === 'version' && this.sidebarTransition === true}" (click)="changePage('version')">
						<div class="integracionesItemIcon">
							<div class="version"></div>
						</div>
					</div>
				</div>
				<div class="line" [ngClass]="{'openingSidebarSelection': this.sidebarTransition === true}">
					<hr class="hrClass" style="margin: 0px 0px;">
				</div>
				<div class="profileBox">
					<div class="profileItem" >
						<div class="profileImageBox">
							<div class="circleProfileImageBox">
							</div>
						</div>

					</div>
					<div class="versionBox">
						{{systemVersion}}
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
