import { Component, Inject, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog,MatDialogRef} from '@angular/material/dialog';
import { DialogRecordatoriosComponent } from '../dialog-recordatorios/dialog-recordatorios.component';
import { DialogEraseOrderComponent } from '../dialog-erase-order/dialog-erase-order.component';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Interceptor } from 'src/app/core/interceptors/interceptor.service';
import axios from 'axios'
import { options } from '@amcharts/amcharts4/core';
import { Observable } from 'rxjs';
import { order } from '@amcharts/amcharts4/.internal/core/utils/Number';

@Component({
	selector: 'app-dialog-order-detail',
	templateUrl: './dialog-order-detail.component.html',
	styleUrls: ['./dialog-order-detail.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
})
export class DialogOrderDetailComponent implements OnInit {

	orderData: any;
	viewUserInformation = true;
	viewQrInformation = false;
	viewEvidenceInformation = false;
	editOrderInformation = false;
	id_status_code_package;
	status_package;
	img_sign;
	img_pickup;
	nombre_status;
	recordatoriosNotification = [];
	iconBtnQR:string;
	url_view_image: boolean = true;
	idUserType=parseInt(localStorage.getItem('id_user_type'));
	rutaArchivo;
	constructor(
		private dialogRef: MatDialogRef<DialogOrderDetailComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialog: MatDialog,
		private http: HttpClient,
		private interceptor: Interceptor
	) {
		this.orderData = this.data;
		this.rutaArchivo = this.orderData.qr;
		console.log(this.orderData.qr);
		if (this.data.status === 'SCHEDULED' && this.data.id_status === 35) {
			this.recordatoriosNotification = [];
			this.id_status_code_package = this.orderData.id_status_code_package;
			this.status_package = this.orderData.id_status_service;
		}
		else if ((this.data.status === 'DISPONIBLE' && this.data.status_package === 14 && this.data.id_status_code_package === 17) || (this.data.status === 'RECOLECTADO' && this.data.status_package === 33 && this.data.id_status_code_package === 32)) {
			console.log(this.orderData);
			this.recordatoriosNotification = this.orderData.resultNotification !== undefined ? this.orderData.resultNotification : [];
			this.id_status_code_package = this.orderData.id_status_code_package;
			this.status_package = this.orderData.status_package;
			this.nombre_status = this.orderData.status;
		} else if (	this.data.status === 'ENTREGADO' &&	this.data.status_package === 16 &&	this.data.id_status_code_package === 18	) {
			console.log(this.orderData);
			this.recordatoriosNotification =  this.orderData.resultNotification !== undefined ? this.orderData.resultNotification : [];
			this.id_status_code_package = this.orderData.id_status_code_package;
			this.status_package = this.orderData.status_package;
			this.nombre_status = this.orderData.status;
			this.img_sign = this.orderData.IMAGE.url_view_imagen_signature;
			this.img_pickup = this.orderData.IMAGE.url_view_image_pickup;
		}

	}

	ngOnInit(): void {
		const mode = localStorage.getItem('mode');
		if(mode === 'light'){
			this.iconBtnQR = 'icon-qr'
		}
		else if(mode === 'dark'){
			this.iconBtnQR = 'icon-qr-dark'
		}
	}



	closeModal() {
		this.dialogRef.close();
	}

	openModalRecordatorios() {
		let dialogRef = this.dialog.open(DialogRecordatoriosComponent, {
			width: '700px',
			height: '623px',
			data: this.orderData,
		});
	}
	openModalEliminarOrder() {
		let dialogRef = this.dialog.open(DialogEraseOrderComponent, {
			width: '361px',
			height: '180px',
			data: this.orderData,
		});
	}
	descargarPNG(){ // Reemplaza con la ruta real de tu archivo PNG
		window.open(this.rutaArchivo);
	}

}



