
import { Component, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-star-rating',
	templateUrl: './star-rating.component.html',
	styleUrls: ['./star-rating.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
})
export class StarRatingComponent implements ICellRendererAngularComp {
	@Input('rating') public rating: number = 0;
	@Input('starCount') public starCount: number = 5;
	@Input('color') public color: string = 'accent';
	@Output() public ratingUpdated = new EventEmitter();

	public snackBarDuration: number = 2000;
	public ratingArr = [];

	constructor() {}

	ngOnInit() {
		// Itera y agrega los valores del índice para el array ratingArr
		for (let index = 0; index < this.starCount; index++) {
			this.ratingArr.push(index);
		}
	}
	// Maneja el evento click en una estrella
	onClick(rating: number) {
		this.ratingUpdated.emit(rating);
		return false;
	}
	// Devuelve el icono correspondiente a la calificación actual
	showIcon(index: number) {
		if (this.rating >= index + 1) {
			return 'star';
		} else {
			return 'star_border';
		}
	}

	agInit(params: ICellRendererParams<any, any>): void {
		this.rating = params.value;
	}
	refresh(params: ICellRendererParams<any, any>): boolean {
		return false;
	}
}

// Enumeración de los colores posibles para las estrellas
export enum StarRatingColor {
	primary = 'primary',
	accent = 'accent',
	warn = 'warn',
}
