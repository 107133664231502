import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WarehouseService } from 'src/app/core/http/warehouse/warehouse.service';
import { DataReturnTicketInterface, TicketReturn } from 'src/app/core/models/warehouse/warehouse.interface';
import * as QRCode from 'qrcode';
@Component({
	selector: 'app-create-tickets',
	templateUrl: './create-tickets.component.html',
	styleUrls: ['./create-tickets.component.scss']
})
export class CreateTicketsComponent implements OnInit {
	ticketToken:string;
	TicketResponse: DataReturnTicketInterface;
	is_fragile:boolean= false;
	@ViewChild('qrCodeCanvasCEDIS', { static: true }) qrCodeCanvasCEDIS: ElementRef;
	@ViewChild('qrCodeCanvasOrigen', { static: true }) qrCodeCanvasOrigen: ElementRef;
	@ViewChild('qrCodeCanvasDestino', { static: true }) qrCodeCanvasDestino: ElementRef;
	constructor(
		private route: ActivatedRoute,
		private warehouseSvc: WarehouseService,
		private navigationRoute : Router
	){

		this.route.paramMap.subscribe((params)=>{
			this.ticketToken = params.get('tokenTicket');
		});
		localStorage.setItem('token','tickets '+this.ticketToken);

		// setTimeout(() => {
		// 	window.location.href = 'https://vivipost.com/';
		// }, 5 * 60 * 1000); // 3 minutes in milliseconds
	}
	ngOnInit(){
		let header ={
			language: localStorage.getItem('language')
		}
		this.warehouseSvc.getDataOfTicket(this.ticketToken,header).then((res:any)=>{
			if(res.status === 200){
				this.TicketResponse = res.body.data_return;
				this.is_fragile = this.TicketResponse.package.is_fragile;
				const qrCodeDataCEDIS = this.TicketResponse.package.qr_cedis;
				const qrCodeDataOrigen = this.TicketResponse.package.qr_pickup;
				const qrCodeDataDestino = this.TicketResponse.package.qr_delivery;
				QRCode.toCanvas(this.qrCodeCanvasCEDIS.nativeElement,  qrCodeDataCEDIS, {
					width: 55, // Specify the desired width
					height: 55 // Specify the desired height
				}, (error) => {
					if (error) {
						console.error('Error generating QR code:', error);
					}
				});
				QRCode.toCanvas(this.qrCodeCanvasOrigen.nativeElement,  qrCodeDataOrigen, {
					width: 55,
					height: 55
				}, (error) => {
					if (error) {
						console.error('Error generating QR code:', error);
					}
				});
				QRCode.toCanvas(this.qrCodeCanvasDestino.nativeElement,  qrCodeDataDestino, {
					width: 55,
					height: 55
				}, (error) => {
					if (error) {
						console.error('Error generating QR code:', error);
					}
				});
			}
		})
		.catch(error=>{
			if(error.status === 403){
				window.location.href = 'https://vivipost.com/';
			}
		});
	}
}
