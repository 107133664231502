<div class="card" style="height: auto; width: auto">
    <div class="card-body">
        <div class="card-title" style="text-align: initial;">
            {{ 'lockers.usage_month' | translate }}
            <i class="fas fa-info-circle" matTooltip="{{'info.lockers.historical.monthly_locker_usage' | translate }}" matTooltipPosition="above">
            </i>
        </div>
        <app-chart-client-admin type="metrics" data="{{dataChart}}" months="{{months}}"></app-chart-client-admin>
    </div>
</div>
