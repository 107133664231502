import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
@Component({
	selector: 'app-add-new-user',
	templateUrl: './add-new-user.component.html',
	styleUrls: ['./add-new-user.component.scss'],
})
export class AddNewUserComponent implements OnInit {
		createCarrierUserForm: FormGroup;
		types: SelectItem[];
		idOffice :number;
		isCompanyEmployeeIdDisabled: boolean = false; // Inicialmente habilitado

		constructor(
			private formBuilder: FormBuilder,
			private adminService: AdminClientService,
			private dialog: MatDialog,
			private toastr: ToastrService
		) {}

		ngOnInit() {
			this.idOffice = JSON.parse(localStorage.getItem('data')).result.id_office
		this.crearFormulario();
		this.getUserTypes();
		}

		modelChangedInformationCarrier(event) {
			if(this.idTypeEmployee !== 11){
				this.createCarrierUserForm.get('company_employee_id').disable();
				this.createCarrierUserForm.get('company_employee_id').clearValidators();
			}
			else{
				this.createCarrierUserForm.get('company_employee_id').enable();
				this.createCarrierUserForm.get('company_employee_id').setValidators([Validators.required]);
			}
		}

		async getUserTypes() {
		this.types = [];
		this.adminService.getUserTypes(localStorage.getItem('language').toLowerCase()).then((res:any)=>{
			console.log(res);
		})
		let res: any = await this.adminService.getUserTypes(localStorage.getItem('language').toLowerCase());
		res.result_list_user.forEach((element) => {
			console.log('List Type User', element);
			this.types.push({
				label: element.user_type,
				value: element.id_user_type,
			});
		});

		// Habilitar o deshabilitar el control company_employee_id en función de isCompanyEmployeeIdDisabled
		if (this.isCompanyEmployeeIdDisabled) {
			this.createCarrierUserForm.get('company_employee_id').disable();
		} else {
			this.createCarrierUserForm.get('company_employee_id').enable();
		}
		}

		private crearFormulario() {
		this.createCarrierUserForm = this.formBuilder.group({
			nombre_empresa: [''],
			company_employee_id: [''],
			id_type_employee: ['', [Validators.required]],
			full_name: ['', Validators.required],
			email: ['', [Validators.required]],
		});
		}

		closeAll() {
		this.dialog.closeAll();
		}

		onSubmit() {
			if (this.createCarrierUserForm.valid === true) {
				this.createUser();
			}
		}

		get idTypeEmployee() {
			return this.createCarrierUserForm.get('id_type_employee').value;
		}

	async createUser() {
		// let body = {
		// 	language: localStorage.getItem('language').toLowerCase(),
		// 	full_name: this.createCarrierUserForm.get('full_name').value,
		// 	email: this.createCarrierUserForm.get('email').value,
		// 	user_type: this.createCarrierUserForm.get('id_type_employee').value,
		// 	company_employee_id: this.createCarrierUserForm.get('company_employee_id').value,
		// };
		const fullName = this.createCarrierUserForm.get('full_name').value;
		const [firstName, surname] = fullName.split(' ', 2);
		let body = {
			language				: localStorage.getItem('language').toLowerCase(),
			surname					: surname,
			first_name				: firstName,
			user					: this.createCarrierUserForm.get('email').value,
			mobile					: this.createCarrierUserForm.get('id_type_employee').value === 7 ? 'false' : 'true',
			accept_term_condition	: true,
			id_registration_method	: 1,
			id_user_type			: this.createCarrierUserForm.get('id_type_employee').value,
			id_company				: Number(localStorage.getItem('id_company')),
			id_office 				: this.idOffice
		};
		const filteredUserType = this.types.filter(
			(userType) => userType.value === this.idTypeEmployee
		);
		await this.adminService.createUser(body).then((a: any) => {
			if (a.mensaje_return.CODE === 200) {
			this.toastr.success(
				'Usuario Agregado de tipo ' + filteredUserType[0].label,
				'Usuario Agregado',
				{
				toastClass: 'toast-extendida',
				timeOut: 5000,
				positionClass: 'toast-bottom-center',
				}
			);
			this.dialog.closeAll();
			}
			else if (a.mensaje_return.CODE === 1204) {
				this.toastr.success(
					'Usuario No Agregado de tipo ' + filteredUserType[0].label,
					'Usuario Existente',
					{
					toastClass: 'toast-warn',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
					}
				);
			}
		})
	}
}
