import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { utils } from 'xlsx';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { DateTextComponent } from '../date-text/date-text.component';
import { EstatusTableOrderAdminComponent } from '../estatus-table-order-admin/estatus-table-order-admin.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { Calendar } from 'primeng/calendar';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogOrderDetailComponent } from '../dialog-order-detail/dialog-order-detail.component';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';
import { AdminGuard } from 'src/app/core/guards/admin.guard';

@Component({
	selector: 'app-table-expired-admin',
	templateUrl: './table-expired-admin.component.html',
	styleUrls: ['./table-expired-admin.component.scss'],
})
export class TableExpiredAdminComponent implements OnInit, AfterViewInit  {
	public columnDefsExpired: ColDef[];
	public defaultColDefExpired: ColDef = {
		sortable: true,
		filter: true,
		flex: 1
	};
	private gridApi;
	public rowDataExpired: any[];
	@ViewChild(AgGridAngular) agGrid!: AgGridAngular;
	@ViewChild('datepicker') datepicker: MatDatepicker<Date>;
	@ViewChild(EstatusTableOrderAdminComponent)
	estatusOrderAdminComponent: EstatusTableOrderAdminComponent;
	@ViewChild('calendar') calendar: Calendar;
	supportLanguages = ['es', 'en'];
	InitialDate = new Date();
	FinalDate = new Date();
	res;
	completedDataCompleted = [];
	countCompleted = 0;
	rangeDates: Date[];
	display = false;
	minDate: Date;
	maxDate: Date;
	orderSelectToExtendDate: any;
	filterText: string = '';
	gridOptions: GridOptions;
	rowData: any[];
	dataTemporal = [];
	public filteredData: any[] = [];
	idLocker: string;
	filters:boolean=false;
	mode:string;
	idUserType = parseInt(localStorage.getItem('id_user_type'));
	constructor(
		private translate: TranslateService,
		private adminService: AdminClientService,
		private toastr: ToastrService,
		private filterService	: FilterService,
		private elementRef		: ElementRef,
		private matdialog: MatDialog)
		{
			let today = new Date();
			this.FinalDate = new Date("2023-06-31");
			this.FinalDate = new Date(today.getFullYear() + '-' + (today.getMonth()+1) +'-'+(today.getDate()));
			this.InitialDate = new Date(this.FinalDate);
			if((today.getDate()) === 31){
				this.InitialDate.setDate(0);
			}
			else{
				this.InitialDate.setMonth(this.InitialDate.getMonth() -1);
			}
			this.rangeDates = [this.InitialDate, this.FinalDate];
			this.idLocker = localStorage.getItem('id_locker');
	}
	ngOnInit() {
		this.mode = localStorage.getItem('mode');
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateTable();
		this.addEventRangeDates();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.get([
					'table-expired.tracking',
					'table-expired.lockerID',
					'table-expired.email',
					'table-expired.employeeName',
					'table-expired.door',
					'table-expired.status',
					'table-expired.inLocker',
					'table-expired.expiredDate',
			]).subscribe((t: any) => {
				this.columnDefsExpired = [
					{
						headerName: t['table-expired.tracking'],
						field: 'tracking',
						headerClass:'borderHeaderExpired',
						cellClass:'expiredClassLink',
						autoHeight: true,
						sortable: true,
						onCellClicked: ($event) => this.openModalUserDetail($event)
					},
					{
						headerName: t['table-expired.lockerID'],
						field: 'lockerID',
						cellClass:'expiredClass',
						headerClass:'borderHeaderExpired',
						sortable: true,
						autoHeight: true,
					},
					{
						headerName: t['table-expired.email'],
						field: 'email',
						cellClass:'expiredClass',
						headerClass:'borderHeaderExpired',
						sortable: true,
						autoHeight: true,
					},
					{
						headerName: t['table-expired.door'],
						field: 'doorNumber',
						cellClass:'expiredClass',
						headerClass:'borderHeaderExpired',
						sortable: true,
						autoHeight: true
					},
					{
						headerName: t['table-expired.status'],
						field: 'status',
						cellClass:'expiredClass',
						headerClass:'borderHeaderExpired',
						cellRenderer: EstatusTableOrderAdminComponent,
						onCellClicked: ($event) => this.openCalendar($event),
						sortable: true,
						autoHeight: true
					},
					{
						headerName: t['table-expired.inLocker'],
						field: 'inLocker',
						cellClass:'expiredClass',
						headerClass:'borderHeaderExpired',
						cellRenderer: DateTextComponent,
						autoHeight: true,
						sortable: true
					},
					{
						headerName: t['table-expired.expiredDate'],
						field: 'registrationDateExpired',
						cellClass:'expiredClass',
						headerClass:'borderHeaderExpired',
						cellRenderer: DateTextComponent,
						autoHeight: true,
						sortable: true,
					}
				];
			});
		});
		this.minDate = new Date();
		this.minDate.setDate(this.minDate.getDate() + 1); // día mínimo es mañana
		this.maxDate = new Date();
		this.maxDate.setDate(this.maxDate.getDate() + 2);
	}
	ngAfterViewInit() {
	}
	updateTable() {
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-expired.tracking'),
					field: 'tracking',
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClassLink',
					autoHeight: true,
					sortable: true,
					onCellClicked: ($event) => this.openModalUserDetail($event),
				},
				{
					headerName: this.translate.instant('table-expired.lockerID'),
					field: 'lockerID',
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					sortable: true,
					autoHeight: true
				},
				{
					headerName: this.translate.instant('table-expired.email'),
					field: 'email',
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					sortable: true,
					autoHeight: true,
				},
				{
					headerName: this.translate.instant('table-expired.door'),
					field: 'doorNumber',
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					sortable: true,
					autoHeight: true,
				},
				{
					headerName: this.translate.instant('table-expired.status'),
					field: 'status',
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					cellRenderer: EstatusTableOrderAdminComponent,
					onCellClicked: ($event) => this.openCalendar($event),
					sortable: true,
					autoHeight: true
				},
				{
					headerName: this.translate.instant('table-expired.inLocker'),
					field: 'inLocker',
					cellClass:'expiredClass',
					headerClass:'borderHeaderExpired',
					cellRenderer: DateTextComponent,
					autoHeight: true,
					sortable: true,
				},
				{
					headerName: this.translate.instant('table-expired.expiredDate'),
					field: 'registrationDateExpired',
					cellClass:'expiredClass',
					cellRenderer: DateTextComponent,
					headerClass:'borderHeaderExpired',
					autoHeight: true,
					sortable: true,
				}
			],
			rowClass:'rowExpiredClass',
			animateRows: true,
			defaultColDef: {
				flex: 1,
			},
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			headerHeight:59,
			rowHeight:59,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}
	onGridReady(params: any) {
		this.gridApi = params;
	}
	onCellClicked(e: CellClickedEvent): void {
		console.log(AdminGuard);
		if(AdminGuard){
			if (e.colDef.field === 'status') {
				this.display = true;
				this.orderSelectToExtendDate = e.data;
			}
		}
		else{
			this.display =false;
		}

	}
	getTableCompleted() {
		this.rowData = [];
		this.idLocker = localStorage.getItem('id_locker');
		if (this.idLocker === 'null' || this.idLocker === null) {
			this.completedDataCompleted = [];
			let lang = localStorage.getItem('language').toLowerCase();
			let company = localStorage.getItem('id_company');
			let data = {
				language: lang,
				id_company: company,
				date_start: this.InitialDate.getTime(),
				date_end: this.FinalDate.getTime(),
			};
			this.adminService.getAllOrders(data).then((res:any) => {
				if(res.return_result !== undefined){
					res.return_result.forEach((f) => {
						if ((f.id_status_code_package === 32 || f.id_status_code_package === 17 )&& f.status_package === 14 && f.extended_package === 1) {
							this.rowData.push({
								order_number: f.order_number,
								tracking: f.tracking_number,
								email: f.email,
								lockerID: f.locker_name,
								phone_used: f.phone_used,
								nameEmploye: f.delivery_employee_name,
								door: f.door_number,
								compartmentSize: f.compartment_size,
								status: f.name_status_package,
								inLocker: f.package_delivery_date,
								dateCollected: f.status_package === 16 ? f.package_pickup_date : '',
								rate_start: f.rate_start,
								id_package_code: f.id_package_code,
								employeeId: f.delivery_company_employee_id,
								carrier: 'N/A',
								doorNumber:	f.door_number + ',' + f.compartment_name,
								doorSize: f.compartment_name,
								id_status_code_package:	f.id_status_code_package,
								status_package: f.status_package,
								qr_order: f.qr_order,
								name_status_notification: f.name_status_notification,
								expiration_date: f.expiration_date,
								extended_package: f.extended_package,
								id_package: f.id_package,
								resultNotification: f.result_notification,
								IMAGE: f.IMAGE,
								registrationDateExpired:  f.registration_date_expired !== undefined ? f.registration_date_expired : null,
								type_delivery : f.type_delivery
							});
							this.filterService.getStarsFilter().subscribe(starsFilter => {
								if(	starsFilter.size > 0){
									const filteredData = this.rowData.filter(item => starsFilter.has(item.rate_start));
									this.filteredData = filteredData;
									this.gridApi.api.setRowData(filteredData);
								}
								else{
									this.gridApi.api.setRowData(this.rowData);
								}
							});
							this.filterService.getEstatusFilter().subscribe(estatusFilter => {
								let filteredData = [...this.rowData];
								if (estatusFilter.size > 0) {
									const filterConditions = [];
									if (estatusFilter.has(1)) {
										filterConditions.push(
										(item) =>
											item.status_package === 33 &&
											item.id_status_code_package === 32 &&
											item.extended_package === 0
										);
									}
									if (estatusFilter.has(2)) {
										filterConditions.push(
										(item) =>
											item.status_package === 16 &&
											item.id_status_code_package === 18 &&
											item.extended_package === 0
										);
									}
									if (estatusFilter.has(3)) {
										filterConditions.push(
										(item) =>
											item.status_package === 14 &&
											item.id_status_code_package === 17 &&
											item.extended_package === 0
										);
									}
									if (estatusFilter.has(4)) {
										filterConditions.push(
										(item) =>
											item.status_package === 14 &&
											item.id_status_code_package === 17 &&
											item.extended_package === 1
										);
									}
									if (estatusFilter.has(5)) {
										filterConditions.push(
										(item) =>
											item.status_package === 14 &&
											item.id_status_code_package === 32 &&
											item.extended_package === 0
										);
									}
									const combinedFilter = (item) =>
										filterConditions.some((condition) => condition(item));
									filteredData = filteredData.filter(combinedFilter);
								}
								this.filteredData = filteredData;
								this.gridApi.api.setRowData(this.filteredData);
							});
							this.rowData = [...this.rowData];
						}
					});
				}

			}).catch((err) => {
				console.log(err);
			});
		} else if (this.idLocker !== 'null' || this.idLocker !== null) {
			this.completedDataCompleted = [];
			let lang = localStorage.getItem('language').toLowerCase();
			let company = localStorage.getItem('id_company');
			let data = {
				language: lang,
				id_locker: this.idLocker,
				id_company: parseInt(company),
				date_start: this.InitialDate.getTime(),
				date_end: this.FinalDate.getTime(),
			};
			this.adminService.getOrders(data).then((res) => {
					if (res.mensaje_return.CODE !== 200) {
						this.rowData = [];
					}
					else if (res.mensaje_return.CODE === 200) {
						res.return_result.map((f) => {
							if (f.id_status_code_package === 32 && f.status_package === 14 && f.extended_package === 1) {
								this.rowData.push({
									order_number: f.order_number,
									tracking: f.tracking_number,
									email: f.email,
									lockerID: f.locker_name,
									phone_used: f.phone_used,
									nameEmploye: f.delivery_employee_name,
									door: f.door_number,
									compartmentSize: f.compartment_size,
									status: f.name_status_package,
									inLocker: f.package_delivery_date,
									dateCollected:f.status_package === 16 ? f.package_pickup_date : '',
									rate_start: f.rate_start,
									id_package_code: f.id_package_code,
									employeeId: f.delivery_company_employee_id,
									carrier: 'N/A',
									doorNumber: f.door_number +	',' +f.compartment_name,
									doorSize: f.compartment_name,
									id_status_code_package:	f.id_status_code_package,
									status_package: f.status_package,
									qr_order: f.qr_order,
									name_status_notification: f.name_status_notification,
									expiration_date: f.expiration_date,
									extended_package: f.extended_package,
									id_package: f.id_package,
									resultNotification: f.result_notification,
									IMAGE: f.IMAGE,
									registrationDateExpired: f.registration_date_expired !== undefined ? f.registration_date_expired : null ,
									type_delivery : f.type_delivery
								});
								this.filterService.getStarsFilter().subscribe(starsFilter => {
									if(	starsFilter.size > 0){
										const filteredData = this.rowData.filter(item => starsFilter.has(item.rate_start));
										this.filteredData = filteredData;
										this.gridApi.api.setRowData(filteredData);
									}
									else{
										this.gridApi.api.setRowData(this.rowData);
									}
								});
								this.filterService.getEstatusFilter().subscribe(estatusFilter => {
									let filteredData = [...this.rowData];
									if (estatusFilter.size > 0) {
										const filterConditions = [];
										if (estatusFilter.has(1)) {
											filterConditions.push(
											(item) =>
												item.status_package === 33 &&
												item.id_status_code_package === 32 &&
												item.extended_package === 0
											);
										}
										if (estatusFilter.has(2)) {
											filterConditions.push(
											(item) =>
												item.status_package === 16 &&
												item.id_status_code_package === 18 &&
												item.extended_package === 0
											);
										}
										if (estatusFilter.has(3)) {
											filterConditions.push(
											(item) =>
												item.status_package === 14 &&
												item.id_status_code_package === 17 &&
												item.extended_package === 0
											);
										}
										if (estatusFilter.has(4)) {
											filterConditions.push(
											(item) =>
												item.status_package === 14 &&
												item.id_status_code_package === 17 &&
												item.extended_package === 1
											);
										}
										if (estatusFilter.has(5)) {
											filterConditions.push(
											(item) =>
												item.status_package === 14 &&
												item.id_status_code_package === 32 &&
												item.extended_package === 0
											);
										}
										const combinedFilter = (item) =>
											filterConditions.some((condition) => condition(item));
										filteredData = filteredData.filter(combinedFilter);
									}
									this.filteredData = filteredData;
									this.gridApi.api.setRowData(this.filteredData);
								});
							}
							this.rowData = [...this.rowData];
						});
					}
			}).catch((error) => {
				console.log(error);
			});
		}
	}
	showDate: Date;
	showDateFinal: Date;
	fechasByMonth: string;

	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
			this.getTableCompleted();
		}
	}
	DownloadExcel() {
		let headerExcel = [
			'package',
			'tracking',
			'lockerID',
			'email',
			'employeeID',
			'employeeName',
			'door',
			'doorSize',
			'status',
			'inLockerDate',
			'inLockerTime',
			'registrationDateExpired',
			'registrationTimeExpired',
		];
		let dataExcel = [];
		this.completedDataCompleted.forEach((e: any) => {
			let info = {
				package: e.order,
				tracking: e.tracking,
				lockerID: e.lockerID,
				email: e.email,
				employeeID: e.employeId,
				employeeName: e.nameEmploye,
				door: e.doors,
				doorSize: e.compartment,
				status: e.status,
				inLockerDate: e.inLocker.slice(0, 10),
				inLockerTime: e.inLocker.slice(11, 19),
				registrationDateExpired: e.registrationDateExpired.slice(0, 10),
				registrationTimeExpired: e.registrationDateExpired.slice(11,19),
			};
			dataExcel.push(info);
		});
		const worksheet = utils.json_to_sheet(dataExcel, {
			header: headerExcel,
		});
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Data');
	}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}

	openModalUserDetail(event) {
		const dialogRef = this.matdialog.open(DialogOrderDetailComponent, {
			width: '1304px',
			height: '95%',
			data: event.data,
		});
		dialogRef.afterClosed().subscribe((result) => {});
	}

	async extenderFechas(calendar) {
		let dateSelect = new Date(calendar.value);
		let month =	dateSelect.getMonth().toString().length == 1 ? '0' + (dateSelect.getMonth() + 1) : dateSelect.getMonth() + 1;
		let day = dateSelect.getDate().toString().length == 1 ? '0' + dateSelect.getDate() : dateSelect.getDate();
		let d = dateSelect.getFullYear() + '-' + month + '-' + day;
		let dataSendUpdate = {
			language: localStorage.getItem('language').toString().toLowerCase(),
			id_company: parseInt(localStorage.getItem('id_company')),
			id_package_code: this.orderSelectToExtendDate.id_package_code,
			date_update: d,
		};
		let res: any = await this.adminService.updateExpirationDate(dataSendUpdate);
		if (res.mensaje_return) {
			this.display = false;
			this.toastr.success('la entrega santifactoriamente','Se ha extendindo',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		}
		this.getTableCompleted();
	}
	cerrarExtender() {
		this.display = false;
		this.orderSelectToExtendDate = [];
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		const dayInitial = this.InitialDate.getDate() < 10 ? '0' + this.InitialDate.getDate() : this.InitialDate.getDate();
		const monthInitial = this.InitialDate.getMonth() + 1 < 10 ? '0' + (this.InitialDate.getMonth() + 1)	: this.InitialDate.getMonth() + 1;
		const yearInitial = this.InitialDate.getFullYear();
		const dayFinal = this.FinalDate.getDate() < 10 ? '0' + this.FinalDate.getDate() : this.FinalDate.getDate();
		const monthFinal = this.FinalDate.getMonth() + 1 < 10 ? '0' + (this.FinalDate.getMonth() + 1) : this.FinalDate.getMonth() + 1;
		const yearFinal = this.FinalDate.getFullYear();
		const fileName = 'Expired'+dayInitial+monthInitial+yearInitial+'_'+dayFinal+monthFinal+yearFinal+'.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Expired',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
		else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
	openCalendar(e: CellClickedEvent) {
		if (e.data.status_package === 14 &&	e.data.id_status_code_package === 32) {
			this.display = true;
			this.orderSelectToExtendDate = e.data;
		}
		else {
			this.display = false;
		}
	}
	showFilters(){
		this.filters = !this.filters;
		if (this.filters) {
			this.registerClickOutsideListener();
		}
		else {
			this.closeFilters();
		}
	}
	private registerClickOutsideListener() {
		document.addEventListener('click', this.clickOutsideHandler.bind(this));
	}
	private clickOutsideHandler(event: Event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.closeFilters();
		}
	}

	private closeFilters() {
		this.filters = false;
		document.removeEventListener('click', this.clickOutsideHandler.bind(this));
	}
}
