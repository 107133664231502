<div class="containerCarrierUser">
	<div class="row" style ="row-gap: 12px;">
		<div class="col-12">
			<div class="headerBox">
				<div class="title">
					{{'addUser.title' | translate}}
				</div>
				<div class="iconClose" (click)="closeAll()"></div>
			</div>
			<hr style="margin: 12px 0px 0px 0px;">
		</div>
		<div class="col-12">
			<form [formGroup]="createCarrierUserForm" class="formGroupBoxCarrier">
				<!-- <div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						Sede
					</div>
					<p-dropdown class="formInputTypeUserCarrier" [options]="defaultSede" optionLabel="label" optionValue="value" formControlName="typeUser">
					</p-dropdown>
				</div> -->
				<div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldTypeEmployee' | translate}}
					</div>
					<p-dropdown class="formInputTypeUserCarrier" [options]="types" optionLabel="label" optionValue="value" formControlName="id_type_employee" (ngModelChange)="modelChangedInformationCarrier($event)">
					</p-dropdown>
					<div class="error-message" *ngIf="createCarrierUserForm.get('id_type_employee').invalid && createCarrierUserForm.get('id_type_employee').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>
				<div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldNameEmployee' | translate}}
					</div>
					<input	type="text"
							class="formInput"
							formControlName="full_name"
							(ngModelChange)="modelChangedInformationCarrier($event)"/>
					<div class="error-message" *ngIf="createCarrierUserForm.get('full_name').invalid && createCarrierUserForm.get('full_name').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>
				<div class="formBoxCarrier" *ngIf="this.idTypeEmployee === 11 ">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldIdEmployee' | translate}}
					</div>
					<input	type="text"
							class="formInput"
							minlength="4" maxlength="8"
							formControlName="company_employee_id"
							(ngModelChange)="modelChangedInformationCarrier($event)"/>
					<div class="error-message" *ngIf="createCarrierUserForm.get('company_employee_id').invalid && createCarrierUserForm.get('company_employee_id').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>
				<div class="formBoxCarrier" *ngIf="this.idTypeEmployee !== 11 ">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldIdEmployee' | translate}}
					</div>
					<input	type="text"
							class="formInput"
							minlength="4" maxlength="8"
							formControlName="company_employee_id"
							(ngModelChange)="modelChangedInformationCarrier($event)"
							disabled />
					<div class="error-message" *ngIf="createCarrierUserForm.get('company_employee_id').invalid && createCarrierUserForm.get('company_employee_id').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>
				<div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldEmail' | translate}}
					</div>
					<input	type="text"
							class="formInput"
							formControlName="email"
							(ngModelChange)="modelChangedInformationCarrier($event)"/>
					<div class="error-message" *ngIf="createCarrierUserForm.get('email').invalid && createCarrierUserForm.get('email').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>
				<div class="boxPassword">
					<div class="iconInfo">
					</div>
					<div class="text">
						La contraseña para el acceso a los lockers
						será enviada al correo que proporcionaste.
					</div>
				</div>
				<!-- <div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						Sede
					</div>
					<p-dropdown class="formInputTypeUserCarrier" [options]="defaultSede" optionLabel="label" optionValue="value" formControlName="typeUser">
					</p-dropdown>
				</div> -->
				<div class="row">
					<div class="col-12">
						<div class="box">
							<div class="boxButton">
								<app-button btnNombre="Cancelar" btnType="secondaryMedium" (click)="closeAll()">
								</app-button>
								<app-button btnNombre="Guardar usuario" btnType="primaryMedium" (click)="onSubmit()">
								</app-button>
							</div>
						</div>
					</div>
				</div>

			</form>

		</div>
	</div>
</div>
