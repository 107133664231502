import { Component, ViewChild } from '@angular/core';
import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexXAxis,
	ApexPlotOptions,
	ApexStroke,
	ApexTitleSubtitle,
	ApexTooltip,
	ApexFill,
	ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	title: ApexTitleSubtitle;
	tooltip: ApexTooltip;
	fill: ApexFill;
	legend: ApexLegend;
};
@Component({
	selector: 'app-califications-average',
	templateUrl: './califications-average.component.html',
	styleUrls: ['./califications-average.component.scss'],
})
export class CalificationsAverageComponent {
	@ViewChild('chart') chart: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	constructor() {
		this.chartOptions = {
			series: [
				{
					name: 'rating-1',
					data: [44],
					color: 'var(--color-star-1)',
				},
				{
					name: 'rating-2',
					data: [53],
					color: 'var(--color-star-2)',
				},
				{
					name: 'rating-3',
					data: [12],
					color: 'var(--color-star-3)',
				},
				{
					name: 'rating-4',
					data: [9],
					color: 'var(--color-star-4)',
				},
				{
					name: 'rating-5',
					data: [25],
					color: 'var(--color-star-5)',
				},
			],
			chart: {
				type: 'bar',
				height: 30,
				stacked: true,
				stackType: '100%',
				toolbar: {
					show: false, // Oculta el menú
				},
				background: 'transparent', // Establece el fondo del gráfico como transparente
				sparkline: {
					enabled: true, // Deshabilita los bordes y las etiquetas del gráfico
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			stroke: {
				width: 1,
				colors: ['var(--color-container) '],
			},
			xaxis: {
				categories: [],
				labels: {
					show: false, // Mostrar etiquetas del eje X
				},
			},

			tooltip: {
				y: {
					formatter: function (val) {
						return val + 'K';
					},
				},
			},
			fill: {
				opacity: 1,
			},
			legend: {
				position: 'top',
				horizontalAlign: 'left',
				offsetX: 40,
				show: false,
			},
		};
	}
}
