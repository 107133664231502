<!-- <div class="wrap-confirm">
    <h5 class="title-confirm">¿Desea la entrega personalmente?</h5>

    <div class="wrap-date-time">
        <input id="dateTimePersonally" type="datetime-local" name="partydate" (change)="changeDateNotify($event)"/>
      </div>

    <div class="wrap-btns">
        <button class="btn-secondary-gbl" (click)="closeModal()">Cancelar</button>
        <button class="btn-primary-gbl" (click)="savePersonalDelivery()">Aceptar</button>
    </div>
</div> -->

<div class="deliverBox">
	<div class="deliverBoxTitle">
		<div class="deliverTitle">
			¿Estas seguro de que quieres hacer esta entrega en persona?
		</div>
		<div class="deliverSubtitle">
			Si la entregas de esta manera, se marcara como “Completada” y podrás encontrarla en la pestaña de ese nombre de esta misma tabla.
		</div>
		<input id="dateTimePersonally" class="deliverCalendar custom-calendar-input" type="datetime-local" name="partydate" (change)="changeDateNotify($event)"/>
	</div>
	<div class="deliverBoxButtons">
		<app-button [activo]="false" btnNombre="Cancelar" btnType="secondaryMedium" (click)="closeModal()">

		</app-button>
		<app-button [activo]="false" btnNombre="Si, quiero entregarla" btnType="primaryMedium" (click)="savePersonalDelivery()">

		</app-button>
	</div>

</div>
