<mat-card-header >
    <mat-card-title class="title-card">        
        <div class="search-box"><mat-icon id="search">search</mat-icon>
            <input (keyup)="applyFilter($event)"  
            value="{{ search }}" 
            placeholder="{{'dashboard.search_user' | translate}}"
            class="input_search" >
        </div>        
    </mat-card-title>
         <input type="file" id="myFile" style="display:none;" accept=".xlsx, .xls, .csv" (change)="onFileChange($event)"/>  
        
        <button disabled="{{ disabled }}" *ngIf="!mobile && user=='true'" id="{{disabled_create_qr}}" (click)="createQR()" mat-button>{{'user.create_qr' | translate}}</button>

        <button (click)="create()" *ngIf="!mobile && user=='true'" id="create" mat-button>{{'user.create_user' | translate}}</button>
        
        <button (click)="create()" *ngIf="mobile && user=='true'" id="create-fab" mat-mini-fab><mat-icon>add</mat-icon></button>
        
        <button *ngIf="mobile && client=='true'" class="button-mat-icon" style="float: right;" onclick="document.getElementById('myFile').click();"><mat-icon class="upload-download-upload">attachment</mat-icon></button> 
        
        <!-- REGISTRAR -->
        <button (click)="register()" *ngIf="!mobile && user=='false'" id="create" mat-button>{{'buttons.regist' | translate}}</button>

        <!-- UPLOAD CLIENT BUTTON 
        <button type="button" onclick="document.getElementById('myFile').click();" id="upload" mat-button>
            {{'buttons.up_client' | translate}}
            <i  class="fas fa-upload" style="margin-left: 3px;"></i>
        </button> -->
        
    
        <!-- DOWNLOAD BUTTON -->
        <button disabled="{{disabled_dowloade_button}}" 
                (click)="exporter.exportTable('xlsx', {fileName:name_file})" 
                class="download-button{{disabled_dowloade}}" 
                mat-button>
            {{'buttons.download' | translate}} <i  class="fas fa-download gray-color" style="margin-left: 3px;"></i>
        </button>

</mat-card-header>
<mat-card-content>
    <div class="example-container">
        <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" class="colortable">
            <ng-container *ngFor="let column of displayedColumns; let i = index" matColumnDef="{{column}}">
                <th mat-header-cell *matHeaderCellDef class="color text-center">
                    {{'table.'+column | translate}}
                    <span *ngIf="column=='qr'">
                        <mat-checkbox (change)="setAll($event.checked)"></mat-checkbox>
                    </span>
                </th>
                    <td mat-cell *matCellDef="let element" >
                    <span *ngIf="displayedColumns.length-1 != i">
                        <span *ngIf="i!=5 && column!='userType' && column!='status' && column!='enabledToken'">{{element[column]}}</span> 
                        <span *ngIf="i==5">{{ element[column] | date: 'dd-MMM-yyyy'}}</span> 
                    </span>
                    <span *ngIf="column=='enabledToken'">
                        <mat-slide-toggle *ngIf="element[column] == 1" [checked]="true" (change)="toggle($event, element)"></mat-slide-toggle>
                        <mat-slide-toggle *ngIf="element[column] == 0" [checked]="false" (change)="toggle($event, element)"></mat-slide-toggle>
                    </span>

                    <span *ngIf="column=='status'">
                        <mat-slide-toggle *ngIf="element.slide_status" [checked]="element.slide_status" (change)="toggleStatus($event, element)"></mat-slide-toggle>
                        <span *ngIf="!element.slide_status">{{element.nombre_status_employee}}</span>
                    </span>

                    <span *ngIf="column=='qr' && element.enabledToken == 1 && element.url_token != 'N/A'" class="link" (click)="modalQr(element)">
                        {{ 'table.view' | translate }}
                    </span>
                    <span *ngIf="column=='qr' && element.enabledToken == 0 && element.url_token == 'N/A'" class="link">
                        <mat-checkbox (change)="selectItem($event.checked,element)"  [checked]="element.checked"></mat-checkbox>
                    </span>

                    <span *ngIf="column=='userType'">{{element[column].name}}</span>
                    <span *ngIf="displayedColumns.length-1 == i">                        
                        <!--<i (click)="edit(element)" class="{{element[column][0]}}"></i> -->
                        <i (click)="deleteUser(element)" class="fas fa-trash download-color m-2"></i>
                        <i *ngIf="element.id_user_type == 11" (click)="logUser(element)" class="{{element[column][2]}}"></i>
                    </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</mat-card-content>