<!-- GRID HEADER -->
<div class="grid-header">
    <!-- TITLE CONTAINER -->
    <div class="span-txt">
      <!-- TITLE -->
      <span class="txt-title">
        Paquetes entregados por tamaño de compartimiento
      </span>
      <!-- ICON INFO -->
      <div class="icon-info">
        <!-- TOOLTIP -->
        <app-tooltip 
            class="tooltip-span" 
            [tooltip]="tooltipHistorical">
        </app-tooltip>
        
      </div>
  
    </div>
      <!-- BTN EXPORTAR -->
      <dropdown-export></dropdown-export>
</div>
    
<!-- CHART -->
 <div id="chart" class="chart-container">
    <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
        [yaxis]="chartOptions.yaxis"
        [legend]="chartOptions.legend"
        [fill]="chartOptions.fill"
        [stroke]="chartOptions.stroke"
        [tooltip]="chartOptions.tooltip"
        [xaxis]="chartOptions.xaxis">
    </apx-chart>
</div>