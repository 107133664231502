//ANGULAR
import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
//APEXCHARTS
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexStroke, ApexMarkers, ApexYAxis, ApexGrid, ApexTitleSubtitle, ApexLegend, ApexFill } from 'ng-apexcharts';

//Tipos de ApexCharts, que son utilizados para definir opciones de gráficos.

@Component({
	selector: 'app-order-metrics-admin',
	templateUrl: './order-metrics-admin.component.html',
	styleUrls: ['./order-metrics-admin.component.scss'],
})
export class OrderMetricsAdminComponent {
	@ViewChild('chart') chart: ChartComponent;
	@Input() dataDeliver: number[] = [];
	@Input() dataRecolected: number[] = [];
	@Input() dataExpired: number[] = [];
	public chartOptions: Partial<ChartOptions>;

	constructor(private translate: TranslateService) {
		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
	}

	ngOnInit() {
		this.loadDataChart();
	}

	ngOnChanges() {
		this.loadDataChart();
	}
	private loadDataChart() {
		this.chartOptions = {
			series: [
				{
					name: this.translate.instant('overview.orderDelivered'),
					data: this.dataDeliver,
				},
				{
					name: this.translate.instant('overview.orderRecolected'),
					data: this.dataRecolected,
				},
				{
					name: this.translate.instant('overview.orderExpired'),
					data: this.dataExpired,
				},
			],
			chart: {
				selection: {
					enabled: true,
				},
				dropShadow: {
					enabled: false,
					enabledOnSeries: undefined,
					top: 0,
					left: 0,
					blur: 3,
					color: '#000',
					opacity: 0.35,
				},
				height: 400,
				width: 1250,
				type: 'line',
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,

			},
			stroke: {
				width: 2,
				colors: ['#41b5f5', '#0082CB', '#18345D'],
				curve: 'straight',
				dashArray: [0, 0, 0],
			},
			markers: {
				size: 0,
				hover: {
					sizeOffset: 6,
				},
				colors: ['#41b5f5', '#0082CB', '#18345D'],
			},
			yaxis:{
				labels: {
					style:{
						colors:'var(--color-primary)'
					}
				},
			},
			xaxis: {
				labels: {
					trim: false,
					style:{
						colors:'var(--color-primary)'
					}
				},
				categories: [
					this.translate.instant('month.Ene'),
					this.translate.instant('month.Feb'),
					this.translate.instant('month.Mar'),
					this.translate.instant('month.Abr'),
					this.translate.instant('month.May'),
					this.translate.instant('month.Jun'),
					this.translate.instant('month.Jul'),
					this.translate.instant('month.Ago'),
					this.translate.instant('month.Sep'),
					this.translate.instant('month.Oct'),
					this.translate.instant('month.Nov'),
					this.translate.instant('month.Dic'),
				],
			},
			tooltip: {
				y: [
					{
						title: {
							formatter: function (val) {
								return val + ' (mins)';
							},
						},
					},
				],
				marker: {
					fillColors: ['#41b5f5', '#0082CB', '#18345D'],
				},
			},
			grid: {
				borderColor: '#s',
			},
			fill: {
				opacity: 1,
				colors: ['#41b5f5', '#0082CB', '#18345D'],
			},
			legend: {
				position: 'bottom',
				fontFamily: 'Inter',
				offsetY: 0,
				labels:{
					colors:'var(--color-primary)'
				},
				markers: {
					fillColors: ['#41b5f5', '#0082CB', '#18345D'], // Colores personalizados para los marcadores de la leyenda
				},
			},
		};
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	markers: ApexMarkers;
	yaxis: ApexYAxis;
	grid: ApexGrid;
	legend: ApexLegend;
	title: ApexTitleSubtitle;
	fill: ApexFill;
	tooltip: any;
};
