import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';

@Component({
	selector: 'app-dialog-recordatorios',
	templateUrl: './dialog-recordatorios.component.html',
	styleUrls: ['./dialog-recordatorios.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
})
export class DialogRecordatoriosComponent implements OnInit {
	recordatorioFormulario: FormGroup;
	package_code;

	dateNotify;
	timeNotify;
	maxDate: Date = new Date();
	minDate = new Date(this.maxDate);

	constructor(
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<DialogRecordatoriosComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private adminService: AdminClientService
	) {
		this.package_code = data.id_package;
		this.recordatorioFormulario = new FormGroup({
			//nombreEmpleado: new FormControl('', Validators.required),
			hora: new FormControl('', Validators.required),
			fecha: new FormControl('', Validators.required),
		});
	}

	ngOnInit(): void {
		this.minDate.setDate(this.maxDate.getDate() - 2);
	}

	closeModal() {
		this.dialogRef.close();
	}

	// async saveNotify(){
	//     const body={
	//         "leng" : localStorage.getItem('language').toLowerCase(),
	//         "id_package" : this.data.dataRow.id_package,
	//         "id_company" : this.data.id_company,
	//         "date" : this.dateNotify+" "+this.timeNotify
	//     }
	//     const res=await this.adminService.saveNotify(body);
	//     if(res["mensaje_return"].ERROR){
	//         this.notifySendError=true;
	//         this.notifySend=false;
	//     }
	//     else{
	//         this.notifySend=true;
	//         this.notifySendError=false;
	//         this.lstNotify.push({date_sent: this.dateNotify+" "+this.timeNotify,name_notifification: "Whatsapp"})
	//     }
	// }

	async agregarRecordatorio() {
		let hora = new Date(this.recordatorioFormulario.get('hora').value);
		let hh = hora.getHours() < 9 ? '0' + (hora.getHours()+1) : (hora.getHours()+1);
		let mm =
			hora.getMinutes() < 9 ? '0' + hora.getMinutes() : hora.getMinutes();
		let ss =
			hora.getSeconds() < 9 ? '0' + hora.getSeconds() : hora.getSeconds();
		let fecha = new Date(this.recordatorioFormulario.get('fecha').value);

		let dd = fecha.getDate() < 9 ? '0' + fecha.getDate() : fecha.getDate();
		let mon = fecha.getMonth() < 9 ? '0' + (fecha.getMonth()+1) : (fecha.getMonth()+1);
		let yyyy = fecha.getFullYear();

		this.dateNotify = yyyy + '-' + mon + '-' + dd;
		this.timeNotify = hh + ':' + mm + ':' + ss;

		let dateFinal = this.dateNotify + ' ' + this.timeNotify;

		const body = {
			leng: localStorage.getItem('language').toLowerCase(),
			id_package: this.package_code,
			id_company: parseInt(localStorage.getItem('id_company')),
			date: dateFinal,
		};
		const res = await this.adminService.saveNotify(body);
		if (res['mensaje_return'].ERROR) {
			this.toastr.success('revisa bien las fecha', 'Oh, hubo un error', {
				toastClass: 'toast-erronea',
				timeOut: 5000,
				positionClass: 'toast-bottom-center',
			});
		} else {
			this.toastr.success(
				'se ha generado santisfactoriamente',
				'El recordatorio',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		}
	}
}
