import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { log } from 'console';
import { SelectItem } from 'primeng/api';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { ModuleCarrierService } from 'src/app/core/http/carriers/module_carrier/module-carrier.service';
import { AddNewCarrierAdmin } from 'src/app/core/models/carriers/module-carrier/carrier-mod.interface';


@Component({
	selector: 'app-add-new-carrier',
	templateUrl: './add-new-carrier.component.html',
	styleUrls: ['./add-new-carrier.component.scss']
})
export class AddNewCarrierComponent implements OnInit {
	@ViewChild('stepper', { static: false }) stepper: MatStepper;
	currentStep							: number  	= 0;
	informationCarrierForm				: FormGroup;
	validationInformationCarrierForm 	: boolean = false;
	preferencesCarrierForm				: FormGroup;
	validationPreferencesCarrierForm 	: boolean = false;
	addUserCarrierForm					: FormGroup;
	validationAddUserCarrierForm 		: boolean = false;
	btnCancelar							: string;
	btnAceptar							: string;
	errorCarrier						: boolean 	= false;
	typeMsgNameCarrier					: string 	= '';
	errorRFC							: boolean	= false;
	typeMsgRFCError						: string 	= '';
	errorAddress						: boolean 	= false;
	typeMsgAddressError					: string 	= '';
	errorTelephone						: boolean 	= false;
	typeMsgTelephoneError				: string 	= '';
	btnTypeStep1						: string = 'primary';
	errorHourExpired					: boolean 	= false;
	typeMsgHourExpiredError				: string 	= '';
	errorDaysExpired					: boolean 	= false;
	typeMsgDaysExpiredError				: string 	= '';
	errorExpiredCredentials				: boolean = false;
	typeMsgExpiredCredentials			: string = '';
	btnTypeStep2						: string = 'disabled';
	errorNameUser						: boolean 	= false;
	typeMsgNameUserError				: string 	= '';
	errortypeUser						: boolean 	= false;
	typeMsgTypeUserError				: string 	= '';
	errorEmail							: boolean = false;
	typeMsgEmail						: string = '';
	btnTypeStep3						: string = 'disabled';
	typeUserModel:number = 10;
	objectToCreateNewCarrier: AddNewCarrierAdmin[];
	types: SelectItem[];
	defaultValue = {
		label: 'CARRIER ADMIN',
		value: 10
	};

	get nameCarrierValue(){
		return this.informationCarrierForm.get('nameCarrier').value;
	}
	get acronymCarrierValue(){
		return this.informationCarrierForm.get('acronymCarrier').value;
	}
	get rfcCarrierValue(){
		return this.informationCarrierForm.get('rfcCarrier').value;
	}
	get addressCarrierValue(){
		return this.informationCarrierForm.get('addressCarrier').value;
	}
	get telephoneCarrierValue(){
		return this.informationCarrierForm.get('telephoneCarrier').value;
	}


	get hourExpiredPackagedValue(){
		return new Date(this.preferencesCarrierForm.get('hourExpiredPackaged').value);
	}
	get daysExpiredPackagedValue(){
		return this.preferencesCarrierForm.get('daysExpiredPackaged').value;
	}
	get whenExpiredCredentialsValue(){
		return this.preferencesCarrierForm.get('whenExpiredCredentials').value;
	}
	get apiValueValue(){
		return this.preferencesCarrierForm.get('apiValue').value;
	}


	get nameUserValue(){
		return this.addUserCarrierForm.get('nameUser').value;
	}
	get typeUserValue(){
		return this.addUserCarrierForm.get('typeUser').value;
	}
	get emailUserValue(){
		return this.addUserCarrierForm.get('emailUser').value;
	}
	constructor(
		private translate: TranslateService,
		private fb: FormBuilder,
		private dialog: MatDialog,
		private adminService: AdminClientService,
		private modCarrierService: ModuleCarrierService
	){
		this.informationCarrierForm = this.fb.group({
			nameCarrier:['',
				[
					Validators.required,
					Validators.maxLength(60),
					Validators.minLength(3),
				]
			],
			acronymCarrier:['',
				[
					Validators.required,
					Validators.maxLength(12)
				]
			],
			rfcCarrier:['',
				[
					Validators.required,
					Validators.pattern(/^([A-ZÑ&]{3,4})\d{2}(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[01])[A-Z\d]{2}[0-9A]$/)
					//! ^ y $ son anclas de inicio y fin de línea, respectivamente, asegurando que la expresión completa coincida con todo el RFC y no solo con una parte de él.
					//! ([A-ZÑ&]{3,4}) captura las primeras 3 o 4 letras del RFC. En esta parte, puedes incluir cualquier letra del alfabeto en mayúsculas, incluyendo la Ñ y el símbolo & si es necesario.
					//! \d{2} coincide con los siguientes 2 dígitos del RFC, que representan el año de registro.
					//! (0[1-9]|1[0-2]) captura el mes de registro. Puede ser cualquier valor de 01 a 12.
					//! (0[1-9]|1\d|2\d|3[01]) captura el día de registro. Puede ser cualquier valor de 01 a 31.
					//! [A-Z\d]{2} coincide con los siguientes 2 caracteres del RFC, que suelen ser letras o números.
					//! [0-9A] captura el último dígito o letra del RFC.
				]
			],
			addressCarrier:	['',
				[
					Validators.required,
					Validators.maxLength(50),
					Validators.minLength(10)
				]
			],
			telephoneCarrier:	['',
				[
					Validators.maxLength(50),
					Validators.minLength(10)
				]
			]
		});
		this.preferencesCarrierForm = this.fb.group({
			hourExpiredPackaged:['',
				[
					Validators.required,
				]
			],
			daysExpiredPackaged:['',
				[
					Validators.required,
				]
			],
			whenExpiredCredentials:['',
				[
					Validators.required,
				]
			],
			apiValue:[false
			]
		});
		this.addUserCarrierForm = this.fb.group({
			nameUser:['',[
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(50)
			]],
			typeUser:[this.defaultValue.value,[
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(50)
			]],
			emailUser:['',[
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(50)
			]]
		})
	}
	ngOnInit(): void {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.btnCancelar = 'registerCarrier.formStep1.btnCancel';
		this.btnAceptar = 'registerCarrier.formStep1.btnContinue';
		this.getUserTypes();
		this.addUserCarrierForm.get('typeUser').setValue(10);
	}
	onStepChanged(event){
	}
	modelChangedInformationCarrier($event) {
		if(this.informationCarrierForm.get('nameCarrier').errors !== null){
			this.errorCarrier = true;
			if(this.informationCarrierForm.get('nameCarrier').errors.minlength !== null){
				this.typeMsgNameCarrier = 'Error en el tamaño requerido de caracteres';
			}
			if(this.informationCarrierForm.get('nameCarrier').errors.required !== null && this.informationCarrierForm.get('nameCarrier').errors.required !== undefined){
				this.typeMsgNameCarrier = 'Error el campo Carrier Name es Requerido';
			}
		}
		else{
			this.errorCarrier = false;
		}
		if(this.informationCarrierForm.get('rfcCarrier').errors !== null){
			this.errorRFC = true;
			if(this.informationCarrierForm.get('rfcCarrier').errors.minlength !== null){
				this.typeMsgRFCError = 'Error en el tamaño requerido de caracteres';
			}
			if(this.informationCarrierForm.get('rfcCarrier').errors.required !== null && this.informationCarrierForm.get('rfcCarrier').errors.required !== undefined){
				this.typeMsgRFCError = 'Error el campo RFC Carrier es Requerido';
			}
		}
		else{
			this.errorRFC = false;
		}
		if(this.informationCarrierForm.get('addressCarrier').errors !== null){
			this.errorAddress = true;
			if(this.informationCarrierForm.get('addressCarrier').errors.minlength !== null){
				this.typeMsgAddressError = 'Error en el tamaño requerido de caracteres';
			}
			if(this.informationCarrierForm.get('addressCarrier').errors.required !== null && this.informationCarrierForm.get('addressCarrier').errors.required !== undefined){
				this.typeMsgAddressError = 'Error el campo Address Carrier es Requerido';
			}
		}
		else{
			this.errorAddress = false;
		}
		if(this.informationCarrierForm.get('telephoneCarrier').errors !== null){
			this.errorTelephone = true;
			if(this.informationCarrierForm.get('telephoneCarrier').errors.minlength !== null){
				this.typeMsgTelephoneError = 'Error en el tamaño requerido de caracteres';
			}
		}
		else{
			this.errorTelephone = false;
		}
		if(this.informationCarrierForm.valid === false){
			this.validationInformationCarrierForm = false;
			this.currentStep = 0;
			this.btnTypeStep1 = "disabled";
		}
		else{
			this.validationInformationCarrierForm = true;
			this.currentStep = 1;
			this.btnTypeStep1 = "primary";
		}
	}
	modelChangedPreferences(event){
			if(this.preferencesCarrierForm.get('hourExpiredPackaged').valid === false){
				this.errorHourExpired = true;
				this.typeMsgHourExpiredError = 'Error el valor es requerido';
			}
			else{
				this.errorHourExpired = false;
			}
			if(this.preferencesCarrierForm.get('daysExpiredPackaged').valid === false){
				this.errorDaysExpired = true;
				this.typeMsgDaysExpiredError = 'Error el valor es requerido';
			}
			else{
				this.errorDaysExpired = false;
			}
			if(this.preferencesCarrierForm.get('whenExpiredCredentials').valid === false){
				this.errorExpiredCredentials = true;
				this.typeMsgExpiredCredentials = 'Error el valor es requerido';
			}
			else{
				this.errorExpiredCredentials = false;
			}
			if(this.preferencesCarrierForm.valid === true){
				this.btnTypeStep2 = 'primary'
			}
			else{
				this.btnTypeStep2 = 'disabled'
			}
			if(this.preferencesCarrierForm.valid === false){
				this.validationPreferencesCarrierForm = false;
				this.currentStep = 1;
				this.btnTypeStep2 = "disabled";
			}
			else{
				this.validationPreferencesCarrierForm = true;
				this.currentStep = 2;
				this.btnTypeStep2 = "primary";
			}
	}

	modelChangedAddUser(event){
		if(this.addUserCarrierForm.get('nameUser').valid !== true){
			this.errorNameUser = true;
			if(this.addUserCarrierForm.get('nameUser').errors.minlength !== null){
				this.typeMsgNameUserError = 'Error en el tamaño requerido de caracteres';
			}
			if(this.addUserCarrierForm.get('nameUser').errors.required !== null && this.addUserCarrierForm.get('nameUser').errors.required !== undefined){
				this.typeMsgNameUserError = 'Error el campo Carrier Name es Requerido';
			}
		}
		else{
			this.errorNameUser = false;
		}
		if(this.addUserCarrierForm.get('typeUser').valid !== true){
			this.errortypeUser = true;
			if(this.addUserCarrierForm.get('typeUser').errors.minlength !== null){
				this.typeMsgTypeUserError = 'Error en el tamaño requerido de caracteres';
			}
			if(this.addUserCarrierForm.get('typeUser').errors.required !== null && this.addUserCarrierForm.get('typeUser').errors.required !== undefined){
				this.typeMsgTypeUserError = 'Error el campo Type User es Requerido';
			}
		}
		else{
			this.errortypeUser = false;
		}
		if(this.addUserCarrierForm.get('emailUser').valid !== true){
			this.errorEmail = true;
			if(this.addUserCarrierForm.get('emailUser').errors.minlength !== null){
				this.typeMsgEmail = 'Error en el tamaño requerido de caracteres';
			}
			if(this.addUserCarrierForm.get('emailUser').errors.maxlength !== null){
				this.typeMsgEmail = 'Error en el tamaño requerido de caracteres';
			}
			if(this.addUserCarrierForm.get('emailUser').errors.required !== null && this.addUserCarrierForm.get('typeUser').errors.required !== undefined){
				this.typeMsgEmail = 'Error el campo Type User es Requerido';
			}
		}
		else{
			this.errorEmail = false;
		}
		if(this.addUserCarrierForm.valid === false){
			this.validationAddUserCarrierForm = false;
			this.currentStep = 1;
			this.btnTypeStep2 = "disabled";
		}
		else{
			this.validationAddUserCarrierForm = true;
			this.currentStep = 2;
			this.btnTypeStep2 = "primary";
		}
	}
	saveInformationCarrier(){
		this.stepper.next();
	}
	savePreferencesCarrier(){
		this.stepper.next();
	}
	saveCarrier(){
		let object = {
			carrier_name: this.nameCarrierValue,
			rfc : this.rfcCarrierValue,
			address : this.addressCarrierValue,
			phone_number : this.telephoneCarrierValue,
			package_expiration_time: formatTimeWithLeadingZero(this.hourExpiredPackagedValue.getHours())+':'+formatTimeWithLeadingZero(this.hourExpiredPackagedValue.getMinutes()),
			days_expire_package: parseInt(this.daysExpiredPackagedValue),
			password_expires_time : parseInt(this.whenExpiredCredentialsValue),
			enable_api : this.apiValueValue,
			user_name : this.nameUserValue,
			user_type: this.typeUserValue,
			email : this.emailUserValue,
			acronym: this.acronymCarrierValue
		};
		function formatTimeWithLeadingZero(value: number): string {
			return value < 10 ? '0' + value : value.toString();
		}
		this.modCarrierService.addNewCarrierAdmin(object).then(res=>{
		})
	}
	async getUserTypes() {
		this.types = [];
		let res: any = await this.adminService.getUserTypes(
			localStorage.getItem('language').toLowerCase()
		);
		res.result_list_user.forEach((element) => {
			if (element.id_user_type === 10) {
				this.types.push({
					label: element.user_type,
					value: parseInt(element.id_user_type),
				});
			}
		});
	}
	closeModal(){
		this.dialog.closeAll();
	}
}
