import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'app-client-admin',
	templateUrl: './client-admin.component.html',
	styleUrls: ['./client-admin.component.scss'],
})
export class ClientAdminComponent implements OnInit {
	//columns = ['idClienteCompany', 'email', 'phone', 'fullName', 'actions'];
	columns = [
		'fullName',
		'email',
		'phone',
		'unit',
		'floor_unit',
		'registration_date',
		'actions',
	];
	data;
	lang;
	users = [];

	constructor(
		private adminService: AdminClientService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.lang = localStorage.getItem('language').toLowerCase();
		this.queryConsumer();
	}

	updateInterface(event) {
		if (event == 'true') this.queryConsumer();
	}

	async queryConsumer() {
		let body = {
			language: this.lang,
		};

		// let res: any = await this.adminService.queryConsumerCompany(body);
		// if (res.mensaje_return.ERROR == false) {
		// 	this.users = [];
		// 	res.result_return.forEach((user: any) => {
		// 		//if(user.email.split("@")[1]!="vivipost.com")
		// 		this.users.push({
		// 			//idClienteCompany     : user.id_client_company,
		// 			fullName:
		// 				user.first_name == 'USER'
		// 					? 'N/A'
		// 					: user.first_name + ' ' + user.surname,
		// 			email:
		// 				user.email.split('@')[1] != 'vivipost.com'
		// 					? user.email
		// 					: user.email,
		// 			id: user.id_user,
		// 			phone: user.main_tlf,
		// 			registration_date: user.registration_date,
		// 			id_user_type: user.id_user_type,
		// 			unit: user.unit,
		// 			floor_unit: user.floor_unit,
		// 			//actions             : [ 'far fa-edit', 'far fa-trash-alt', "far fa-file-archive-o"]
		// 			actions: ['far fa-trash-alt'],
		// 		});
		// 	});
		// 	this.data = JSON.stringify(this.users);
		// } else {
		// }
	}

	onTabChanged(event) {
		//Desde aqui se debe hacer para activar los tabuladores y dejarlos activados
	}
}
