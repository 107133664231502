//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
//Modules
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
//Components
import { FooterAdminComponent } from './footer-admin/footer-admin.component';
import { MaterialModule } from '../../../shared/libraries/angular-material/material.module';
import { CardsAdminComponent } from './cards-admin/cards-admin.component';
import { TableOrderssAdminComponent } from './table-orderss-admin/table-orderss-admin.component';
import { LockerGridAdminComponent } from './locker-grid-admin/locker-grid-admin.component';
import { OrdersTableAdminComponent } from './orders-table-admin/orders-table-admin.component';
import { StarRatingAdminComponent } from './star-rating-admin/star-rating-admin.component';
import { ChartClientAdminComponent } from './chart-client-admin/chart-client-admin.component';
import { ChartBarAdminComponent } from './chart-bar-admin/chart-bar-admin.component';
import { CamTableAdminComponent } from './cam-table-admin/cam-table-admin.component';
import { TablePAdminComponent } from './table-p-admin/table-p-admin.component';
import { AuditTableAdminComponent } from './audit-table-admin/audit-table-admin.component';
import { OrderClientAdminComponent } from './order-client-admin/order-client-admin.component';
import { VersionAdminComponent } from './version-admin/version-admin.component';
import { ClientProfileAdminComponent } from './client-profile-admin/client-profile-admin.component';
import { OrderAdminComponent } from './order-admin/order-admin.component';
import { PieChartAdminComponent } from './pie-chart-admin/pie-chart-admin.component';
import { ClientUserAdminComponent } from './client-user-admin/client-user-admin.component';
import { ClientTableAdminComponent } from './client-table-admin/client-table-admin.component';
import { ClientAdminComponent } from './client-admin/client-admin.component';
import { RegisterAdminComponent } from './register-admin/register-admin.component';
import { CreateUserAdminComponent } from './create-user-admin/create-user-admin.component';
import { LockersClientGridAdminComponent } from './lockers-client-grid-admin/lockers-client-grid-admin.component';
import { HistoricalCardsAdminComponent } from './historical-cards-admin/historical-cards-admin.component';
import { MonthlyAdminComponent } from './monthly-admin/monthly-admin.component';
import { PackagesReturnAdminComponent } from './packages-return-admin/packages-return-admin.component';
import { PackagesDeliveredAdminComponent } from './packages-delivered-admin/packages-delivered-admin.component';
import { DoorStatusAdminComponent } from './door-status-admin/door-status-admin.component';
import { LocationAdminComponent } from './location-admin/location-admin.component';
import { SummaryTableAdminComponent } from './summary-table-admin/summary-table-admin.component';
import { CompletedTableAdminComponent } from './completed-table-admin/completed-table-admin.component';
import { RefundTableAdminComponent } from './refund-table-admin/refund-table-admin.component';
import { GeneralTableAdminComponent } from './general-table-admin/general-table-admin.component';
import { PendingTableAdminComponent } from './pending-table-admin/pending-table-admin.component';
import { ExpiredTableAdminComponent } from './expired-table-admin/expired-table-admin.component';
import { ScheduleTableAdminComponent } from './schedule-table-admin/schedule-table-admin.component';
import { CompartmentSizeComponent } from './compartment-size/compartment-size.component';
import { AverageCollectionComponent } from './average-collection/average-collection.component';
import { AveragePickupComponent } from './average-pickup/average-pickup.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CalendarModule } from 'primeng/calendar';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { TableFilterByYearComponent } from './table-filter-by-year/table-filter-by-year.component';
import { TableFilterByDayComponent } from './table-filter-by-day/table-filter-by-day.component';
import { HistoricalPackagesComponent } from './historical-packages/historical-packages.component';
import { PackagesCollectedReturnedComponent } from './packages-collected-returned/packages-collected-returned.component';
import { CalificationsAverageComponent } from './califications-average/califications-average.component';
import { UsageStateComponent } from './usage-state/usage-state.component';
import { PackagesDeliverySizesComponent } from './packages-delivery-sizes/packages-delivery-sizes.component';
import { KpiLockerComponent } from './kpi-locker/kpi-locker.component';
import { OrderMetricsAdminComponent } from './order-metrics-admin/order-metrics-admin.component';
import { TableGeneralAdminComponent } from './table-general-admin/table-general-admin.component';
import { AgGridModule } from 'ag-grid-angular';
import { TableExpiredAdminComponent } from './table-expired-admin/table-expired-admin.component';
import { SelectLockerComponent } from './select-locker/select-locker.component';
import { SelectComponent } from './select/select.component';
import { DropdownModule } from 'primeng/dropdown';
import { EstatusTableOrderAdminComponent } from './estatus-table-order-admin/estatus-table-order-admin.component';
import { LockerMapComponent } from './locker-map/locker-map.component';
import { UserDetailModalComponent } from './user-detail-modal/user-detail-modal.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { ReturnOrdersComponent } from './return-orders/return-orders.component';
import { ReturnLotesComponent } from './return-lotes/return-lotes.component';
import { ReturnClientsComponent } from './return-clients/return-clients.component';
import { DateTextComponent } from './date-text/date-text.component';
import { DateTextExpiredComponent } from './date-text-expired/date-text-expired.component';
import { ActivityTableAdminComponent } from './activity-table-admin/activity-table-admin.component';
import { DialogModule } from 'primeng/dialog';
import { DialogOrderDetailComponent } from './dialog-order-detail/dialog-order-detail.component';
import { EstatusOrderComponent } from './estatus-order/estatus-order.component';
import { DialogRecordatoriosComponent } from './dialog-recordatorios/dialog-recordatorios.component';
import { ClientTableComponent } from './client-table/client-table.component';
import { GridLockerComponent } from './grid-locker/grid-locker.component';
import { EraseOrderComponent } from './erase-order/erase-order.component';
import { ChartsOverviewModule } from './charts/charts-overview.module';
import { DialogEraseOrderComponent } from './dialog-erase-order/dialog-erase-order.component';
import { ConfirmChangeStatusUserComponent } from './confirm-change-status-user/confirm-change-status-user.component';
import { DialogAuditUserComponent } from './dialog-audit-user/dialog-audit-user.component';
import { DialogDeliverPersonallyComponent } from './dialog-deliver-personally/dialog-deliver-personally.component';
import { CardsSummaryComponent } from './cards-summary/cards-summary.component';
import { Grid1Component } from './grid1/grid1.component';
import { Grid2Component } from './grid2/grid2.component';
import { ChartsGrid1Module } from './grid1/charts-grid1/charts-grid1.module';
import { ChartsGrid2Module } from './grid2/charts-grid2/charts-grid2.module';
import { ChartDonutSummaryComponent } from './chart-donut-summary/chart-donut-summary.component';
import { TableUserComponent } from './table-user/table-user.component';
import { TableSupervisorComponent } from './table-supervisor/table-supervisor.component';
import { TableLocalAdminsComponent } from './table-local-admins/table-local-admins.component';
import { TestModule } from 'src/app/shared/test/test.module';
import { TableSuperAdminComponent } from './table-super-admin/table-super-admin.component';
import { TableCarrierAdminComponent } from './table-carrier-admin/table-carrier-admin.component';
import { DialogCarrierDetailComponent } from './dialog-carrier-detail/dialog-carrier-detail.component';
import { TableCarrierOrderComponent } from './table-carrier-order/table-carrier-order.component';
import { TableCarrierUserComponent } from './table-carrier-user/table-carrier-user.component';
import { AddNewCarrierComponent } from './add-new-carrier/add-new-carrier.component';
import { DialogChangeStatusCarrierComponent } from './dialog-change-status-carrier/dialog-change-status-carrier.component';
import { PhoneFormatDirective } from './dialogs-admin/order-create/phone-format.directive';
import { ClientsMetricsAdminComponent } from './clients-metrics-admin/clients-metrics-admin.component';
import { DialogImageLockerComponent } from './dialog-image-locker/dialog-image-locker.component';
import { OxxoToOxxoGeneralComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-general/oxxo-to-oxxo-general.component';
import { OxxoToOxxoWarehouseComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-warehouse/oxxo-to-oxxo-warehouse.component';
import { OxxoToOxxoExpiredComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-expired/oxxo-to-oxxo-expired.component';
import { OxxoToOxxoScheduledComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-scheduled/oxxo-to-oxxo-scheduled.component';
import { EstatusOxxoToOxxoComponent } from './tables-oxxo-to-oxxo/estatus-oxxo-to-oxxo/estatus-oxxo-to-oxxo.component';
import { DialogOxxoToOxxoDetailComponent } from './dialog-oxxo-to-oxxo-detail/dialog-oxxo-to-oxxo-detail.component';
import { DialogEstatusOxxoToOxxoComponent } from './tables-oxxo-to-oxxo/dialog-estatus-oxxo-to-oxxo/dialog-estatus-oxxo-to-oxxo.component';
import { EstatusTableCarrierOrderComponent } from './estatus-table-carrier-order/estatus-table-carrier-order.component';
import { TableGeneralCarrierComponent } from './tables-carriers/table-general-carrier/table-general-carrier.component';
import { TableExpiredCarrierComponent } from './tables-carriers/table-expired-carrier/table-expired-carrier.component';
import { TableScheduledCarrierComponent } from './tables-carriers/table-scheduled-carrier/table-scheduled-carrier.component';
import { TableUserSupervisorCarrierComponent } from './tables-carriers/table-user-supervisor-carrier/table-user-supervisor-carrier.component';
import { TableUserLocalAdminCarrierComponent } from './tables-carriers/table-user-local-admin-carrier/table-user-local-admin-carrier.component';
import { TableUserUsersCarrierComponent } from './tables-carriers/table-user-users-carrier/table-user-users-carrier.component';
import { TableUserCarrierAdminCarrierComponent } from './tables-carriers/table-user-carrier-admin-carrier/table-user-carrier-admin-carrier.component';
import { TableUserSuperAdminCarrierComponent } from './tables-carriers/table-user-super-admin-carrier/table-user-super-admin-carrier.component';
import { OxxoToOxxoInLockerComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-in-locker/oxxo-to-oxxo-in-locker.component';
import { OxxoToOxxoReturnsComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-returns/oxxo-to-oxxo-returns.component';
import { FilterOxxoToOxxoComponent } from './tables-oxxo-to-oxxo/filter-oxxo-to-oxxo/filter-oxxo-to-oxxo.component';
import { FilterOrdersComponent } from './filter-orders/filter-orders.component';
import { FilterClientsComponent } from './filter-clients/filter-clients.component';
import { FilterCarriersComponent } from './filter-carriers/filter-carriers.component';
import { FilterCarriersOrdersComponent } from './filter-carriers-orders/filter-carriers-orders.component';
import { TableAdminUserComponent } from './tables-users/table-admin-user/table-admin-user.component';
import { TableEyrUserComponent } from './tables-users/table-eyr-user/table-eyr-user.component';
import { TableCccUserComponent } from './tables-users/table-ccc-user/table-ccc-user.component';
import { TablesSuperAdminUserComponent } from './tables-users/tables-super-admin-user/tables-super-admin-user.component';
import { TableCompletedComponent } from './tables/table-completed/table-completed.component';
import { TablePendingComponent } from './tables/table-pending/table-pending.component';
import { TableReturnedComponent } from './tables/table-returned/table-returned.component';
import { TableGeneralComponent } from './tables/table-general/table-general.component';
import { EstatusTableLogsComponent } from './estatus-table-logs/estatus-table-logs.component';
import { EditUserRendererComponent } from './edit-user-renderer/edit-user-renderer.component';
import { DialogUpdateUserComponent } from './dialog-update-user/dialog-update-user.component';
import { DoorStatusGenerateTockenComponent } from './door-status-generate-tocken/door-status-generate-tocken.component';
import { LockerGridGenerateTockenComponent } from './locker-grid-generate-tocken/locker-grid-generate-tocken.component';
import { TablePropertiesComponent } from './table-properties/table-properties.component';
import { KpiLockerAccessPointComponent } from './kpi-locker-access-point/kpi-access-point.component';
import { TableAccessPointComponent } from './table-access-point/table-access-point.component';
import { KpiAccessPointFamilyComponent } from './kpi-locker-access-point-family/kpi-access-point-family.component';
import { TableAccessPointFamilyComponent } from './table-access-point-family/table-access-point-family.component';
import { TableAllComponent } from './table-inventory/table-all/table-all.component';
import { TableReadyToInstallComponent } from './table-inventory/table-ready-to-install/table-ready-to-install.component';
import { TableNotPairedComponent } from './table-inventory/table-not-paired/table-not-paired.component';
import { TableUserAllFetchComponent } from './table-user-fetch/table-user-all-fetch/table-user-all-fetch.component';
import { TableUserInstallerFetchComponent } from './table-user-fetch/table-user-installer-fetch/table-user-installer-fetch.component';
import { TableUserAdminFetchComponent } from './table-user-fetch/table-user-admin-fetch/table-user-admin-fetch.component';
import { EstatusTableInventoryComponent } from './table-inventory/estatus-table-inventory/estatus-table-inventory.component';
import { EstatusTableAccessPointComponent } from './estatus-table-access-point/estatus-table-access-point.component';

@NgModule({
	declarations: [
		FooterAdminComponent,
		CardsAdminComponent,
		LockerGridAdminComponent,
		OrdersTableAdminComponent,
		StarRatingAdminComponent,
		ChartClientAdminComponent,
		ChartBarAdminComponent,
		CamTableAdminComponent,
		TablePAdminComponent,
		AuditTableAdminComponent,
		OrderClientAdminComponent,
		VersionAdminComponent,
		ClientProfileAdminComponent,
		OrderAdminComponent,
		PieChartAdminComponent,
		ClientUserAdminComponent,
		ClientTableAdminComponent,
		ClientAdminComponent,
		RegisterAdminComponent,
		CreateUserAdminComponent,
		LockersClientGridAdminComponent,
		HistoricalCardsAdminComponent,
		MonthlyAdminComponent,
		PackagesReturnAdminComponent,
		PackagesDeliveredAdminComponent,
		DoorStatusAdminComponent,
		LocationAdminComponent,
		SummaryTableAdminComponent,
		CompletedTableAdminComponent,
		RefundTableAdminComponent,
		GeneralTableAdminComponent,
		PendingTableAdminComponent,
		ExpiredTableAdminComponent,
		ScheduleTableAdminComponent,
		CompartmentSizeComponent,
		AverageCollectionComponent,
		AveragePickupComponent,
		MonthPickerComponent,
		TableFilterByYearComponent,
		TableFilterByDayComponent,
		TableOrderssAdminComponent,
		HistoricalPackagesComponent,
		PackagesCollectedReturnedComponent,
		CalificationsAverageComponent,
		UsageStateComponent,
		PackagesDeliverySizesComponent,
		KpiLockerComponent,
		OrderMetricsAdminComponent,
		TableGeneralAdminComponent,
		TableExpiredAdminComponent,
		SelectLockerComponent,
		SelectComponent,
		EstatusTableOrderAdminComponent,
		LockerMapComponent,
		UserDetailModalComponent,
		AddNewUserComponent,
		ReturnOrdersComponent,
		ReturnLotesComponent,
		ReturnClientsComponent,
		DateTextComponent,
		DateTextExpiredComponent,
		ActivityTableAdminComponent,
		DialogOrderDetailComponent,
		EstatusOrderComponent,
		DialogRecordatoriosComponent,
		ClientTableComponent,
		GridLockerComponent,
		EraseOrderComponent,
		DialogEraseOrderComponent,
		EraseOrderComponent,
		ConfirmChangeStatusUserComponent,
		DialogAuditUserComponent,
		DialogDeliverPersonallyComponent,
		CardsSummaryComponent,
		Grid1Component,
		Grid2Component,
		ChartDonutSummaryComponent,
		TableUserComponent,
		TableSupervisorComponent,
		TableLocalAdminsComponent,
		TableCarrierAdminComponent,
		TableSuperAdminComponent,
		DialogCarrierDetailComponent,
		TableCarrierOrderComponent,
		TableCarrierUserComponent,
		AddNewCarrierComponent,
		DialogChangeStatusCarrierComponent,
		ClientsMetricsAdminComponent,
		DialogImageLockerComponent,
		OxxoToOxxoGeneralComponent,
		OxxoToOxxoWarehouseComponent,
		OxxoToOxxoExpiredComponent,
		OxxoToOxxoScheduledComponent,
		OxxoToOxxoInLockerComponent,
		OxxoToOxxoReturnsComponent,
		EstatusOxxoToOxxoComponent,
		DialogOxxoToOxxoDetailComponent,
		DialogEstatusOxxoToOxxoComponent,
		EstatusTableCarrierOrderComponent,
		TableGeneralCarrierComponent,
		TableExpiredCarrierComponent,
		TableScheduledCarrierComponent,
		TableUserSupervisorCarrierComponent,
		TableUserLocalAdminCarrierComponent,
		TableUserUsersCarrierComponent,
		TableUserCarrierAdminCarrierComponent,
		TableUserSuperAdminCarrierComponent,
		FilterOxxoToOxxoComponent,
		FilterOrdersComponent,
		FilterClientsComponent,
		FilterCarriersComponent,
		FilterCarriersOrdersComponent,
		TableAdminUserComponent,
		TableEyrUserComponent,
		TableCccUserComponent,
		TablesSuperAdminUserComponent,
		TableCompletedComponent,
		TablePendingComponent,
		TableReturnedComponent,
		TableGeneralComponent,
		EstatusTableLogsComponent,
		EditUserRendererComponent,
		DialogUpdateUserComponent,
		DoorStatusGenerateTockenComponent,
		LockerGridGenerateTockenComponent,
		TablePropertiesComponent,
		KpiLockerAccessPointComponent,
		TableAccessPointComponent,
		KpiAccessPointFamilyComponent,
		TableAccessPointFamilyComponent,
  TableAllComponent,
  TableReadyToInstallComponent,
  TableNotPairedComponent,
  TableUserAllFetchComponent,
  TableUserInstallerFetchComponent,
  TableUserAdminFetchComponent,
  EstatusTableInventoryComponent,
  EstatusTableAccessPointComponent
	],
	exports: [
		FooterAdminComponent,
		CardsAdminComponent,
		LockerGridAdminComponent,
		OrdersTableAdminComponent,
		StarRatingAdminComponent,
		ChartClientAdminComponent,
		ChartBarAdminComponent,
		CamTableAdminComponent,
		TablePAdminComponent,
		AuditTableAdminComponent,
		OrderClientAdminComponent,
		VersionAdminComponent,
		ClientProfileAdminComponent,
		OrderAdminComponent,
		PieChartAdminComponent,
		ClientUserAdminComponent,
		ClientTableAdminComponent,
		ClientAdminComponent,
		RegisterAdminComponent,
		CreateUserAdminComponent,
		LockersClientGridAdminComponent,
		HistoricalCardsAdminComponent,
		MonthlyAdminComponent,
		PackagesReturnAdminComponent,
		PackagesDeliveredAdminComponent,
		DoorStatusAdminComponent,
		LocationAdminComponent,
		SummaryTableAdminComponent,
		CompletedTableAdminComponent,
		RefundTableAdminComponent,
		GeneralTableAdminComponent,
		PendingTableAdminComponent,
		ExpiredTableAdminComponent,
		ScheduleTableAdminComponent,
		CompartmentSizeComponent,
		AverageCollectionComponent,
		AveragePickupComponent,
		MonthPickerComponent,
		TableFilterByYearComponent,
		TableFilterByDayComponent,
		TableOrderssAdminComponent,
		HistoricalPackagesComponent,
		PackagesCollectedReturnedComponent,
		CalificationsAverageComponent,
		UsageStateComponent,
		PackagesDeliverySizesComponent,
		KpiLockerComponent,
		OrderMetricsAdminComponent,
		TableGeneralAdminComponent,
		TableExpiredAdminComponent,
		SelectLockerComponent,
		SelectComponent,
		EstatusTableOrderAdminComponent,
		LockerMapComponent,
		UserDetailModalComponent,
		AddNewUserComponent,
		ReturnOrdersComponent,
		ReturnLotesComponent,
		ReturnClientsComponent,
		DateTextComponent,
		DateTextExpiredComponent,
		ActivityTableAdminComponent,
		DialogOrderDetailComponent,
		EstatusOrderComponent,
		DialogRecordatoriosComponent,
		ClientTableComponent,
		GridLockerComponent,
		EraseOrderComponent,
		DialogEraseOrderComponent,
		EraseOrderComponent,
		ConfirmChangeStatusUserComponent,
		DialogAuditUserComponent,
		DialogDeliverPersonallyComponent,
		CardsSummaryComponent,
		Grid1Component,
		Grid2Component,
		ChartDonutSummaryComponent,
		TableUserComponent,
		TableSupervisorComponent,
		TableLocalAdminsComponent,
		TableCarrierAdminComponent,
		TableSuperAdminComponent,
		DialogCarrierDetailComponent,
		TableCarrierOrderComponent,
		TableCarrierUserComponent,
		AddNewCarrierComponent,
		DialogChangeStatusCarrierComponent,
		ClientsMetricsAdminComponent,
		DialogImageLockerComponent,
		OxxoToOxxoGeneralComponent,
		OxxoToOxxoWarehouseComponent,
		OxxoToOxxoExpiredComponent,
		OxxoToOxxoScheduledComponent,
		OxxoToOxxoInLockerComponent,
		OxxoToOxxoReturnsComponent,
		EstatusOxxoToOxxoComponent,
		DialogOxxoToOxxoDetailComponent,
		DialogEstatusOxxoToOxxoComponent,
		EstatusTableCarrierOrderComponent,
		TableGeneralCarrierComponent,
		TableExpiredCarrierComponent,
		TableScheduledCarrierComponent,
		TableUserSupervisorCarrierComponent,
		TableUserLocalAdminCarrierComponent,
		TableUserUsersCarrierComponent,
		TableUserCarrierAdminCarrierComponent,
		TableUserSuperAdminCarrierComponent,
		FilterOxxoToOxxoComponent,
		FilterOrdersComponent,
		FilterClientsComponent,
		FilterCarriersComponent,
		FilterCarriersOrdersComponent,
		TableAdminUserComponent,
		TableEyrUserComponent,
		TableCccUserComponent,
		TablesSuperAdminUserComponent,
		TableCompletedComponent,
		TablePendingComponent,
		TableReturnedComponent,
		TableGeneralComponent,
		EstatusTableLogsComponent,
		EditUserRendererComponent,
		DialogUpdateUserComponent,
		DoorStatusGenerateTockenComponent,
		LockerGridGenerateTockenComponent,
		TableAllComponent,
		TableReadyToInstallComponent,
		TableNotPairedComponent,
		TablePropertiesComponent,
		TableUserAllFetchComponent,
		TableUserInstallerFetchComponent,
		TableUserAdminFetchComponent,

		KpiLockerAccessPointComponent,
		TableAccessPointComponent,

		KpiAccessPointFamilyComponent,
		TableAccessPointFamilyComponent,
		EstatusTableInventoryComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		NgApexchartsModule,
		SharedModule,
		HighchartsChartModule,
		TranslateModule,
		RouterModule,
		NgxPaginationModule,
		MatDatepickerModule,
		MatNativeDateModule,
		CalendarModule,
		AgGridModule,
		DropdownModule,
		DialogModule,
		ChartsOverviewModule,
		ChartsGrid1Module,
		ChartsGrid2Module,
		TestModule
	]
})
export class ComponentsAdminClientModule {}
