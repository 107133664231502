import { Component, ViewChild } from '@angular/core';

import {
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexChart,
	ApexLegend,
	ApexResponsive,
	ChartComponent,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	labels: string[];
	colors: string[];
	legend: ApexLegend;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive | ApexResponsive[];
};

@Component({
	selector: 'app-headquarters-grid2',
	templateUrl: './headquarters-grid2.component.html',
	styleUrls: ['./headquarters-grid2.component.scss'],
})
export class HeadquartersGrid2Component {
	@ViewChild('chart') chart: ChartComponent;
	public chartOptions: Partial<ChartOptions>;
	totalSeries: number;
	tooltipHeadquarters: string;

	constructor() {
		this.chartOptions = {
			chart: {
				height: 240,
				type: 'donut',
			},
			series: [50, 90, 40],
			plotOptions: {
				pie: {
					startAngle: -90,
					endAngle: 90,
					offsetY: 20,
					dataLabels: {
						minAngleToShowLabel: 9999, // Establece un valor muy alto para que ninguna etiqueta se muestre
					},
				},
			},
			colors: [
				'var(--color-chart-300)',
				'var(--color-chart-200)',
				'var(--color-chart-100)',
			],
			labels: ['Puebla', 'Queretaro', 'Baja'],
			legend: {
				show: true,
				floating: true,
				fontSize: 'var(--font-c3)',
				width: 200,
				position: 'bottom',
				fontFamily: 'var(--primary-font)',
				offsetX: -20,
				offsetY: -25,
				horizontalAlign: 'center',
				labels: {
					useSeriesColors: true,
				},
				formatter: function (seriesName, opts) {
					return (
						seriesName +
						':  ' +
						opts.w.globals.series[opts.seriesIndex]
					);
				},
				itemMargin: {
					horizontal: 3,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						legend: {
							show: false,
						},
					},
				},
			],
		};
		this.totalSeries = this.chartOptions.series.reduce((a, b) => a + b, 0);
	}
}
