<div class="row">
	<div class="col-10">
		<div class="input-container">
			<input type="text" class="input-gbl" (keyup)="onFilterTextChange($event)" placeholder="{{'dashboard.search_user' | translate}}">
			<button type="submit" class="btn-search"> <div class="icon-search"></div></button>
		</div>
	</div>
	<div class="col-2 text-end">
		<button-export (click)="onExport()"></button-export>
	</div>

</div>

<div class="row">
	<div class="table-responsive">
		<ag-grid-angular #agGrid
			class="ag-theme-alpine"
			[gridOptions]="this.gridOptions"
			[rowData]="this.superAdmin"
			[columnDefs]="this.columnDefs"
			[quickFilterText]="this.filterText"
			style="width: 100%; height: 750px;  margin-top:24px">
		</ag-grid-angular>
	</div>
</div>
