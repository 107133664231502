<div class="card" *ngIf="this.kpi_boolean === false && kpi_money === false">
    <div class="number-value">
        {{number_value}}
    </div>
    <div class="text-value">
        {{text_value}}
    </div>
</div>
<div class="card" *ngIf="kpi_boolean === true && kpi_money === false">
    <div class="kpi-value">
        <div class="row">
            <div class="col-12 height-kpi-header">
                <p class="kpi-margin" [ngClass]="{'font-header-kpi-success': kpi_value > 0, 'font-header-kpi-danger': kpi_value < 0}">
                    <!-- ARROW -->
                    <i class="fa fa-solid fa-arrow-right" [ngClass]="{'arrow-success': kpi_value > 0, 'arrow-danger': kpi_value < 0}">
    
                    </i>
                    {{ kpi_value }}%
                    <span class="span-kpi"> {{kpi_text}} </span>
                </p>
            </div>
        </div>
    </div>
    <div class="number-value-kpi">
        {{number_value}}
    </div>
    <div class="text-value-kpi">
        {{text_value}}
    </div>
</div>
<div class="card" *ngIf="kpi_money === true">
    <div class="number-value">
        {{kpi_money_value | currency:'USD':'symbol':'1.0'}}
    </div>
    <div class="text-value">
        {{kpi_money_text}}
    </div>
</div>