import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import Highcharts from 'highcharts';

@Component({
	selector: 'app-chart-client-admin',
	templateUrl: './chart-client-admin.component.html',
	styleUrls: ['./chart-client-admin.component.scss'],
})
export class ChartClientAdminComponent implements OnInit {
	chartOptions: {};
	@Input() data;
	@Input() minute;
	@Input() type;
	@Input() months: string;
	meses;
	Highcharts = Highcharts;
	heigthgraphics = 340;
	quantity = [];
	lang;
	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		this.quantity = [];
		this.lang = localStorage.getItem('language');
		this.meses =
			this.lang === 'EN'
				? [
						'Jan',
						'Feb',
						'Mar',
						'Apr',
						'May',
						'Jun',
						'Jul',
						'Aug',
						'Sep',
						'Oct',
						'Nov',
						'Dec',
				  ]
				: [
						'Ene',
						'Feb',
						'Mar',
						'Abr',
						'May',
						'Jun',
						'Jul',
						'Ago',
						'Sep',
						'Oct',
						'Nov',
						'Dic',
				  ];

		if (this.data) {
			let datos: any[] = JSON.parse(this.data);
			datos.forEach((element) => {
				if (this.type === 'dashboard' || this.type === 'orders') {
					this.quantity.push(element.orders || element);
				}
			});
		}

		const categories = this.meses ? this.meses : [''];
		const labelsStyle = {
			color: '#000000',
			fontFamily: 'Noto-Ikea-Regular',
			fontSize: '14px',
			fontWeight: 'bold',
		};

		if (this.type === 'dashboard') {
			this.chartOptions = this.getChartOptions(categories, labelsStyle);
		} else if (this.type === 'orders') {
			this.chartOptions = this.getChartOptions(categories, {
				...labelsStyle,
				fontFamily: 'Font-Roboto',
			});
		} else if (this.type === 'equipment') {
			const xAxisCategories = this.minute
				? JSON.parse(this.minute)
				: ['0:00'];
			this.chartOptions = this.getChartOptions(xAxisCategories, {
				...labelsStyle,
				fontFamily: 'Font-Roboto',
			});
		} else {
			this.setChart();
		}
	}

	private getChartOptions(xAxisCategories: string[], labelsStyle: any) {
		return {
			title: { text: '' },
			xAxis: {
				categories: xAxisCategories,
				labels: { style: labelsStyle },
			},
			yAxis: { title: { text: '' } },
			legend: { enabled: false },
			plotOptions: { series: { label: { connectorAllowed: true } } },
			series: [
				{
					lineWidth: 3,
					marker: { enabled: true },
					color: '#5B8FF9',
					name: '',
					data: this.quantity ? this.quantity : [''],
					dataLabels: { enabled: true },
				},
			],
			credits: { enabled: false },
		};
	}
	setChart() {
		this.chartOptions;
	}
}
