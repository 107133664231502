<div [ngClass]="tableClass">
    <div class="grid-header">
        <div class="grid-header-column-1">
            <div class="input-container">
                <input [(ngModel)]="filterText" type="text" class="input-gbl" (keyup)="onFilterTextChange($event)" placeholder="{{'dashboard.search_user' | translate}}">
                <button type="submit" class="btn-search">
					<div class="icon-search">
					</div>
				</button>
            </div>
        </div>
        <div class="grid-header-column-2">
            <calendar class="{{calendar}}"></calendar>
            <filter-funnel class="{{filterFunnel}}" ></filter-funnel>
            <button-export class="{{btnExport}}" (click)="onExport()"></button-export>
        </div>
    </div>


	<div>
		<ag-grid-angular
			#agGrid
			class="ag-theme-alpine"
			[gridOptions]="this.gridOptions"
			[rowData]="this.rowData"
			[columnDefs]="this.columnDefs"
			[quickFilterText]="this.filterText"
			[animateRows]="true"
			style="width: 100%; height: 740px;"
			(gridReady)="onGridReady($event)">
		</ag-grid-angular>
	</div>

</div>


