<!--? CONTAINER TABLE} -->
<div class="container-table">
    <!--? GRID HEADER | COLUMN 1  -->
    <div [ngClass]="{
        'spacing-between-rows' : mainFilter }">
        <!--? INPUT MAIN SEARCH -->
        <div [ngClass]="{
            'grid-header-column-1' : mainFilter,
            'none' : !mainFilter}">
            <!--? SEARCH -->
            <div class="input-container">
                <!-- INPUT -->
                <input
                    type="text"
                    class="input-gbl"
                    placeholder="{{'dashboard.search_user' | translate}}"
                    [(ngModel)]="searchTxt">

                <!--? BTN SEARCH -->
                <button type="submit" class="btn-search">
                    <!-- ICON SEARCH -->
                    <div class="icon-search"></div>

                </button>

            </div>

        </div>

        <!--? HEADER COMPONENTS | COLUMN 2 -->
        <div class="grid-header-column-2">
        <!-- CALENDAR -->
            <calendar
                [ngClass]="{
                '' : calendar,
                'none' : !calendar}">
            </calendar>

        <!-- FUNNEL -->
            <filter-funnel
                [ngClass]="{
                '' : filterFunnel,
                'none' : !filterFunnel}">
            </filter-funnel>

        <!-- BUTTON EXPORT -->
            <button-export
                [ngClass]="{
                '' : btnExport,
                'none' : !btnExport}">
            </button-export>

        </div>

    </div>

    <!--? TABLE GRID -->
    <div class="row-2">
        <!--? TABLE -->
        <table class="table" aria-label="nueva Tabla">
           <!--? TABLE HEAD -->
           <thead>
                <!--? TABLE ROW -->
                <tr class="tr-grid" [style.grid-template-columns] ="'repeat(' + this.columnlength + ', 1fr)'">
                <!--? TABLE HEADER -->
                    <th *ngFor="let th of tableHeader; let i = index"
                        class="grid-th"
                        (click)="sortArray(th.filtro)">
                    <!--? BTN SORT -->
                        <button *ngIf="sortBtn">
                            <!-- ICONS ARROW | UP | DOWN-->
                            <div [ngClass]=" {
                                'icon-arrow-up' : ascending ,
                                'icon-arrow-down' : !ascending }">
                            </div>

                        </button>

                        <!-- TH ITERATION -->
                        <span>
                            {{th.th | translate}}
                        </span>

                    </th>

                </tr>

           </thead>

           <!--? TABLE BODY -->
           <tbody *ngIf="!searchTxt">
               <!--? TABLE ROW -->
                <tr class="tr-grid"
                    *ngFor="let td of tableData | slice: page : page + pageLenght"
                    [style.grid-template-columns]="'repeat(' + this.columnlength + ', 1fr)'">
                    <!--? TABLE DATA -->
                    <td *ngFor="let header of tableHeader">
                        <!-- TD | INPUT STYLE | ROUTER LINK -->
                        <span   *ngIf="!header.starRating && !header.statusLocker && !header.statusLockerId && !header.statusLogs"
                                [ngClass]="{
                                'input-style'           :header.input === true,
                                'cursor-pointer'        :header.tooltip,
                                'router-link'           :header.route,
                                'btn-style'             :header.btn }"
                                [routerLink]="td[header.route]">
                                <!-- TXT -->
                                {{ td[header.filtro] }}
                        </span>
                         <!--? ICONS INFO -->
                        <div [ngClass]="{'icon-info': header.tooltipInfo}">
                            <!-- TOOLTIP CENTER SM -->
                            <tooltip
                                class="tooltip"
                                [position]="tooltipPosition"
                                [tooltip]="header.tooltipInfo">
                            </tooltip>
                        </div>


                        <!-- TOOLTIP ACTIVE -->
                        <div    *ngIf="!header.starRating"
                                [ngClass]="{
                                'tooltip-active'    :header.tooltip,
                                'none'              :!header.tooltip }">
                                {{ td[header.tooltip] }}
                        </div>

                         <!-- TD STATUS LOCKER ID  | EXPIRED | EXTENDED | SCHEDULE | COLLECTED | ON HOLD | ON LOCKER -->
                        <span
                                *ngIf="!header.starRating && !header.statusLocker && !header.statusLogs && header.statusLockerId"
                                [ngClass]="{
                                'status-success'        :td[header.statusLockerId] === 1,
                                'status-warn'           :td[header.statusLockerId] === 2,
                                'status-danger'         :td[header.statusLockerId] === 3,
                                'status-available'      :td[header.statusLockerId] === 4,
                                'status-info'           :td[header.statusLockerId] === 5 }"
                                [routerLink]="header.route">
                                <!-- TXT -->
                                {{
                                (td[header.statusLockerId] === 1 ? 'status.lockerID.collected'  :
                                (td[header.statusLockerId] === 2 ? 'status.lockerID.schedule'   :
                                (td[header.statusLockerId] === 3 ? 'status.lockerID.extended'   :
                                (td[header.statusLockerId] === 4 ? 'status.lockerID.onHold'     :
                                (td[header.statusLockerId] === 5 ? 'status.lockerID.onLocker'   : ''))))) | translate }}
                        </span>

                        <!-- TD STATUS LOCKER ID | EXPIRED -->
                        <span
                                *ngIf="!header.starRating && !header.statusLocker && !header.statusLogs && header.statusLockerId"
                                [ngClass]="{
                                'status-danger'     :td[header.statusLockerId] === 6 }"
                                [routerLink]="header.route">
                                <!-- TXT -->
                                {{
                                (td[header.statusLockerId] === 6 ? 'status.lockerID.expired'   : '') | translate }}

                                <!-- ICON CHEVRON -->
                                <div [ngClass]="{
                                    'icon-chevron'     :td[header.statusLockerId] === 6 }">
                                </div>
                        </span>

                        <!-- TD STATUS LOCKER  | ACTIVE | INACTIVE -->
                        <span
                            *ngIf="!header.starRating && !header.statusLockerId && !header.statusLogs && header.statusLocker"
                            [ngClass]="{
                            'status-success'        :td[header.statusLocker] === 1,
                            'status-danger'         :td[header.statusLocker] === 2 }"
                            [routerLink]="header.route">
                            <!-- TXT -->
                            {{
                            (td[header.statusLocker] === 1 ? 'status.locker.active'   :
                            (td[header.filtro]       === 2 ? 'status.locker.inactive' : '')) | translate }}
                        </span>

                        <!-- TD STATUS LOGS  | PACKET COLLECTED | PACKET SCHEDULE | DOOR OPEN | PACKET EXPIRED | FAULTY DOOR -->
                        <span
                            *ngIf="!header.starRating && !header.statusLockerId && !header.statusLocker && header.statusLogs"
                            [ngClass]="{
                                'status-success'        :td[header.statusLogs] === 1,
                                'status-warn'           :td[header.statusLogs] === 2,
                                'status-available'      :td[header.statusLogs] === 3,
                                'status-danger'         :td[header.statusLogs] === 4,
                                'status-muted'          :td[header.statusLogs] === 5 }"
                                [routerLink]="header.route">
                                <!-- TXT -->
                                {{
                                (td[header.statusLogs] === 1 ? 'status.logs.collected'  :
                                (td[header.statusLogs] === 2 ? 'status.logs.schedule'   :
                                (td[header.statusLogs] === 3 ? 'status.logs.doorOpen'   :
                                (td[header.statusLogs] === 4 ? 'status.logs.expired'    :
                                (td[header.statusLogs] === 5 ? 'status.logs.faulty'     : ''))))) | translate }}
                        </span>

                        <!-- STARS -->
                        <stars
                            *ngIf="header.starRating"
                            [stars]="stars"
                            [rating]="td[header.starRating]">
                        </stars>

                    </td>

                </tr>

            </tbody>

            <!--? TBODY | ON SEARCH -->
           <tbody *ngIf="searchTxt">
                <!--? TABLE ROW | ON SEARCH -->
                <tr
                    class="tr-grid"
                    *ngFor="let td of tableData | search: searchTxt:page:pageLenght"
                    [style.grid-template-columns]="'repeat(' + this.columnlength + ', 1fr)'">
                    <!-- TD | ON SEARCH -->
                    <td *ngFor="let header of tableHeader">
                          <!-- TD | INPUT STYLE | ROUTER LINK -->
                        <span   *ngIf="!header.starRating && !header.statusLocker && !header.statusLockerId && !header.statusLogs"
                                [ngClass]="{
                                'input-style'       :header.input === true,
                                'cursor-pointer'       :header.tooltip,
                                'router-link'       :header.route }"
                                [routerLink]="td[header.route]">
                                <!-- TXT -->
                                {{ td[header.filtro] }}
                        </span>

                        <!-- TOOLTIP ACTIVE -->
                        <div    *ngIf="!header.starRating"
                                [ngClass]="{
                                'tooltip-active'    :header.tooltip,
                                'none'              :!header.tooltip }">
                                {{ td[header.tooltip] }}
                        </div>
                         <!-- TD STATUS LOCKER ID  | EXPIRED | EXTENDED | SCHEDULE | COLLECTED | ON HOLD | ON LOCKER -->
                        <span
                                *ngIf="!header.starRating && !header.statusLocker && !header.statusLogs && header.statusLockerId"
                                [ngClass]="{
                                'status-success'        :td[header.statusLockerId] === 1,
                                'status-warn'           :td[header.statusLockerId] === 2,
                                'status-danger'         :td[header.statusLockerId] === 3,
                                'status-available'      :td[header.statusLockerId] === 4,
                                'status-info'           :td[header.statusLockerId] === 5 }"
                                [routerLink]="header.route">
                                <!-- TXT -->
                                {{
                                (td[header.statusLockerId] === 1 ? 'status.lockerID.collected'  :
                                (td[header.statusLockerId] === 2 ? 'status.lockerID.schedule'   :
                                (td[header.statusLockerId] === 3 ? 'status.lockerID.extended'   :
                                (td[header.statusLockerId] === 4 ? 'status.lockerID.onHold'     :
                                (td[header.statusLockerId] === 5 ? 'status.lockerID.onLocker'   : ''))))) | translate }}
                        </span>
                        <!-- TD STATUS LOCKER ID | EXPIRED -->
                        <span
                                *ngIf="!header.starRating && !header.statusLocker && !header.statusLogs && header.statusLockerId"
                                [ngClass]="{
                                'status-danger'     :td[header.statusLockerId] === 6 }"
                                [routerLink]="header.route">
                                <!-- TXT -->
                                {{
                                (td[header.statusLockerId] === 6 ? 'status.lockerID.expired'   : '') | translate }}

                                <!-- ICON CHEVRON -->
                                <div [ngClass]="{
                                    'icon-chevron'     :td[header.statusLockerId] === 6 }">
                                </div>
                        </span>

                        <!-- TD STATUS LOCKER  | ACTIVE | INACTIVE -->
                        <span
                            *ngIf="!header.starRating && !header.statusLockerId && !header.statusLogs && header.statusLocker"
                            [ngClass]="{
                            'status-success'        :td[header.statusLocker] === 1,
                            'status-danger'         :td[header.statusLocker] === 2 }"
                            [routerLink]="header.route">
                            <!-- TXT -->
                            {{
                            (td[header.statusLocker] === 1 ? 'status.locker.active'   :
                            (td[header.filtro]       === 2 ? 'status.locker.inactive' : '')) | translate }}
                        </span>

                        <!-- TD STATUS LOGS  | PACKET COLLECTED | PACKET SCHEDULE | DOOR OPEN | PACKET EXPIRED | FAULTY DOOR -->
                        <span
                            *ngIf="!header.starRating && !header.statusLockerId && !header.statusLocker && header.statusLogs"
                            [ngClass]="{
                                'status-success'        :td[header.statusLogs] === 1,
                                'status-warn'           :td[header.statusLogs] === 2,
                                'status-available'      :td[header.statusLogs] === 3,
                                'status-danger'         :td[header.statusLogs] === 4,
                                'status-muted'          :td[header.statusLogs] === 5 }"
                                [routerLink]="header.route">
                                <!-- TXT -->
                                {{
                                (td[header.statusLogs] === 1 ? 'status.logs.collected'  :
                                (td[header.statusLogs] === 2 ? 'status.logs.schedule'   :
                                (td[header.statusLogs] === 3 ? 'status.logs.doorOpen'   :
                                (td[header.statusLogs] === 4 ? 'status.logs.expired'    :
                                (td[header.statusLogs] === 5 ? 'status.logs.faulty'     : ''))))) | translate }}
                        </span>

                    </td>

                </tr>

            </tbody>

        </table>

    </div>

</div>

<!--? PAGINATION -->
<div [ngClass]="{
    'pagination-table' : pagination,
    'none' : !pagination }">
    <!--? PAGINATION COLLAPSE -->
    <div class="pagination-collapse">
        <!-- ELEMENTS -->
        <span>Elementos por página</span>

        <!--? COLLAPSE DATA TABLE -->
        <div class="collapse-dataTable">
            {{pageLenght}}
            <!--  ICON CHEVRON -->
            <div class="down-chevron"></div>

        </div>

    </div>

    <!--? BTNS PAGINATION -->
    <div class="butons-pagination">
        <!--? PREV -->
        <button (click)="prevPage()" [ngClass]="{ 'icon-disable' : page == 0 }">
            <!-- ICON PREV -->
            <div class="icon-prev"></div>

        </button>

        Page {{groupCount}}

        <!--? NEXT -->
        <button (click)="nextPage()" [ngClass]="{ 'icon-disable': page >= tableLenght - pageLenght }">
            <!-- ICON NEXT -->
            <div class="icon-next"></div>

        </button>
    </div>
</div>
<section class="modal-table">
    <div class="modal-container">
        Modal
    </div>
</section>
