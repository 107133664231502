import { Component, OnInit, SimpleChanges } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'app-version-admin',
	templateUrl: './version-admin.component.html',
	styleUrls: ['./version-admin.component.scss'],
})
export class VersionAdminComponent implements OnInit {
	panelOpenState = false;
	version: any = [];
	leng = localStorage.getItem('language');
	dataVersion;

	constructor(
		private component: AppComponent,
		private adminService: AdminClientService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		this.leng = localStorage.getItem('language');
	}

	async getDataVersion() {
		let data = {
			language: localStorage.getItem('language'),
			app: 2,
		};

		this.dataVersion = await this.adminService.getDataVersion(data);
		if (!this.dataVersion.mensaje_return.ERROR) {
			if (this.dataVersion.return_version.length > 0) {
				this.version = this.dataVersion.return_version;
			}
		}
	}

	ngOnInit() {
		this.getDataVersion();
	}
}
