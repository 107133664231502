<div class="row container-graphic">
    
    <!--? HEADER -->
    <div class="row header-graphic">
        <div class="col">
            <div class="header-txt">
                Califications average
            </div>
        </div>
    </div>

    <!--? KPI -->
    <div class="row">
        <h2 class="kpi-custome">4.75</h2>
        <span class="kpi-footer">En base a 10,680 reseñas</span>
    </div>

    <!--? CHART -->
    <div class="justify-content-center" id="chart">
        <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [xaxis]="chartOptions.xaxis"
            [stroke]="chartOptions.stroke"
            [fill]="chartOptions.fill"
            [title]="chartOptions.title"
            [tooltip]="chartOptions.tooltip"
            [legend]="chartOptions.legend">
        </apx-chart>
      </div>

      <!--? STARS -->
    <div class="col text-center justify-content-center" >
        <!-- STAR 1 -->
        <p class="d-inline container-foot">
            1
            <i class="fa fa-sharp fa-solid fa-star star-1"></i>
        </p>     
        <!-- STAR 2 -->
        <p class="d-inline container-foot">
            2
            <i class="fa fa-sharp fa-solid fa-star star-2"></i>
        </p>     
        <!-- STAR 3 -->
        <p class="d-inline container-foot">
            3
            <i class="fa fa-sharp fa-solid fa-star star-3"></i>
        </p>     
        <!-- STAR 4 -->
        <p class="d-inline container-foot">
            4
            <i class="fa fa-sharp fa-solid fa-star star-4"></i>
        </p>     
        <!-- STAR 5 -->
        <p class="d-inline container-foot">
            5
            <i class="fa fa-sharp fa-solid fa-star star-5"></i>
        </p>     
    </div>

</div>