<!-- CARDS ITERATION -->
<div class="grid-row">
    <!--? CARDS SM -->
        <div class='smCard' *ngFor="let item of dataCard; let i = index">
			<div class="size" >
				<div class="card-grid-content">
					<!--? HEADER -->
					<div class="header">
						<!-- TITLE -->
						<div [ngClass]="{'title-txt' : header === true,'none'   : header === null}">
							{{item.header | translate }}
						</div>
						<!-- TOOLTIP -->
						<div  [ngClass]="{'icon-info' : header === true,'none'   : header == null }" (mouseover)="activateTooltip(i)" (mouseleave)="desactivateToolTip(i)">                        <!-- TOOLTIP CENTER SM -->
							<app-tooltip-collection [idCard]="idCard" [tooltip]="dataCard[idCard]" *ngIf="showTooltip === true && idCard === i">
							</app-tooltip-collection>
						</div>
					</div>
					<!--? BODY -->
					<div [ngClass]="{'body' : body === true,'none' : body == null }">
						<span>{{item.data}}</span>
					</div>
					<!--? FOOTER -->
					<div [ngClass]="{'footer' : footer === true,'none' : footer === false }">
						<!-- ARROW -->
						<div [ngClass]="{'success-arrow' : item.percent >= 0, 'danger-arrow' : item.percent < 0}">
						</div>
						<!-- TXT -->
						<div [ngClass]="{'success': item.percent >= 0,'danger': item.percent < 0,'none': item.percent == null}">
							{{item.percent}}%
						</div>
						<!-- FOOTER -->
						<span [ngClass]="{'none': item.footer == null}">
							{{item.footer | translate }}
						</span>
						<!-- TOTAL -->
						<span [ngClass]="{'none': item.footerData == null}">
							({{item.footerData}})
						</span>
					</div>

				</div>
			</div>
    </div>
</div>


