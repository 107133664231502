import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FetchService {
	private token: string;
	public baseUrl: string = environment.url_server_backend;
	setToken(token: string) {
		this.token = token;
	}

	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}
	constructor(private http: HttpClient) { }
	async getAllProperties() {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			})
		};
		let url = this.baseUrl + '/server/locker/list-all-properties';
		return await this.http.get(url, options).toPromise();
	}
	async getMetricProperties() {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			})
		};
		let url = this.baseUrl + '/server/locker/kpis-properties';
		return await this.http.get(url, options).toPromise();
	}

	async getListPropertiesAccessPoints(params:any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params:params
		};
		let url = this.baseUrl + '/server/locker/list-property-access-points';
		return await this.http.get(url, options).toPromise();
	}
	async getMetricListPropertiesAccessPoints(params:any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params:params
		};
		let url = this.baseUrl + '/server/locker/kpis-property';
		return await this.http.get(url, options).toPromise();
	}

	async getFamilyAccessPoint(params:any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params:params
		};
		let url = this.baseUrl + '/server/locker/query-access-point';
		return await this.http.get(url, options).toPromise();
	}

	async getMetricFamilyAccessPoint(params:any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params:params
		};
		let url = this.baseUrl + '/server/locker/kpis-access-point';
		return await this.http.get(url, options).toPromise();
	}

	async getAllInventory() {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			})
		};
		let url = this.baseUrl + '/server/locker/list-all-lockers';
		return await this.http.get(url, options).toPromise();
	}

	async getMetricInventory() {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			})
		};
		let url = this.baseUrl + '/server/locker/kpis-inventory';
		return await this.http.get(url, options).toPromise();
	}
	async getAllUsers(){
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			})
		};
		let url = this.baseUrl + '/server/user/list-all-users';
		return await this.http.get(url, options).toPromise();
	}
	async getUsersMetrics(){
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			})
		};
		let url = this.baseUrl + '/server/user/kpis-users';
		return await this.http.get(url, options).toPromise();
	}
}
