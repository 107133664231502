<div class="containerCarrierUser">
	<div class="row">
		<div class="col-12">
			<div class="headerBox">
				<div class="title">
					Update User
				</div>
				<div class="iconClose"></div>
			</div>
			<hr>
			<form [formGroup]="updateUserForm" class="formGroupBoxCarrier">
				<div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldTypeEmployee' | translate}}
					</div>
					<input	type="text"
							class="formInput"
							formControlName="userType">
					<div class="error-message" *ngIf="updateUserForm.get('userType').invalid && updateUserForm.get('userType').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>
				<div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldNameEmployee' | translate}}
					</div>
					<input	type="text"
							class="formInput"
							formControlName="full_name"/>
					<div class="error-message" *ngIf="updateUserForm.get('full_name').invalid && updateUserForm.get('full_name').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>
				<div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldEmail' | translate}}
					</div>
					<input	type="text"
							class="formInput"
							formControlName="email"/>
					<div class="error-message" *ngIf="updateUserForm.get('email').invalid && updateUserForm.get('email').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>



				<div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldPassword' | translate}}
					</div>
					<input	type="text"
							class="formInput"
							formControlName="password"/>
					<div class="error-message" *ngIf="updateUserForm.get('password').invalid && updateUserForm.get('password').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>

				<div class="formBoxCarrier">
					<div class="formBoxTextCarrier">
						{{'addUser.fieldConfirmPassword' | translate}}
					</div>
					<input	type="text"
							class="formInput"
							formControlName="confirm_password"/>
					<div class="error-message" *ngIf="updateUserForm.get('confirm_password').invalid && updateUserForm.get('confirm_password').touched">
						{{'addUser.errorFieldRequired' | translate}}
					</div>
				</div>

				<div class="box">
					<div class="boxButton">
						<app-button btnNombre="Generar Contraseña" btnType="secondaryMedium" (click)="generatePassword()">
						</app-button>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div class="box">
							<div class="boxButton">
								<app-button btnNombre="Cancelar" btnType="secondaryMedium" (click)="closeAll()">
								</app-button>
								<app-button *ngIf="this.updateUserForm.valid" btnNombre="Guardar usuario" btnType="primaryMedium" [activo]="false" (click)="onSubmit()">
								</app-button>
								<app-button *ngIf="!this.updateUserForm.valid" btnNombre="Guardar usuario" btnType="primaryMedium" [activo]="true" (click)="onSubmit()">
								</app-button>
							</div>
						</div>
					</div>
				</div>
			</form>

		</div>
	</div>
</div>
