import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'button-create-order',
	templateUrl: './button-create-order.component.html',
	styleUrls: ['./button-create-order.component.scss'],
})
export class ButtonCreateOrderComponent {
	constructor(private translate: TranslateService) {
		this.translate.use(localStorage.getItem('language').toLowerCase())
	}

}
