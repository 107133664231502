import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import { DataPackageServiceGeneral, DataPackageServiceInTransit, ReturnResultQueryGeneralPackage, ReturnResultQueryInTransitPackage, ReturnResultQueryScheduledPackage } from 'src/app/core/models/app-client/app-client.interface';

@Component({
	selector: 'app-dialog-oxxo-to-oxxo-detail',
	templateUrl: './dialog-oxxo-to-oxxo-detail.component.html',
	styleUrls: ['./dialog-oxxo-to-oxxo-detail.component.scss']
})
export class DialogOxxoToOxxoDetailComponent implements OnInit {
	viewRemitenteInformation	= true;
	viewCedisInformation 		= false;
	viewDestinatarioInformation = false;
	orderDetail:ReturnResultQueryGeneralPackage;
	scheduledDetail:ReturnResultQueryScheduledPackage;
	orderDetailInTransint: ReturnResultQueryInTransitPackage;
	from:string;


	constructor(
		private dialogRef: MatDialogRef<DialogOxxoToOxxoDetailComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialog: MatDialog,
		private translate: TranslateService
	) { }

	ngOnInit(){
		this.from = this.data.from;
		if(this.data.from === 'general'){
			this.orderDetail = this.data.rowData;
		}
		else if(this.data.from ==='scheduled'){
			this.scheduledDetail = this.data.rowData;
		}
		else if(this.data.from === 'warehouse'){
			this.orderDetailInTransint = this.data.rowData;
		}

	}

	closeModal() {
		this.dialogRef.close();
	}
	ViewRemitenteInformation(){
		this.viewRemitenteInformation		=true;
		this.viewCedisInformation 			= false;
		this.viewDestinatarioInformation 	= false;
	}

	ViewCedisInformation(){
		this.viewRemitenteInformation		= false;
		this.viewCedisInformation 			= true;
		this.viewDestinatarioInformation 	= false;
	}
	ViewDestinatarioInformation(){
		this.viewRemitenteInformation		= false;
		this.viewCedisInformation 			= false;
		this.viewDestinatarioInformation	= true;
	}

}
