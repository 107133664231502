<!--? NAVBAR GRID -->
<div class="grid-navbar">

    <!--? CONTAINER LOGO -->
    <div class="container-logo">
        <!-- LOGO NAVBAR -->
        <div class="logo-navbar" routerLink="overview"></div>

    </div>

    <!--? CONTAINER MENU -->
    <ul class="container-menu" [style.grid-template-columns]="'repeat(' + cantidadColumnsArr + ', 83px)'">
        <!-- ALIGN ITEMS -->
        <li class="align-menu" *ngFor="let menu of navMenu">
            <!-- LINK MENU -->
            <a  class="link-menu"
                [routerLink]="menu.route"
                routerLinkActive="active"
                (click)="eraseTracking()">
                {{menu.txt | translate | titlecase }}
            </a>
        </li>
    </ul>

    <!--? CONTAINER DROPDOWN -->
    <div class="grid-user">
        <!-- NOTIFICATIONS GRID  -->
        <div class="grid-notification-profile">
            <!-- ICON BELL  -->
            <button class="icon-bell" (click)="changeStatus()" >
                <!-- NOTIFICATION NUM -->
                <span [ngClass]=" {
                    'notification-txt' :notificationSeen == true,
                    'notification-none' :notificationSeen == false }">
                    {{sizeAll}}
                </span>
            </button>
            <!-- NOTIFICATION COMPONENT -->
            <notifications
                [ngClass]="{
                'notification-component': dropdownService.status == true,
                'notification-none' : dropdownService.status == false }"
                [orders]="this.notificationOrders"
                [sizeOrders]="this.sizeOrders"
                [users]="this.notificationUser"
                [all]="this.all"
                [sizeAll]="this.sizeAll"
                [sizeUser]="this.sizeUsers">
            </notifications>
        </div>
        <!-- DROPDOWN MENU -->
        <div class="profile-dropdown-menu">
            <!-- USERNAME ROLE -->
            <div class="grid-span">
                <span>{{ username | lowercase | titlecase}}</span>
                <span>{{ role |  titlecase }}</span>
            </div>
            <!-- DROPDOWN NAVBAR -->
            <div class="grid-dropdown">
                <!-- ICON CHEVRON -->
                <div class="icon-chevron">
                    <!-- DROPDOWN COMPONENT -->
                    <dropdown-navbar class="dropdown-component">
                    </dropdown-navbar>

                </div>

            </div>

        </div>
        
    </div>

</div>
