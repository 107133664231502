import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-card-kpi',
	templateUrl: './card-kpi.component.html',
	styleUrls: ['./card-kpi.component.scss'],
})
export class CardKpiComponent {
	@Input() number_value?: number;
	@Input() text_value?: string;

	@Input() kpi_boolean: boolean;
	@Input() kpi_value?: number;
	@Input() kpi_text?: string;

	@Input() kpi_money: boolean;
	@Input() kpi_money_text?: boolean;
	@Input() kpi_money_value?: number;
}
