<!-- ! CONTAINER CAMERAS -->
<div class="container-cam">
  <!-- ? CONTENEDOR -->
  <mat-card-content>
    <!-- ? TABLE -->
    <table
      mat-table
      [dataSource]="dataSource"
      class="table-cam">
      <!--  CONTAINER -->
      <ng-container
        *ngFor="let column of displayedColumns; let i = index"
        matColumnDef="{{column}}">

        <!-- TABLE HEADER -->
        <th mat-header-cell *matHeaderCellDef
            class="text-center">
          <!-- HEADER -->
          <span *ngIf="i!=0"> {{'table.'+column.toLowerCase() | translate}} </span>
        </th>

        <!-- TABLE DATA -->
        <td mat-cell *matCellDef="let element; let j=index" style="text-align:center;">
          <!--  TABLE DATA -->
          <span *ngIf="i!=0 && column!='monitor_name'">
            {{element[column]}}
          </span>
          <!--  BUTTON CAM -->
          <span class="underline-text" *ngIf="column=='monitor_name'"
            (click)="viewCam(element)">
            {{ element[column] | translate }}
          </span>
        </td>
      </ng-container>

      <!-- TABLE HEADER -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <!-- TABLE DATA -->
      <tr class="table-row" mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

    </table>
    <!--! CAMERA OLD TAB -------------------------------------------------------------------->
<!-- <div *ngIf="!camera" id="camera-tab">
      <mat-card>

        <mat-card-header
        fxLayout="row"
        fxLayoutAlign="space-between center"> -->

        <!--? TITLE -->
        <!-- <mat-card-title class="title-card">
          Camera {{cameraName}}
        </mat-card-title>

        <div id="back" (click)="hiddenCam();">
          <i class="fas fa-chevron-left"></i> {{'cameras.back' | translate}}
        </div>

        </mat-card-header>
        <mat-card-content
          id="single-camera"
          fxLayout="row"
          fxLayout.sm="column"
          fxLayout.xs="column">
            <canvas
              *ngIf="!url_cam_event"
              #canvas style="height: 385px; width: 100%; margin:0; padding:0; border:none;">
            </canvas>
            <iframe
              *ngIf="url_cam_event"
              [src]="urlSafe"
              scrolling="auto"
              fxFlex="70%"
              style="height: 385px; width: 100%; margin:0; padding:0; border:none;">
            </iframe>
            <div
              class="not-safari"
              *ngIf="!safari"
              id="camera-detail"
              fxFlex="30%"
              fxLayout="row"
              fxLayout.fxLayout.lt-lg="column"
              fxLayout.sm="column"
              fxLayout.xs="column"> -->
              <!--? FORM -->
              <!-- <form> -->
                <!-- ? TITLE -->
                <!-- <p> {{'cameras.request' | translate}}</p>
                <br> -->
                <!--? INPUTS -->
                <!-- <div class="datetime" > -->

                  <!--? TITLE DATE -->
                  <!-- <label for="date">
                    {{'general.date' | translate }}
                  </label> -->
                  <!--? TIME PICKER 1 -->

                  <!-- <input *ngIf="device" matInput id="date" type="date" name="ddate" [(ngModel)]="ddate_stamp">
                  <mat-form-field *ngIf="!device" apareance="outline">
                    <input name="date" [(ngModel)]="date_stamp" matInput id="date" [owlDateTimeTrigger]="date" [owlDateTime]="date" readonly >
                    <span class="trigger" [owlDateTimeTrigger]="date" matPrefix class="material-icons">calendar_today</span>
                    <span class="trigger" [owlDateTimeTrigger]="date" matSuffix class="material-icons">expand_more</span>
                    <owl-date-time [pickerType]="'calendar'" #date></owl-date-time>
                  </mat-form-field>
                </div>
                <div class="time" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start center">
                  <div fxFlex="40%" fxFlex.sm="100%" fxFlex.xs="100%" class="datetime">
                    <label for="sdate">{{'general.start_time' | translate}}</label>
                    <input *ngIf="device" matInput id="sdate" type="time" name="dsdate" [(ngModel)]="dtime_start">
                    <mat-form-field apareance="outline" *ngIf="!device">
                      <input name="sdate" [(ngModel)]="time_start" matInput id="sdate" [owlDateTimeTrigger]="sdate" [owlDateTime]="sdate" readonly>
                      <span class="trigger" [owlDateTimeTrigger]="sdate" matPrefix class="material-icons">schedule</span>
                      <span class="trigger" [owlDateTimeTrigger]="sdate" matSuffix class="material-icons">expand_more</span>
                      <owl-date-time [pickerType]="'timer'" #sdate></owl-date-time>
                    </mat-form-field>
                  </div>
                  <div fxFlex="40%" fxFlex.sm="100%" fxFlex.xs="100%" class="datetime">
                    <label for="fdate">{{'general.end_time' | translate}}</label>
                    <input *ngIf="device" matInput id="fdate" type="time" [(ngModel)]="time_end" name="dfdate">
                    <mat-form-field apareance="outline" *ngIf="!device">
                      <input name="fdate" [(ngModel)]="time_end" matInput id="fdate" [owlDateTimeTrigger]="fdate" [owlDateTime]="fdate" readonly >
                      <span class="trigger" [owlDateTimeTrigger]="fdate" matPrefix class="material-icons">schedule</span>
                      <span class="trigger" [owlDateTimeTrigger]="fdate" matSuffix class="material-icons">expand_more</span>
                      <owl-date-time [pickerType]="'timer'" #fdate></owl-date-time>
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row">
                  <button id="create" (click)="record()" [disabled]="(date_stamp===undefined || time_start===undefined || time_end===undefined) && (ddate_stamp===undefined || dtime_start===undefined || time_end===undefined) ">{{'cameras.recording' | translate }}</button>
                </div>
              </form>

            </div>
            <div *ngIf="safari" id="camera-detail" fxFlex="100%" fxLayout="row" fxLayout.fxLayout.lt-lg="column" fxLayout.sm="column" fxLayout.xs="column">
              <form action="" id="safari">
                <p>{{'cameras.request' | translate}}</p>
                <br>
                <div class="datetime">
                  <mat-form-field *ngIf="!device" appareance="outline">
                    <mat-label for="date">{{'general.date' | translate}}</mat-label>
                      <input matInput [(ngModel)]="sdate_stamp" readonly [owlDateTimeTrigger]="date" [owlDateTime]="date" type="date" name="date" id="date">
                      <span class="trigger" [owlDateTimeTrigger]="date" matPrefix class="material-icons">calendar_today</span>
                      <owl-date-time [pickerType]="'calendar'" #date></owl-date-time>
                  </mat-form-field>
                  <mat-form-field *ngIf="device" appareance="outline">
                    <mat-label for="date">{{'general.date' | translate}}</mat-label>
                    <input [(ngModel)]="stime_start" matInput readonly type="date" name="date" id="date">
                  </mat-form-field>
                  <mat-form-field *ngIf="!device" appareance="outline">
                    <mat-label for="sdate">{{'general.start_time' | translate}}</mat-label>
                      <input [(ngModel)]="stime_end" matInput readonly [owlDateTimeTrigger]="sdate" [owlDateTime]="sdate" type="time" name="sdate" id="sdate">
                      <span  class="trigger" [owlDateTimeTrigger]="sdate" matPrefix class="material-icons">calendar_today</span>
                      <owl-date-time [pickerType]="'timer'" #sdate></owl-date-time>
                  </mat-form-field>
                  <mat-form-field *ngIf="device" appareance="outline">
                    <mat-label for="sdate">{{'general.start_time' | translate}}</mat-label>
                    <input matInput readonly type="time" name="sdate" id="sdate">
                  </mat-form-field>
                  <mat-form-field *ngIf="!device" appareance="outline">
                    <mat-label for="fdate">{{'general.end_time' | translate}}</mat-label>
                      <input matInput readonly [owlDateTimeTrigger]="fdate" [owlDateTime]="fdate" type="time" name="fdate" id="fdate">
                      <span  class="trigger" [owlDateTimeTrigger]="fdate" matPrefix class="material-icons">calendar_today</span>
                      <owl-date-time  [pickerType]="'timer'" #fdate></owl-date-time>
                  </mat-form-field>
                  <mat-form-field *ngIf="device" appareance="outline">
                    <mat-label for="fdate">{{'general.end_time' | translate}}</mat-label>
                      <input  matInput readonly type="time" name="fdate" id="fdate">
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                  <button class="btn button-blue" (click)="record()">{{'cameras.recording' | translate }}</button>
                </div>
              </form>
            </div>
          </mat-card-content>
          <mat-card-footer style="height: 50px;">
            <mat-spinner *ngIf="spinner" diameter="40"></mat-spinner>
          </mat-card-footer>
        </mat-card>
        <mat-card *ngIf="dataTableEvent.length > 0" >
          <mat-card-content id="single-camera" fxFlex="95%" fxLayout="column">
            <app-table-p-admin type="recordCam" dataForTable="{{dataTableAuxEvent}}" columns="{{dataColumnEvent}}" (click)="onclickEvent()"></app-table-p-admin>
          </mat-card-content>
        </mat-card>
    </div> -->
    <!--! CAMERA OLD TAB -------------------------------------------------------------------->
  </mat-card-content>
</div>


<!--! NEW TAB --------------------------------------------------------------------->
<div *ngIf="camera" class="camera-tab-test">
  <!--? CONTAINER -->
  <div class="justify-content-between">

    <!--? ROW -->
    <div class="row">

      <!--! COL 1 ------------->
      <div class="col col-left">
        <div class="cam-box">
          <canvas
            *ngIf="!url_cam_event"
            #canvas style="height: 385px; width: 100%; margin:0; padding:0; border:none;">
          </canvas>
          <iframe
            *ngIf="url_cam_event"
            [src]="urlSafe"
            scrolling="auto"
            fxFlex="70%"
            style="height: 385px; width: 100%; margin:0; padding:0; border:none;">
          </iframe>
        </div>
      </div>

      <!--! COL 2 ------------->
      <div class="col col-right">
        <!-- ROW -->
        <div class="row">
          <!-- TITLE -->
          <div class="col text-start title-form">
            Solicita una grabación de {{cameraName}}
          </div>
          <!-- CLOSE BTN -->
          <div class="col">
            <button
              class="btn-close"
              aria-label="Close"
              id="back"
              (click)="hiddenCam();">
            </button>
          </div>
        </div>

        <!-- HR -->
        <hr style="color: var(--color-primary-200);">

        <!-- FORM -->
        <form>
          <!--? FIRST ROW -->
          <div class="row">
            <!--* DATAPICKER -->
            <span class="text-start font-primary mb-1">
              Selecciona una fecha
            </span>
            <p-calendar
              [showIcon]="true"
              class="selectYear input-gbl"
              view="year"
              style="margin-left: 10px;"
              dateFormat="yy"
              [readonlyInput]="true"
              inputId="pickYear"
              placeholder="{{ 'Colocar fecha' | translate }}">
            </p-calendar>
          </div>
          <!--? SECOND ROW -->
          <div class="row mt-custome">
            <!-- COL-1 -->
            <div class="col-5 text-start">
              <!-- TITLE DATE PICKER -->
              <span class="text-start font-primary">
                Selecciona una hora de inicio
              </span>
              <!-- TIME PICKER -->
                <div class="card flex justify-content-center">
                  <!-- START TIME -->
                  <p-calendar
                    [(ngModel)]="date"
                    [timeOnly]="true"
                    [showSeconds]="true">
                  </p-calendar>
                </div>
            </div>
            <!-- COL-1 -->
            <div class="col text-start">
              <!-- TITLE DATE PICKER -->
              <span class="text-start font-primary">
                Selecciona una hora final
              </span>
              <!-- TIME PICKER  -->
              <div class="card flex justify-content-center">
                <!-- END TIME -->
                <p-calendar
                  [(ngModel)]="date"
                  [timeOnly]="true"
                  [showSeconds]="true">
                </p-calendar>
              </div>
            </div>
          </div>

          <!--? SPAN TXT -->
          <div class="row">
            <span class="text-start span-txt">
              Nota: este proceso puede tardar varios minutos
            </span>
          </div>

          <!--? BUTTON -->
          <div class="text-start">
            <button
              type="submit"
              class="btn-primary-gbl">
                {{ 'Buscar grabación' | translate }}
            </button>
          </div>

        </form>

      </div>

    </div>

  </div>

</div>
