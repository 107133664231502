import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TableUserService } from 'src/app/core/http/user.service';
import { SuperAdminResult, UsersCarriersInterface, SuperAdminInterface } from 'src/app/core/models/users.interfaces';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
import { UserDetailModalComponent } from '../../user-detail-modal/user-detail-modal.component';
import { ConfirmChangeStatusUserComponent } from '../../confirm-change-status-user/confirm-change-status-user.component';
import { DialogAuditUserComponent } from '../../dialog-audit-user/dialog-audit-user.component';
import { DatePipe } from '@angular/common';
import { ToggleComponent } from 'src/app/shared/components/toggle/toggle.component';
import { DialogUpdateUserComponent } from '../../dialog-update-user/dialog-update-user.component';
import { EditUserRendererComponent } from '../../edit-user-renderer/edit-user-renderer.component';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-table-user-admin-fetch',
  templateUrl: './table-user-admin-fetch.component.html',
  styleUrls: ['./table-user-admin-fetch.component.scss']
})
export class TableUserAdminFetchComponent implements OnInit {
	valueInput;
	public columnDefs: ColDef[];
	filterText: string = '';
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	users: UsersCarriersInterface[];
	@Input() rowData;
	constructor(
		private translate: TranslateService,
		private userService: TableUserService,
		private dialog: MatDialog,
		private datePipe: DatePipe,
		private http: HttpClient)
	{ }

	ngOnInit(): void {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataSuperAdmin();
		this.loadData();
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=> {
			this.translate.use(event.lang);
			this.translate.get([
				'usersFetch.table.admins.name',
				'usersFetch.table.admins.EmployeeID',
				'usersFetch.table.admins.ActiveDays',
				'usersFetch.table.admins.DateOfPasswordChange',
				'usersFetch.table.admins.Access',
			]).subscribe( (t:any)=>{
				this.columnDefs = [
					{
						headerName: t['usersFetch.table.admins.name'],
						field: 'name',
						sortable: true,
						autoHeight: true,
						cellClass:'superAllClass',
						headerClass:'borderHeaderAll',
						cellRenderer: this.centeredCellRenderer,
					},
					{
						headerName: t['usersFetch.table.admins.ActiveDays'],
						field: 'active_days',
						sortable: true,
						autoHeight: true,
						cellClass:'superAllClass',
						headerClass:'borderHeaderAll',
					},
					{
						headerName: t['usersFetch.table.admins.DateOfPasswordChange'],
						field: 'last_conection',
						sortable: true,
						autoHeight: true,
						cellClass:'superAllClass',
						headerClass:'borderHeaderAll',
						cellRenderer: DateTextTablesComponent
					},
					{
						headerName: t['usersFetch.table.admins.Access'],
						field: 'access',
						sortable: true,
						autoHeight: true,
						cellClass:'superAllClass',
						headerClass:'borderHeaderAll',
						cellRenderer: ToggleComponent,
						onCellClicked: ($event) => this.openConfirmChangeStatus($event)
					}
				]
			})
		})
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	updateDataSuperAdmin(){
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.gridOptions = {
			columnDefs: [
				{
					headerName:this.translate.instant('usersFetch.table.admins.name'),
					field: 'name',
					sortable: true,
					autoHeight: true,
					cellClass:'superAllClass',
					headerClass:'borderHeaderAll',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('usersFetch.table.admins.ActiveDays'),
					field: 'active_days',
					sortable: true,
					autoHeight: true,
					cellClass:'superAllClass',
					headerClass:'borderHeaderAll',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('usersFetch.table.admins.DateOfPasswordChange'),
					field: 'last_conection',
					sortable: true,
					autoHeight: true,
					cellClass:'superAllClass',
					headerClass:'borderHeaderAll',
					cellRenderer: DateTextTablesComponent
				},
				{
					headerName: this.translate.instant('usersFetch.table.admins.Access'),
					field: 'access',
					sortable: true,
					autoHeight: true,
					cellClass:'superAllClass',
					headerClass:'borderHeaderAll',
					cellRenderer: ToggleComponent,
					onCellClicked: ($event) => this.openConfirmChangeStatus($event)
				}
			],
			animateRows: true,
			pagination: true,
			rowHeight:61,
			headerHeight:61,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			enableCellChangeFlash: true,
			rowClass:'rowAllClass',
			defaultColDef: {
				flex: 1,
			},
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}

	formatedDateTime;

	formattedDate(item) {
		const parts = item.split(' ');
		const dateParts = parts[1].split('-');
		const timeParts = parts[0].split(':');

		const year = +dateParts[2];
		const month = +dateParts[0] - 1; // Restar 1 porque los meses en Date comienzan desde 0
		const day = +dateParts[1];
		const hours = +timeParts[0];
		const minutes = +timeParts[1];
		const seconds = +timeParts[2];

		const parsedDate = new Date(year, month, day, hours, minutes, seconds);

		// Formatear la fecha y hora en el formato deseado (YYYY-MM-DD HH:MM:SS)
		const formattedYear = parsedDate.getFullYear();
		const formattedMonth = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
		const formattedDay = parsedDate.getDate().toString().padStart(2, '0');
		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');
		const formattedSeconds = seconds.toString().padStart(2, '0');

		this.formatedDateTime = `${formattedYear}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	}

	loadData(){
		// this.rowData=[];

		// this.http.get('../../../../../../assets/dummy/propertiesTable.json').toPromise().then((res:any)=>{
		// 	console.log(res.usersFetch_all);
		// 	this.rowData = res.user_all;
		// })
	}

	onExport() {
		const fileName = 'Admin.xlsx';
		const excelParams = {
			fileName: fileName,
			sheetName: 'Admin',
			columnWidths: [30, 20, 20, 20],
		};
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_status,id_language,id_user,audit_package,audit_employee,code_master,url_token,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				const { id_status,id_language,id_user,audit_package,audit_employee,code_master,url_token,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		}
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}


	openModalUserDetail(event) {
		const dialogRef = this.dialog.open(UserDetailModalComponent, {
			width: '1456px',
			height: '95%',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}
	openConfirmChangeStatus(event) {
		const dialogRef = this.dialog.open(ConfirmChangeStatusUserComponent, {
			width: '361px',
			height: '231px',
			data: event,
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
			const newData = this.users.slice();
			newData[result].status = 'INACTIVE';
			this.users = newData;
		});
	}
	openModalUserAudit(event) {
		const dialogRef = this.dialog.open(DialogAuditUserComponent, {
			width: '959px',
			height: '796px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}
	openUpdateUser(event){
		const dialogRef = this.dialog.open(DialogUpdateUserComponent, {
			width: '488px',
			height: '780px',
			data: {
				data: event.data,
				type:9
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.loadData();
		});
	}
}
