import { ITooltipComp } from 'ag-grid-community';

export class TooltipComponent implements ITooltipComp {
	eGui: any;
	currentCellElement: HTMLElement;
	init(params:any) {
		const eGui = (this.eGui = document.createElement('div'));
		this.currentCellElement = params.api.getCellRendererInstances(params)[0].eGui;
		const data = params.api.getDisplayedRowAtIndex(params.rowIndex)?.data;


		if(params.colDef.field === 'address'){
			eGui.innerHTML = `
			<div style="margin-top:-2rem;">
				<div style="z-index: 2; width: 12px; height: 12px; background: var(--color-container); rotate: 45deg; position: relative; bottom: 6px;"></div>
				<div style="width: 12px; height: 12px; background: var(--color-container); box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); rotate: 45deg; position: relative; bottom: 17px;"></div>
				<span style="position: relative; z-index: 1; display: flex; font-size: var(--font-c3); background: var(--color-container); border-radius: var(--radius-xs); width: 291px; height: 60px;	bottom: 80px; left: -30px; padding: 15px;	box-shadow: 2px 2px 5px rgba(2, 2, 2, 0.5);	color: var(--color-primary); margin-left: -8rem;">
					${data.address}
				</span>
			</div>

		`;
		}
		else if(params.colDef.field ==='lockerID'){
			eGui.innerHTML = `
			<div style="margin-top:-2rem;">
				<div style="z-index: 2; width: 12px; height: 12px; background: var(--color-container); rotate: 45deg; position: relative; bottom: 6px;"></div>
				<div style="width: 12px; height: 12px; background: var(--color-container); box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); rotate: 45deg; position: relative; bottom: 17px;"></div>
				<span style="position: relative; z-index: 1; display: flex; font-size: var(--font-c3); background: var(--color-container); border-radius: var(--radius-xs); width: 110px; height: 40px;	bottom: 60px; left: -76px; padding: 15px;	box-shadow: 2px 2px 5px rgba(2, 2, 2, 0.5);	color: var(--color-primary);">
					${data.lockername}
				</span>
			</div>
        `;
		}


	}

	getGui() {
		return this.eGui;
	}
}
