import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TableUserService } from 'src/app/core/http/user.service';
import { SuperAdminResult, UsersCarriersInterface, SuperAdminInterface } from 'src/app/core/models/users.interfaces';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
import { UserDetailModalComponent } from '../../user-detail-modal/user-detail-modal.component';
import { ConfirmChangeStatusUserComponent } from '../../confirm-change-status-user/confirm-change-status-user.component';
import { DialogAuditUserComponent } from '../../dialog-audit-user/dialog-audit-user.component';
import { ToggleComponent } from 'src/app/shared/components/toggle/toggle.component';
import { EditUserRendererComponent } from '../../edit-user-renderer/edit-user-renderer.component';
import { DialogUpdateUserComponent } from '../../dialog-update-user/dialog-update-user.component';

@Component({
  selector: 'app-tables-super-admin-user',
  templateUrl: './tables-super-admin-user.component.html',
  styleUrls: ['./tables-super-admin-user.component.scss']
})
export class TablesSuperAdminUserComponent implements OnInit {
	valueInput;
	public columnDefs: ColDef[];
	filterText: string = '';
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	users: UsersCarriersInterface[];
	superAdmin: SuperAdminInterface[];
	constructor(private translate: TranslateService,private userService: TableUserService,private dialog: MatDialog) { }

	ngOnInit(): void {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataSuperAdmin();
		this.loadData();
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=> {
			this.translate.use(event.lang);
			this.translate.get([
				'tableER.fullName',
				'tableER.email',
				'tableER.activeDays',
				'tableER.registerDate',
				'tableER.expiredDate',
				'tableER.statusUser',
				'tableER.actions'
			]).subscribe( (t:any)=>{
				this.columnDefs = [
					{
						headerName: t['tableER.fullName'],
						field: 'first_name',
						sortable: true,
						autoHeight: true,
						cellClass:'superAdminClassLink',
						headerClass:'borderHeaderGeneralSuperAdmin',
						cellRenderer: this.centeredCellRenderer,
						onCellClicked: ($event) => this.openModalUserDetail($event)
					},
					{
						headerName: t['tableER.email'],
						field: 'email',
						sortable: true,
						autoHeight: true,
						cellClass:'superAdminClass',
						headerClass:'borderHeaderGeneralSuperAdmin',
						cellRenderer: this.centeredCellRenderer
					},
					{
						headerName: t['tableER.activeDays'],
						field: 'active_days',
						sortable: true,
						autoHeight: true,
						cellClass:'superAdminClass',
						headerClass:'borderHeaderGeneralSuperAdmin',
						cellRenderer: this.centeredCellRenderer
					},
					{
						headerName: t['tableER.registerDate'],
						field: 'registration_date',
						sortable: true,
						autoHeight: true,
						cellClass:'superAdminClass',
						headerClass:'borderHeaderGeneralSuperAdmin',
						cellRenderer: DateTextTablesComponent
					},
					{
						headerName: t['tableER.expiredDate'],
						field: 'registration_date',
						sortable: true,
						autoHeight: true,
						cellClass:'superAdminClass',
						headerClass:'borderHeaderGeneralSuperAdmin',
						cellRenderer: DateTextTablesComponent
					},
					{
						headerName: t['tableER.statusUser'],
						field: 'id_status',
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderCarrierAdmin',
						cellClass:'carrierAdminClass',
						cellRenderer: ToggleComponent,
						onCellClicked: ($event) => this.openConfirmChangeStatus($event),
					},
					{
						headerName: t['tableER.actions'],
						field: 'id_status',
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderCarrierAdmin',
						cellClass:'carrierAdminClass',
						cellRenderer: EditUserRendererComponent,
						onCellClicked: ($event) => this.openUpdateUser($event),
					}
				]
			})
		})
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	updateDataSuperAdmin(){
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.gridOptions = {
			columnDefs: [
				{
					headerName:this.translate.instant('tableER.fullName'),
					field: 'first_name',
					sortable: true,
					autoHeight: true,
					cellClass:'superAdminClassLink',
					headerClass:'borderHeaderGeneralSuperAdmin',
					cellRenderer: this.centeredCellRenderer,
					onCellClicked: ($event) => this.openModalUserDetail($event)
				},
				{
					headerName: this.translate.instant('tableER.email'),
					field: 'email',
					sortable: true,
					autoHeight: true,
					cellClass:'superAdminClass',
					headerClass:'borderHeaderGeneralSuperAdmin',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('tableER.activeDays'),
					field: 'active_days',
					sortable: true,
					autoHeight: true,
					cellClass:'superAdminClass',
					headerClass:'borderHeaderGeneralSuperAdmin',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('tableER.registerDate'),
					field: 'registration_date',
					sortable: true,
					autoHeight: true,
					cellClass:'superAdminClass',
					headerClass:'borderHeaderGeneralSuperAdmin',
					cellRenderer: DateTextTablesComponent
				},
				{
					headerName: this.translate.instant('tableER.expiredDate'),
					field: 'registration_date',
					sortable: true,
					autoHeight: true,
					cellClass:'superAdminClass',
					headerClass:'borderHeaderGeneralSuperAdmin',
					cellRenderer: DateTextTablesComponent
				},
				{
					headerName:  this.translate.instant('tableER.statusUser'),
					field: 'id_status',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderGeneralSuperAdmin',
					cellClass:'superAdminClass',
					cellRenderer: ToggleComponent,
					onCellClicked: ($event) => this.openConfirmChangeStatus($event)
				},
				{
					headerName: this.translate.instant('tableER.actions'),
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderCarrierAdmin',
					cellClass:'superAdminClass',
					cellRenderer: EditUserRendererComponent,
					onCellClicked: ($event) => this.openUpdateUser($event),
				}
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			enableCellChangeFlash: true,
			rowClass:'rowSuperAdminClass',
			defaultColDef: {
				flex: 1,
			},
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}

	loadData(){
		this.superAdmin=[];
		let data ={
			language: localStorage.getItem('language').toLowerCase()
		}
		this.userService.getSuperAdminAsincrono(data).then( (res:SuperAdminResult)=>{
			if(res.result_return){
				res.result_return.forEach((item:any)=>{
					let hourRegistrationDate = item.registration_date.substring(0,2);
					let minutesRegistrationDate = item.registration_date.substring(3,5);
					let secondsRegistrationDate = item.registration_date.substring(6,8);
					let monthRegistrationDate = parseInt(item.registration_date.substring(9,11));
					let dayRegistrationDate = item.registration_date.substring(12,14);
					let yearRegistrationDate = item.registration_date.substring(15,19);
					let registrationDateString = yearRegistrationDate +'-'+monthRegistrationDate+'-'+dayRegistrationDate+' '+hourRegistrationDate+':'+minutesRegistrationDate+':'+secondsRegistrationDate;
					this.superAdmin.push({
						acronym_language:item.acronym_language,
						active_days: item.active_days,
						alias_username: item.alias_username,
						audit_package: item.audit_package,
						code_master: item.code_master,
						courier_name: item.courier_name,
						email: item.email,
						enable_token: item.enable_token,
						first_name: item.first_name,
						id_language: item.id_language,
						id_status: item.id_status,
						id_user: item.id_user,
						language_name: item.language_name,
						name_status: item.name_status,
						registration_date: registrationDateString,
						audit_employee: item.result_audit_emploche,
						surname: item.surname,
						url_token: item.url_token
					})
				})
				this.superAdmin = [...this.superAdmin];
			}
		}).catch(err=>{
			console.log(err)
		})
	}

	onExport() {
		const fileName = 'SuperAdmin-Users.xlsx';
		const excelParams = {
			fileName: fileName,
			sheetName: 'SuperAdmin-Users',
			columnWidths: [30, 20, 20, 20],
		};
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_status,id_language,id_user,audit_package,audit_employee,code_master,url_token,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		} else if (this.superAdmin.length > 0 && this.filteredData.length === 0) {
			const Data = this.superAdmin || [];
			const excelData = Data.map((row) => {
				const { id_status,id_language,id_user,audit_package,audit_employee,code_master,url_token,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		}
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}


	openModalUserDetail(event) {
		const dialogRef = this.dialog.open(UserDetailModalComponent, {
			width: '1456px',
			height: '95%',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}
	openConfirmChangeStatus(event) {
		const dialogRef = this.dialog.open(ConfirmChangeStatusUserComponent, {
			width: '361px',
			height: '231px',
			data: event,
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
			const newData = this.users.slice();
			newData[result].status = 'INACTIVE';
			this.users = newData;
		});
	}
	openModalUserAudit(event) {
		const dialogRef = this.dialog.open(DialogAuditUserComponent, {
			width: '959px',
			height: '796px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}
	openUpdateUser(event){
		const dialogRef = this.dialog.open(DialogUpdateUserComponent, {
			width: '488px',
			height: '780px',
			data: {
				data: event.data,
				type:7
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.loadData();
		});
	}
}
