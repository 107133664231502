import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { OrderCreateComponent } from '../dialogs-admin/order-create/order-create.component';

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements ICellRendererAngularComp {
	data;
	dataGrid;
	public lockerSelect;
	s: number = null;
	m: number = null;
	g: number = null;
	eg: number = null;
	l: number = null;
	xl: number = null;
	xxl: number = null;
	xxxl: number = null;
	ml: number = null;
	select_compartment: boolean = true;
	cStatus;
	modulo;
	parentComponent: OrderCreateComponent;
	agInit(params: ICellRendererParams): void {
		this.data = params.data;
		localStorage.removeItem('lockerName');
		this.parentComponent = this.parent;
	}

	refresh(params: ICellRendererParams) {
		return false;
	}

	constructor(
		private adminService: AdminClientService,
		private parent: OrderCreateComponent
	) {}

	async seleccionarLocker() {
		if (this.parentComponent) {
			this.parentComponent.updateStepCurrent(2);
		}

		let grid = [];
		this.data.MODULE.forEach((e, i) => {
			grid.push({
				name_locker_module: e.name_locker_module,
				template_module: e.template_module,
			});
		});
		localStorage.setItem('lockerHash', this.data.locker_hash);
		localStorage.setItem('lockerCode', this.data.locker_code);
		localStorage.setItem('lockerName', this.data.locker_name);
		localStorage.setItem('grid', JSON.stringify(grid));
		localStorage.setItem('select_locker_create_order', 'true');
		localStorage.setItem('id_locker', this.data.id_locker);
		let data = {
			language: localStorage.getItem('language').toLowerCase(),
			id_locker: this.data.id_locker,
		};
		this.lockerSelect = this.data.id_locker;
		this.dataGrid = await this.adminService.getLockersGrid(data);
		this.dataGrid.quantity_compartment.compartment.forEach((e, i) => {
			if (e.compartment_name == 'S') {
				this.s = e.quantity;
			} else if (e.compartment_name == 'M/L') {
				this.ml = e.quantity;
			} else if (e.compartment_name == 'EG') {
				this.eg = e.quantity;
			} else if (e.compartment_name == 'XXXL') {
				this.xxxl = e.quantity;
			} else if (e.compartment_name == 'XXL') {
				this.xxl = e.quantity;
			} else if (e.compartment_name == 'XL') {
				this.xl = e.quantity;
			} else if (e.compartment_name == 'L') {
				this.l = e.quantity;
			} else if (e.compartment_name == 'M') {
				this.m = e.quantity;
			}
		});
		if (this.s == 0) {
			this.s = null;
		}

		if (this.ml == 0) {
			this.ml = null;
		}

		if (this.g == 0) {
			this.g = null;
		}

		if (this.eg == 0) {
			this.eg = null;
		}

		if (this.xxxl == 0) {
			this.xxxl = null;
		}

		if (this.xxl == 0) {
			this.xxl = null;
		}

		if (this.xl == 0) {
			this.xl = null;
		}

		if (this.l == 0) {
			this.l = null;
		}

		if (this.m == 0) {
			this.m = null;
		}

		this.modulo = JSON.stringify(this.dataGrid.modulo_status);
		this.cStatus = JSON.stringify(
			this.dataGrid.quantity_compartment.compartment_status
		);
		this.dataGrid.modulo_status.forEach((m: any) => {
			m.DOOR.forEach((d: any) => {
				if (
					(d.compartment_name == 'S' && d.name_status == 'OCUPADO') ||
					(d.compartment_name == 'S' && d.name_status == 'VECIDO') ||
					(d.compartment_name == 'S' && d.name_status == 'FALLO')
				)
					this.s = this.s ? this.s - 1 : this.s;

				if (
					(d.compartment_name == 'M' && d.name_status == 'OCUPADO') ||
					(d.compartment_name == 'M' && d.name_status == 'VECIDO') ||
					(d.compartment_name == 'M' && d.name_status == 'FALLO')
				)
					this.m = this.m ? this.m - 1 : this.m;

				if (
					(d.compartment_name == 'G' && d.name_status == 'OCUPADO') ||
					(d.compartment_name == 'G' && d.name_status == 'VECIDO') ||
					(d.compartment_name == 'G' && d.name_status == 'FALLO')
				)
					this.g = this.g ? this.g - 1 : this.g;

				if (
					(d.compartment_name == 'EG' &&
						d.name_status == 'OCUPADO') ||
					(d.compartment_name == 'EG' && d.name_status == 'VECIDO') ||
					(d.compartment_name == 'EG' && d.name_status == 'FALLO')
				)
					this.eg = this.eg ? this.eg - 1 : this.eg;

				if (
					(d.compartment_name == 'XXXL' &&
						d.name_status == 'OCUPADO') ||
					(d.compartment_name == 'XXXL' &&
						d.name_status == 'VECIDO') ||
					(d.compartment_name == 'XXXL' && d.name_status == 'FALLO')
				)
					this.xxxl = this.xxxl ? this.xxxl - 1 : this.xxxl;

				if (
					(d.compartment_name == 'XXL' &&
						d.name_status == 'OCUPADO') ||
					(d.compartment_name == 'XXL' &&
						d.name_status == 'VECIDO') ||
					(d.compartment_name == 'XXL' && d.name_status == 'FALLO')
				)
					this.xxl = this.xxl ? this.xxl - 1 : this.xxl;

				if (
					(d.compartment_name == 'XL' &&
						d.name_status == 'OCUPADO') ||
					(d.compartment_name == 'XL' && d.name_status == 'VECIDO') ||
					(d.compartment_name == 'XL' && d.name_status == 'FALLO')
				)
					this.xl = this.xl ? this.xl - 1 : this.xl;

				if (
					(d.compartment_name == 'L' && d.name_status == 'OCUPADO') ||
					(d.compartment_name == 'L' && d.name_status == 'VECIDO') ||
					(d.compartment_name == 'L' && d.name_status == 'FALLO')
				)
					this.l = this.l ? this.l - 1 : this.l;

				if (
					(d.compartment_name == 'M/L' &&
						d.name_status == 'OCUPADO') ||
					(d.compartment_name == 'M/L' &&
						d.name_status == 'VECIDO') ||
					(d.compartment_name == 'M/L' && d.name_status == 'FALLO')
				)
					this.ml = this.ml ? this.ml - 1 : this.ml;
			});
		});
	}
}
