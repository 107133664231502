import { Component, Input } from '@angular/core';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'app-packages-delivered-admin',
	templateUrl: './packages-delivered-admin.component.html',
	styleUrls: ['./packages-delivered-admin.component.scss'],
})
export class PackagesDeliveredAdminComponent {
	@Input() dataForMonthly!: any[];
	compSizes;
	metrics: any;

	ngOnInit(): void {
		this.getMetrics().catch((err) => {
			console.log(err);
		});
	}

	constructor(private adminService: AdminClientService) {}

	async getMetrics() {
		this.metrics = await this.adminService.getMetrics(this.dataForMonthly);
		this.compSizes = this.metrics[5][1];
		this.compSizes = JSON.stringify(this.compSizes);
	}
}
