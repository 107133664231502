import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss']
})
export class ButtonIconComponent implements OnInit {

	@Input() btnNombre 	: 	string;
	@Input() btnType   	:	string;
	@Input() activo    	: 	string;
	@Input() icon 		:	string;

	constructor(private translate: TranslateService) {

	}

	ngOnInit(): void {
	}

}
