<div class="row">
    <div class="col-6">
		<div class="input-container">
			<input
				[(ngModel)]="filterText"
				type="text"
				class="input-gbl"
				(keyup)="onFilterTextChange($event)"
				placeholder="{{'dashboard.search_user' | translate}}"/>
			<button
				type="submit"
				class="btn-search">
				<div class="icon-search"></div>
			</button>
		</div>
    </div>
    <div class="col-6 buttons-and-ranges">
		<p-calendar class="selectYear"
                    [showIcon]="true"
                    [(ngModel)]="rangeDates"
                    (ngModelChange)="addEventRangeDates()"
                    selectionMode="range"
                    [readonlyInput]="true"
                    inputId="range" >
        </p-calendar>
		<app-button-icon
			*ngIf="this.mode ==='light'"
			[activo]="false"
			btnNombre="Filtros"
			btnType="secondaryMedium"
			icon="icon-funnel"
			(click)="showFilters()">
		</app-button-icon>
		<app-button-icon
			*ngIf="this.mode ==='dark'"
			[activo]="false"
			btnNombre="Filtros"
			btnType="secondaryMedium"
			icon="icon-funnel-dark"
			(click)="showFilters()">
		</app-button-icon>
        <button-export (click)="onExport()"></button-export>
    </div>

	<div class="col-12">
		<ag-grid-angular
			#agGrid
			class="ag-theme-alpine"
			[gridOptions]="this.gridOptions"
			[rowData]="this.rowData"
			[columnDefs]="this.columnDefs"
			[quickFilterText]="this.filterText"
			style="width: 100%; height: 740px; border: none; margin-top:24px;"
			(gridReady)="onGridReady($event)">
		</ag-grid-angular>
		<!-- <ag-grid-angular
			#agGrid
			class="ag-theme-alpine"
			[gridOptions]="this.gridOptions"
			[rowData]="this.rowData"
			[columnDefs]="this.columnDefs"
			[quickFilterText]="this.filterText"
			[paginationPageSize]="this.selectedCity"
			style="width: 100%; height: 705px; border: none; margin-top:24px;"
			(gridReady)="onGridReady($event)">
		</ag-grid-angular> -->

	</div>
</div>

<!-- <div class="paginationBox">
	<div class="leftPanel">
		<div>
			<p-dropdown class="boxSelect" [options]="cities" [(ngModel)]="selectedCity" optionLabel="name" optionValue="value" (onChange)="changeSize()"></p-dropdown>
		</div>
		<div class="boxSelectText">
			<div class="text-item">
				1 to {{this.selectedCity}}
			</div>
			<div class="subtext-item">
				of  {{this.sizeData}} items
			</div>
		</div>
	</div>
	<div class="rightPanel">
		<div class="pagination">
			Página {{ currentPage }} de {{ totalP }} pages
		</div>
		<div class="paginationControl">
			<button class="btnPreviosPage" (click)="gotoPreviousPage()"> < </button>
			<button class="btnPreviosPage" (click)="gotoNextPage()"> > </button>
		</div>
	</div>
</div> -->






<!-- !CALENDARIO DE EXTENDER FECHAS -->
<div *ngIf="display" class="extendCard">
    <div class="row">
        <div class="col-12">
            <p-calendar
				#calendar
				[inline]="true"
				[minDate]="minDate"
				[maxDate]="maxDate">
			</p-calendar>
        </div>
        <div class="col-6" style="text-align: start;">
			<app-button
				btnNombre="general.cancel"
				btnType="secondaryMedium"
				(click)="cerrarExtender()">
			</app-button>
        </div>
        <div class="col-6" style="text-align: end;">
			<app-button
				*ngIf="calendar.value === undefined"
				[activo]="true"
				btnNombre="table.extend"
				btnType="primaryMedium"
				(click)="extenderFechas(calendar)">
			</app-button>
			<app-button
				*ngIf="calendar.value !== undefined"
				[activo]="false"
				btnNombre="table.extend"
				btnType="primaryMedium"
				(click)="extenderFechas(calendar)">
			</app-button>
        </div>
    </div>
</div>



<!-- <app-filter-orders [filters]="this.filters">

</app-filter-orders> -->
