<!-- NOTIFICATIONS COMPONENT -->
<div class="container-tabs">
  <!--? NOTIFICATION DROPDOWN -->
  <div class="dropdown-notification">
      <!-- HEADER -->
      <div class="header-notifications">
          <span class="span-title">Notificaciones</span>
          <div class="markers">
              <div class="icon-view"></div>
              <span class="markers-txt" (click)="markRead()">Marcar como leídas</span>
          </div>
      </div>
      <!-- BREADCRUMB -->
      <div class="tabs-notification">

          <!--! TAB 1 ---------------------------------------------------------------------------------->
          <input type="radio" class="tabs-radio-notification" name="tabsNotification" id="notificationTab1"checked>
          <!--? TITLE -->
          <label for="notificationTab1" class="tabs-label txt-tab" (click)="orderSeen()">
              Ordenes
              <div [ngClass]=" {
                   'circle-orders' : tabOrderSeen == true,
                   'none' : tabOrderSeen == false }">
                  {{sizeOrders}}
              </div>

            </label>
            <div class="tabs-content animate__animated animate__fadeIn">
                <!--? ORDERS TAB -->
                <!-- NOTIFICATIONS -->
                <div class="notification-container"
                     *ngFor="let icon of orders | slice: pageOrders : pageOrders + pageOrdersLenght"
                     (click)="openOrder(icon)">
                    <!-- ICONS -->
                    <div class="bg-icon">
                        <!-- SEEN -->
                        <div [ngClass]=" {
                            'seen-notification' : markAllread == true,
                            'none' : markAllread == false
                        }"></div>
                        <!-- ICON -->
                        <div [ngClass]="{
							'icon-box-1':icon.id_topic_audit ===1,
							'icon-box-2':icon.id_topic_audit ===2,
							'icon-box-3':icon.id_topic_audit ===3,
							'icon-box-4':icon.id_topic_audit ===4,
							'icon-box-5':icon.id_topic_audit ===5,
							'icon-box-6':icon.id_topic_audit ===6,
							'icon-box-7':icon.id_topic_audit ===7,
							'icon-box-collected': icon.id_topic_audit == 8,
                            'icon-box-delivered': icon.id_topic_audit === 9,
							'icon-box-10': icon.id_topic_audit === 10,
                            'icon-box-expired': icon.id_topic_audit == 11,
							'icon-box-12':icon.id_topic_audit ===12,
							'icon-box-13':icon.id_topic_audit ===13,
							'icon-box-14':icon.id_topic_audit ===14,
							'icon-box-15':icon.id_topic_audit ===15,
							'icon-box-16':icon.id_topic_audit ===16,
							'icon-box-17':icon.id_topic_audit ===17,
							'icon-box-18':icon.id_topic_audit ===18,
							'icon-box-19':icon.id_topic_audit ===19
                        }">
                        </div>
                    </div>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 1">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido actualizada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 2">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido eliminada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 3">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido registrada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 4">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha logueado en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 5">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha salido en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 6">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> tuvo un error <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 7">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha logueado en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 8">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido recolectada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 9">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido entregada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 10">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha usado el Master Code en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 11">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha expirado en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 12">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido agendada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 13">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido enviada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 14">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido retornada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 15">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha abierto la puerta en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 16">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha cerrado la puerta <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 17">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha tenido un fallo en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 18">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> esta ocupada la puerta en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 19">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha cancelado en el <strong>{{icon.locker_name}}</strong>
                    </span>


                    <div class="space-grid"></div>
                    <span class="time-notification" *ngIf="icon.time_elapsed<180">hace {{icon.time_elapsed}} minutos </span>
                    <span class="time-notification" *ngIf="icon.time_elapsed>180 && icon.time_elapsed < 1440" >hace {{icon.time_elapsed / 60}} horas </span>
					<span class="time-notification" *ngIf="icon.time_elapsed>1440">hace {{((icon.time_elapsed/60)/24) | number:'1.0-0'}} dias </span>
                </div>
                <!-- LOAD MORE -->
                <span class="load-more-span" (click)="loadMoreOrders()" >Cargar más</span>
            </div>

            <!--! TAB 2 ---------------------------------------------------------------------------------->
            <input type="radio" class="tabs-radio-notification" name="tabsNotification" id="notificationTab2">
            <!--? TITLE -->
            <label for="notificationTab2" class="tabs-label txt-tab" (click)="userSeen()">
                Usuarios
                <div [ngClass]=" {
                     'circle-orders' : tabUserSeen == true,
                     'none' : tabUserSeen == false }">
                    {{sizeUser}}
                </div>
            </label>
            <div class="tabs-content animate__animated animate__fadeIn">
                <!--? USERS TAB -->
                <!-- NOTIFICATIONS -->
                <div class="notification-container"
                     *ngFor="let icon of users | slice: pageUsers : pageUsers + pageUsersLenght"
                     (click)="openUser(icon)">
                    <!-- ICONS -->
                    <div class="bg-icon">
                        <!-- SEEN -->
                        <div [ngClass]=" {
                            'seen-notification' : markAllread == true,
                            'none' : markAllread == false
                        }"></div>
                        <!-- ICON -->
                        <div [ngClass]="{
							'icon-user-1':icon.id_topic_audit ===1,
							'icon-user-2':icon.id_topic_audit ===2,
							'icon-user-3':icon.id_topic_audit ===3,
							'icon-user-4':icon.id_topic_audit ===4,
							'icon-user-5':icon.id_topic_audit ===5,
							'icon-user-6':icon.id_topic_audit ===6,
							'icon-user-credentials': icon.id_topic_audit == 7,
							'icon-user-collected': icon.id_topic_audit == 8,
							'icon-user-delivery': icon.id_topic_audit == 9,
                            'icon-user-master-code': icon.id_topic_audit == 10,
							'icon-user-11':icon.id_topic_audit ===11,
							'icon-user-12':icon.id_topic_audit ===12,
							'icon-user-13':icon.id_topic_audit ===13,
							'icon-user-14':icon.id_topic_audit ===14,
							'icon-user-15':icon.id_topic_audit ===15,
							'icon-user-16':icon.id_topic_audit ===16,
							'icon-user-17':icon.id_topic_audit ===17,
							'icon-user-18':icon.id_topic_audit ===18,
							'icon-user-19':icon.id_topic_audit ===19
                        }">
                        </div>
                    </div>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 1">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido actualizada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 2">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido eliminada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 3">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido registrada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 4">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha logueado en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 5">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha salido en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 6">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> tuvo un error <strong>{{icon.locker_name}}</strong>
                    </span>
                    <span class="span-notification" *ngIf="icon.id_topic_audit == 7">
                        El usuario <strong>{{icon.company_employee_id}}</strong> ha ingresado sus credenciales al <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 8">
                        El usuario <strong>{{icon.company_employee_id}}</strong> ha retirado un paquete en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 9">
                        El usuario <strong>{{icon.company_employee_id}}</strong> ha entregado un paquete en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 10">
                        El usuario <strong>{{icon.company_employee_id}}</strong> ha usado su codigo master en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 11">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha expirado en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 12">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido agendada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 13">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido enviada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 14">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido retornada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 15">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha abierto la puerta en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 16">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha cerrado la puerta <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 17">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha tenido un fallo en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 18">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> esta ocupada la puerta en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 19">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha cancelado en el <strong>{{icon.locker_name}}</strong>
                    </span>


                    <div class="space-grid"></div>
					<span class="time-notification" *ngIf="icon.time_elapsed<180">hace {{icon.time_elapsed}} minutos </span>
                    <span class="time-notification" *ngIf="icon.time_elapsed>180 && icon.time_elapsed < 1440" >hace {{icon.time_elapsed / 60}} horas </span>
					<span class="time-notification" *ngIf="icon.time_elapsed>1440">hace {{((icon.time_elapsed/60)/24) | number:'1.0-0'}} dias </span>
                </div>
                <!-- LOAD MORE -->
                <span class="load-more-span" (click)="loadMoreUsers()" >Cargar más</span>
            </div>

            <!--! TAB 3 ---------------------------------------------------------------------------------->
            <input type="radio" class="tabs-radio-notification" name="tabsNotification" id="notificationTab3">
            <!--? TITLE -->
            <label for="notificationTab3" class="tabs-label txt-tab" (click)="allSeen()">
                Todas
                <div [ngClass]=" {
                    'circle-orders' : tabAllSeen == true,
                    'none' : tabAllSeen == false }">
                    {{this.sizeAll}}
                </div>
            </label>
            <div class="tabs-content animate__animated animate__fadeIn">
                <!--? ALL TAB -->
                <!-- NOTIFICATIONS -->
                <!-- NOTIFICATIONS -->
                <div class="notification-container"
                     *ngFor="let icon of all | slice: pageAll : pageAll + pageAllLenght"
                     (click)="openOrder(icon)">
                    <!-- ICONS -->
                    <div class="bg-icon">
                        <!-- SEEN -->
                        <div [ngClass]=" {
                            'seen-notification' : markAllread == true,
                            'none' : markAllread == false
                        }"></div>
                        <!-- ICON -->
                        <div [ngClass]="{
							'icon-box-1':icon.id_topic_audit ===1,
							'icon-box-2':icon.id_topic_audit ===2,
							'icon-box-3':icon.id_topic_audit ===3,
							'icon-box-4':icon.id_topic_audit ===4,
							'icon-box-5':icon.id_topic_audit ===5,
							'icon-box-6':icon.id_topic_audit ===6,
							'icon-user-credentials': icon.id_topic_audit == 7,
                            'icon-box-delivered': icon.id_topic_audit === 9,
                            'icon-box-collected': icon.id_topic_audit == 8,
							'icon-user-collected': icon.id_topic_audit == 8,
							'icon-user-delivery': icon.id_topic_audit == 9,
                            'icon-user-master-code': icon.id_topic_audit == 10,
							'icon-box-expired': icon.id_topic_audit == 11,
							'icon-box-12':icon.id_topic_audit ===12,
							'icon-box-13':icon.id_topic_audit ===13,
							'icon-box-14':icon.id_topic_audit ===14,
							'icon-box-15':icon.id_topic_audit ===15,
							'icon-box-16':icon.id_topic_audit ===16,
							'icon-box-17':icon.id_topic_audit ===17,
							'icon-box-18':icon.id_topic_audit ===18,
							'icon-box-19':icon.id_topic_audit ===19
                          }">
                        </div>
                    </div>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 1">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido actualizada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 2">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido eliminada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 3">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido registrada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 4">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha logueado en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 5">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha salido en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 6">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> tuvo un error <strong>{{icon.locker_name}}</strong>
                    </span>
                    <span class="span-notification" *ngIf="icon.id_topic_audit == 7">
                        El usuario <strong>{{icon.company_employee_id}}</strong> ha ingresado sus credenciales al <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 8">
                        El usuario <strong>{{icon.company_employee_id}}</strong> ha retirado un paquete en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 9">
                        El usuario <strong>{{icon.company_employee_id}}</strong> ha entregado un paquete en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 10">
                        El usuario <strong>{{icon.company_employee_id}}</strong> ha usado su codigo master en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 11">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha expirado en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 12">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido agendada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 13">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido enviada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 14">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha sido retornada en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 15">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha abierto la puerta en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 16">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha cerrado la puerta <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 17">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha tenido un fallo en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit == 18">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> esta ocupada la puerta en el <strong>{{icon.locker_name}}</strong>
                    </span>
					<span class="span-notification" *ngIf="icon.id_topic_audit === 19">
                        La orden con tracking <strong>{{icon.tracking_number}}</strong> ha cancelado en el <strong>{{icon.locker_name}}</strong>
                    </span>

                    <div class="space-grid"></div>
                    <span class="time-notification" *ngIf="icon.time_elapsed<180">hace {{icon.time_elapsed}} minutos </span>
                    <span class="time-notification" *ngIf="icon.time_elapsed>180 && icon.time_elapsed < 1440" >hace {{icon.time_elapsed / 60 | number:'1.0-0'}} horas </span>
					<span class="time-notification" *ngIf="icon.time_elapsed>1440">hace {{((icon.time_elapsed/60)/24) | number:'1.0-0'}} dias </span>
                </div>
                <!-- LOAD MORE -->
                <span class="load-more-span" (click)="loadMoreAll()" >Cargar más</span>
            </div>
        </div>
        <button class="btn-activity" routerLink="log-package"> Ver actividad</button>
    </div>

</div>
